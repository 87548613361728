import debounceFn, { DebouncedFunction } from "debounce-fn";
import { useEffect, useLayoutEffect, useMemo, useRef } from "react";

export function useDebouncedCallback<Args extends unknown[]>(
  fn: (...args: Args) => void,
  wait: number
): DebouncedFunction<Args, void> {
  let callbackRef = useRef<((...args: Args) => void) | null>(null);

  useLayoutEffect(() => {
    callbackRef.current = fn;
  });

  let debouncedFn = useMemo(
    () =>
      debounceFn<Args, void>(
        (...args) => {
          callbackRef.current?.(...args);
        },
        {
          wait,
        }
      ),
    [wait]
  );

  useEffect(() => () => debouncedFn.cancel(), [debouncedFn]);

  return debouncedFn;
}
