import { useCallback } from "react";
import styled from "styled-components/macro";

import { CloseIcon } from "../Close/CloseIcon";

interface BaseSkillProps {
  name: string;
  className?: string;
}

interface SkillProps extends BaseSkillProps {
  onRemove?: (name: string) => void;
}

export function Skill({ name, className, onRemove }: SkillProps) {
  const removeable = !!onRemove;
  const handleRemoveClick = useCallback(() => {
    onRemove?.(name);
  }, [onRemove, name]);

  return (
    <SkillContainer className={className}>
      <Name $removeable={removeable}>{name}</Name>
      {removeable && (
        <>
          <OverlayText>
            <Name>{name}</Name>
          </OverlayText>
          <OverlayRemoveBtn
            type="button"
            onClick={handleRemoveClick}
            title="Remove tag"
          >
            <CloseIcon />
          </OverlayRemoveBtn>
        </>
      )}
    </SkillContainer>
  );
}

interface SelectedSkillProps extends BaseSkillProps {
  onRemove: (skill: string) => void;
}

export function SelectedSkill({
  name,
  className,
  onRemove,
}: SelectedSkillProps) {
  const handleRemoveClick = useCallback(() => {
    onRemove(name);
  }, [onRemove, name]);

  return (
    <SkillContainer className={className}>
      <Name>{name}</Name>
      <RemoveBtn type="button" onClick={handleRemoveClick} title="Remove tag">
        <CloseIcon />
      </RemoveBtn>
    </SkillContainer>
  );
}

interface SkillSwitchProps extends BaseSkillProps {
  onClick?: (skill: string) => void;
}

export function SkillSwitch({ name, className, onClick }: SkillSwitchProps) {
  const handleClick = useCallback(() => {
    onClick?.(name);
  }, [onClick, name]);

  return (
    <SkillBtnContainer
      type="button"
      className={className}
      title={name}
      onClick={handleClick}
    >
      <Name>{name}</Name>
    </SkillBtnContainer>
  );
}

const BaseSkill = styled.div`
  padding: 2px 8px;
  border-radius: 8px;
  white-space: nowrap;
  max-width: 250px;
  box-sizing: border-box;
`;

const Name = styled.span<{ $removeable?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  opacity: ${(p) => (p.$removeable ? 0 : 1)};
`;

const OverlayText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2px 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
`;

const RemoveBtn = styled.button`
  padding: 4px;
  border-radius: 50%;
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
  margin: 0 -4px 0 2px;

  &:focus {
    outline: 0 none;
  }

  &:hover {
    background-color: ${(p) => p.theme.colors.service.hover};
  }

  &:active {
    background-color: ${(p) => p.theme.colors.service.selected};
  }
`;

const OverlayRemoveBtn = styled(RemoveBtn)`
  display: none;
  position: absolute;
  right: 4px;
  top: 4px;
  margin: 0;
`;

const SkillContainer = styled(BaseSkill)`
  position: relative;
  cursor: inherit;
  color: ${(p) => p.theme.colors.text.black};
  background-color: ${(p) => p.theme.colors.service.selected};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;

  &:hover {
    ${RemoveBtn} {
      display: block;
    }

    ${OverlayText} {
      width: calc(100% - 30px);
      padding-right: 0;

      ${Name} {
        text-overflow: clip;
      }
    }
  }
`;

const SkillBtnContainer = styled(BaseSkill.withComponent("button"))`
  border: 0 none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  & > svg {
    flex: 0 0 auto;
  }

  color: ${(p) => p.theme.colors.text.grey};
  background-color: transparent;

  &:hover {
    color: ${(p) => p.theme.colors.text.black};
    background-color: ${(p) => p.theme.colors.service.selected};
  }

  &:focus-visible {
    color: ${(p) => p.theme.colors.text.black};
    background-color: ${(p) => p.theme.colors.service.selected};
  }
`;
