import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components/macro";
import { typographyCSS } from "../theme";
import { Input } from "./Input";

export const Box = styled.div`
  margin-top: 8px;
  padding: 20px 24px;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
`;

export const Hint = styled.div`
  margin-top: 8px;
  color: ${(p) => p.theme.colors.text.grey};
  ${(p) => typographyCSS(p.theme.typo.body2)};
`;

export const ErrorMsg = styled.p`
  margin: 8px 0 0;
  color: ${(p) => p.theme.colors.statuses.error};
`;

export const Title = styled.div`
  ${(p) => typographyCSS(p.theme.typo.highlighted)};
  margin-bottom: 8px;
`;

export const InputRow = styled.div`
  width: 408px;
  margin-bottom: 8px;
`;

export const Row = styled.div`
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FormInput = styled(Input)`
  width: 100%;
`;

export const Section = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ChipList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  cursor: default;
  position: relative;
`;

export const NoBreak = styled.div`
  display: flex;
  align-items: inherit;
  cursor: inherit;
  gap: inherit;
  flex-wrap: nowrap;
`;

export const Buttons = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Textarea = styled(TextareaAutosize)`
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.colors.borders.border};
  border-radius: 8px;
  padding: 8px 12px;
  resize: none;
  background-color: ${(p) => p.theme.colors.backgrounds.main};

  &:hover {
    border-color: ${(p) => p.theme.colors.borders.border};
    background: ${(p) => p.theme.colors.backgrounds.main};
    box-shadow: 0px 0px 0px 3px ${(p) => p.theme.colors.service.focus};
  }

  &:focus {
    outline: 0 none;
    border-color: ${(p) => p.theme.colors.ui.purple};
    background: ${(p) => p.theme.colors.backgrounds.main};
    box-shadow: 0px 0px 0px 3px ${(p) => p.theme.colors.service.focus};
  }
`;
