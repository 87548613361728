import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import styled from "styled-components/macro";
import * as yup from "yup";
import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";
import { useIsEmailFreeLazyQuery } from "../../../graphql";
import { typographyCSS } from "../../../theme";

const SUBMISSION = "__SUBMISSION__";

export interface FormData {
  email: string;
  [SUBMISSION]: void;
}

const schema = yup
  .object({
    email: yup.string().email("Email is incorrect").required("Fill email"),
  })
  .required();

interface EmailStageProps {
  email: string;
  onComplete: (email: string) => void;
}

export function EmailStage({ email, onComplete }: EmailStageProps) {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email,
    },
  });
  const [isEmailFree] = useIsEmailFreeLazyQuery();

  const handleFormSubmit = (data: FormData) => {
    clearErrors(SUBMISSION);

    return isEmailFree({
      variables: {
        email: data.email,
      },
      onCompleted({ isEmailFree }) {
        if (isEmailFree) {
          onComplete(data.email);
        } else {
          setError("email", {
            type: "validate",
            message: "Email is already registered",
          });
          // reset({ email: data.email });
        }
      },
      onError() {
        setError(SUBMISSION, {
          type: "submit",
          message: "Form submission failed. Please, try again later",
        });
      },
    });
  };

  return (
    <Container>
      <h1>Sign up</h1>
      <Description>
        Use your work 👨‍💼 email to make it easier to join or invite your
        colleagues
      </Description>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <InputRow>
          <Input
            icon="mail"
            placeholder="Work email"
            hasError={!!errors.email}
            {...register("email")}
          />
          {errors.email && <ErrorMsg>{errors.email.message}</ErrorMsg>}
        </InputRow>
        <Button $kind="contained" $size="l" $fullWidth disabled={isSubmitting}>
          Sign up with email
        </Button>
        {errors[SUBMISSION] && (
          <ErrorMsg>{errors[SUBMISSION].message}</ErrorMsg>
        )}
        <Info>
          Try Baselance free for 14-days, no credit card required. By signing
          up, you agree to the Baselance{" "}
          <a href="https://baselance.co/terms">Terms of Service</a> and{" "}
          <a href="https://baselance.co/privacy">Privacy Policy</a>. You also
          agree to receive email from Baselance, which you can unsubscribe from
          at any time.
        </Info>
      </form>
    </Container>
  );
}

const Container = styled.div`
  max-width: 400px;
  text-align: center;
`;

const Description = styled.div`
  margin: 8px 0 24px;
`;

const InputRow = styled.div`
  margin-bottom: 16px;

  label {
    width: 100%;
  }
`;

const ErrorMsg = styled.div`
  margin-top: 4px;
  text-align: center;
  color: ${(p) => p.theme.colors.statuses.error};
`;

const Info = styled.div`
  margin-top: 16px;
  ${(p) => typographyCSS(p.theme.typo.body2)};
  color: ${(p) => p.theme.colors.text.grey};
`;
