import styled from "styled-components/macro";
import { BackButton } from "./Overpage/BackButton";

interface NoAccessProps {
  backTo: string;
}

export function NoAccess({ backTo }: NoAccessProps) {
  return (
    <Container>
      <TopPanel>
        <BackButton defaultTo={backTo} />
      </TopPanel>
      <Content>
        <Box>
          <h2>You have no access</h2>
          Ask your administrator to give you access
        </Box>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 2678px;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  padding-bottom: 80px;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
`;

const TopPanel = styled.div`
  height: 56px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const Content = styled.div`
  padding: 24px;
`;

const Box = styled.div`
  margin: 0 auto;
  padding: 20px 24px;
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
  width: 100%;
  max-width: 500px;
`;
