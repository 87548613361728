import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  JSON: any;
  NaiveDate: any;
  ObjectId: any;
  Upload: any;
  Void: any;
};

export enum AccessLevel {
  Admin = 'ADMIN',
  Deleted = 'DELETED',
  Filler = 'FILLER',
  Full = 'FULL',
  Readonly = 'READONLY'
}

export type AddListCandidateInput = {
  listId: Scalars['ID'];
  positionId: Scalars['ID'];
  talentId: Scalars['ID'];
};

export type AddListCandidatePayload = {
  __typename?: 'AddListCandidatePayload';
  list: List;
};

export type AnswerSurveyInput = {
  currentTool: Scalars['String'];
  dbSize: Scalars['String'];
  industry: Scalars['String'];
  role: Scalars['String'];
  teamSize: Scalars['String'];
};

export type AvailabilityRange = {
  __typename?: 'AvailabilityRange';
  createdAt: Scalars['DateTime'];
  endDate: Scalars['NaiveDate'];
  id: Scalars['ObjectId'];
  kind: AvailabilityRangeKind;
  startDate: Scalars['NaiveDate'];
};

export type AvailabilityRangeKind = {
  __typename?: 'AvailabilityRangeKind';
  color: Scalars['String'];
  name: Scalars['String'];
};

export enum CandidateStatus {
  Approved = 'APPROVED',
  Candidate = 'CANDIDATE',
  Rejected = 'REJECTED'
}

/** Freelancer contact card */
export type Card = Node & {
  __typename?: 'Card';
  /** About */
  about: Maybe<Scalars['String']>;
  availability: Array<AvailabilityRange>;
  /** List of categories */
  categories: Maybe<Array<Category>>;
  /** Comment */
  comment: Maybe<Scalars['String']>;
  /** List of contact data */
  contacts: Maybe<Array<Scalars['String']>>;
  /** Date of creation */
  createdAt: Scalars['Date'];
  /** Is talent favorite */
  favorite: Scalars['Boolean'];
  /** The ID of an object */
  id: Scalars['ID'];
  /** Card image data */
  image: Maybe<Image>;
  /** Is card from sample data? */
  isSample: Scalars['Boolean'];
  /** Labels */
  labels: Maybe<Array<CardLabel>>;
  /** Card contact link */
  link: Maybe<Scalars['String']>;
  lists: Array<List>;
  /** Location */
  location: Maybe<Scalars['String']>;
  /** Full name */
  name: Scalars['String'];
  /** Notes */
  notes: Maybe<Scalars['String']>;
  /** Talent skills */
  oldSkills: Array<SkillType>;
  /** Owner of card */
  owner: Maybe<Viewer>;
  /** Price value */
  price: Maybe<Scalars['String']>;
  /** Price comment */
  priceComment: Maybe<Scalars['String']>;
  /** Period of price (i.e. per hour) */
  priceUnit: Maybe<Scalars['String']>;
  /** Average rating */
  rating: Maybe<Scalars['Float']>;
  /** Talent reviews */
  reviews: Maybe<Array<Review>>;
  /** Card sharing token */
  shareToken: Maybe<Scalars['String']>;
  /** Tags */
  skills: Maybe<Array<Scalars['String']>>;
  /** List of works */
  works: Maybe<Array<TalentWork>>;
};

/** Aggregations of card list */
export type CardAggregations = {
  __typename?: 'CardAggregations';
  /** Aggregations for labels */
  labels: Maybe<TermsAggregationResult>;
  /** Aggregations for skills */
  skills: Array<SkillsAggregationResult>;
  /** Aggregations for tags */
  tags: Maybe<TermsAggregationResult>;
  /** Untagged talents count */
  untagged: Scalars['Int'];
};

/** A connection to a list of items. */
export type CardConnection = {
  __typename?: 'CardConnection';
  /** Cards aggregations */
  aggregations: Maybe<CardAggregations>;
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CardEdge>>>;
  /** ID of the list */
  id: Maybe<Scalars['String']>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CardEdge = {
  __typename?: 'CardEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<Card>;
};

export enum CardLabel {
  Blacklisted = 'BLACKLISTED',
  Favorites = 'FAVORITES',
  New = 'NEW',
  Nexttime = 'NEXTTIME'
}

/** Category */
export type Category = Node & {
  __typename?: 'Category';
  /** Count of cards in category */
  cardsCount: Scalars['Int'];
  /** Icon name */
  icon: Scalars['String'];
  /** The ID of an object */
  id: Scalars['ID'];
  /** Name of category */
  name: Scalars['String'];
  /** Owner of category */
  owner: Viewer;
  /** Code for system categories */
  systemCode: Maybe<Scalars['String']>;
};

export type ChangePasswordInput = {
  clientMutationId: InputMaybe<Scalars['String']>;
  newPassword: Scalars['String'];
  password: InputMaybe<Scalars['String']>;
};

export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload';
  clientMutationId: Maybe<Scalars['String']>;
  result: Maybe<Scalars['Boolean']>;
};

export type Chat = {
  __typename?: 'Chat';
  id: Scalars['String'];
  messages: Array<ChatMessage>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  channel: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kind: ChatMessageKind;
  reactions: Maybe<Array<ChatMessageReaction>>;
  replyTo: Maybe<Scalars['ID']>;
  sender: Maybe<User>;
  sentAt: Scalars['DateTime'];
  text: Scalars['String'];
};

export enum ChatMessageKind {
  System = 'SYSTEM',
  User = 'USER'
}

export type ChatMessageReaction = {
  __typename?: 'ChatMessageReaction';
  count: Scalars['Int'];
  emoji: Scalars['String'];
  mine: Scalars['Boolean'];
};

export type CreateAvailabilityRangeInput = {
  endDate: Scalars['NaiveDate'];
  kindColor: Scalars['String'];
  kindName: Scalars['String'];
  startDate: Scalars['NaiveDate'];
  talentId: Scalars['ID'];
};

export type CreateAvailabilityRangePayload = {
  __typename?: 'CreateAvailabilityRangePayload';
  range: AvailabilityRange;
  talentId: Scalars['ObjectId'];
};

export type CreateCardInput = {
  about: InputMaybe<Scalars['String']>;
  categories: Array<Scalars['ID']>;
  clientMutationId: InputMaybe<Scalars['String']>;
  comment: InputMaybe<Scalars['String']>;
  contacts: InputMaybe<Array<Scalars['String']>>;
  image: InputMaybe<Scalars['ID']>;
  imageUrl: InputMaybe<Scalars['String']>;
  labels: InputMaybe<Array<CardLabel>>;
  link: InputMaybe<Scalars['String']>;
  location: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notes: InputMaybe<Scalars['String']>;
  price: InputMaybe<Scalars['String']>;
  priceComment: InputMaybe<Scalars['String']>;
  pricePeriod: InputMaybe<Scalars['String']>;
  source: InputMaybe<Scalars['String']>;
  tags: InputMaybe<Array<Scalars['String']>>;
  works: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateCardPayload = {
  __typename?: 'CreateCardPayload';
  card: Maybe<Card>;
  clientMutationId: Maybe<Scalars['String']>;
  viewer: Maybe<Viewer>;
};

export type CreateCategoryInput = {
  clientMutationId: InputMaybe<Scalars['String']>;
  icon: Scalars['String'];
  name: Scalars['String'];
};

export type CreateCategoryPayload = {
  __typename?: 'CreateCategoryPayload';
  category: Maybe<Category>;
  clientMutationId: Maybe<Scalars['String']>;
  viewer: Maybe<Viewer>;
};

export type CreateImageInput = {
  clientMutationId: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

export type CreateImagePayload = {
  __typename?: 'CreateImagePayload';
  clientMutationId: Maybe<Scalars['String']>;
  image: Maybe<Image>;
};

export type CreateListInput = {
  client: Scalars['String'];
  overview: Scalars['String'];
  positions: Array<PositionInput>;
  team: Array<Scalars['ID']>;
  title: Scalars['String'];
};

export type CreateListPayload = {
  __typename?: 'CreateListPayload';
  list: List;
};

export type CreateReviewInput = {
  clientMutationId: InputMaybe<Scalars['String']>;
  comment: InputMaybe<Scalars['String']>;
  evaluation: Scalars['Int'];
  talentId: Scalars['ID'];
};

export type CreateReviewPayload = {
  __typename?: 'CreateReviewPayload';
  clientMutationId: Maybe<Scalars['String']>;
  review: Review;
  talent: Card;
};

export type CreateWorkInput = {
  cardId: Scalars['ID'];
  clientMutationId: InputMaybe<Scalars['String']>;
  image: InputMaybe<Scalars['ID']>;
  imageUrl: InputMaybe<Scalars['String']>;
};

export type CreateWorkPayload = {
  __typename?: 'CreateWorkPayload';
  clientMutationId: Maybe<Scalars['String']>;
  talent: Card;
  work: TalentWork;
};

export type DeleteAvailabilityRangeInput = {
  rangeId: Scalars['ObjectId'];
};

export type DeleteAvailabilityRangePayload = {
  __typename?: 'DeleteAvailabilityRangePayload';
  range: Maybe<AvailabilityRange>;
};

export type DeleteCardInput = {
  clientMutationId: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteCardPayload = {
  __typename?: 'DeleteCardPayload';
  clientMutationId: Maybe<Scalars['String']>;
  deletedId: Maybe<Scalars['ID']>;
  viewer: Maybe<Viewer>;
};

export type DeleteCategoryInput = {
  clientMutationId: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteCategoryPayload = {
  __typename?: 'DeleteCategoryPayload';
  clientMutationId: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  viewer: Maybe<Viewer>;
};

export type DeleteListInput = {
  listId: Scalars['ID'];
};

export type DeleteListPayload = {
  __typename?: 'DeleteListPayload';
  listId: Scalars['ID'];
};

export type DeleteReviewInput = {
  clientMutationId: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteReviewPayload = {
  __typename?: 'DeleteReviewPayload';
  clientMutationId: Maybe<Scalars['String']>;
  deletedId: Scalars['ID'];
  talent: Card;
};

export type DeleteWorkInput = {
  clientMutationId: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteWorkPayload = {
  __typename?: 'DeleteWorkPayload';
  card: Maybe<Card>;
  clientMutationId: Maybe<Scalars['String']>;
  deletedId: Maybe<Scalars['ID']>;
};

export type EditCategoryInput = {
  clientMutationId: InputMaybe<Scalars['String']>;
  icon: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EditCategoryPayload = {
  __typename?: 'EditCategoryPayload';
  category: Maybe<Category>;
  clientMutationId: Maybe<Scalars['String']>;
  viewer: Maybe<Viewer>;
};

export type EditUserInput = {
  avatar: InputMaybe<Scalars['ID']>;
  clientMutationId: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
};

export type EditUserPayload = {
  __typename?: 'EditUserPayload';
  clientMutationId: Maybe<Scalars['String']>;
  viewer: Maybe<Viewer>;
};

export type Image = Node & {
  __typename?: 'Image';
  avatar: ImageSrc;
  id: Scalars['ID'];
  originalUrl: Scalars['String'];
  /** URL of preview image (height 180) */
  previewH180: Scalars['String'];
  /** URL of preview of original image (cropped if required) */
  previewOriginal: Scalars['String'];
  /** URL of preview image (resized to height 180) */
  resizedH180: Scalars['String'];
  /** URL of thumbnail (94x94) */
  thumb94: Scalars['String'];
  /** Sources of work preview with specified width */
  work: ImageSrc;
};


export type ImageAvatarArgs = {
  size: Scalars['Int'];
};


export type ImageWorkArgs = {
  width: InputMaybe<Scalars['Int']>;
};

export type ImageSrc = {
  __typename?: 'ImageSrc';
  src: Scalars['String'];
  srcSet: Scalars['String'];
};

export type InitAccountAsOrgInput = {
  invitationEmails: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export type Invitation = {
  __typename?: 'Invitation';
  accountName: Scalars['String'];
  token: Scalars['String'];
};

export type JoinByInvitationInput = {
  name: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type List = {
  __typename?: 'List';
  client: Scalars['String'];
  createdAt: Scalars['DateTime'];
  creator: User;
  id: Scalars['ID'];
  overview: Scalars['String'];
  positions: Array<ListPosition>;
  status: ListStatus;
  team: Array<User>;
  title: Scalars['String'];
};

export type ListCandidate = {
  __typename?: 'ListCandidate';
  id: Scalars['ID'];
  status: CandidateStatus;
  talent: Card;
  talentId: Scalars['ID'];
};

export type ListPosition = {
  __typename?: 'ListPosition';
  candidates: Array<ListCandidate>;
  count: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['ID'];
  skills: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

export enum ListStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export enum ListsOwnership {
  All = 'ALL',
  My = 'MY'
}

export type Mutation = {
  __typename?: 'Mutation';
  addListCandidate: AddListCandidatePayload;
  answerSurvey: Scalars['Boolean'];
  changePassword: Maybe<ChangePasswordPayload>;
  createAvailabilityRange: CreateAvailabilityRangePayload;
  /** Create new card */
  createCard: Maybe<CreateCardPayload>;
  createCategory: Maybe<CreateCategoryPayload>;
  createImage: Maybe<CreateImagePayload>;
  createList: CreateListPayload;
  createReview: Maybe<CreateReviewPayload>;
  createWork: Maybe<CreateWorkPayload>;
  deleteAvailabilityRange: DeleteAvailabilityRangePayload;
  deleteCard: Maybe<DeleteCardPayload>;
  deleteCategory: Maybe<DeleteCategoryPayload>;
  deleteList: DeleteListPayload;
  deleteReview: Maybe<DeleteReviewPayload>;
  deleteSampleTalents: Scalars['Void'];
  deleteWork: Maybe<DeleteWorkPayload>;
  editCategory: Maybe<EditCategoryPayload>;
  editUser: Maybe<EditUserPayload>;
  initAccountAsIndividual: Scalars['Void'];
  initAccountAsOrg: Scalars['Void'];
  joinByInvitation: Scalars['Void'];
  postChatMessage: PostChatMessagePayload;
  refetchTalent: Scalars['Void'];
  /** Refresh talent portfolio */
  refreshTalentPortfolio: Maybe<RefreshTalentPortfolioPayload>;
  removeListCandidate: RemoveListCandidatePayload;
  sendInvitations: Scalars['Void'];
  /** Set or unset favorite flag */
  setCardFavorite: Maybe<SetCardFavoritePayload>;
  setCardTags: Maybe<SetCardTagsPayload>;
  setCategoryOrder: Maybe<SetCategoryOrderPayload>;
  setListPositionSkills: SetListPositionSkillsPayload;
  setListStatus: SetListStatusPayload;
  setListTeam: SetListTeamPayload;
  setUserNeedsImport: Scalars['Boolean'];
  /** Update card */
  updateCard: Maybe<UpdateCardPayload>;
  updateList: UpdateListPayload;
  updateListCandidateStatus: UpdateListCandidateStatusPayload;
  uploadImage: Maybe<UploadImagePayload>;
};


export type MutationAddListCandidateArgs = {
  input: AddListCandidateInput;
};


export type MutationAnswerSurveyArgs = {
  input: AnswerSurveyInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationCreateAvailabilityRangeArgs = {
  input: CreateAvailabilityRangeInput;
};


export type MutationCreateCardArgs = {
  input: CreateCardInput;
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationCreateImageArgs = {
  input: CreateImageInput;
};


export type MutationCreateListArgs = {
  input: CreateListInput;
};


export type MutationCreateReviewArgs = {
  input: CreateReviewInput;
};


export type MutationCreateWorkArgs = {
  input: CreateWorkInput;
};


export type MutationDeleteAvailabilityRangeArgs = {
  input: DeleteAvailabilityRangeInput;
};


export type MutationDeleteCardArgs = {
  input: DeleteCardInput;
};


export type MutationDeleteCategoryArgs = {
  input: DeleteCategoryInput;
};


export type MutationDeleteListArgs = {
  input: DeleteListInput;
};


export type MutationDeleteReviewArgs = {
  input: DeleteReviewInput;
};


export type MutationDeleteWorkArgs = {
  input: DeleteWorkInput;
};


export type MutationEditCategoryArgs = {
  input: EditCategoryInput;
};


export type MutationEditUserArgs = {
  input: EditUserInput;
};


export type MutationInitAccountAsOrgArgs = {
  input: InitAccountAsOrgInput;
};


export type MutationJoinByInvitationArgs = {
  input: JoinByInvitationInput;
};


export type MutationPostChatMessageArgs = {
  input: PostChatMessageInput;
};


export type MutationRefetchTalentArgs = {
  force: InputMaybe<Scalars['Boolean']>;
  talentId: Scalars['ID'];
  url: Scalars['String'];
};


export type MutationRefreshTalentPortfolioArgs = {
  input: RefreshTalentPortfolioInput;
};


export type MutationRemoveListCandidateArgs = {
  input: RemoveListCandidateInput;
};


export type MutationSendInvitationsArgs = {
  input: SendInvitationsInput;
};


export type MutationSetCardFavoriteArgs = {
  input: SetCardFavoriteInput;
};


export type MutationSetCardTagsArgs = {
  input: SetCardTagsInput;
};


export type MutationSetCategoryOrderArgs = {
  input: SetCategoryOrderInput;
};


export type MutationSetListPositionSkillsArgs = {
  input: SetListPositionSkillsInput;
};


export type MutationSetListStatusArgs = {
  input: SetListStatusInput;
};


export type MutationSetListTeamArgs = {
  input: SetListTeamInput;
};


export type MutationUpdateCardArgs = {
  input: UpdateCardInput;
};


export type MutationUpdateListArgs = {
  input: UpdateListInput;
};


export type MutationUpdateListCandidateStatusArgs = {
  input: UpdateListCandidateStatusInput;
};


export type MutationUploadImageArgs = {
  input: UploadImageInput;
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>;
};

export type PositionInput = {
  count: Scalars['Int'];
  description: Scalars['String'];
  id: InputMaybe<Scalars['ID']>;
  skills: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

export type PostChatMessageInput = {
  channel: InputMaybe<Scalars['String']>;
  chatId: Scalars['String'];
  replyTo: InputMaybe<Scalars['ID']>;
  text: Scalars['String'];
};

export type PostChatMessagePayload = {
  __typename?: 'PostChatMessagePayload';
  message: ChatMessage;
};

/** Profile source information */
export type ProfileSource = {
  __typename?: 'ProfileSource';
  beta: Scalars['Boolean'];
  id: Scalars['String'];
  providesWorks: Scalars['Boolean'];
  title: Scalars['String'];
};

/** Data ectracted from profile url */
export type ProfileUrlExtractedData = {
  __typename?: 'ProfileUrlExtractedData';
  /** About text */
  about: Maybe<Scalars['String']>;
  /** Avatar URL */
  avatarUrl: Maybe<Scalars['String']>;
  /** List of contact data */
  contacts: Maybe<Array<ProfileUrlOverview>>;
  /** Location */
  location: Maybe<Scalars['String']>;
  /** Full name */
  name: Maybe<Scalars['String']>;
  /** List of parsed skills */
  skills: Maybe<Array<Scalars['String']>>;
};

/** Extracted Profile URL data */
export type ProfileUrlExtraction = {
  __typename?: 'ProfileUrlExtraction';
  duplicate: Scalars['Boolean'];
  extractable: Scalars['Boolean'];
  profile: Maybe<ProfileUrlExtractedData>;
  source: Maybe<ProfileSource>;
  status: Scalars['String'];
  url: Scalars['String'];
};

/** Profile URL overview */
export type ProfileUrlOverview = {
  __typename?: 'ProfileUrlOverview';
  duplicate: Scalars['Boolean'];
  extractable: Scalars['Boolean'];
  source: Maybe<ProfileSource>;
  status: Scalars['String'];
  url: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  availabilityKinds: Array<AvailabilityRangeKind>;
  /** All categories */
  categories: Array<Category>;
  chat: Chat;
  hasSampleTalents: Scalars['Boolean'];
  invitation: Maybe<Invitation>;
  isEmailFree: Scalars['Boolean'];
  list: List;
  lists: Array<List>;
  /** Retreive profile url data */
  profileUrlExtraction: Maybe<ProfileUrlExtraction>;
  /** Retreive profile URLs overview */
  profileUrlsOverview: Array<ProfileUrlOverview>;
  /** All skills */
  skills: Array<SkillType>;
  /** Retreive talent data */
  talent: Maybe<Card>;
  team: Array<User>;
  viewer: Maybe<Viewer>;
};


export type QueryChatArgs = {
  chatId: Scalars['String'];
};


export type QueryInvitationArgs = {
  token: Scalars['String'];
};


export type QueryIsEmailFreeArgs = {
  email: Scalars['String'];
};


export type QueryListArgs = {
  listId: Scalars['ID'];
};


export type QueryListsArgs = {
  ownership: InputMaybe<ListsOwnership>;
  status: InputMaybe<ListStatus>;
};


export type QueryProfileUrlExtractionArgs = {
  url: Scalars['String'];
};


export type QueryProfileUrlsOverviewArgs = {
  urls: Array<Scalars['String']>;
};


export type QueryTalentArgs = {
  id: Scalars['ID'];
};

export type RefreshTalentPortfolioInput = {
  clientMutationId: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  source: Scalars['String'];
};

export type RefreshTalentPortfolioPayload = {
  __typename?: 'RefreshTalentPortfolioPayload';
  card: Maybe<Card>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type RemoveListCandidateInput = {
  listId: Scalars['ID'];
  positionId: Scalars['ID'];
  talentId: Scalars['ID'];
};

export type RemoveListCandidatePayload = {
  __typename?: 'RemoveListCandidatePayload';
  list: List;
};

/** Review */
export type Review = {
  __typename?: 'Review';
  /** Review */
  comment: Maybe<Scalars['String']>;
  /** Date of creation */
  createdAt: Maybe<Scalars['Date']>;
  /** Creator of review */
  creator: Teammate;
  /** Evaluation */
  evaluation: Scalars['Int'];
  /** The ID of an object */
  id: Scalars['ID'];
};

export type SendInvitationsInput = {
  accessLevel: AccessLevel;
  invitationEmails: Array<Scalars['String']>;
};

export type SetCardFavoriteInput = {
  clientMutationId: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  value: Scalars['Boolean'];
};

export type SetCardFavoritePayload = {
  __typename?: 'SetCardFavoritePayload';
  card: Maybe<Card>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type SetCardTagsInput = {
  clientMutationId: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  tags: Array<Scalars['String']>;
};

export type SetCardTagsPayload = {
  __typename?: 'SetCardTagsPayload';
  card: Maybe<Card>;
  clientMutationId: Maybe<Scalars['String']>;
  viewer: Maybe<Viewer>;
};

export type SetCategoryOrderInput = {
  clientMutationId: InputMaybe<Scalars['String']>;
  order: Array<Scalars['ID']>;
};

export type SetCategoryOrderPayload = {
  __typename?: 'SetCategoryOrderPayload';
  categories: Array<Category>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type SetListPositionSkillsInput = {
  listId: Scalars['ID'];
  positionId: Scalars['ID'];
  skills: Array<Scalars['String']>;
};

export type SetListPositionSkillsPayload = {
  __typename?: 'SetListPositionSkillsPayload';
  list: List;
};

export type SetListStatusInput = {
  listId: Scalars['ID'];
  status: ListStatus;
};

export type SetListStatusPayload = {
  __typename?: 'SetListStatusPayload';
  list: List;
};

export type SetListTeamInput = {
  listId: Scalars['ID'];
  team: Array<Scalars['ID']>;
};

export type SetListTeamPayload = {
  __typename?: 'SetListTeamPayload';
  list: List;
};

/** Skill data */
export type SkillType = {
  __typename?: 'SkillType';
  /** Color of skill */
  color: Scalars['String'];
  /** Name of skill */
  name: Scalars['String'];
};

/** Skills aggregation data */
export type SkillsAggregationResult = {
  __typename?: 'SkillsAggregationResult';
  /** Documents count */
  docCount: Scalars['Int'];
  /** Skill data */
  skill: SkillType;
};

export type Subscription = {
  __typename?: 'Subscription';
  chat: ChatMessage;
};


export type SubscriptionChatArgs = {
  chatId: Scalars['String'];
};

export enum SubscriptionPeriod {
  Month = 'MONTH',
  Year = 'YEAR'
}

export enum SubscriptionPlan {
  Personal = 'PERSONAL',
  Superuser = 'SUPERUSER'
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Locked = 'LOCKED',
  None = 'NONE'
}

/** Work */
export type TalentWork = {
  __typename?: 'TalentWork';
  /** Date of creation */
  createdAt: Maybe<Scalars['Date']>;
  /** The ID of an object */
  id: Scalars['ID'];
  /** Uploaded image data */
  image: Maybe<Image>;
  /** URL to work */
  link: Maybe<Scalars['String']>;
  /** Title of work */
  title: Maybe<Scalars['String']>;
};

/** Teammate */
export type Teammate = {
  __typename?: 'Teammate';
  /** The ID of an object */
  id: Scalars['ID'];
  /** Username */
  name: Scalars['String'];
};

/** Bucket date of Terms Aggregation */
export type TermBucket = {
  __typename?: 'TermBucket';
  /** Documents count */
  docCount: Scalars['Int'];
  /** Term value */
  key: Scalars['String'];
};

/** Terms Aggregation data */
export type TermsAggregationResult = {
  __typename?: 'TermsAggregationResult';
  /** Buckets of terms */
  buckets: Maybe<Array<TermBucket>>;
};

export type UpdateCardInput = {
  about: InputMaybe<Scalars['String']>;
  categories: Array<Scalars['ID']>;
  clientMutationId: InputMaybe<Scalars['String']>;
  contacts: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  image: InputMaybe<Scalars['ID']>;
  location: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notes: InputMaybe<Scalars['String']>;
  price: InputMaybe<Scalars['String']>;
  priceComment: InputMaybe<Scalars['String']>;
  pricePeriod: InputMaybe<Scalars['String']>;
  tags: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateCardPayload = {
  __typename?: 'UpdateCardPayload';
  card: Maybe<Card>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type UpdateListCandidateStatusInput = {
  listId: Scalars['ID'];
  positionId: Scalars['ID'];
  status: CandidateStatus;
  talentId: Scalars['ID'];
};

export type UpdateListCandidateStatusPayload = {
  __typename?: 'UpdateListCandidateStatusPayload';
  list: List;
};

export type UpdateListInput = {
  client: Scalars['String'];
  listId: Scalars['ID'];
  overview: Scalars['String'];
  positions: Array<PositionInput>;
  team: Array<Scalars['ID']>;
  title: Scalars['String'];
};

export type UpdateListPayload = {
  __typename?: 'UpdateListPayload';
  list: List;
};

export type UploadImageInput = {
  clientMutationId: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
};

export type UploadImagePayload = {
  __typename?: 'UploadImagePayload';
  clientMutationId: Maybe<Scalars['String']>;
  image: Image;
};

export type User = {
  __typename?: 'User';
  avatar: Maybe<Image>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Aggregated data for user */
export type UserAggregations = {
  __typename?: 'UserAggregations';
  /** All user price periods */
  pricePeriods: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All user tags */
  tags: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** User stats data */
export type UserStats = {
  __typename?: 'UserStats';
  /** Counter for added cards */
  cardCounter: Maybe<Scalars['Int']>;
  /** Counter for cards added in manual */
  manualCardCounter: Maybe<Scalars['Int']>;
  /** Cards counter value */
  onboardingStartValue: Maybe<Scalars['Int']>;
};

/** User subscription data */
export type UserSubscription = {
  __typename?: 'UserSubscription';
  /** Subscription expiration date */
  activeTill: Maybe<Scalars['Date']>;
  /** Subscription was cancelled after failed payment */
  failedPayment: Maybe<Scalars['Boolean']>;
  /** Subscription ID */
  id: Maybe<Scalars['String']>;
  /** Provider related data */
  meta: Maybe<Scalars['JSON']>;
  /** Subscription period */
  period: Maybe<SubscriptionPeriod>;
  /** Subscription plan */
  plan: Maybe<SubscriptionPlan>;
  /** Subscription status */
  status: Maybe<SubscriptionStatus>;
};

/** A person who uses our app */
export type Viewer = Node & {
  __typename?: 'Viewer';
  /** User's access level */
  accessLevel: Scalars['String'];
  /** Kind of account */
  accountKind: Scalars['String'];
  /** User aggregations */
  aggregations: Maybe<UserAggregations>;
  /** User amplitude id */
  amplitudeId: Maybe<Scalars['String']>;
  /** User avatar */
  avatar: Maybe<Image>;
  /** User cards */
  cards: Maybe<CardConnection>;
  /** Count of cards user has created */
  cardsCount: Maybe<Scalars['Int']>;
  /** User categories */
  categories: Array<Category>;
  /** User chatra id */
  chatraId: Maybe<Scalars['String']>;
  /** User email */
  email: Maybe<Scalars['String']>;
  /** Feature flags of viewer */
  features: ViewerFeatures;
  /** Trial mode flag */
  hasEnabledTrial: Scalars['Boolean'];
  /** Does user set password? */
  hasPassword: Maybe<Scalars['Boolean']>;
  /** User heap id */
  heapId: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
  /** User intercom id */
  intercomId: Maybe<Scalars['String']>;
  /** Does user complete survey? */
  isSurveyRequired: Scalars['Boolean'];
  /** User location */
  location: Maybe<Scalars['String']>;
  /** Username */
  name: Scalars['String'];
  /** User Sentry id */
  sentryId: Maybe<Scalars['String']>;
  /** Is curresnt session shadowed */
  shadowedSession: Maybe<Scalars['Boolean']>;
  /** User stats */
  stats: Maybe<UserStats>;
  /** User subscription data */
  subscription: Maybe<UserSubscription>;
};


/** A person who uses our app */
export type ViewerCardsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  category: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  labels: InputMaybe<Array<InputMaybe<CardLabel>>>;
  last: InputMaybe<Scalars['Int']>;
  listId: InputMaybe<Scalars['String']>;
  location: InputMaybe<Scalars['String']>;
  search: InputMaybe<Scalars['String']>;
  tags: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  untagged: InputMaybe<Scalars['Boolean']>;
};

/** ViewerFeatures */
export type ViewerFeatures = {
  __typename?: 'ViewerFeatures';
  /** Basic app feature flag */
  app: Scalars['Boolean'];
  /** Lists feature flag */
  lists: Scalars['Boolean'];
};

export type AddListCandidateMutationVariables = Exact<{
  input: AddListCandidateInput;
}>;


export type AddListCandidateMutation = { __typename?: 'Mutation', addListCandidate: { __typename?: 'AddListCandidatePayload', list: { __typename?: 'List', id: string, positions: Array<{ __typename?: 'ListPosition', id: string, candidates: Array<{ __typename?: 'ListCandidate', id: string, talentId: string, status: CandidateStatus }> }> } } };

export type AnswerSurveyMutationVariables = Exact<{
  input: AnswerSurveyInput;
}>;


export type AnswerSurveyMutation = { __typename?: 'Mutation', answerSurvey: boolean };

export type AuthQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', id: string, email: string | null, name: string, hasPassword: boolean | null, shadowedSession: boolean | null, chatraId: string | null, intercomId: string | null, heapId: string | null, sentryId: string | null, accessLevel: string, location: string | null, isSurveyRequired: boolean, hasEnabledTrial: boolean, accountKind: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null, features: { __typename?: 'ViewerFeatures', app: boolean, lists: boolean }, subscription: { __typename?: 'UserSubscription', status: SubscriptionStatus | null } | null } | null };

export type AvailabilityKindsQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailabilityKindsQuery = { __typename?: 'Query', availabilityKinds: Array<{ __typename?: 'AvailabilityRangeKind', name: string, color: string }> };

export type CardListQueryVariables = Exact<{
  first: Scalars['Int'];
  after: InputMaybe<Scalars['String']>;
  category: InputMaybe<Scalars['ID']>;
  labels: InputMaybe<Array<InputMaybe<CardLabel>> | InputMaybe<CardLabel>>;
  tags: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  untagged: InputMaybe<Scalars['Boolean']>;
  query: InputMaybe<Scalars['String']>;
}>;


export type CardListQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', id: string, cards: { __typename?: 'CardConnection', edges: Array<{ __typename?: 'CardEdge', card: { __typename?: 'Card', id: string, name: string, location: string | null, price: string | null, priceUnit: string | null, about: string | null, notes: string | null, favorite: boolean, skills: Array<string> | null, contacts: Array<string> | null, rating: number | null, isSample: boolean, image: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null, works: Array<{ __typename?: 'TalentWork', id: string, title: string | null, link: string | null, image: { __typename?: 'Image', id: string, originalUrl: string, previewOriginal: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor: string | null }, aggregations: { __typename?: 'CardAggregations', untagged: number, tags: { __typename?: 'TermsAggregationResult', buckets: Array<{ __typename?: 'TermBucket', key: string, docCount: number }> | null } | null, skills: Array<{ __typename?: 'SkillsAggregationResult', docCount: number, skill: { __typename?: 'SkillType', name: string, color: string } }>, labels: { __typename?: 'TermsAggregationResult', buckets: Array<{ __typename?: 'TermBucket', key: string, docCount: number }> | null } | null } | null } | null } | null };

export type CategoriesListQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesListQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', id: string, name: string, icon: string, cardsCount: number, systemCode: string | null }> };

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', id: string, name: string, icon: string, systemCode: string | null }> };

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'ChangePasswordPayload', result: boolean | null } | null };

export type ChatQueryVariables = Exact<{
  chatId: Scalars['String'];
}>;


export type ChatQuery = { __typename?: 'Query', chat: { __typename?: 'Chat', id: string, messages: Array<{ __typename?: 'ChatMessage', id: string, kind: ChatMessageKind, text: string, replyTo: string | null, channel: string | null, sentAt: any, reactions: Array<{ __typename?: 'ChatMessageReaction', emoji: string, count: number, mine: boolean }> | null, sender: { __typename?: 'User', name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null } | null }> } };

export type CreateAvailabilityRangeMutationVariables = Exact<{
  input: CreateAvailabilityRangeInput;
}>;


export type CreateAvailabilityRangeMutation = { __typename?: 'Mutation', createAvailabilityRange: { __typename?: 'CreateAvailabilityRangePayload', talentId: any, range: { __typename?: 'AvailabilityRange', id: any, startDate: any, endDate: any, createdAt: any, kind: { __typename?: 'AvailabilityRangeKind', name: string, color: string } } } };

export type CreateAvatarMutationVariables = Exact<{
  url: Scalars['String'];
}>;


export type CreateAvatarMutation = { __typename?: 'Mutation', createImage: { __typename?: 'CreateImagePayload', image: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null } | null };

export type CreateCategoryMutationVariables = Exact<{
  name: Scalars['String'];
  icon: Scalars['String'];
}>;


export type CreateCategoryMutation = { __typename?: 'Mutation', createCategory: { __typename?: 'CreateCategoryPayload', category: { __typename?: 'Category', id: string, name: string, icon: string, systemCode: string | null } | null } | null };

export type CreateListMutationVariables = Exact<{
  input: CreateListInput;
}>;


export type CreateListMutation = { __typename?: 'Mutation', createList: { __typename?: 'CreateListPayload', list: { __typename?: 'List', id: string, title: string, client: string, overview: string, status: ListStatus, createdAt: any, positions: Array<{ __typename?: 'ListPosition', id: string, title: string, description: string, count: number, skills: Array<string> | null, candidates: Array<{ __typename?: 'ListCandidate', id: string, talentId: string, status: CandidateStatus, talent: { __typename?: 'Card', id: string, name: string, location: string | null, price: string | null, priceUnit: string | null, about: string | null, notes: string | null, favorite: boolean, skills: Array<string> | null, contacts: Array<string> | null, rating: number | null, isSample: boolean, image: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null, works: Array<{ __typename?: 'TalentWork', id: string, title: string | null, link: string | null, image: { __typename?: 'Image', id: string, originalUrl: string, previewOriginal: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> | null } }> }>, creator: { __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }, team: Array<{ __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> } } };

export type CreateReviewMutationVariables = Exact<{
  input: CreateReviewInput;
}>;


export type CreateReviewMutation = { __typename?: 'Mutation', createReview: { __typename?: 'CreateReviewPayload', talent: { __typename?: 'Card', id: string, rating: number | null, reviews: Array<{ __typename?: 'Review', id: string, comment: string | null, evaluation: number, createdAt: any | null, creator: { __typename?: 'Teammate', id: string, name: string } }> | null } } | null };

export type CreateTalentMutationVariables = Exact<{
  input: CreateCardInput;
}>;


export type CreateTalentMutation = { __typename?: 'Mutation', createCard: { __typename?: 'CreateCardPayload', card: { __typename?: 'Card', id: string, name: string, location: string | null, price: string | null, priceUnit: string | null, priceComment: string | null, favorite: boolean, contacts: Array<string> | null, rating: number | null, skills: Array<string> | null, about: string | null, notes: string | null, createdAt: any, reviews: Array<{ __typename?: 'Review', id: string, comment: string | null, evaluation: number, createdAt: any | null, creator: { __typename?: 'Teammate', id: string, name: string } }> | null, works: Array<{ __typename?: 'TalentWork', id: string, link: string | null, image: { __typename?: 'Image', id: string, originalUrl: string, previewOriginal: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> | null, categories: Array<{ __typename?: 'Category', id: string, name: string, icon: string }> | null, image: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null, lists: Array<{ __typename?: 'List', id: string, title: string }>, availability: Array<{ __typename?: 'AvailabilityRange', id: any, startDate: any, endDate: any, createdAt: any, kind: { __typename?: 'AvailabilityRangeKind', name: string, color: string } }> } | null } | null };

export type CreateWorkMutationVariables = Exact<{
  input: CreateWorkInput;
}>;


export type CreateWorkMutation = { __typename?: 'Mutation', createWork: { __typename?: 'CreateWorkPayload', talent: { __typename?: 'Card', id: string, works: Array<{ __typename?: 'TalentWork', id: string, link: string | null, image: { __typename?: 'Image', id: string, originalUrl: string, previewOriginal: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> | null } } | null };

export type DeleteAvailabilityRangeMutationVariables = Exact<{
  input: DeleteAvailabilityRangeInput;
}>;


export type DeleteAvailabilityRangeMutation = { __typename?: 'Mutation', deleteAvailabilityRange: { __typename?: 'DeleteAvailabilityRangePayload', range: { __typename?: 'AvailabilityRange', id: any } | null } };

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCategoryMutation = { __typename?: 'Mutation', deleteCategory: { __typename?: 'DeleteCategoryPayload', id: string | null } | null };

export type DeleteListMutationVariables = Exact<{
  input: DeleteListInput;
}>;


export type DeleteListMutation = { __typename?: 'Mutation', deleteList: { __typename?: 'DeleteListPayload', listId: string } };

export type DeleteReviewMutationVariables = Exact<{
  input: DeleteReviewInput;
}>;


export type DeleteReviewMutation = { __typename?: 'Mutation', deleteReview: { __typename?: 'DeleteReviewPayload', deletedId: string, talent: { __typename?: 'Card', id: string, rating: number | null, reviews: Array<{ __typename?: 'Review', id: string, comment: string | null, evaluation: number, createdAt: any | null, creator: { __typename?: 'Teammate', id: string, name: string } }> | null } } | null };

export type DeleteSampleTalentsMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteSampleTalentsMutation = { __typename?: 'Mutation', deleteSampleTalents: any };

export type DeleteTalentMutationVariables = Exact<{
  input: DeleteCardInput;
}>;


export type DeleteTalentMutation = { __typename?: 'Mutation', deleteCard: { __typename?: 'DeleteCardPayload', deletedId: string | null } | null };

export type DeleteWorkMutationVariables = Exact<{
  input: DeleteWorkInput;
}>;


export type DeleteWorkMutation = { __typename?: 'Mutation', deleteWork: { __typename?: 'DeleteWorkPayload', deletedId: string | null } | null };

export type EditCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  icon: Scalars['String'];
}>;


export type EditCategoryMutation = { __typename?: 'Mutation', editCategory: { __typename?: 'EditCategoryPayload', category: { __typename?: 'Category', id: string, name: string, icon: string, cardsCount: number, systemCode: string | null } | null } | null };

export type EditProfileMutationVariables = Exact<{
  input: EditUserInput;
}>;


export type EditProfileMutation = { __typename?: 'Mutation', editUser: { __typename?: 'EditUserPayload', viewer: { __typename?: 'Viewer', id: string, name: string, email: string | null, location: string | null, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null } | null } | null };

export type HasSampleTalentsQueryVariables = Exact<{ [key: string]: never; }>;


export type HasSampleTalentsQuery = { __typename?: 'Query', hasSampleTalents: boolean };

export type InitAccountAsIndividualMutationVariables = Exact<{ [key: string]: never; }>;


export type InitAccountAsIndividualMutation = { __typename?: 'Mutation', initAccountAsIndividual: any };

export type InitAccountAsOrgMutationVariables = Exact<{
  input: InitAccountAsOrgInput;
}>;


export type InitAccountAsOrgMutation = { __typename?: 'Mutation', initAccountAsOrg: any };

export type InvitationQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type InvitationQuery = { __typename?: 'Query', invitation: { __typename?: 'Invitation', token: string, accountName: string } | null };

export type IsEmailFreeQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type IsEmailFreeQuery = { __typename?: 'Query', isEmailFree: boolean };

export type JoinMutationVariables = Exact<{
  input: JoinByInvitationInput;
}>;


export type JoinMutation = { __typename?: 'Mutation', joinByInvitation: any };

export type ListDetailsQueryVariables = Exact<{
  listId: Scalars['ID'];
}>;


export type ListDetailsQuery = { __typename?: 'Query', list: { __typename?: 'List', id: string, title: string, client: string, overview: string, status: ListStatus, createdAt: any, positions: Array<{ __typename?: 'ListPosition', id: string, title: string, description: string, count: number, skills: Array<string> | null, candidates: Array<{ __typename?: 'ListCandidate', id: string, talentId: string, status: CandidateStatus, talent: { __typename?: 'Card', id: string, name: string, location: string | null, price: string | null, priceUnit: string | null, about: string | null, notes: string | null, favorite: boolean, skills: Array<string> | null, contacts: Array<string> | null, rating: number | null, isSample: boolean, image: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null, works: Array<{ __typename?: 'TalentWork', id: string, title: string | null, link: string | null, image: { __typename?: 'Image', id: string, originalUrl: string, previewOriginal: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> | null } }> }>, creator: { __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }, team: Array<{ __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> } };

export type ListsQueryVariables = Exact<{
  ownership: InputMaybe<ListsOwnership>;
  status: InputMaybe<ListStatus>;
}>;


export type ListsQuery = { __typename?: 'Query', lists: Array<{ __typename?: 'List', id: string, title: string, client: string, status: ListStatus, createdAt: any, creator: { __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }, positions: Array<{ __typename?: 'ListPosition', id: string, title: string, description: string, count: number, candidates: Array<{ __typename?: 'ListCandidate', id: string, talentId: string, status: CandidateStatus }> }> }> };

export type PostChatMessageMutationVariables = Exact<{
  input: PostChatMessageInput;
}>;


export type PostChatMessageMutation = { __typename?: 'Mutation', postChatMessage: { __typename?: 'PostChatMessagePayload', message: { __typename?: 'ChatMessage', id: string, kind: ChatMessageKind, text: string, replyTo: string | null, channel: string | null, sentAt: any, reactions: Array<{ __typename?: 'ChatMessageReaction', emoji: string, count: number, mine: boolean }> | null, sender: { __typename?: 'User', name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null } | null } } };

export type ProfileUrlExtractionQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type ProfileUrlExtractionQuery = { __typename?: 'Query', profileUrlExtraction: { __typename?: 'ProfileUrlExtraction', url: string, duplicate: boolean, extractable: boolean, status: string, source: { __typename?: 'ProfileSource', id: string, title: string, beta: boolean, providesWorks: boolean } | null, profile: { __typename?: 'ProfileUrlExtractedData', name: string | null, location: string | null, avatarUrl: string | null, about: string | null, skills: Array<string> | null, contacts: Array<{ __typename?: 'ProfileUrlOverview', url: string, duplicate: boolean, extractable: boolean, status: string, source: { __typename?: 'ProfileSource', id: string, title: string, beta: boolean, providesWorks: boolean } | null }> | null } | null } | null };

export type RemoveListCandidateMutationVariables = Exact<{
  input: RemoveListCandidateInput;
}>;


export type RemoveListCandidateMutation = { __typename?: 'Mutation', removeListCandidate: { __typename?: 'RemoveListCandidatePayload', list: { __typename?: 'List', id: string, title: string, client: string, overview: string, status: ListStatus, createdAt: any, positions: Array<{ __typename?: 'ListPosition', id: string, title: string, description: string, count: number, skills: Array<string> | null, candidates: Array<{ __typename?: 'ListCandidate', id: string, talentId: string, status: CandidateStatus, talent: { __typename?: 'Card', id: string, name: string, location: string | null, price: string | null, priceUnit: string | null, about: string | null, notes: string | null, favorite: boolean, skills: Array<string> | null, contacts: Array<string> | null, rating: number | null, isSample: boolean, image: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null, works: Array<{ __typename?: 'TalentWork', id: string, title: string | null, link: string | null, image: { __typename?: 'Image', id: string, originalUrl: string, previewOriginal: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> | null } }> }>, creator: { __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }, team: Array<{ __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> } } };

export type SetCategoryOrderMutationVariables = Exact<{
  input: SetCategoryOrderInput;
}>;


export type SetCategoryOrderMutation = { __typename?: 'Mutation', setCategoryOrder: { __typename?: 'SetCategoryOrderPayload', categories: Array<{ __typename?: 'Category', id: string, name: string, icon: string, cardsCount: number, systemCode: string | null }> } | null };

export type SetListPositionSkillsMutationVariables = Exact<{
  input: SetListPositionSkillsInput;
}>;


export type SetListPositionSkillsMutation = { __typename?: 'Mutation', setListPositionSkills: { __typename?: 'SetListPositionSkillsPayload', list: { __typename?: 'List', id: string, title: string, client: string, overview: string, status: ListStatus, createdAt: any, positions: Array<{ __typename?: 'ListPosition', id: string, title: string, description: string, count: number, skills: Array<string> | null, candidates: Array<{ __typename?: 'ListCandidate', id: string, talentId: string, status: CandidateStatus, talent: { __typename?: 'Card', id: string, name: string, location: string | null, price: string | null, priceUnit: string | null, about: string | null, notes: string | null, favorite: boolean, skills: Array<string> | null, contacts: Array<string> | null, rating: number | null, isSample: boolean, image: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null, works: Array<{ __typename?: 'TalentWork', id: string, title: string | null, link: string | null, image: { __typename?: 'Image', id: string, originalUrl: string, previewOriginal: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> | null } }> }>, creator: { __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }, team: Array<{ __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> } } };

export type SetListStatusMutationVariables = Exact<{
  input: SetListStatusInput;
}>;


export type SetListStatusMutation = { __typename?: 'Mutation', setListStatus: { __typename?: 'SetListStatusPayload', list: { __typename?: 'List', id: string, status: ListStatus } } };

export type SetListTeamMutationVariables = Exact<{
  input: SetListTeamInput;
}>;


export type SetListTeamMutation = { __typename?: 'Mutation', setListTeam: { __typename?: 'SetListTeamPayload', list: { __typename?: 'List', id: string, team: Array<{ __typename?: 'User', id: string, name: string }> } } };

export type SetCardFavoriteMutationVariables = Exact<{
  input: SetCardFavoriteInput;
}>;


export type SetCardFavoriteMutation = { __typename?: 'Mutation', setCardFavorite: { __typename?: 'SetCardFavoritePayload', card: { __typename?: 'Card', id: string, favorite: boolean } | null } | null };

export type SetTalentTagsMutationVariables = Exact<{
  input: SetCardTagsInput;
}>;


export type SetTalentTagsMutation = { __typename?: 'Mutation', setCardTags: { __typename?: 'SetCardTagsPayload', card: { __typename?: 'Card', id: string, skills: Array<string> | null } | null } | null };

export type SetUserNeedsImportMutationVariables = Exact<{ [key: string]: never; }>;


export type SetUserNeedsImportMutation = { __typename?: 'Mutation', setUserNeedsImport: boolean };

export type SkillsQueryVariables = Exact<{ [key: string]: never; }>;


export type SkillsQuery = { __typename?: 'Query', skills: Array<{ __typename?: 'SkillType', name: string, color: string }> };

export type TalentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TalentQuery = { __typename?: 'Query', talent: { __typename?: 'Card', id: string, name: string, location: string | null, price: string | null, priceUnit: string | null, priceComment: string | null, favorite: boolean, contacts: Array<string> | null, rating: number | null, skills: Array<string> | null, about: string | null, notes: string | null, createdAt: any, reviews: Array<{ __typename?: 'Review', id: string, comment: string | null, evaluation: number, createdAt: any | null, creator: { __typename?: 'Teammate', id: string, name: string } }> | null, works: Array<{ __typename?: 'TalentWork', id: string, link: string | null, image: { __typename?: 'Image', id: string, originalUrl: string, previewOriginal: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> | null, categories: Array<{ __typename?: 'Category', id: string, name: string, icon: string }> | null, image: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null, lists: Array<{ __typename?: 'List', id: string, title: string }>, availability: Array<{ __typename?: 'AvailabilityRange', id: any, startDate: any, endDate: any, createdAt: any, kind: { __typename?: 'AvailabilityRangeKind', name: string, color: string } }> } | null };

export type TeamQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamQuery = { __typename?: 'Query', team: Array<{ __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> };

export type UpdateListCandidateStatusMutationVariables = Exact<{
  input: UpdateListCandidateStatusInput;
}>;


export type UpdateListCandidateStatusMutation = { __typename?: 'Mutation', updateListCandidateStatus: { __typename?: 'UpdateListCandidateStatusPayload', list: { __typename?: 'List', id: string, title: string, client: string, overview: string, status: ListStatus, createdAt: any, positions: Array<{ __typename?: 'ListPosition', id: string, title: string, description: string, count: number, skills: Array<string> | null, candidates: Array<{ __typename?: 'ListCandidate', id: string, talentId: string, status: CandidateStatus, talent: { __typename?: 'Card', id: string, name: string, location: string | null, price: string | null, priceUnit: string | null, about: string | null, notes: string | null, favorite: boolean, skills: Array<string> | null, contacts: Array<string> | null, rating: number | null, isSample: boolean, image: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null, works: Array<{ __typename?: 'TalentWork', id: string, title: string | null, link: string | null, image: { __typename?: 'Image', id: string, originalUrl: string, previewOriginal: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> | null } }> }>, creator: { __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }, team: Array<{ __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> } } };

export type UpdateListMutationVariables = Exact<{
  input: UpdateListInput;
}>;


export type UpdateListMutation = { __typename?: 'Mutation', updateList: { __typename?: 'UpdateListPayload', list: { __typename?: 'List', id: string, title: string, client: string, overview: string, status: ListStatus, createdAt: any, positions: Array<{ __typename?: 'ListPosition', id: string, title: string, description: string, count: number, skills: Array<string> | null, candidates: Array<{ __typename?: 'ListCandidate', id: string, talentId: string, status: CandidateStatus, talent: { __typename?: 'Card', id: string, name: string, location: string | null, price: string | null, priceUnit: string | null, about: string | null, notes: string | null, favorite: boolean, skills: Array<string> | null, contacts: Array<string> | null, rating: number | null, isSample: boolean, image: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null, works: Array<{ __typename?: 'TalentWork', id: string, title: string | null, link: string | null, image: { __typename?: 'Image', id: string, originalUrl: string, previewOriginal: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> | null } }> }>, creator: { __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }, team: Array<{ __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> } } };

export type UpdateTalentMutationVariables = Exact<{
  input: UpdateCardInput;
}>;


export type UpdateTalentMutation = { __typename?: 'Mutation', updateCard: { __typename?: 'UpdateCardPayload', card: { __typename?: 'Card', id: string, name: string, location: string | null, price: string | null, priceUnit: string | null, priceComment: string | null, favorite: boolean, contacts: Array<string> | null, rating: number | null, skills: Array<string> | null, about: string | null, notes: string | null, createdAt: any, reviews: Array<{ __typename?: 'Review', id: string, comment: string | null, evaluation: number, createdAt: any | null, creator: { __typename?: 'Teammate', id: string, name: string } }> | null, works: Array<{ __typename?: 'TalentWork', id: string, link: string | null, image: { __typename?: 'Image', id: string, originalUrl: string, previewOriginal: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> | null, categories: Array<{ __typename?: 'Category', id: string, name: string, icon: string }> | null, image: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null, lists: Array<{ __typename?: 'List', id: string, title: string }>, availability: Array<{ __typename?: 'AvailabilityRange', id: any, startDate: any, endDate: any, createdAt: any, kind: { __typename?: 'AvailabilityRangeKind', name: string, color: string } }> } | null } | null };

export type UploadAvatarMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadAvatarMutation = { __typename?: 'Mutation', uploadImage: { __typename?: 'UploadImagePayload', image: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } } | null };

export type UploadWorkMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadWorkMutation = { __typename?: 'Mutation', uploadImage: { __typename?: 'UploadImagePayload', image: { __typename?: 'Image', id: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } } | null };

export type AvailabilityRangeFragmentFragment = { __typename?: 'AvailabilityRange', id: any, startDate: any, endDate: any, createdAt: any, kind: { __typename?: 'AvailabilityRangeKind', name: string, color: string } };

export type CategoryListItemFragment = { __typename?: 'Category', id: string, name: string, icon: string, cardsCount: number, systemCode: string | null };

export type ChatMessageFragment = { __typename?: 'ChatMessage', id: string, kind: ChatMessageKind, text: string, replyTo: string | null, channel: string | null, sentAt: any, reactions: Array<{ __typename?: 'ChatMessageReaction', emoji: string, count: number, mine: boolean }> | null, sender: { __typename?: 'User', name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null } | null };

export type ListDetailsFragment = { __typename?: 'List', id: string, title: string, client: string, overview: string, status: ListStatus, createdAt: any, positions: Array<{ __typename?: 'ListPosition', id: string, title: string, description: string, count: number, skills: Array<string> | null, candidates: Array<{ __typename?: 'ListCandidate', id: string, talentId: string, status: CandidateStatus, talent: { __typename?: 'Card', id: string, name: string, location: string | null, price: string | null, priceUnit: string | null, about: string | null, notes: string | null, favorite: boolean, skills: Array<string> | null, contacts: Array<string> | null, rating: number | null, isSample: boolean, image: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null, works: Array<{ __typename?: 'TalentWork', id: string, title: string | null, link: string | null, image: { __typename?: 'Image', id: string, originalUrl: string, previewOriginal: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> | null } }> }>, creator: { __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }, team: Array<{ __typename?: 'User', id: string, name: string, avatar: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> };

export type ReviewFragmentFragment = { __typename?: 'Review', id: string, comment: string | null, evaluation: number, createdAt: any | null, creator: { __typename?: 'Teammate', id: string, name: string } };

export type TalentDetailsFragment = { __typename?: 'Card', id: string, name: string, location: string | null, price: string | null, priceUnit: string | null, priceComment: string | null, favorite: boolean, contacts: Array<string> | null, rating: number | null, skills: Array<string> | null, about: string | null, notes: string | null, createdAt: any, reviews: Array<{ __typename?: 'Review', id: string, comment: string | null, evaluation: number, createdAt: any | null, creator: { __typename?: 'Teammate', id: string, name: string } }> | null, works: Array<{ __typename?: 'TalentWork', id: string, link: string | null, image: { __typename?: 'Image', id: string, originalUrl: string, previewOriginal: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> | null, categories: Array<{ __typename?: 'Category', id: string, name: string, icon: string }> | null, image: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null, lists: Array<{ __typename?: 'List', id: string, title: string }>, availability: Array<{ __typename?: 'AvailabilityRange', id: any, startDate: any, endDate: any, createdAt: any, kind: { __typename?: 'AvailabilityRangeKind', name: string, color: string } }> };

export type TalentPreviewFragmentFragment = { __typename?: 'Card', id: string, name: string, location: string | null, price: string | null, priceUnit: string | null, about: string | null, notes: string | null, favorite: boolean, skills: Array<string> | null, contacts: Array<string> | null, rating: number | null, isSample: boolean, image: { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null, works: Array<{ __typename?: 'TalentWork', id: string, title: string | null, link: string | null, image: { __typename?: 'Image', id: string, originalUrl: string, previewOriginal: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null }> | null };

export type UserAvatarFragmentFragment = { __typename?: 'Image', id: string, avatar48: { __typename?: 'ImageSrc', src: string, srcSet: string }, avatar128: { __typename?: 'ImageSrc', src: string, srcSet: string } };

export type WorkDetailsFragment = { __typename?: 'TalentWork', id: string, link: string | null, image: { __typename?: 'Image', id: string, originalUrl: string, previewOriginal: string, preview200: { __typename?: 'ImageSrc', src: string, srcSet: string }, preview400: { __typename?: 'ImageSrc', src: string, srcSet: string } } | null };

export const CategoryListItemFragmentDoc = gql`
    fragment CategoryListItem on Category {
  id
  name
  icon
  cardsCount
  systemCode
}
    `;
export const UserAvatarFragmentFragmentDoc = gql`
    fragment UserAvatarFragment on Image {
  id
  avatar48: avatar(size: 48) {
    src
    srcSet
  }
  avatar128: avatar(size: 128) {
    src
    srcSet
  }
}
    `;
export const ChatMessageFragmentDoc = gql`
    fragment ChatMessage on ChatMessage {
  id
  kind
  text
  replyTo
  channel
  reactions {
    emoji
    count
    mine
  }
  sender {
    name
    avatar {
      ...UserAvatarFragment
    }
  }
  sentAt
}
    ${UserAvatarFragmentFragmentDoc}`;
export const TalentPreviewFragmentFragmentDoc = gql`
    fragment TalentPreviewFragment on Card {
  id
  name
  location
  price
  priceUnit
  about
  notes
  favorite
  skills
  contacts
  rating
  isSample
  image {
    id
    avatar48: avatar(size: 48) {
      src
      srcSet
    }
    avatar128: avatar(size: 128) {
      src
      srcSet
    }
  }
  works {
    id
    title
    link
    image {
      id
      originalUrl
      previewOriginal
      preview200: work(width: 200) {
        src
        srcSet
      }
      preview400: work(width: 400) {
        src
        srcSet
      }
    }
  }
}
    `;
export const ListDetailsFragmentDoc = gql`
    fragment ListDetails on List {
  id
  title
  client
  overview
  status
  positions {
    id
    title
    description
    count
    skills
    candidates {
      id
      talentId
      talent {
        ...TalentPreviewFragment
      }
      status
    }
  }
  createdAt
  creator {
    id
    name
    avatar {
      ...UserAvatarFragment
    }
  }
  team {
    id
    name
    avatar {
      ...UserAvatarFragment
    }
  }
}
    ${TalentPreviewFragmentFragmentDoc}
${UserAvatarFragmentFragmentDoc}`;
export const ReviewFragmentFragmentDoc = gql`
    fragment ReviewFragment on Review {
  id
  creator {
    id
    name
  }
  comment
  evaluation
  createdAt
}
    `;
export const WorkDetailsFragmentDoc = gql`
    fragment WorkDetails on TalentWork {
  id
  link
  image {
    id
    originalUrl
    previewOriginal
    preview200: work(width: 200) {
      src
      srcSet
    }
    preview400: work(width: 400) {
      src
      srcSet
    }
  }
}
    `;
export const AvailabilityRangeFragmentFragmentDoc = gql`
    fragment AvailabilityRangeFragment on AvailabilityRange {
  id
  startDate
  endDate
  createdAt
  kind {
    name
    color
  }
}
    `;
export const TalentDetailsFragmentDoc = gql`
    fragment TalentDetails on Card {
  id
  name
  location
  price
  priceUnit
  priceComment
  favorite
  contacts
  rating
  reviews {
    ...ReviewFragment
  }
  skills
  about
  notes
  createdAt
  works {
    ...WorkDetails
  }
  categories {
    id
    name
    icon
  }
  image {
    id
    avatar48: avatar(size: 48) {
      src
      srcSet
    }
    avatar128: avatar(size: 128) {
      src
      srcSet
    }
  }
  lists {
    id
    title
  }
  availability {
    ...AvailabilityRangeFragment
  }
}
    ${ReviewFragmentFragmentDoc}
${WorkDetailsFragmentDoc}
${AvailabilityRangeFragmentFragmentDoc}`;
export const AddListCandidateDocument = gql`
    mutation AddListCandidate($input: AddListCandidateInput!) {
  addListCandidate(input: $input) {
    list {
      id
      positions {
        id
        candidates {
          id
          talentId
          status
        }
      }
    }
  }
}
    `;
export type AddListCandidateMutationFn = Apollo.MutationFunction<AddListCandidateMutation, AddListCandidateMutationVariables>;

/**
 * __useAddListCandidateMutation__
 *
 * To run a mutation, you first call `useAddListCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddListCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addListCandidateMutation, { data, loading, error }] = useAddListCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddListCandidateMutation(baseOptions?: Apollo.MutationHookOptions<AddListCandidateMutation, AddListCandidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddListCandidateMutation, AddListCandidateMutationVariables>(AddListCandidateDocument, options);
      }
export type AddListCandidateMutationHookResult = ReturnType<typeof useAddListCandidateMutation>;
export type AddListCandidateMutationResult = Apollo.MutationResult<AddListCandidateMutation>;
export type AddListCandidateMutationOptions = Apollo.BaseMutationOptions<AddListCandidateMutation, AddListCandidateMutationVariables>;
export const AnswerSurveyDocument = gql`
    mutation AnswerSurvey($input: AnswerSurveyInput!) {
  answerSurvey(input: $input)
}
    `;
export type AnswerSurveyMutationFn = Apollo.MutationFunction<AnswerSurveyMutation, AnswerSurveyMutationVariables>;

/**
 * __useAnswerSurveyMutation__
 *
 * To run a mutation, you first call `useAnswerSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerSurveyMutation, { data, loading, error }] = useAnswerSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnswerSurveyMutation(baseOptions?: Apollo.MutationHookOptions<AnswerSurveyMutation, AnswerSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnswerSurveyMutation, AnswerSurveyMutationVariables>(AnswerSurveyDocument, options);
      }
export type AnswerSurveyMutationHookResult = ReturnType<typeof useAnswerSurveyMutation>;
export type AnswerSurveyMutationResult = Apollo.MutationResult<AnswerSurveyMutation>;
export type AnswerSurveyMutationOptions = Apollo.BaseMutationOptions<AnswerSurveyMutation, AnswerSurveyMutationVariables>;
export const AuthDocument = gql`
    query Auth {
  viewer {
    id
    email
    name
    hasPassword
    avatar {
      ...UserAvatarFragment
    }
    shadowedSession
    chatraId
    intercomId
    heapId
    sentryId
    accessLevel
    location
    isSurveyRequired
    hasEnabledTrial
    accountKind
    features {
      app
      lists
    }
    subscription {
      status
    }
  }
}
    ${UserAvatarFragmentFragmentDoc}`;

/**
 * __useAuthQuery__
 *
 * To run a query within a React component, call `useAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthQuery(baseOptions?: Apollo.QueryHookOptions<AuthQuery, AuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthQuery, AuthQueryVariables>(AuthDocument, options);
      }
export function useAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthQuery, AuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthQuery, AuthQueryVariables>(AuthDocument, options);
        }
export type AuthQueryHookResult = ReturnType<typeof useAuthQuery>;
export type AuthLazyQueryHookResult = ReturnType<typeof useAuthLazyQuery>;
export type AuthQueryResult = Apollo.QueryResult<AuthQuery, AuthQueryVariables>;
export const AvailabilityKindsDocument = gql`
    query AvailabilityKinds {
  availabilityKinds {
    name
    color
  }
}
    `;

/**
 * __useAvailabilityKindsQuery__
 *
 * To run a query within a React component, call `useAvailabilityKindsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityKindsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityKindsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailabilityKindsQuery(baseOptions?: Apollo.QueryHookOptions<AvailabilityKindsQuery, AvailabilityKindsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailabilityKindsQuery, AvailabilityKindsQueryVariables>(AvailabilityKindsDocument, options);
      }
export function useAvailabilityKindsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailabilityKindsQuery, AvailabilityKindsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailabilityKindsQuery, AvailabilityKindsQueryVariables>(AvailabilityKindsDocument, options);
        }
export type AvailabilityKindsQueryHookResult = ReturnType<typeof useAvailabilityKindsQuery>;
export type AvailabilityKindsLazyQueryHookResult = ReturnType<typeof useAvailabilityKindsLazyQuery>;
export type AvailabilityKindsQueryResult = Apollo.QueryResult<AvailabilityKindsQuery, AvailabilityKindsQueryVariables>;
export const CardListDocument = gql`
    query CardList($first: Int!, $after: String, $category: ID, $labels: [CardLabel], $tags: [String], $untagged: Boolean, $query: String) {
  viewer {
    id
    cards(
      first: $first
      after: $after
      category: $category
      labels: $labels
      tags: $tags
      untagged: $untagged
      search: $query
    ) {
      edges {
        card: node {
          ...TalentPreviewFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      aggregations {
        untagged
        tags {
          buckets {
            key
            docCount
          }
        }
        skills {
          skill {
            name
            color
          }
          docCount
        }
        labels {
          buckets {
            key
            docCount
          }
        }
      }
    }
  }
}
    ${TalentPreviewFragmentFragmentDoc}`;

/**
 * __useCardListQuery__
 *
 * To run a query within a React component, call `useCardListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      category: // value for 'category'
 *      labels: // value for 'labels'
 *      tags: // value for 'tags'
 *      untagged: // value for 'untagged'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCardListQuery(baseOptions: Apollo.QueryHookOptions<CardListQuery, CardListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardListQuery, CardListQueryVariables>(CardListDocument, options);
      }
export function useCardListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardListQuery, CardListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardListQuery, CardListQueryVariables>(CardListDocument, options);
        }
export type CardListQueryHookResult = ReturnType<typeof useCardListQuery>;
export type CardListLazyQueryHookResult = ReturnType<typeof useCardListLazyQuery>;
export type CardListQueryResult = Apollo.QueryResult<CardListQuery, CardListQueryVariables>;
export const CategoriesListDocument = gql`
    query CategoriesList {
  categories {
    ...CategoryListItem
  }
}
    ${CategoryListItemFragmentDoc}`;

/**
 * __useCategoriesListQuery__
 *
 * To run a query within a React component, call `useCategoriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesListQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesListQuery, CategoriesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesListQuery, CategoriesListQueryVariables>(CategoriesListDocument, options);
      }
export function useCategoriesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesListQuery, CategoriesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesListQuery, CategoriesListQueryVariables>(CategoriesListDocument, options);
        }
export type CategoriesListQueryHookResult = ReturnType<typeof useCategoriesListQuery>;
export type CategoriesListLazyQueryHookResult = ReturnType<typeof useCategoriesListLazyQuery>;
export type CategoriesListQueryResult = Apollo.QueryResult<CategoriesListQuery, CategoriesListQueryVariables>;
export const CategoriesDocument = gql`
    query Categories {
  categories {
    id
    name
    icon
    systemCode
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    result
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ChatDocument = gql`
    query Chat($chatId: String!) {
  chat(chatId: $chatId) {
    id
    messages {
      ...ChatMessage
    }
  }
}
    ${ChatMessageFragmentDoc}`;

/**
 * __useChatQuery__
 *
 * To run a query within a React component, call `useChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useChatQuery(baseOptions: Apollo.QueryHookOptions<ChatQuery, ChatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatQuery, ChatQueryVariables>(ChatDocument, options);
      }
export function useChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatQuery, ChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatQuery, ChatQueryVariables>(ChatDocument, options);
        }
export type ChatQueryHookResult = ReturnType<typeof useChatQuery>;
export type ChatLazyQueryHookResult = ReturnType<typeof useChatLazyQuery>;
export type ChatQueryResult = Apollo.QueryResult<ChatQuery, ChatQueryVariables>;
export const CreateAvailabilityRangeDocument = gql`
    mutation CreateAvailabilityRange($input: CreateAvailabilityRangeInput!) {
  createAvailabilityRange(input: $input) {
    range {
      ...AvailabilityRangeFragment
    }
    talentId
  }
}
    ${AvailabilityRangeFragmentFragmentDoc}`;
export type CreateAvailabilityRangeMutationFn = Apollo.MutationFunction<CreateAvailabilityRangeMutation, CreateAvailabilityRangeMutationVariables>;

/**
 * __useCreateAvailabilityRangeMutation__
 *
 * To run a mutation, you first call `useCreateAvailabilityRangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAvailabilityRangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAvailabilityRangeMutation, { data, loading, error }] = useCreateAvailabilityRangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAvailabilityRangeMutation(baseOptions?: Apollo.MutationHookOptions<CreateAvailabilityRangeMutation, CreateAvailabilityRangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAvailabilityRangeMutation, CreateAvailabilityRangeMutationVariables>(CreateAvailabilityRangeDocument, options);
      }
export type CreateAvailabilityRangeMutationHookResult = ReturnType<typeof useCreateAvailabilityRangeMutation>;
export type CreateAvailabilityRangeMutationResult = Apollo.MutationResult<CreateAvailabilityRangeMutation>;
export type CreateAvailabilityRangeMutationOptions = Apollo.BaseMutationOptions<CreateAvailabilityRangeMutation, CreateAvailabilityRangeMutationVariables>;
export const CreateAvatarDocument = gql`
    mutation CreateAvatar($url: String!) {
  createImage(input: {url: $url}) {
    image {
      id
      avatar48: avatar(size: 48) {
        src
        srcSet
      }
      avatar128: avatar(size: 128) {
        src
        srcSet
      }
    }
  }
}
    `;
export type CreateAvatarMutationFn = Apollo.MutationFunction<CreateAvatarMutation, CreateAvatarMutationVariables>;

/**
 * __useCreateAvatarMutation__
 *
 * To run a mutation, you first call `useCreateAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAvatarMutation, { data, loading, error }] = useCreateAvatarMutation({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useCreateAvatarMutation(baseOptions?: Apollo.MutationHookOptions<CreateAvatarMutation, CreateAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAvatarMutation, CreateAvatarMutationVariables>(CreateAvatarDocument, options);
      }
export type CreateAvatarMutationHookResult = ReturnType<typeof useCreateAvatarMutation>;
export type CreateAvatarMutationResult = Apollo.MutationResult<CreateAvatarMutation>;
export type CreateAvatarMutationOptions = Apollo.BaseMutationOptions<CreateAvatarMutation, CreateAvatarMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($name: String!, $icon: String!) {
  createCategory(input: {name: $name, icon: $icon}) {
    category {
      id
      name
      icon
      systemCode
    }
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      icon: // value for 'icon'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const CreateListDocument = gql`
    mutation CreateList($input: CreateListInput!) {
  createList(input: $input) {
    list {
      ...ListDetails
    }
  }
}
    ${ListDetailsFragmentDoc}`;
export type CreateListMutationFn = Apollo.MutationFunction<CreateListMutation, CreateListMutationVariables>;

/**
 * __useCreateListMutation__
 *
 * To run a mutation, you first call `useCreateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListMutation, { data, loading, error }] = useCreateListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateListMutation(baseOptions?: Apollo.MutationHookOptions<CreateListMutation, CreateListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateListMutation, CreateListMutationVariables>(CreateListDocument, options);
      }
export type CreateListMutationHookResult = ReturnType<typeof useCreateListMutation>;
export type CreateListMutationResult = Apollo.MutationResult<CreateListMutation>;
export type CreateListMutationOptions = Apollo.BaseMutationOptions<CreateListMutation, CreateListMutationVariables>;
export const CreateReviewDocument = gql`
    mutation CreateReview($input: CreateReviewInput!) {
  createReview(input: $input) {
    talent {
      id
      rating
      reviews {
        ...ReviewFragment
      }
    }
  }
}
    ${ReviewFragmentFragmentDoc}`;
export type CreateReviewMutationFn = Apollo.MutationFunction<CreateReviewMutation, CreateReviewMutationVariables>;

/**
 * __useCreateReviewMutation__
 *
 * To run a mutation, you first call `useCreateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReviewMutation, { data, loading, error }] = useCreateReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReviewMutation(baseOptions?: Apollo.MutationHookOptions<CreateReviewMutation, CreateReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReviewMutation, CreateReviewMutationVariables>(CreateReviewDocument, options);
      }
export type CreateReviewMutationHookResult = ReturnType<typeof useCreateReviewMutation>;
export type CreateReviewMutationResult = Apollo.MutationResult<CreateReviewMutation>;
export type CreateReviewMutationOptions = Apollo.BaseMutationOptions<CreateReviewMutation, CreateReviewMutationVariables>;
export const CreateTalentDocument = gql`
    mutation CreateTalent($input: CreateCardInput!) {
  createCard(input: $input) {
    card {
      ...TalentDetails
    }
  }
}
    ${TalentDetailsFragmentDoc}`;
export type CreateTalentMutationFn = Apollo.MutationFunction<CreateTalentMutation, CreateTalentMutationVariables>;

/**
 * __useCreateTalentMutation__
 *
 * To run a mutation, you first call `useCreateTalentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTalentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTalentMutation, { data, loading, error }] = useCreateTalentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTalentMutation(baseOptions?: Apollo.MutationHookOptions<CreateTalentMutation, CreateTalentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTalentMutation, CreateTalentMutationVariables>(CreateTalentDocument, options);
      }
export type CreateTalentMutationHookResult = ReturnType<typeof useCreateTalentMutation>;
export type CreateTalentMutationResult = Apollo.MutationResult<CreateTalentMutation>;
export type CreateTalentMutationOptions = Apollo.BaseMutationOptions<CreateTalentMutation, CreateTalentMutationVariables>;
export const CreateWorkDocument = gql`
    mutation CreateWork($input: CreateWorkInput!) {
  createWork(input: $input) {
    talent {
      id
      works {
        ...WorkDetails
      }
    }
  }
}
    ${WorkDetailsFragmentDoc}`;
export type CreateWorkMutationFn = Apollo.MutationFunction<CreateWorkMutation, CreateWorkMutationVariables>;

/**
 * __useCreateWorkMutation__
 *
 * To run a mutation, you first call `useCreateWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkMutation, { data, loading, error }] = useCreateWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkMutation, CreateWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkMutation, CreateWorkMutationVariables>(CreateWorkDocument, options);
      }
export type CreateWorkMutationHookResult = ReturnType<typeof useCreateWorkMutation>;
export type CreateWorkMutationResult = Apollo.MutationResult<CreateWorkMutation>;
export type CreateWorkMutationOptions = Apollo.BaseMutationOptions<CreateWorkMutation, CreateWorkMutationVariables>;
export const DeleteAvailabilityRangeDocument = gql`
    mutation DeleteAvailabilityRange($input: DeleteAvailabilityRangeInput!) {
  deleteAvailabilityRange(input: $input) {
    range {
      id
    }
  }
}
    `;
export type DeleteAvailabilityRangeMutationFn = Apollo.MutationFunction<DeleteAvailabilityRangeMutation, DeleteAvailabilityRangeMutationVariables>;

/**
 * __useDeleteAvailabilityRangeMutation__
 *
 * To run a mutation, you first call `useDeleteAvailabilityRangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAvailabilityRangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAvailabilityRangeMutation, { data, loading, error }] = useDeleteAvailabilityRangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAvailabilityRangeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAvailabilityRangeMutation, DeleteAvailabilityRangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAvailabilityRangeMutation, DeleteAvailabilityRangeMutationVariables>(DeleteAvailabilityRangeDocument, options);
      }
export type DeleteAvailabilityRangeMutationHookResult = ReturnType<typeof useDeleteAvailabilityRangeMutation>;
export type DeleteAvailabilityRangeMutationResult = Apollo.MutationResult<DeleteAvailabilityRangeMutation>;
export type DeleteAvailabilityRangeMutationOptions = Apollo.BaseMutationOptions<DeleteAvailabilityRangeMutation, DeleteAvailabilityRangeMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($id: ID!) {
  deleteCategory(input: {id: $id}) {
    id
  }
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const DeleteListDocument = gql`
    mutation DeleteList($input: DeleteListInput!) {
  deleteList(input: $input) {
    listId
  }
}
    `;
export type DeleteListMutationFn = Apollo.MutationFunction<DeleteListMutation, DeleteListMutationVariables>;

/**
 * __useDeleteListMutation__
 *
 * To run a mutation, you first call `useDeleteListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteListMutation, { data, loading, error }] = useDeleteListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteListMutation, DeleteListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteListMutation, DeleteListMutationVariables>(DeleteListDocument, options);
      }
export type DeleteListMutationHookResult = ReturnType<typeof useDeleteListMutation>;
export type DeleteListMutationResult = Apollo.MutationResult<DeleteListMutation>;
export type DeleteListMutationOptions = Apollo.BaseMutationOptions<DeleteListMutation, DeleteListMutationVariables>;
export const DeleteReviewDocument = gql`
    mutation DeleteReview($input: DeleteReviewInput!) {
  deleteReview(input: $input) {
    deletedId
    talent {
      id
      rating
      reviews {
        ...ReviewFragment
      }
    }
  }
}
    ${ReviewFragmentFragmentDoc}`;
export type DeleteReviewMutationFn = Apollo.MutationFunction<DeleteReviewMutation, DeleteReviewMutationVariables>;

/**
 * __useDeleteReviewMutation__
 *
 * To run a mutation, you first call `useDeleteReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReviewMutation, { data, loading, error }] = useDeleteReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteReviewMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReviewMutation, DeleteReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReviewMutation, DeleteReviewMutationVariables>(DeleteReviewDocument, options);
      }
export type DeleteReviewMutationHookResult = ReturnType<typeof useDeleteReviewMutation>;
export type DeleteReviewMutationResult = Apollo.MutationResult<DeleteReviewMutation>;
export type DeleteReviewMutationOptions = Apollo.BaseMutationOptions<DeleteReviewMutation, DeleteReviewMutationVariables>;
export const DeleteSampleTalentsDocument = gql`
    mutation DeleteSampleTalents {
  deleteSampleTalents
}
    `;
export type DeleteSampleTalentsMutationFn = Apollo.MutationFunction<DeleteSampleTalentsMutation, DeleteSampleTalentsMutationVariables>;

/**
 * __useDeleteSampleTalentsMutation__
 *
 * To run a mutation, you first call `useDeleteSampleTalentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSampleTalentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSampleTalentsMutation, { data, loading, error }] = useDeleteSampleTalentsMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteSampleTalentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSampleTalentsMutation, DeleteSampleTalentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSampleTalentsMutation, DeleteSampleTalentsMutationVariables>(DeleteSampleTalentsDocument, options);
      }
export type DeleteSampleTalentsMutationHookResult = ReturnType<typeof useDeleteSampleTalentsMutation>;
export type DeleteSampleTalentsMutationResult = Apollo.MutationResult<DeleteSampleTalentsMutation>;
export type DeleteSampleTalentsMutationOptions = Apollo.BaseMutationOptions<DeleteSampleTalentsMutation, DeleteSampleTalentsMutationVariables>;
export const DeleteTalentDocument = gql`
    mutation DeleteTalent($input: DeleteCardInput!) {
  deleteCard(input: $input) {
    deletedId
  }
}
    `;
export type DeleteTalentMutationFn = Apollo.MutationFunction<DeleteTalentMutation, DeleteTalentMutationVariables>;

/**
 * __useDeleteTalentMutation__
 *
 * To run a mutation, you first call `useDeleteTalentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTalentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTalentMutation, { data, loading, error }] = useDeleteTalentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTalentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTalentMutation, DeleteTalentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTalentMutation, DeleteTalentMutationVariables>(DeleteTalentDocument, options);
      }
export type DeleteTalentMutationHookResult = ReturnType<typeof useDeleteTalentMutation>;
export type DeleteTalentMutationResult = Apollo.MutationResult<DeleteTalentMutation>;
export type DeleteTalentMutationOptions = Apollo.BaseMutationOptions<DeleteTalentMutation, DeleteTalentMutationVariables>;
export const DeleteWorkDocument = gql`
    mutation DeleteWork($input: DeleteWorkInput!) {
  deleteWork(input: $input) {
    deletedId
  }
}
    `;
export type DeleteWorkMutationFn = Apollo.MutationFunction<DeleteWorkMutation, DeleteWorkMutationVariables>;

/**
 * __useDeleteWorkMutation__
 *
 * To run a mutation, you first call `useDeleteWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkMutation, { data, loading, error }] = useDeleteWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWorkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkMutation, DeleteWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkMutation, DeleteWorkMutationVariables>(DeleteWorkDocument, options);
      }
export type DeleteWorkMutationHookResult = ReturnType<typeof useDeleteWorkMutation>;
export type DeleteWorkMutationResult = Apollo.MutationResult<DeleteWorkMutation>;
export type DeleteWorkMutationOptions = Apollo.BaseMutationOptions<DeleteWorkMutation, DeleteWorkMutationVariables>;
export const EditCategoryDocument = gql`
    mutation EditCategory($id: ID!, $name: String!, $icon: String!) {
  editCategory(input: {id: $id, name: $name, icon: $icon}) {
    category {
      ...CategoryListItem
    }
  }
}
    ${CategoryListItemFragmentDoc}`;
export type EditCategoryMutationFn = Apollo.MutationFunction<EditCategoryMutation, EditCategoryMutationVariables>;

/**
 * __useEditCategoryMutation__
 *
 * To run a mutation, you first call `useEditCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCategoryMutation, { data, loading, error }] = useEditCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      icon: // value for 'icon'
 *   },
 * });
 */
export function useEditCategoryMutation(baseOptions?: Apollo.MutationHookOptions<EditCategoryMutation, EditCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCategoryMutation, EditCategoryMutationVariables>(EditCategoryDocument, options);
      }
export type EditCategoryMutationHookResult = ReturnType<typeof useEditCategoryMutation>;
export type EditCategoryMutationResult = Apollo.MutationResult<EditCategoryMutation>;
export type EditCategoryMutationOptions = Apollo.BaseMutationOptions<EditCategoryMutation, EditCategoryMutationVariables>;
export const EditProfileDocument = gql`
    mutation EditProfile($input: EditUserInput!) {
  editUser(input: $input) {
    viewer {
      id
      name
      email
      location
      avatar {
        ...UserAvatarFragment
      }
    }
  }
}
    ${UserAvatarFragmentFragmentDoc}`;
export type EditProfileMutationFn = Apollo.MutationFunction<EditProfileMutation, EditProfileMutationVariables>;

/**
 * __useEditProfileMutation__
 *
 * To run a mutation, you first call `useEditProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProfileMutation, { data, loading, error }] = useEditProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditProfileMutation(baseOptions?: Apollo.MutationHookOptions<EditProfileMutation, EditProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditProfileMutation, EditProfileMutationVariables>(EditProfileDocument, options);
      }
export type EditProfileMutationHookResult = ReturnType<typeof useEditProfileMutation>;
export type EditProfileMutationResult = Apollo.MutationResult<EditProfileMutation>;
export type EditProfileMutationOptions = Apollo.BaseMutationOptions<EditProfileMutation, EditProfileMutationVariables>;
export const HasSampleTalentsDocument = gql`
    query HasSampleTalents {
  hasSampleTalents
}
    `;

/**
 * __useHasSampleTalentsQuery__
 *
 * To run a query within a React component, call `useHasSampleTalentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasSampleTalentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasSampleTalentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasSampleTalentsQuery(baseOptions?: Apollo.QueryHookOptions<HasSampleTalentsQuery, HasSampleTalentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasSampleTalentsQuery, HasSampleTalentsQueryVariables>(HasSampleTalentsDocument, options);
      }
export function useHasSampleTalentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasSampleTalentsQuery, HasSampleTalentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasSampleTalentsQuery, HasSampleTalentsQueryVariables>(HasSampleTalentsDocument, options);
        }
export type HasSampleTalentsQueryHookResult = ReturnType<typeof useHasSampleTalentsQuery>;
export type HasSampleTalentsLazyQueryHookResult = ReturnType<typeof useHasSampleTalentsLazyQuery>;
export type HasSampleTalentsQueryResult = Apollo.QueryResult<HasSampleTalentsQuery, HasSampleTalentsQueryVariables>;
export const InitAccountAsIndividualDocument = gql`
    mutation InitAccountAsIndividual {
  initAccountAsIndividual
}
    `;
export type InitAccountAsIndividualMutationFn = Apollo.MutationFunction<InitAccountAsIndividualMutation, InitAccountAsIndividualMutationVariables>;

/**
 * __useInitAccountAsIndividualMutation__
 *
 * To run a mutation, you first call `useInitAccountAsIndividualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitAccountAsIndividualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initAccountAsIndividualMutation, { data, loading, error }] = useInitAccountAsIndividualMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitAccountAsIndividualMutation(baseOptions?: Apollo.MutationHookOptions<InitAccountAsIndividualMutation, InitAccountAsIndividualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitAccountAsIndividualMutation, InitAccountAsIndividualMutationVariables>(InitAccountAsIndividualDocument, options);
      }
export type InitAccountAsIndividualMutationHookResult = ReturnType<typeof useInitAccountAsIndividualMutation>;
export type InitAccountAsIndividualMutationResult = Apollo.MutationResult<InitAccountAsIndividualMutation>;
export type InitAccountAsIndividualMutationOptions = Apollo.BaseMutationOptions<InitAccountAsIndividualMutation, InitAccountAsIndividualMutationVariables>;
export const InitAccountAsOrgDocument = gql`
    mutation InitAccountAsOrg($input: InitAccountAsOrgInput!) {
  initAccountAsOrg(input: $input)
}
    `;
export type InitAccountAsOrgMutationFn = Apollo.MutationFunction<InitAccountAsOrgMutation, InitAccountAsOrgMutationVariables>;

/**
 * __useInitAccountAsOrgMutation__
 *
 * To run a mutation, you first call `useInitAccountAsOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitAccountAsOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initAccountAsOrgMutation, { data, loading, error }] = useInitAccountAsOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitAccountAsOrgMutation(baseOptions?: Apollo.MutationHookOptions<InitAccountAsOrgMutation, InitAccountAsOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitAccountAsOrgMutation, InitAccountAsOrgMutationVariables>(InitAccountAsOrgDocument, options);
      }
export type InitAccountAsOrgMutationHookResult = ReturnType<typeof useInitAccountAsOrgMutation>;
export type InitAccountAsOrgMutationResult = Apollo.MutationResult<InitAccountAsOrgMutation>;
export type InitAccountAsOrgMutationOptions = Apollo.BaseMutationOptions<InitAccountAsOrgMutation, InitAccountAsOrgMutationVariables>;
export const InvitationDocument = gql`
    query Invitation($token: String!) {
  invitation(token: $token) {
    token
    accountName
  }
}
    `;

/**
 * __useInvitationQuery__
 *
 * To run a query within a React component, call `useInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useInvitationQuery(baseOptions: Apollo.QueryHookOptions<InvitationQuery, InvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvitationQuery, InvitationQueryVariables>(InvitationDocument, options);
      }
export function useInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationQuery, InvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvitationQuery, InvitationQueryVariables>(InvitationDocument, options);
        }
export type InvitationQueryHookResult = ReturnType<typeof useInvitationQuery>;
export type InvitationLazyQueryHookResult = ReturnType<typeof useInvitationLazyQuery>;
export type InvitationQueryResult = Apollo.QueryResult<InvitationQuery, InvitationQueryVariables>;
export const IsEmailFreeDocument = gql`
    query IsEmailFree($email: String!) {
  isEmailFree(email: $email)
}
    `;

/**
 * __useIsEmailFreeQuery__
 *
 * To run a query within a React component, call `useIsEmailFreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailFreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailFreeQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsEmailFreeQuery(baseOptions: Apollo.QueryHookOptions<IsEmailFreeQuery, IsEmailFreeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsEmailFreeQuery, IsEmailFreeQueryVariables>(IsEmailFreeDocument, options);
      }
export function useIsEmailFreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsEmailFreeQuery, IsEmailFreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsEmailFreeQuery, IsEmailFreeQueryVariables>(IsEmailFreeDocument, options);
        }
export type IsEmailFreeQueryHookResult = ReturnType<typeof useIsEmailFreeQuery>;
export type IsEmailFreeLazyQueryHookResult = ReturnType<typeof useIsEmailFreeLazyQuery>;
export type IsEmailFreeQueryResult = Apollo.QueryResult<IsEmailFreeQuery, IsEmailFreeQueryVariables>;
export const JoinDocument = gql`
    mutation Join($input: JoinByInvitationInput!) {
  joinByInvitation(input: $input)
}
    `;
export type JoinMutationFn = Apollo.MutationFunction<JoinMutation, JoinMutationVariables>;

/**
 * __useJoinMutation__
 *
 * To run a mutation, you first call `useJoinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinMutation, { data, loading, error }] = useJoinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinMutation(baseOptions?: Apollo.MutationHookOptions<JoinMutation, JoinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinMutation, JoinMutationVariables>(JoinDocument, options);
      }
export type JoinMutationHookResult = ReturnType<typeof useJoinMutation>;
export type JoinMutationResult = Apollo.MutationResult<JoinMutation>;
export type JoinMutationOptions = Apollo.BaseMutationOptions<JoinMutation, JoinMutationVariables>;
export const ListDetailsDocument = gql`
    query ListDetails($listId: ID!) {
  list(listId: $listId) {
    ...ListDetails
  }
}
    ${ListDetailsFragmentDoc}`;

/**
 * __useListDetailsQuery__
 *
 * To run a query within a React component, call `useListDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDetailsQuery({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useListDetailsQuery(baseOptions: Apollo.QueryHookOptions<ListDetailsQuery, ListDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDetailsQuery, ListDetailsQueryVariables>(ListDetailsDocument, options);
      }
export function useListDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDetailsQuery, ListDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDetailsQuery, ListDetailsQueryVariables>(ListDetailsDocument, options);
        }
export type ListDetailsQueryHookResult = ReturnType<typeof useListDetailsQuery>;
export type ListDetailsLazyQueryHookResult = ReturnType<typeof useListDetailsLazyQuery>;
export type ListDetailsQueryResult = Apollo.QueryResult<ListDetailsQuery, ListDetailsQueryVariables>;
export const ListsDocument = gql`
    query Lists($ownership: ListsOwnership, $status: ListStatus) {
  lists(ownership: $ownership, status: $status) {
    id
    title
    client
    status
    createdAt
    creator {
      id
      name
      avatar {
        ...UserAvatarFragment
      }
    }
    positions {
      id
      title
      description
      count
      candidates {
        id
        talentId
        status
      }
    }
  }
}
    ${UserAvatarFragmentFragmentDoc}`;

/**
 * __useListsQuery__
 *
 * To run a query within a React component, call `useListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListsQuery({
 *   variables: {
 *      ownership: // value for 'ownership'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListsQuery(baseOptions?: Apollo.QueryHookOptions<ListsQuery, ListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListsQuery, ListsQueryVariables>(ListsDocument, options);
      }
export function useListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListsQuery, ListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListsQuery, ListsQueryVariables>(ListsDocument, options);
        }
export type ListsQueryHookResult = ReturnType<typeof useListsQuery>;
export type ListsLazyQueryHookResult = ReturnType<typeof useListsLazyQuery>;
export type ListsQueryResult = Apollo.QueryResult<ListsQuery, ListsQueryVariables>;
export const PostChatMessageDocument = gql`
    mutation PostChatMessage($input: PostChatMessageInput!) {
  postChatMessage(input: $input) {
    message {
      ...ChatMessage
    }
  }
}
    ${ChatMessageFragmentDoc}`;
export type PostChatMessageMutationFn = Apollo.MutationFunction<PostChatMessageMutation, PostChatMessageMutationVariables>;

/**
 * __usePostChatMessageMutation__
 *
 * To run a mutation, you first call `usePostChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postChatMessageMutation, { data, loading, error }] = usePostChatMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<PostChatMessageMutation, PostChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostChatMessageMutation, PostChatMessageMutationVariables>(PostChatMessageDocument, options);
      }
export type PostChatMessageMutationHookResult = ReturnType<typeof usePostChatMessageMutation>;
export type PostChatMessageMutationResult = Apollo.MutationResult<PostChatMessageMutation>;
export type PostChatMessageMutationOptions = Apollo.BaseMutationOptions<PostChatMessageMutation, PostChatMessageMutationVariables>;
export const ProfileUrlExtractionDocument = gql`
    query ProfileUrlExtraction($url: String!) {
  profileUrlExtraction(url: $url) {
    url
    duplicate
    extractable
    status
    source {
      id
      title
      beta
      providesWorks
    }
    profile {
      name
      location
      avatarUrl
      about
      skills
      contacts {
        url
        duplicate
        extractable
        status
        source {
          id
          title
          beta
          providesWorks
        }
      }
    }
  }
}
    `;

/**
 * __useProfileUrlExtractionQuery__
 *
 * To run a query within a React component, call `useProfileUrlExtractionQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileUrlExtractionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileUrlExtractionQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useProfileUrlExtractionQuery(baseOptions: Apollo.QueryHookOptions<ProfileUrlExtractionQuery, ProfileUrlExtractionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileUrlExtractionQuery, ProfileUrlExtractionQueryVariables>(ProfileUrlExtractionDocument, options);
      }
export function useProfileUrlExtractionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileUrlExtractionQuery, ProfileUrlExtractionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileUrlExtractionQuery, ProfileUrlExtractionQueryVariables>(ProfileUrlExtractionDocument, options);
        }
export type ProfileUrlExtractionQueryHookResult = ReturnType<typeof useProfileUrlExtractionQuery>;
export type ProfileUrlExtractionLazyQueryHookResult = ReturnType<typeof useProfileUrlExtractionLazyQuery>;
export type ProfileUrlExtractionQueryResult = Apollo.QueryResult<ProfileUrlExtractionQuery, ProfileUrlExtractionQueryVariables>;
export const RemoveListCandidateDocument = gql`
    mutation RemoveListCandidate($input: RemoveListCandidateInput!) {
  removeListCandidate(input: $input) {
    list {
      ...ListDetails
    }
  }
}
    ${ListDetailsFragmentDoc}`;
export type RemoveListCandidateMutationFn = Apollo.MutationFunction<RemoveListCandidateMutation, RemoveListCandidateMutationVariables>;

/**
 * __useRemoveListCandidateMutation__
 *
 * To run a mutation, you first call `useRemoveListCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveListCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeListCandidateMutation, { data, loading, error }] = useRemoveListCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveListCandidateMutation(baseOptions?: Apollo.MutationHookOptions<RemoveListCandidateMutation, RemoveListCandidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveListCandidateMutation, RemoveListCandidateMutationVariables>(RemoveListCandidateDocument, options);
      }
export type RemoveListCandidateMutationHookResult = ReturnType<typeof useRemoveListCandidateMutation>;
export type RemoveListCandidateMutationResult = Apollo.MutationResult<RemoveListCandidateMutation>;
export type RemoveListCandidateMutationOptions = Apollo.BaseMutationOptions<RemoveListCandidateMutation, RemoveListCandidateMutationVariables>;
export const SetCategoryOrderDocument = gql`
    mutation SetCategoryOrder($input: SetCategoryOrderInput!) {
  setCategoryOrder(input: $input) {
    categories {
      ...CategoryListItem
    }
  }
}
    ${CategoryListItemFragmentDoc}`;
export type SetCategoryOrderMutationFn = Apollo.MutationFunction<SetCategoryOrderMutation, SetCategoryOrderMutationVariables>;

/**
 * __useSetCategoryOrderMutation__
 *
 * To run a mutation, you first call `useSetCategoryOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCategoryOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCategoryOrderMutation, { data, loading, error }] = useSetCategoryOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCategoryOrderMutation(baseOptions?: Apollo.MutationHookOptions<SetCategoryOrderMutation, SetCategoryOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCategoryOrderMutation, SetCategoryOrderMutationVariables>(SetCategoryOrderDocument, options);
      }
export type SetCategoryOrderMutationHookResult = ReturnType<typeof useSetCategoryOrderMutation>;
export type SetCategoryOrderMutationResult = Apollo.MutationResult<SetCategoryOrderMutation>;
export type SetCategoryOrderMutationOptions = Apollo.BaseMutationOptions<SetCategoryOrderMutation, SetCategoryOrderMutationVariables>;
export const SetListPositionSkillsDocument = gql`
    mutation SetListPositionSkills($input: SetListPositionSkillsInput!) {
  setListPositionSkills(input: $input) {
    list {
      ...ListDetails
    }
  }
}
    ${ListDetailsFragmentDoc}`;
export type SetListPositionSkillsMutationFn = Apollo.MutationFunction<SetListPositionSkillsMutation, SetListPositionSkillsMutationVariables>;

/**
 * __useSetListPositionSkillsMutation__
 *
 * To run a mutation, you first call `useSetListPositionSkillsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetListPositionSkillsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setListPositionSkillsMutation, { data, loading, error }] = useSetListPositionSkillsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetListPositionSkillsMutation(baseOptions?: Apollo.MutationHookOptions<SetListPositionSkillsMutation, SetListPositionSkillsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetListPositionSkillsMutation, SetListPositionSkillsMutationVariables>(SetListPositionSkillsDocument, options);
      }
export type SetListPositionSkillsMutationHookResult = ReturnType<typeof useSetListPositionSkillsMutation>;
export type SetListPositionSkillsMutationResult = Apollo.MutationResult<SetListPositionSkillsMutation>;
export type SetListPositionSkillsMutationOptions = Apollo.BaseMutationOptions<SetListPositionSkillsMutation, SetListPositionSkillsMutationVariables>;
export const SetListStatusDocument = gql`
    mutation SetListStatus($input: SetListStatusInput!) {
  setListStatus(input: $input) {
    list {
      id
      status
    }
  }
}
    `;
export type SetListStatusMutationFn = Apollo.MutationFunction<SetListStatusMutation, SetListStatusMutationVariables>;

/**
 * __useSetListStatusMutation__
 *
 * To run a mutation, you first call `useSetListStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetListStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setListStatusMutation, { data, loading, error }] = useSetListStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetListStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetListStatusMutation, SetListStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetListStatusMutation, SetListStatusMutationVariables>(SetListStatusDocument, options);
      }
export type SetListStatusMutationHookResult = ReturnType<typeof useSetListStatusMutation>;
export type SetListStatusMutationResult = Apollo.MutationResult<SetListStatusMutation>;
export type SetListStatusMutationOptions = Apollo.BaseMutationOptions<SetListStatusMutation, SetListStatusMutationVariables>;
export const SetListTeamDocument = gql`
    mutation SetListTeam($input: SetListTeamInput!) {
  setListTeam(input: $input) {
    list {
      id
      team {
        id
        name
      }
    }
  }
}
    `;
export type SetListTeamMutationFn = Apollo.MutationFunction<SetListTeamMutation, SetListTeamMutationVariables>;

/**
 * __useSetListTeamMutation__
 *
 * To run a mutation, you first call `useSetListTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetListTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setListTeamMutation, { data, loading, error }] = useSetListTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetListTeamMutation(baseOptions?: Apollo.MutationHookOptions<SetListTeamMutation, SetListTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetListTeamMutation, SetListTeamMutationVariables>(SetListTeamDocument, options);
      }
export type SetListTeamMutationHookResult = ReturnType<typeof useSetListTeamMutation>;
export type SetListTeamMutationResult = Apollo.MutationResult<SetListTeamMutation>;
export type SetListTeamMutationOptions = Apollo.BaseMutationOptions<SetListTeamMutation, SetListTeamMutationVariables>;
export const SetCardFavoriteDocument = gql`
    mutation SetCardFavorite($input: SetCardFavoriteInput!) {
  setCardFavorite(input: $input) {
    card {
      id
      favorite
    }
  }
}
    `;
export type SetCardFavoriteMutationFn = Apollo.MutationFunction<SetCardFavoriteMutation, SetCardFavoriteMutationVariables>;

/**
 * __useSetCardFavoriteMutation__
 *
 * To run a mutation, you first call `useSetCardFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCardFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCardFavoriteMutation, { data, loading, error }] = useSetCardFavoriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCardFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<SetCardFavoriteMutation, SetCardFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCardFavoriteMutation, SetCardFavoriteMutationVariables>(SetCardFavoriteDocument, options);
      }
export type SetCardFavoriteMutationHookResult = ReturnType<typeof useSetCardFavoriteMutation>;
export type SetCardFavoriteMutationResult = Apollo.MutationResult<SetCardFavoriteMutation>;
export type SetCardFavoriteMutationOptions = Apollo.BaseMutationOptions<SetCardFavoriteMutation, SetCardFavoriteMutationVariables>;
export const SetTalentTagsDocument = gql`
    mutation SetTalentTags($input: SetCardTagsInput!) {
  setCardTags(input: $input) {
    card {
      id
      skills
    }
  }
}
    `;
export type SetTalentTagsMutationFn = Apollo.MutationFunction<SetTalentTagsMutation, SetTalentTagsMutationVariables>;

/**
 * __useSetTalentTagsMutation__
 *
 * To run a mutation, you first call `useSetTalentTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTalentTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTalentTagsMutation, { data, loading, error }] = useSetTalentTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTalentTagsMutation(baseOptions?: Apollo.MutationHookOptions<SetTalentTagsMutation, SetTalentTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTalentTagsMutation, SetTalentTagsMutationVariables>(SetTalentTagsDocument, options);
      }
export type SetTalentTagsMutationHookResult = ReturnType<typeof useSetTalentTagsMutation>;
export type SetTalentTagsMutationResult = Apollo.MutationResult<SetTalentTagsMutation>;
export type SetTalentTagsMutationOptions = Apollo.BaseMutationOptions<SetTalentTagsMutation, SetTalentTagsMutationVariables>;
export const SetUserNeedsImportDocument = gql`
    mutation SetUserNeedsImport {
  setUserNeedsImport
}
    `;
export type SetUserNeedsImportMutationFn = Apollo.MutationFunction<SetUserNeedsImportMutation, SetUserNeedsImportMutationVariables>;

/**
 * __useSetUserNeedsImportMutation__
 *
 * To run a mutation, you first call `useSetUserNeedsImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserNeedsImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserNeedsImportMutation, { data, loading, error }] = useSetUserNeedsImportMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetUserNeedsImportMutation(baseOptions?: Apollo.MutationHookOptions<SetUserNeedsImportMutation, SetUserNeedsImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserNeedsImportMutation, SetUserNeedsImportMutationVariables>(SetUserNeedsImportDocument, options);
      }
export type SetUserNeedsImportMutationHookResult = ReturnType<typeof useSetUserNeedsImportMutation>;
export type SetUserNeedsImportMutationResult = Apollo.MutationResult<SetUserNeedsImportMutation>;
export type SetUserNeedsImportMutationOptions = Apollo.BaseMutationOptions<SetUserNeedsImportMutation, SetUserNeedsImportMutationVariables>;
export const SkillsDocument = gql`
    query Skills {
  skills {
    name
    color
  }
}
    `;

/**
 * __useSkillsQuery__
 *
 * To run a query within a React component, call `useSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSkillsQuery(baseOptions?: Apollo.QueryHookOptions<SkillsQuery, SkillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SkillsQuery, SkillsQueryVariables>(SkillsDocument, options);
      }
export function useSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SkillsQuery, SkillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SkillsQuery, SkillsQueryVariables>(SkillsDocument, options);
        }
export type SkillsQueryHookResult = ReturnType<typeof useSkillsQuery>;
export type SkillsLazyQueryHookResult = ReturnType<typeof useSkillsLazyQuery>;
export type SkillsQueryResult = Apollo.QueryResult<SkillsQuery, SkillsQueryVariables>;
export const TalentDocument = gql`
    query Talent($id: ID!) {
  talent(id: $id) {
    ...TalentDetails
  }
}
    ${TalentDetailsFragmentDoc}`;

/**
 * __useTalentQuery__
 *
 * To run a query within a React component, call `useTalentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTalentQuery(baseOptions: Apollo.QueryHookOptions<TalentQuery, TalentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TalentQuery, TalentQueryVariables>(TalentDocument, options);
      }
export function useTalentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TalentQuery, TalentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TalentQuery, TalentQueryVariables>(TalentDocument, options);
        }
export type TalentQueryHookResult = ReturnType<typeof useTalentQuery>;
export type TalentLazyQueryHookResult = ReturnType<typeof useTalentLazyQuery>;
export type TalentQueryResult = Apollo.QueryResult<TalentQuery, TalentQueryVariables>;
export const TeamDocument = gql`
    query Team {
  team {
    id
    name
    avatar {
      ...UserAvatarFragment
    }
  }
}
    ${UserAvatarFragmentFragmentDoc}`;

/**
 * __useTeamQuery__
 *
 * To run a query within a React component, call `useTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamQuery(baseOptions?: Apollo.QueryHookOptions<TeamQuery, TeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
      }
export function useTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamQuery, TeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
        }
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>;
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>;
export type TeamQueryResult = Apollo.QueryResult<TeamQuery, TeamQueryVariables>;
export const UpdateListCandidateStatusDocument = gql`
    mutation UpdateListCandidateStatus($input: UpdateListCandidateStatusInput!) {
  updateListCandidateStatus(input: $input) {
    list {
      ...ListDetails
    }
  }
}
    ${ListDetailsFragmentDoc}`;
export type UpdateListCandidateStatusMutationFn = Apollo.MutationFunction<UpdateListCandidateStatusMutation, UpdateListCandidateStatusMutationVariables>;

/**
 * __useUpdateListCandidateStatusMutation__
 *
 * To run a mutation, you first call `useUpdateListCandidateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListCandidateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListCandidateStatusMutation, { data, loading, error }] = useUpdateListCandidateStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateListCandidateStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateListCandidateStatusMutation, UpdateListCandidateStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateListCandidateStatusMutation, UpdateListCandidateStatusMutationVariables>(UpdateListCandidateStatusDocument, options);
      }
export type UpdateListCandidateStatusMutationHookResult = ReturnType<typeof useUpdateListCandidateStatusMutation>;
export type UpdateListCandidateStatusMutationResult = Apollo.MutationResult<UpdateListCandidateStatusMutation>;
export type UpdateListCandidateStatusMutationOptions = Apollo.BaseMutationOptions<UpdateListCandidateStatusMutation, UpdateListCandidateStatusMutationVariables>;
export const UpdateListDocument = gql`
    mutation UpdateList($input: UpdateListInput!) {
  updateList(input: $input) {
    list {
      ...ListDetails
    }
  }
}
    ${ListDetailsFragmentDoc}`;
export type UpdateListMutationFn = Apollo.MutationFunction<UpdateListMutation, UpdateListMutationVariables>;

/**
 * __useUpdateListMutation__
 *
 * To run a mutation, you first call `useUpdateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListMutation, { data, loading, error }] = useUpdateListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateListMutation, UpdateListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateListMutation, UpdateListMutationVariables>(UpdateListDocument, options);
      }
export type UpdateListMutationHookResult = ReturnType<typeof useUpdateListMutation>;
export type UpdateListMutationResult = Apollo.MutationResult<UpdateListMutation>;
export type UpdateListMutationOptions = Apollo.BaseMutationOptions<UpdateListMutation, UpdateListMutationVariables>;
export const UpdateTalentDocument = gql`
    mutation UpdateTalent($input: UpdateCardInput!) {
  updateCard(input: $input) {
    card {
      ...TalentDetails
    }
  }
}
    ${TalentDetailsFragmentDoc}`;
export type UpdateTalentMutationFn = Apollo.MutationFunction<UpdateTalentMutation, UpdateTalentMutationVariables>;

/**
 * __useUpdateTalentMutation__
 *
 * To run a mutation, you first call `useUpdateTalentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentMutation, { data, loading, error }] = useUpdateTalentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTalentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTalentMutation, UpdateTalentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTalentMutation, UpdateTalentMutationVariables>(UpdateTalentDocument, options);
      }
export type UpdateTalentMutationHookResult = ReturnType<typeof useUpdateTalentMutation>;
export type UpdateTalentMutationResult = Apollo.MutationResult<UpdateTalentMutation>;
export type UpdateTalentMutationOptions = Apollo.BaseMutationOptions<UpdateTalentMutation, UpdateTalentMutationVariables>;
export const UploadAvatarDocument = gql`
    mutation UploadAvatar($file: Upload!) {
  uploadImage(input: {file: $file}) {
    image {
      id
      avatar48: avatar(size: 48) {
        src
        srcSet
      }
      avatar128: avatar(size: 128) {
        src
        srcSet
      }
    }
  }
}
    `;
export type UploadAvatarMutationFn = Apollo.MutationFunction<UploadAvatarMutation, UploadAvatarMutationVariables>;

/**
 * __useUploadAvatarMutation__
 *
 * To run a mutation, you first call `useUploadAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAvatarMutation, { data, loading, error }] = useUploadAvatarMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UploadAvatarMutation, UploadAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadAvatarMutation, UploadAvatarMutationVariables>(UploadAvatarDocument, options);
      }
export type UploadAvatarMutationHookResult = ReturnType<typeof useUploadAvatarMutation>;
export type UploadAvatarMutationResult = Apollo.MutationResult<UploadAvatarMutation>;
export type UploadAvatarMutationOptions = Apollo.BaseMutationOptions<UploadAvatarMutation, UploadAvatarMutationVariables>;
export const UploadWorkDocument = gql`
    mutation UploadWork($file: Upload!) {
  uploadImage(input: {file: $file}) {
    image {
      id
      preview200: work(width: 200) {
        src
        srcSet
      }
      preview400: work(width: 400) {
        src
        srcSet
      }
    }
  }
}
    `;
export type UploadWorkMutationFn = Apollo.MutationFunction<UploadWorkMutation, UploadWorkMutationVariables>;

/**
 * __useUploadWorkMutation__
 *
 * To run a mutation, you first call `useUploadWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadWorkMutation, { data, loading, error }] = useUploadWorkMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadWorkMutation(baseOptions?: Apollo.MutationHookOptions<UploadWorkMutation, UploadWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadWorkMutation, UploadWorkMutationVariables>(UploadWorkDocument, options);
      }
export type UploadWorkMutationHookResult = ReturnType<typeof useUploadWorkMutation>;
export type UploadWorkMutationResult = Apollo.MutationResult<UploadWorkMutation>;
export type UploadWorkMutationOptions = Apollo.BaseMutationOptions<UploadWorkMutation, UploadWorkMutationVariables>;