import { MemberType } from "../../types";

const FILTER_SORT_IDX_MATCH = 1;
const FILTER_SORT_IDX_BEGIN_LINE = 2;
const FILTER_SORT_IDX_BEGIN_WORD = 3;
const FILTER_SORT_IDX_REST = 4;

export function filterMembers(
  members: MemberType[],
  filterInput: string
): MemberType[] {
  if (filterInput.length === 0) {
    return members;
  }

  const result = [] as {
    member: MemberType;
    sortIdx: number;
  }[];

  members.forEach((member) => {
    const occurPosition = member.name.toLowerCase().indexOf(filterInput);
    if (occurPosition < 0) {
      return;
    }

    let sortIdx = FILTER_SORT_IDX_REST;

    if (filterInput === member.name) {
      sortIdx = FILTER_SORT_IDX_MATCH;
    } else if (occurPosition === 0) {
      sortIdx = FILTER_SORT_IDX_BEGIN_LINE;
    } else if (new RegExp(`\\b${escapeRegExp(filterInput)}`).test(member.name)) {
      sortIdx = FILTER_SORT_IDX_BEGIN_WORD;
    }

    result.push({ member: member, sortIdx });
  });

  result.sort((a, b) => a.sortIdx - b.sortIdx);

  return result.map((res) => res.member);
}

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
