import { forwardRef, ReactNode } from "react";
import styled from "styled-components/macro";
import { ActionButton } from "./ActionButton";

interface DialogProps extends Record<string, unknown> {
  title?: ReactNode;
  content: ReactNode;
  buttons?: ReactNode;
  close?: () => void;
}

export const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  ({ title, content, buttons, close, ...restProps }, ref) => {
    return (
      <Container {...restProps} ref={ref}>
        {close && <CloseButton tabIndex={-1} icon="cross" onClick={close} />}
        {title && <Title>{title}</Title>}
        <Content>{content}</Content>
        {buttons && <Buttons>{buttons}</Buttons>}
      </Container>
    );
  }
);

const Container = styled.div`
  position: relative;
  padding: 32px 40px 40px;
  border-radius: 16px;
  width: 600px;
  box-sizing: border-box;
  background-color: ${(p) => p.theme.colors.backgrounds.main};

  &:focus {
    outline: 0;
  }
`;

const Title = styled.h2`
  margin-bottom: 16px;
`;

const Content = styled.div``;

const Buttons = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 8px;
`;

const CloseButton = styled(ActionButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;
