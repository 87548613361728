import { useCallback, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import { useSetUserNeedsImportMutation } from "../../../graphql";

import ImportImg from "../assets/signin-promo.png";
import Import2XImg from "../assets/signin-promo@2x.png";
import Import3XImg from "../assets/signin-promo@3x.png";

interface ImportStageProps {
  onComplete: () => void;
}

export function ImportStage({ onComplete }: ImportStageProps) {
  const [isRequested, setRequested] = useState(false);
  const [error, setError] = useState("");
  const [setUserNeedsImport, { loading }] = useSetUserNeedsImportMutation();
  const handleImportRequest = useCallback(() => {
    setUserNeedsImport({
      onCompleted() {
        setRequested(true);
      },
      onError() {
        setError("Something went wrong. Try again later.");
      },
    });
  }, [setUserNeedsImport]);

  if (isRequested) {
    return (
      <Container>
        <h1>We got your request</h1>
        <Description>
          We will provide you with the details about importing your database to
          Baselance soon!
        </Description>
        <Button
          type="button"
          $size="l"
          $kind="contained"
          $fullWidth
          onClick={onComplete}
        >
          Take me to Baselance
        </Button>
        <Image
          src={ImportImg}
          srcSet={`${Import2XImg} 2x, ${Import3XImg} 3x`}
        />
      </Container>
    );
  }

  return (
    <Container>
      <h1>Import talents</h1>
      <Description>
        We can help you to transfer you talents database to Baselance. Send the
        request and we will contact you shortly.
      </Description>
      {error && <ErrorMsg>{error}</ErrorMsg>}
      <Button
        type="button"
        $size="l"
        $kind="contained"
        $fullWidth
        onClick={handleImportRequest}
        disabled={loading}
      >
        I want to import my talents
      </Button>
      <Button
        type="button"
        $size="l"
        $kind="outlined"
        $fullWidth
        onClick={onComplete}
      >
        Skip for now
      </Button>
      <Image
        src={ImportImg}
        srcSet={`${Import2XImg} 2x, ${Import3XImg} 3x`}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 400px;
  margin-bottom: 80px;

  h1 {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 440px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }

  button {
    margin-bottom: 8px;
  }
`;

const Description = styled.div`
  margin: 8px 0 24px;
`;

const ErrorMsg = styled.div`
  margin-bottom: 4px;
  text-align: center;
  color: ${(p) => p.theme.colors.statuses.error};
`;

const Image = styled.img`
  position: absolute;
  top: 20px;
  right: 475px;
  height: 215px;
  transform: rotateY(180deg);

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;
