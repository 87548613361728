import { ApolloClient, InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";

export const apolloClient = new ApolloClient({
  link: createUploadLink({
    uri: "/graphql",
  }),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          talent: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: "Card",
                id: args!.id,
              });
            },
          },
          categories: {
            merge(_, incoming: any[]) {
              return incoming;
            },
          },
          skills: {
            merge(_, incoming: any[]) {
              return incoming;
            },
          },
        },
      },
      Card: {
        fields: {
          skills: {
            merge(_, incoming: any[]) {
              return incoming;
            },
          },
          works: {
            merge(_, incoming: any[]) {
              return incoming;
            },
          },
        },
      },
      List: {
        fields: {
          positions: {
            merge(_, incoming: any[]) {
              return incoming;
            },
          },
        },
      },
      ListPosition: {
        fields: {
          candidates: {
            merge(_, incoming: any[]) {
              return incoming;
            },
          },
        },
      },
      Viewer: {
        fields: {
          cards: relayStylePagination(["category", "labels", "tags", "search"]),
        },
      },
      SkillType: {
        keyFields: ["name"],
      },
    },
  }),
});
