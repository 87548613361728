import styled, { keyframes } from "styled-components/macro";
import { WorkSlot as BaseWorkSlot } from "./ui";
import { typographyCSS } from "../../../../theme";
import { Filename as BaseFilename } from "../../../../components/Filename";

interface UploadSlotProps {
  filename: string;
  error: boolean;
}

export function UploadSlot({ filename, error }: UploadSlotProps) {
  return (
    <Container>
      <Content>
        <Filename name={filename} />
        {error ? <ErrorBar /> : <ProgressBar />}
        <Status $error={error}>
          {error ? "Error occured" : "Uploading..."}
        </Status>
      </Content>
      {/*
        <DeleteWorkAction
          workId={work.id}
          render={(getReferenceProps) => (
            <DeleteButton {...getReferenceProps()}>
              <UIIcon name="smallcross" />
            </DeleteButton>
          )}
        />
      */}
    </Container>
  );
}

const DeleteButton = styled.button`
  display: block;
  position: absolute;
  top: 8px;
  right: 8px;
  border: 0 none;
  border-radius: 6px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  cursor: pointer;
  padding: 5px;
  opacity: 0;
  transition: opacity 0.2s;

  &:focus-visible {
    outline: 3px solid ${(p) => p.theme.colors.service.focus};
    outline-offset: 0;
  }

  &:hover,
  &:active {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 8px;
    }
  }

  &:hover::after {
    background-color: ${(p) => p.theme.colors.service.hover};
  }

  &:active::after {
    background-color: ${(p) => p.theme.colors.service.selected};
  }

  &:focus-visible {
    outline: 3px solid ${(p) => p.theme.colors.service.focus};
    outline-offset: 0;
  }
`;

export const Container = styled(BaseWorkSlot)`
  &:hover, &:focus-within {
    ${DeleteButton} {
      opacity: 1;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 16px;
  overflow: hidden;
`;

const Filename = styled(BaseFilename)`
  padding-bottom: 12px;
`;

const Status = styled.div<{ $error: boolean }>`
  ${(p) => typographyCSS(p.theme.typo.body2)};
  padding-top: 8px;
  color: ${(p) =>
    p.$error ? p.theme.colors.statuses.error : p.theme.colors.text.grey};
`;

const ErrorBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${(p) => p.theme.colors.statuses.error};
`;

const progressAnimation = keyframes`
  from {
    left: 0;
    transform: translateX(-75%);
  }

  to {
    left: 100%;
    transform: translateX(-25%);
  }
`;

const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  background-color: ${(p) => p.theme.colors.borders.separator};
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    width: 40%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background-color: ${(p) => p.theme.colors.highlights.purple.fg};
    animation: ${progressAnimation} 0.8s ease-in-out infinite alternate;
  }
`;
