import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, Navigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import * as yup from "yup";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Logo } from "../../components/Logo/Logo";
import { useInvitationQuery, useJoinMutation } from "../../graphql";

const SUBMISSION = "__SUBMISSION__";

export interface FormData {
  name: string;
  password: string;
  [SUBMISSION]: void;
}

const schema = yup
  .object({
    name: yup.string().required("Fill name"),
    password: yup.string().required("Fill password"),
  })
  .required();

export function JoinPage() {
  const { token } = useParams();

  const [join] = useJoinMutation();
  const { data, loading } = useInvitationQuery({
    variables: {
      token: token ?? "",
    },
    skip: !token,
  });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      password: "",
    },
  });

  if (loading) {
    return null;
  }

  if (!data?.invitation) {
    return <Navigate to="/" replace />;
  }

  const invitation = data.invitation;

  const handleFormSubmit = async (data: FormData) => {
    clearErrors(SUBMISSION);
    return join({
      variables: {
        input: {
          token: token ?? "",
          name: data.name,
          password: data.password,
        },
      },
      onCompleted() {
        document.location.href = "/";
      },
      onError() {
        setError(SUBMISSION, {
          type: "submission",
          message: "Something went wrong. Try again later.",
        });
      },
    });
  };

  return (
    <Container>
      <Panel>
        <LogoLink href="/">
          <Logo />
        </LogoLink>
        <SignInSection>
          <span>Already have an account? </span>
          <PanelLink to="/auth/signin">Sign in</PanelLink>
        </SignInSection>
      </Panel>
      <Content>
        <h1>Welcome to Baselance</h1>
        <Description>
          You've been invited to join {invitation.accountName} team.
          <br />
          Finish registration to start using Baselance!
        </Description>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <InputRow>
            <Input
              icon="user"
              placeholder="Name"
              hasError={!!errors.name}
              {...register("name")}
            />
            {errors.name && <ErrorMsg>{errors.name.message}</ErrorMsg>}
          </InputRow>
          <InputRow>
            <Input
              icon="lock"
              type="password"
              placeholder="Password"
              hasError={!!errors.password}
              {...register("password")}
            />
            {errors.password && <ErrorMsg>{errors.password.message}</ErrorMsg>}
          </InputRow>
          <Button
            $kind="contained"
            $size="l"
            $fullWidth
            disabled={isSubmitting}
          >
            Join
          </Button>
          {errors[SUBMISSION] && (
            <ErrorMsg>{errors[SUBMISSION].message}</ErrorMsg>
          )}
        </form>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Panel = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  flex: 0 0 ${(p) => p.theme.panelHeight};
  height: ${(p) => p.theme.panelHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoLink = styled.a`
  svg {
    display: block;
  }
`;

const SignInSection = styled.div`
  @media screen and (max-width: 440px) {
    span {
      display: none;
    }
  }
`;

const PanelLink = styled(Link)`
  color: ${(p) => p.theme.colors.ui.purple};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Content = styled.div`
  flex: 1 0 auto;
  padding-top: 40px;
  width: 400px;
  text-align: center;

  h1 {
    margin-bottom: 24px;
  }
`;

const Description = styled.div`
  margin: 8px 0 24px;
`;

const InputRow = styled.div`
  margin-bottom: 16px;

  label {
    width: 100%;
  }
`;

const ErrorMsg = styled.div`
  margin-top: 4px;
  text-align: center;
  color: ${(p) => p.theme.colors.statuses.error};
`;
