import styled from "styled-components/macro";

import { ContactIconKind, ContactIcons } from "./images/all";

export type { ContactIconKind };

const titles: { [key in ContactIconKind]: string | undefined } = {
  applemusic: "Apple Music",
  artstation: "ArtStation",
  behance: "Behance",
  contact: "",
  dribbble: "Dribbble",
  email: "Email",
  facebook: "Facebook",
  fbmessenger: "Messenger",
  github: "GitHub",
  instagram: "Instagram",
  line: "Line",
  linkedin: "LinkedIn",
  linktree: "Linktree",
  phone: "Phone",
  pinterest: "Pinterest",
  skype: "Skype",
  snapchat: "Snapchat",
  soundcloud: "SoundCloud",
  telegram: "Telegram",
  tiktok: "TikTok",
  twitch: "Twitch",
  twitter: "Twitter",
  viber: "Viber",
  vimeo: "Vimeo",
  vk: "VK",
  website: undefined,
  wechat: "WeChat",
  whatsapp: "WhatsApp",
  yelp: "Yelp",
  youtube: "YouTube",
};

interface CategoryIconProps {
  name: ContactIconKind;
  url?: string;
  className?: string;
}

export function ContactIcon({ className, name, url }: CategoryIconProps) {
  let Icon = ContactIcons[name];
  let title = titles[name];

  return (
    <IconWrapper className={className} title={title || url}>
      {Icon && <Icon />}
    </IconWrapper>
  );
}

const IconWrapper = styled.div`
  overflow: hidden;
  flex: 0 0 auto;

  & svg {
    display: block;
  }
`;
