import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { ActionButton } from "../../../components/ActionButton";
import { UserAvatar } from "../../../components/Avatar";
import { Spinner } from "../../../components/Spinner";
import { useTeamPicker } from "../../../components/TeamPicker/TeamPicker";
import { useSetListTeamMutation } from "../../../graphql";
import { MemberType } from "../../../types";

interface ChatHeaderProps {
  list: {
    id: string;
    creator: MemberType;
    team: MemberType[];
  };
  onChatClose: () => void;
}

export function ChatHeader({ list, onChatClose }: ChatHeaderProps) {
  const members = useMemo(() => [list.creator, ...list.team].reverse(), [list]);
  const [setListTeam, { loading }] = useSetListTeamMutation();

  const handleTeamSelectionDone = useCallback(
    (newMembers: MemberType[]) => {
      if (newMembers.length === 0) return;

      setListTeam({
        variables: {
          input: {
            listId: list.id,
            team: [
              ...list.team.map((member) => member.id),
              ...newMembers.map((member) => member.id),
            ],
          },
        },
      });
    },
    [list, setListTeam]
  );

  const [getReferenceProps, teamPickerElement] = useTeamPicker({
    selectedMembers: members,
    onDone: handleTeamSelectionDone,
  });
  return (
    <Container>
      <MembersContainer>
        <Members>
          {members.map((member) => (
            <Avatar key={member.id} size="xxs" user={member} />
          ))}
        </Members>
        {loading ? (
          <Spinner $size={32} />
        ) : (
          <ActionButton icon="plus" {...getReferenceProps()} />
        )}
        {teamPickerElement}
      </MembersContainer>
      <CloseContainer>
        <ActionButton icon="cross" onClick={onChatClose} />
      </CloseContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MembersContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Members = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
`;

const Avatar = styled(UserAvatar)`
  outline: 2px solid ${(p) => p.theme.colors.backgrounds.main};
`;

const CloseContainer = styled.div`
  @media screen and (min-width: 1440px) {
    display: none;
  }
`;
