import { useCallback, useRef } from "react";
import styled from "styled-components/macro";
import format from "date-fns/fp/format";

import { CandidateStatus } from "../../../graphql";
import { typographyCSS } from "../../../theme";
import { ListDetailsType } from "../../../types";

import {
  CandidateActionPayload,
  TalentListItem,
  TalentListPlaceholder,
} from "./TalentListItem";
import { rangeArray } from "../../../utils/rangeArray";

interface SidebarProps {
  list: ListDetailsType | null | undefined;
  onCandidateStatusChange: (
    positionId: string,
    talentId: string,
    status: CandidateStatus,
    hook?: (start: boolean) => void
  ) => void;
}

export function Sidebar({ list, onCandidateStatusChange }: SidebarProps) {
  const mainBoxRef = useRef<HTMLDivElement>(null);
  const declinedBoxRef = useRef<HTMLDivElement>(null);
  const hasRejectedCandidates = list?.positions.some((position) =>
    position.candidates.some((c) => c.status === CandidateStatus.Rejected)
  );

  const handleCandidateRollback = useCallback(
    ({ positionId, talentId, hook }: CandidateActionPayload) => {
      onCandidateStatusChange(
        positionId,
        talentId,
        CandidateStatus.Candidate,
        hook
      );
    },
    [onCandidateStatusChange]
  );

  return (
    <Container>
      {list ? (
        <Box
          ref={mainBoxRef}
          style={
            {
              viewTransitionName: `list-sidebar`,
            } as any
          }
        >
          <Section>
            <h2>{list.title}</h2>
            {list.client && <div>{list.client}</div>}
          </Section>
          <Section>
            <Title>Approved talents</Title>
            <TalentList>
              {list.positions.flatMap((position) => {
                const approvedCandidates = position.candidates.filter(
                  (candidate) => candidate.status === CandidateStatus.Approved
                );
                const placeholdersRange = rangeArray(
                  Math.max(0, position.count - approvedCandidates.length)
                );

                return [
                  ...approvedCandidates.map((candidate) => (
                    <TalentListItem
                      key={candidate.talent.id}
                      talent={candidate.talent}
                      position={position}
                      actionIcon="cross"
                      parentRef={mainBoxRef}
                      onAction={handleCandidateRollback}
                    />
                  )),
                  ...placeholdersRange.map((index) => (
                    <TalentListPlaceholder
                      key={`placeholder-${position.id}-${
                        approvedCandidates.length + index
                      }`}
                      position={position}
                      index={approvedCandidates.length + index}
                    />
                  )),
                ];
              })}
            </TalentList>
          </Section>
          <Created>
            Created on {format("d MMM yyyy", new Date(list.createdAt))}
          </Created>
        </Box>
      ) : null}
      {list && hasRejectedCandidates ? (
        <Box
          ref={declinedBoxRef}
          style={
            {
              viewTransitionName: `decline-box`,
            } as any
          }
        >
          <Title>Declined candidates</Title>
          <TalentList>
            {list.positions.flatMap((position) =>
              position.candidates
                .filter(
                  (candidate) => candidate.status === CandidateStatus.Rejected
                )
                .map((candidate) => (
                  <TalentListItem
                    key={candidate.talent.id}
                    talent={candidate.talent}
                    position={position}
                    actionIcon="revert"
                    parentRef={declinedBoxRef}
                    onAction={handleCandidateRollback}
                  />
                ))
            )}
          </TalentList>
        </Box>
      ) : (
        <div
          style={
            {
              viewTransitionName: `decline-box`,
            } as any
          }
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 300px;
  flex: 0 0 300px;
`;

const Box = styled.div`
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 16px;
  padding: 16px 24px;
  margin-bottom: 16px;
  contain: paint;
  position: relative;
  background-color: ${(p) => p.theme.colors.backgrounds.main};

  h2 {
    margin-top: 8px;
  }
`;

const Section = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  ${(p) => typographyCSS(p.theme.typo.highlighted)};
  font-weight: 700;
  margin-bottom: 16px;
`;

const TalentList = styled.ul`
  margin: 0;
  padding: 0;
`;

const Created = styled.div`
  color: ${(p) => p.theme.colors.text.grey};
  ${(p) => typographyCSS(p.theme.typo.body2)};
`;
