import { createContext, useContext, useMemo, useState, ReactNode } from "react";
import { AvatarNoImage, TalentAvatar } from "../../../components/Avatar";
import { UIIcon } from "../../../components/UIIcon/UIIcon";
import { Channel, ChatController } from "../../../components/Chat/types";
import { useChatFetcher } from "../../../components/Chat/useChatFetcher";
import {
  AvatarImageData,
  ListDetailsType,
  ChatMessageType,
} from "../../../types";

export const ListChatControllerContext = createContext<ChatController | null>(
  null
);

export function useListChatController() {
  let controller = useContext(ListChatControllerContext);
  if (controller === null) {
    throw new Error("Use ListsChatControllerProvider");
  }

  return controller;
}

export function channelFromTalent(
  talent: {
    id: string;
    name: string;
    image: AvatarImageData | null;
  },
  msg: ChatMessageType | null = null
): Channel {
  return {
    id: talent.id,
    name: talent.name,
    renderAvatar(size) {
      return <TalentAvatar size={size} talent={talent} />;
    },
    lastMessage: msg,
  };
}

interface ListsChatControllerProviderProps {
  list: ListDetailsType;
  children?: ReactNode | undefined;
}

export function ListsChatControllerProvider({
  list,
  children,
}: ListsChatControllerProviderProps) {
  const chatId = list.id;
  const candidates = useMemo(() => {
    return list.positions.flatMap((position) => position.candidates);
  }, [list.positions]);
  const [selectedChannel, setSelectedChannel] = useState<Channel | null>(null);
  const [isHidden, setHidden] = useState(true);

  const [allMessages, loading, setActiveMode, setBackgroundMode] =
    useChatFetcher(chatId);

  const channels = useMemo(() => {
    const channels = allMessages.reduceRight((memo, msg) => {
      if (!msg.channel || memo.some((channel) => channel.id === msg.channel)) {
        return memo;
      }

      const candidate = candidates.find(
        (candidate) => candidate.talent.id === msg.channel
      );

      if (!candidate) {
        return memo;
      }

      memo.push(channelFromTalent(candidate.talent, msg));

      return memo;
    }, [] as Channel[]);

    if (channels.length > 0) {
      let lastGeneralMessage = allMessages
        .slice()
        .reverse()
        .find((msg) => msg.channel === null);
      channels.unshift({
        id: null,
        name: "General chat",
        lastMessage: lastGeneralMessage ?? null,
        renderAvatar(size) {
          return (
            <AvatarNoImage size={size} colorIndex={5}>
              <UIIcon name="bubble" />
            </AvatarNoImage>
          );
        },
      });
    }

    return channels;
  }, [allMessages, candidates]);

  let messages = useMemo(() => {
    return selectedChannel
      ? allMessages.filter((msg) => msg.channel === selectedChannel.id)
      : allMessages;
  }, [allMessages, selectedChannel]);

  let controller: ChatController = useMemo(
    () => ({
      isHidden,
      chatId,
      loading,
      hasChannels: channels.length > 0,
      channels,
      selectedChannel,
      messages,
      setSelectedChannel,
      setActiveMode,
      setBackgroundMode,
      setHidden,
    }),
    [
      isHidden,
      chatId,
      loading,
      channels,
      selectedChannel,
      messages,
      setActiveMode,
      setBackgroundMode,
    ]
  );

  return (
    <ListChatControllerContext.Provider
      value={controller}
      children={children}
    />
  );
}
