import {
  offset,
  useFloating,
  shift,
  useInteractions,
  useDismiss,
  UseFloatingReturn,
  FloatingPortal,
  useFloatingNodeId,
  FloatingNode,
  Placement,
  FloatingOverlay,
} from "@floating-ui/react";
import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import styled from "styled-components/macro";
import { EmojiData, Picker as EmojiMartPicker } from "emoji-mart";
import appConfig from "../../appConfig";
import { stickyTop } from "../../utils/stickyTop";

interface UseEmojiPickerOptions {
  opened: boolean;
  placement?: Placement;
  nested?: boolean;
  onClose: () => void;
  onEmojiSelected: (iconName: string) => void;
}

export function useEmojiPicker({
  opened,
  placement = "right-start",
  nested = false,
  onClose,
  onEmojiSelected,
}: UseEmojiPickerOptions) {
  const topMemoRef = useRef<number | null>(null);

  const handleOpenChange = useCallback(
    (willBeOpened: boolean) => {
      if (!willBeOpened) {
        topMemoRef.current = null;
        onClose();
      }
    },
    [onClose]
  );

  const nodeId = useFloatingNodeId();

  const floatingReturn = useFloating({
    nodeId,
    open: opened,
    onOpenChange: handleOpenChange,
    placement,
    strategy: "fixed",
    middleware: [
      offset(2),
      shift({ crossAxis: true, padding: 5 }),
      stickyTop({ topMemoRef }),
    ],
  });

  const interactionsReturn = useInteractions([
    useDismiss(floatingReturn.context),
  ]);

  const element = (
    <EmojiPicker
      floatingReturn={floatingReturn}
      interactionsReturn={interactionsReturn}
      onEmojiSelected={onEmojiSelected}
    />
  );

  const emojiPickerElement = (
    <FloatingPortal>
      {opened ? (
        <FloatingNode id={nodeId}>
          {nested ? element : <Overlay lockScroll>{element}</Overlay>}
        </FloatingNode>
      ) : null}
    </FloatingPortal>
  );

  const getReferenceProps = useCallback(
    (props?: React.HTMLProps<Element>) =>
      interactionsReturn.getReferenceProps.call(null, {
        ...props,
        ref: floatingReturn.reference,
      }),
    [interactionsReturn.getReferenceProps, floatingReturn.reference]
  );

  return [getReferenceProps, emojiPickerElement] as [
    typeof getReferenceProps,
    typeof emojiPickerElement
  ];
}

export type UseEmojiPickerReturn = ReturnType<typeof useEmojiPicker>;

interface EmojiPickerProps {
  floatingReturn: UseFloatingReturn;
  interactionsReturn: ReturnType<typeof useInteractions>;
  onEmojiSelected: (iconName: string) => void;
}

function EmojiPicker({
  floatingReturn,
  interactionsReturn,
  onEmojiSelected,
}: EmojiPickerProps) {
  const instance = useRef<EmojiMartPicker | null>(null);
  const handlerRef = useRef(onEmojiSelected);
  const { x, y, floating, strategy, refs } = floatingReturn;
  const { getFloatingProps } = interactionsReturn;

  useLayoutEffect(() => {
    handlerRef.current = onEmojiSelected;
  });

  useEffect(() => {
    instance.current = new EmojiMartPicker({
      ref: refs.floating,
      set: appConfig.emoji.set,
      skinTonePosition: "none",
      previewPosition: "none",
      maxFrequentRows: 0,
      theme: "light",
      onEmojiSelect: (emoji: EmojiData) => {
        if (emoji.id) {
          handlerRef.current(emoji.id);
        }
      },
    });

    return () => {
      instance.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      ref={floating}
      style={{
        position: strategy,
        transform: `translate(${x ?? 0}px, ${y ?? 0}px)`,
      }}
      {...getFloatingProps()}
    />
  );
}

const Container = styled.div`
  top: 0;
  left: 0;
  z-index: ${(p) => p.theme.layers.menu};
`;

const Overlay = styled(FloatingOverlay)`
  z-index: ${(p) => p.theme.layers.overlay};
`;
