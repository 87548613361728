import { useMemo } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";
import { isGrantedAny } from "../../../acl";

import { CategoryIcon } from "../../../components/CategoryIcon/CategoryIcon";
import { useViewer } from "../../../components/context/auth_gate";
import { UIIcon } from "../../../components/UIIcon/UIIcon";
import { useCategoriesListQuery } from "../../../graphql";
import { typographyCSS } from "../../../theme";
import { CategoryListItemType } from "../../../types";

const placeholders = Array.apply(null, Array(4)).map((_, i) => i);

export function CategoryList() {
  const viewer = useViewer();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const selectedCategoryId = searchParams.get("category") ?? null;
  let { data, loading } = useCategoriesListQuery();
  const rawCategories = data?.categories;

  const categories = useMemo(
    () =>
      rawCategories
        ? rawCategories
            .filter((cat) => !cat.systemCode || cat.cardsCount > 0)
            .sort(systemToTheEnd)
        : [],
    [rawCategories]
  );

  return (
    <Container>
      <Content>
        <List>
          {loading &&
            placeholders.map((i) => (
              <ListItem key={i} className="_placeholder">
                <CategoryLink as="div">
                  <IconPlaceholder />
                  <CategoryTitle>
                    <TitlePlaceholder />
                  </CategoryTitle>
                  <CategoryCounter>
                    <CounterPlaceholder />
                  </CategoryCounter>
                </CategoryLink>
              </ListItem>
            ))}
          {categories.map((category) => (
            <ListItem key={category.id}>
              <CategoryLink
                to={`/talents?category=${category.id}`}
                $selected={category.id === selectedCategoryId}
              >
                <CategoryIcon name={category.icon} />
                <CategoryTitle>{category.name}</CategoryTitle>
                <CategoryCounter>{category.cardsCount}</CategoryCounter>
              </CategoryLink>
            </ListItem>
          ))}
        </List>
      </Content>
      {isGrantedAny(
        viewer,
        "category.add",
        "category.edit",
        "category.delete",
        "category.arrange"
      ) && (
        <Bottom>
          <ManageLink
            to="/talents/manage-categories"
            state={{ backgroundLocation: location }}
          >
            <UIIcon name="pencil" />
            Manage categories
          </ManageLink>
        </Bottom>
      )}
    </Container>
  );
}

function systemToTheEnd(
  first: CategoryListItemType,
  second: CategoryListItemType
) {
  if (!!first.systemCode === !!second.systemCode) {
    return 0;
  }

  return first.systemCode ? 1 : -1;
}

const Container = styled.div`
  flex: 0 0 261px;
  width: 261px;
  box-sizing: border-box;
  border-right: 1px solid ${(p) => p.theme.colors.borders.separator};
  position: sticky;
  top: ${(p) => p.theme.panelHeight};
  max-height: calc(100vh - ${(p) => p.theme.panelHeight});
  background-color: ${(p) => (p) => p.theme.colors.backgrounds.main};

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 1;
  overflow: auto;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  ${(p) => typographyCSS(p.theme.typo.body2)};
  color: ${(p) => p.theme.colors.text.black};
`;

const ListItem = styled.li`
  list-style: none;
  margin: 0;

  &._placeholder {
    &:nth-child(2) {
      opacity: 0.8;
    }
    &:nth-child(3) {
      opacity: 0.6;
    }
    &:nth-child(4) {
      opacity: 0.4;
    }
  }
`;

interface CategoryLinkProps {
  $selected?: boolean;
}

const CategoryLink = styled(Link)<CategoryLinkProps>`
  display: flex;
  padding: 0 16px;
  box-sizing: border-box;
  width: 100%;
  min-height: 48px;
  align-items: center;
  text-decoration: none;

  background-color: ${(p) =>
    p.$selected ? p.theme.colors.service.selected : "transparent"};

  &:hover,
  &:focus {
    outline: 0 none;
    background-color: ${(p) =>
      p.$selected
        ? p.theme.colors.service.selected
        : p.theme.colors.service.hover};
  }
`;

const CategoryTitle = styled.div`
  flex: 1;
  margin: 0 8px;
`;

const CategoryCounter = styled.div`
  flex: 0 0 auto;
  color: ${(p) => p.theme.colors.text.grey};
`;

const Bottom = styled.div`
  flex: 0 0 48px;
  height: 48px;
  border-top: 1px solid ${(p) => p.theme.colors.borders.separator};
  justify-content: center;
  background-color: white;
`;

const ManageLink = styled(Link)`
  padding: 0 18px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 100%;
  text-decoration: none;
  box-sizing: border-box;

  &:hover,
  &:focus {
    outline: 0 none;
    background-color: ${(p) => p.theme.colors.backgrounds.grey};
  }
`;

const IconPlaceholder = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  border-radius: 50%;
`;

const TitlePlaceholder = styled.span`
  display: inline-block;
  width: 144px;
  height: 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  margin-top: 4px;
`;

const CounterPlaceholder = styled.span`
  display: inline-block;
  width: 20px;
  height: 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  margin-top: 4px;
`;
