import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";

import { CardLabel, useCardListQuery } from "../../../graphql";
import { notEmpty } from "../../../utils/notEmpty";

import { FilterBar, getArray, TAGS_KEY } from "./FilterBar/FilterBar";
import { GridView } from "./GridView";

export function TalentsList() {
  const [searchParams] = useSearchParams();
  const selectedTags = getArray(searchParams, TAGS_KEY);
  const wantsUntagged = searchParams.get("untagged") === "1";
  const searchTerm = searchParams.get("q") ?? null;
  const categoryId = searchParams.get("category") ?? null;
  const wantsFav = searchParams.get("fav") === "1";

  let { data, previousData, loading, fetchMore } = useCardListQuery({
    variables: {
      first: 36,
      category: categoryId,
      tags: selectedTags.length > 0 ? selectedTags : null,
      untagged: selectedTags.length === 0 ? wantsUntagged : null,
      labels: wantsFav ? [CardLabel.Favorites] : null,
      after: null,
      query: searchTerm,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const talentsEdges = data?.viewer?.cards?.edges;
  const talents = useMemo(
    () =>
      talentsEdges ? talentsEdges.map((e) => e?.card).filter(notEmpty) : [],
    [talentsEdges]
  );
  const hasMore = data?.viewer?.cards?.pageInfo.hasNextPage ?? false;
  const endCursor = data?.viewer?.cards?.pageInfo.endCursor;

  const untaggedCount =
    data?.viewer?.cards?.aggregations?.untagged ??
    previousData?.viewer?.cards?.aggregations?.untagged ??
    0;
  const skills =
    data?.viewer?.cards?.aggregations?.skills ??
    previousData?.viewer?.cards?.aggregations?.skills ??
    [];

  return (
    <Container>
      <FilterBar skills={skills} untaggedCount={untaggedCount} />
      <GridView
        talents={talents}
        loading={loading}
        hasMore={hasMore}
        onLoadMore={() => {
          if (endCursor) {
            fetchMore({ variables: { after: endCursor } });
          }
        }}
      />
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
`;
