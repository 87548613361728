import styled from "styled-components/macro";
import { Button } from "../../components/Button";
import { BackButton } from "../../components/Overpage/BackButton";

import JetpackImg from "./assets/jetpack.png";
import Jetpack2xImg from "./assets/jetpack@2x.png";
import Jetpack3XImg from "./assets/jetpack@3x.png";

export function UpgradePlan() {
  return (
    <Container>
      <TopPanel>
        <BackButton defaultTo="/talents" />
      </TopPanel>
      <Content>
        <Image
          src={JetpackImg}
          srcSet={`${Jetpack2xImg} 2x,${Jetpack3XImg} 3x`}
        />
        <Title>Upgrade your plan!</Title>
        <Subtitle>
          Upgrade your plan to supercharge your processes and use more features.
          <br />
          If you have any questions or need support feel free to contact us –{" "}
          <a href="mailto:hi@baselance.co">hi@baselance.co</a>
          <ButtonContainer>
            <Button
              size="m"
              kind="contained"
              as="a"
              target="_blank"
              href="https://www.baselance.co/pricing"
            >
              Compare plans
            </Button>
          </ButtonContainer>
        </Subtitle>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  background: #f6f8fa;
  min-height: 100vh;
  box-sizing: border-box;
`;

const TopPanel = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
`;

const Image = styled.img`
  margin-top: 24px;
  max-width: 80%;
`;

const Title = styled.h1`
  margin-top: 30px;
`;

const Subtitle = styled.p`
  margin-top: 10px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;
