import { RefObject, useCallback, useMemo, useRef } from "react";
import styled from "styled-components/macro";

import { TalentAvatar } from "../../../components/Avatar";
import { BaseButton } from "../../../components/Button";
import { ColorButton } from "../../../components/ColorButton";
import { ContactIcon } from "../../../components/ContactIcon/ContactIcon";
import { useOverpageHandler } from "../../../components/Overpage/useOverpageHandler";
import { UIIcon } from "../../../components/UIIcon/UIIcon";
import { CandidateStatus } from "../../../graphql";
import { useDelayedMouseEnter } from "../../../hooks/useDelayedMouseEnter";
import { typographyCSS } from "../../../theme";
import { TalentPreviewType } from "../../../types";
import { isEmpty } from "../../../utils/isEmpty";
import { notEmpty } from "../../../utils/notEmpty";
import { prepareContacts } from "../../../utils/prepareContacts";
import { wordChars } from "../../../utils/wordChars";
import { DeleteCandidateModal } from "./DeleteCandidateModal";
import { channelFromTalent, useListChatController } from "./ListChatController";

interface CandidateCardProps {
  talent: TalentPreviewType;
  positionId: string;
  listId: string;
  parentRef: RefObject<HTMLElement>;
  onCandidateStatusChange: (
    positionId: string,
    talentId: string,
    status: CandidateStatus,
    hook?: (start: boolean) => void
  ) => void;
}

export function CandidateCard({
  talent,
  positionId,
  listId,
  parentRef,
  onCandidateStatusChange,
}: CandidateCardProps) {
  const { setSelectedChannel, setHidden } = useListChatController();
  const containerRef = useRef<HTMLLIElement>(null);
  const [webContacts] = useMemo(
    () => prepareContacts(talent?.contacts ?? []),
    [talent]
  );

  const [cardHovered, cardHoverHandlers] = useDelayedMouseEnter();
  const [expanded, mouseHandlers] = useDelayedMouseEnter();
  const workImages = talent.works?.filter(notEmpty).filter((w) => !!w.image);
  const hasWorks = !!workImages && workImages.length > 0;
  const hasDetails = !isEmpty(webContacts) || !isEmpty(talent.notes);
  const talentLinkHandler = useOverpageHandler({
    to: `/talents/${talent.id}`,
  });

  const transitionHook = useCallback(
    (start: boolean) => {
      const value = start ? "2" : "auto";
      if (containerRef.current) {
        containerRef.current.style.zIndex = value;
      }
      if (parentRef.current) {
        parentRef.current.style.zIndex = value;
      }
    },
    [parentRef]
  );

  const handleDeclineClick = useCallback(() => {
    onCandidateStatusChange(
      positionId,
      talent.id,
      CandidateStatus.Rejected,
      transitionHook
    );
  }, [onCandidateStatusChange, positionId, talent.id, transitionHook]);

  const handleApproveClick = useCallback(() => {
    onCandidateStatusChange(
      positionId,
      talent.id,
      CandidateStatus.Approved,
      transitionHook
    );
  }, [onCandidateStatusChange, positionId, talent.id, transitionHook]);

  const handleChatClick = useCallback(() => {
    setSelectedChannel(channelFromTalent(talent));
    setHidden(false);
  }, [talent, setSelectedChannel, setHidden]);

  return (
    <Container
      ref={containerRef}
      className={cardHovered ? "__hovered" : undefined}
      style={
        {
          viewTransitionName: `candidate-${wordChars(positionId)}-${wordChars(
            talent.id
          )}`,
        } as any
      }
      {...cardHoverHandlers}
    >
      <BackgroundLink {...talentLinkHandler} />
      <Preview
        withDetails={hasDetails}
        {...mouseHandlers}
        className={expanded ? "__expanded" : undefined}
      >
        <WorksPreview tabIndex={-1} {...talentLinkHandler}>
          <WorksBackground>
            <WorkSlot>
              {workImages?.[0] && (
                <WorkImg
                  src={workImages[0].image?.preview200.src || undefined}
                  srcSet={workImages[0].image?.preview200.srcSet || undefined}
                  alt={workImages[0].title || undefined}
                />
              )}
            </WorkSlot>
            <WorkSlot>
              {workImages?.[1] && (
                <WorkImg
                  src={workImages[1].image?.preview200.src || undefined}
                  srcSet={workImages[1].image?.preview200.srcSet || undefined}
                  alt={workImages[1].title || undefined}
                />
              )}
            </WorkSlot>
            <WorkSlot>
              {workImages?.[2] && (
                <WorkImg
                  src={workImages[2].image?.preview200.src || undefined}
                  srcSet={workImages[2].image?.preview200.srcSet || undefined}
                  alt={workImages[2].title || undefined}
                />
              )}
            </WorkSlot>
          </WorksBackground>
        </WorksPreview>

        {hasDetails && (
          <DetailsPreview
            pinned={!hasWorks}
            className={expanded ? "__expanded" : undefined}
          >
            <BackgroundLink tabIndex={-1} {...talentLinkHandler} />
            {webContacts.length > 0 && (
              <WebContacts>
                {webContacts.map((contact) => (
                  <WebContactLink
                    key={contact.url}
                    href={contact.url}
                    target="_blank"
                  >
                    <WebContactIcon name={contact.icon} url={contact.url} />
                  </WebContactLink>
                ))}
              </WebContacts>
            )}
            <NotesPreview
              $placeholder={!talent.notes}
              tabIndex={-1}
              {...talentLinkHandler}
            >
              {(() => {
                if (talent.notes) {
                  return talent.notes;
                }
                if (!hasWorks) {
                  return "No artworks or notes";
                }
                return "No notes";
              })()}
            </NotesPreview>
          </DetailsPreview>
        )}
        {!hasWorks && !hasDetails && (
          <BlankPreview tabIndex={-1} {...talentLinkHandler}>
            No portfolio yet
          </BlankPreview>
        )}
      </Preview>
      <MainContainer>
        <AvatarLink {...talentLinkHandler} tabIndex={-1}>
          <TalentAvatar talent={talent} size="s" />
        </AvatarLink>
        <InfoCol>
          {(talent.name || talent.location) && (
            <>
              <NameLink {...talentLinkHandler} tabIndex={-1}>
                {talent.name ? talent.name : "—"}
              </NameLink>
              <SecondaryDataLink {...talentLinkHandler} tabIndex={-1}>
                {!talent.location && !talent.price ? (
                  "—"
                ) : (
                  <>
                    {talent.location && (
                      <SecondaryItem>{talent.location}</SecondaryItem>
                    )}
                    {talent.price && (
                      <SecondaryItem>
                        {talent.price}
                        {talent.priceUnit ? ` / ${talent.priceUnit}` : ""}
                      </SecondaryItem>
                    )}
                  </>
                )}
              </SecondaryDataLink>
            </>
          )}
        </InfoCol>
        <ActionCol>
          <ColorButton $color="red" onClick={handleDeclineClick}>
            Decline
          </ColorButton>
          <ColorButton $color="green" onClick={handleApproveClick}>
            Approve
          </ColorButton>
        </ActionCol>
      </MainContainer>
      <CardActions $active={false}>
        <CardActionButton onClick={handleChatClick}>
          <UIIcon name="bubble" />
        </CardActionButton>
        <DeleteCandidateModal
          talent={talent}
          positionId={positionId}
          listId={listId}
          trigger={(getTriggerProps) => (
            <CardActionButton {...getTriggerProps()}>
              <UIIcon name="trashbin" />
            </CardActionButton>
          )}
        />
      </CardActions>
    </Container>
  );
}

const CardActions = styled.div<{ $active: boolean }>`
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: ${(p) => (p.$active ? 1 : 0)};
  display: flex;
  align-items: center;
  gap: 4px;
  transition: opacity 0.1s ease-in-out;
`;

const CardActionButton = styled(BaseButton)`
  color: white;
  background-color: ${(p) => p.theme.colors.backgrounds.inverse};
  padding: 4px;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;
`;

const Container = styled.li`
  list-style: none;
  margin: 8px 0 0;
  box-sizing: border-box;
  position: relative;
  padding: 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border-radius: 16px;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  contain: paint;

  &:first-child {
    margin: 0;
  }

  &.__hovered:hover ${CardActions} {
    opacity: 1;
  }
`;

const BackgroundLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 16px;
`;

interface DetailsPreviewProps {
  pinned: boolean;
}

const DetailsPreview = styled.div<DetailsPreviewProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 8px;
  opacity: ${(p) => (p.pinned ? "1" : "0")};
  border-radius: 8px;
  white-space: pre-wrap;
  transition: opacity 0.2s;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  align-items: center;

  &::before {
    content: "";
    margin: auto 0 0;
  }

  &::after {
    content: "";
    margin: 0 0 auto;
  }
`;

const WebContacts = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  margin-left: -4px;
`;

const WebContactIcon = styled(ContactIcon)`
  padding: 4px;
  width: 20px;
  height: 20px;
  color: ${(p) => p.theme.colors.text.black};
`;

const WebContactLink = styled(BaseButton.withComponent("a"))`
  display: block;
  background-color: ${(p) => p.theme.colors.service.selected};
  border-radius: 50%;
`;

const NotesPreview = styled.a<{ $placeholder: boolean }>`
  display: block;
  max-width: 100%;
  text-decoration: none;
  overflow: hidden;
  overflow-wrap: break-word;
  text-align: center;
  ${(p) => typographyCSS(p.theme.typo.body2)};
  color: ${(p) =>
    p.$placeholder ? p.theme.colors.text.grey : p.theme.colors.text.black};
`;

const BlankPreview = styled.a`
  display: block;
  text-decoration: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  color: #817a85;
  background: ${(p) => p.theme.colors.backgrounds.grey};
  border-radius: 8px;
  transition: opacity 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WorksPreview = styled.a`
  display: block;
  overflow: hidden;
  position: relative;
  transition: opacity 0.2s;
`;

interface PreviewProps {
  withDetails: boolean;
}

const Preview = styled.div<PreviewProps>`
  position: relative;
  display: block;

  &:focus-within ${DetailsPreview} {
    opacity: 1;
  }

  &:hover.__expanded ${DetailsPreview} {
    opacity: 1;
  }

  &:hover.__expanded ${WorksPreview} {
    opacity: ${(p) => (p.withDetails ? 0 : 1)};
  }
`;

const WorksBackground = styled.div`
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  grid-column-gap: 4px;
  position: relative;
  background: white;
`;

const WorkSlot = styled.div<{ $placeholder?: boolean }>`
  align-items: center;
  background: ${(p) =>
    p.$placeholder ? p.theme.colors.backgrounds.grey : "transparent"};
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  position: relative;
  border-radius: 8px;
`;

const WorkImg = styled.img`
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
  object-fit: cover;
  top: 0;
`;

const MainContainer = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const AvatarLink = styled.a`
  flex: 0 0 auto;
  position: relative;
  background: ${(p) => p.theme.colors.backgrounds.grey};
  border-radius: 50%;
  display: block;
  line-height: 0;
  overflow: hidden;
  text-decoration: none;
`;

const InfoCol = styled.div`
  flex: 1;
  overflow: hidden;
`;

const ActionCol = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: end;
`;

const NameLink = styled.a`
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => p.theme.colors.text.black};
  ${(p) => typographyCSS(p.theme.typo.highlighted)};
  display: block;
  position: relative;
`;

const SecondaryDataLink = styled.a`
  display: flex;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => p.theme.colors.text.grey};
  ${(p) => typographyCSS(p.theme.typo.body2)};
  transition: color 0.2s;
  position: relative;
`;

const SecondaryItem = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;

  &:before {
    display: inline;
    content: " • ";
  }

  &:first-child {
    &:before {
      all: initial;
    }
  }
`;
