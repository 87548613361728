import styled from "styled-components/macro";
import { Emoji as EmojiMartEmoji, init } from "emoji-mart";
import { useEffect, useLayoutEffect, useRef } from "react";
import appConfig from "../../appConfig";

init({ set: appConfig.emoji.set });

type CategoryIconSize = "m" | "s";

interface CategoryIconProps {
  size?: CategoryIconSize;
  name: string;
  className?: string;
}

export function CategoryIcon({
  className,
  name: rawName,
  size = "m",
}: CategoryIconProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const instance = useRef<EmojiMartEmoji | null>(null);
  const sizeValue = getSizeValue(size);
  const name = fixName(rawName);

  useLayoutEffect(() => {
    if (instance.current) {
      instance.current.update({ id: name, size: sizeValue });
    }
  }, [name, sizeValue]);

  useEffect(() => {
    instance.current = new EmojiMartEmoji({
      ref: ref,
      set: appConfig.emoji.set,
      id: name,
      size: getSizeValue(size),
    });

    return () => {
      instance.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <IconWrapper ref={ref} $sizeValue={sizeValue} className={className} />;
}

const IconWrapper = styled.div<{ $sizeValue: number }>`
  flex: 0 0 auto;
  width: ${(p) => p.$sizeValue}px;
  height: ${(p) => p.$sizeValue}px;

  & img {
    display: block !important;
    position: static !important;
    top: 0 !important;
  }
`;

function getSizeValue(size: CategoryIconSize): number {
  switch (size) {
    case "m":
      return 24;
    case "s":
      return 20;
  }
}

function fixName(oldName: string): string {
  switch (oldName) {
    case "designer":
      return "man";
    case "ios":
      return "bust_in_silhouette";
    case "manager":
      return "woman-raising-hand";
    case "mask":
      return "japanese_goblin";
    case "monkey":
      return "see_no_evil";
    case "pieboy":
      return "man-bowing";
    case "pr":
      return "dancers";
    case "schul":
      return "skull";
    case "smile":
      return "sunglasses";
    case "suit":
      return "necktie";
  }

  return oldName;
}
