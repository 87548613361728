import styled from "styled-components";

interface PieProps {
  radius: number;
  fraction: number;
  className?: string;
}

export function Pie({ fraction, className, radius }: PieProps) {
  const size = radius * 2;
  const angle = 2 * Math.PI * fraction;
  const arcY = radius - radius * Math.cos(angle);
  const arcX = radius + radius * Math.sin(angle);
  const arcFlag = fraction > 0.5 ? 1 : 0;

  return (
    <PieSvg
      className={className}
      width={`${size}`}
      height={`${size}`}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      {fraction >= 1 ? (
        <circle
          cx={`${radius}`}
          cy={`${radius}`}
          r={`${radius}`}
          fill="var(--color-filled)"
        />
      ) : (
        <circle
          cx={`${radius}`}
          cy={`${radius}`}
          r={`${radius - 0.5}`}
          fill="var(--color-bg)"
          stroke="var(--color-border)"
        />
      )}
      {fraction > 0 && fraction < 1 && (
        <path
          d={`M${radius},${radius}L${radius},0A${radius},${radius} 1 ${arcFlag},1 ${arcX},${arcY}Z`}
          fill="var(--color-filled)"
        />
      )}
    </PieSvg>
  );
}

const PieSvg = styled.svg`
  --color-bg: ${(p) => p.theme.colors.backgrounds.main};
  --color-filled: ${(p) => p.theme.colors.statuses.success};
  --color-border: ${(p) => p.theme.colors.borders.separator};
`;
