import styled from "styled-components/macro";
import { HighlightColorKind, typographyCSS } from "../theme";
import { BaseButton } from "./Button";

export const ColorButton = styled(BaseButton)<{ $color: HighlightColorKind }>`
  text-align: center;
  text-decoration: none;
  background: transparent;
  border: 1px solid ${(p) => p.theme.colors.text.black};
  border-radius: 6px;
  padding: 6px 16px;
  ${(p) => typographyCSS(p.theme.typo.buttonM)};

  &:enabled {
    &:hover {
      background-color: ${(p) => p.theme.colors.highlights[p.$color].fg};
      border-color: ${(p) => p.theme.colors.highlights[p.$color].fg};
      color: ${(p) => p.theme.colors.text.white};
    }

    &:hover:not(:active)::after {
      background-color: transparent;
    }
  }
`;
