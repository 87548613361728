import {useCallback, useEffect, useRef} from "react";

export function useUnmounted(): () => boolean {
  const ref = useRef(false);
  const getter = useCallback(() => ref.current, []);

  useEffect(() => {
    ref.current = false;
    return () => {
      ref.current = true;
    }
  }, []);

  return getter;
}

