import { useCallback, useMemo } from "react";
import styled from "styled-components/macro";
import format from "date-fns/fp/format";
import { AvatarPlaceholder, TalentAvatar } from "../../../../components/Avatar";
import { TalentType } from "../../../../types";
import { ContactIcon as BaseContactIcon } from "../../../../components/ContactIcon/ContactIcon";

import { typographyCSS } from "../../../../theme";
import { CategoryIcon } from "../../../../components/CategoryIcon/CategoryIcon";
import { ActionButton } from "../../../../components/ActionButton";
import { useSetCardFavoriteMutation } from "../../../../graphql";
import { prepareContacts } from "../../../../utils/prepareContacts";
import { useViewer } from "../../../../components/context/auth_gate";
import { isGranted } from "../../../../acl";
import { UIIcon } from "../../../../components/UIIcon/UIIcon";
import { BaseButton } from "../../../../components/Button";
import { Header, Separator } from "../ui";
import { ContactItem } from "./ContactItem";
import { ListsSection } from "./ListsSection";
import { FloatingTree } from "@floating-ui/react";

interface SidebarProps {
  loading: boolean;
  talent: TalentType | null | undefined;
}

export function Sidebar({ loading, talent }: SidebarProps) {
  const viewer = useViewer();
  const [webContacts, contacts] = useMemo(
    () => prepareContacts(talent?.contacts ?? []),
    [talent]
  );

  const [setCardFavorite] = useSetCardFavoriteMutation();

  const hadleFavClick = useCallback(() => {
    if (!talent) {
      return;
    }
    setCardFavorite({
      variables: {
        input: {
          id: talent.id,
          value: !talent.favorite,
          clientMutationId: null,
        },
      },
      optimisticResponse: {
        setCardFavorite: {
          __typename: "SetCardFavoritePayload",
          card: {
            __typename: "Card",
            id: talent.id,
            favorite: !talent.favorite,
          },
        },
      },
    });
  }, [talent, setCardFavorite]);

  return (
    <Container>
      <Centered>
        {talent ? (
          <>
            <FavoriteButton
              icon={talent.favorite ? "heartfilled" : "heart"}
              $active={talent.favorite}
              onClick={hadleFavClick}
              disabled={!isGranted(viewer, "talent.edit")}
            />
            <Rating>
              {talent.rating ? talent.rating.toFixed(1) : "—"}
              {talent.rating && talent.rating < 2.5 ? (
                <UIIcon name="shitcolor" />
              ) : (
                <UIIcon name="star" />
              )}
            </Rating>
            <TalentAvatar talent={talent} size="l" />
            <Name>
              <h3>{talent.name}</h3>
            </Name>
            <Location>{talent.location}</Location>
            {talent.price && (
              <Price>
                {talent.price}
                {talent.priceUnit ? ` / ${talent.priceUnit}` : ""}
              </Price>
            )}
            {talent.priceComment && (
              <Price>
                {talent.priceComment}
              </Price>
            )}
            <WebContacts>
              {webContacts &&
                webContacts.map((contact) => (
                  <WebContactLink
                    key={contact.url}
                    href={contact.url}
                    target="_blank"
                  >
                    <WebContactIcon name={contact.icon} url={contact.url} />
                  </WebContactLink>
                ))}
            </WebContacts>
          </>
        ) : (
          <>
            <AvatarPlaceholder size="l" />
            <NamePlaceholder />
            <SecondaryPlaceholder />
            <SecondaryPlaceholder />
            <WebContacts>
              <WebContactIconPlaceholder />
              <WebContactIconPlaceholder />
              <WebContactIconPlaceholder />
            </WebContacts>
          </>
        )}
      </Centered>
      <Separator />
      {contacts.length > 0 && (
        <Section>
          <Header>Contacts</Header>
          <ContactList>
            {contacts.map((contact) => (
              <ContactItem key={contact.title} contact={contact} />
            ))}
          </ContactList>
        </Section>
      )}
      {talent?.categories && (
        <Section>
          <Header>Categories</Header>
          <CategoryList>
            {talent?.categories.map((category) => (
              <CategoryListItem key={category.id}>
                <CategoryIcon name={category.icon} />
                {category.name}
              </CategoryListItem>
            ))}
          </CategoryList>
        </Section>
      )}
      {talent?.notes && (
        <Section>
          <Header>Notes</Header>
          <Notes>{talent.notes}</Notes>
        </Section>
      )}
      {talent?.lists && (
        <FloatingTree>
          <Section
            as={ListsSection}
            lists={talent.lists}
            talentId={talent.id}
          />
        </FloatingTree>
      )}
      {talent && (
        <Created>
          Created on {format("d MMM yyyy", new Date(talent.createdAt))}
        </Created>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 300px;
  flex: 0 0 300px;
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 16px;
  padding: 24px 24px 16px;
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  margin: 16px auto 4px;
  text-align: center;
`;

const Location = styled.div`
  color: ${(p) => p.theme.colors.text.grey};
  text-align: center;
`;

const Price = styled.div`
  color: ${(p) => p.theme.colors.text.grey};
  text-align: center;
`;

const WebContacts = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  justify-content: center;
`;

const WebContactIcon = styled(BaseContactIcon)`
  padding: 4px;
  width: 20px;
  height: 20px;
  color: ${(p) => p.theme.colors.text.white};
`;

const WebContactLink = styled(BaseButton.withComponent("a"))`
  display: block;
  border-radius: 50%;
  background: ${(p) => p.theme.colors.backgrounds.inverse};
`;

const NamePlaceholder = styled.div`
  width: 150px;
  height: 20px;
  background: ${(p) => p.theme.colors.backgrounds.grey};
  margin: 20px auto 8px;
`;

const SecondaryPlaceholder = styled.div`
  width: 120px;
  height: 16px;
  background: ${(p) => p.theme.colors.backgrounds.grey};
  margin: 4px auto 4px;
`;

const WebContactIconPlaceholder = styled.div`
  border-radius: 50%;
  background: ${(p) => p.theme.colors.backgrounds.grey};
  width: 24px;
  height: 24px;
`;

const CategoryList = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
`;

const CategoryListItem = styled.li`
  list-style: none;
  margin: 0 0 8px;
  gap: 8px;
  display: flex;
`;

const ContactList = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
`;

const Created = styled.div`
  color: ${(p) => p.theme.colors.text.grey};
  ${(p) => typographyCSS(p.theme.typo.body2)};
`;

const FavoriteButton = styled(ActionButton)<{ $active: boolean }>`
  position: absolute;
  top: 14px;
  left: 12px;
  color: ${(p) => (p.$active ? "inherit" : p.theme.colors.ui.greyFilled)};
`;

const Rating = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Notes = styled.div`
  overflow-wrap: break-word;
`;
