import { useCallback } from "react";
import styled from "styled-components/macro";
import { CategoryData } from "../../types";
import { CategoryIcon } from "../CategoryIcon/CategoryIcon";
import { CloseIcon } from "../Close/CloseIcon";

interface SelectedCategoryProps {
  category: CategoryData;
  onClick: (category: CategoryData) => void;
}

export function SelectedCategory({ category, onClick }: SelectedCategoryProps) {
  const handleClick = useCallback(() => {
    onClick(category);
  }, [onClick, category]);

  return (
    <Button type="button" onClick={handleClick} title={category.name}>
      <CategoryIcon size="s" name={category.icon} />
      <Name>{category.name}</Name>
      <CloseContainer>
        <CloseIcon />
      </CloseContainer>
    </Button>
  );
}

const Button = styled.button`
  cursor: pointer;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 1px 5px 1px 3px;
  box-sizing: border-box;
  max-width: 280px;
`;

const Name = styled.span`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CloseContainer = styled.div`
  flex: 0 0 auto;
  padding: 2px;
`;
