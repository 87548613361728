export async function copyToClipboard(value: string) {
  if (!navigator?.clipboard) {
    // TODO: send to sentry
    return false;
  }

  try {
    await navigator.clipboard.writeText(value);
  } catch (err) {
    // Send to sentry
    return false;
  }

  return true;
}
