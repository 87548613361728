import { useCallback } from "react";
import styled from "styled-components";
import { BaseButton } from "../../../components/Button";
import { ReactComponent as ExcelSvg } from "./services/excel.svg";
import { ReactComponent as AirtableSvg } from "./services/airtable.svg";
import { ReactComponent as EvernoteSvg } from "./services/evernote.svg";
import { ReactComponent as NotebookSvg } from "./services/notebook.svg";
import { ReactComponent as OtherSvg } from "./services/other.svg";

interface Service {
  icon: JSX.Element | null;
  title: string;
  value: string;
}

const Services: Service[] = [
  { icon: <EvernoteSvg />, title: "Evernote", value: "evernote" },
  { icon: <AirtableSvg />, title: "Airtable", value: "airtable" },
  { icon: <ExcelSvg />, title: "Excel / Google Sheets", value: "excel" },
  { icon: <NotebookSvg />, title: "Note / Notebook", value: "note" },
  { icon: <OtherSvg />, title: "Other", value: "other" },
];

interface ServiceSelectProps {
  value: string;
  onChange: (value: string) => void;
}

export function ServiceSelect({
  value: currentValue,
  onChange,
}: ServiceSelectProps) {
  return (
    <Container>
      {Services.map((service) => (
        <SelectVariant
          key={service.value}
          service={service}
          selected={service.value === currentValue}
          onChange={onChange}
        />
      ))}
    </Container>
  );
}

interface SelectVariantProps {
  service: Service;
  selected: boolean;
  onChange: (value: string) => void;
}

function SelectVariant({ service, selected, onChange }: SelectVariantProps) {
  const handleClick = useCallback(() => {
    onChange(service.value);
  }, [onChange, service.value]);

  return (
    <VariantButton type="button" onClick={handleClick} $selected={selected}>
      {service.icon}
      <div>{service.title}</div>
    </VariantButton>
  );
}

const Container = styled.div``;

const VariantButton = styled(BaseButton)<{ $selected: boolean }>`
  width: 100%;
  height: 48px;
  margin-bottom: 8px;
  padding: 6px 8px;
  flex: 1 1 auto;
  border: 1px solid
    ${(p) =>
      p.$selected
        ? p.theme.colors.ui.purple
        : p.theme.colors.borders.separator};
  border-radius: 8px;
  cursor: pointer;
  background: ${(p) =>
    p.$selected ? p.theme.colors.highlights.purple.bgAlt : "transparent"};
  color: ${(p) =>
    p.$selected ? p.theme.colors.ui.purple : p.theme.colors.text.black};
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    color 0.15s ease-in-out;
`;
