import styled from "styled-components/macro";
import { BackButton } from "../../components/Overpage/BackButton";
import { PasswordForm } from "./PasswordForm";
import { ProfileForm } from "./ProfileForm";

export function ProfilePage() {
  return (
    <Container>
      <TopPanel>
        <BackButton defaultTo="/talents" />
      </TopPanel>
      <Title>Profile</Title>
      <Content>
        <ProfileForm />
        <PasswordForm />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  margin: 0 auto;
  padding-bottom: 80px;
  box-sizing: border-box;
  max-width: 2678px;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  min-height: 100%;
`;

const TopPanel = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 24px;
`;

const Content = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;
