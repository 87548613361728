import { Middleware } from "@floating-ui/react";
import { MutableRefObject, useRef } from "react";

interface StickyTopOptions {
  topMemoRef: MutableRefObject<number | null>;
}

export const stickyTop = ({ topMemoRef }: StickyTopOptions): Middleware => ({
  name: "stickyTop",
  fn({ x, y }) {
    const prevY = topMemoRef.current;
    const nextY = prevY && prevY < y ? prevY : y;
    topMemoRef.current = nextY;

    return { y: nextY, x };
  },
});

interface UseStickyTopReturn {
  middleware: Middleware;
  handleClose: () => void;
}

export function useStickyTop(): UseStickyTopReturn {
  const topMemoRef = useRef<number | null>(null);
  const returnRef = useRef<UseStickyTopReturn>();

  if (!returnRef.current) {
    returnRef.current = {
      middleware: stickyTop({ topMemoRef }),
      handleClose: () => (topMemoRef.current = null),
    };
  }

  return returnRef.current;
}
