import { useState } from "react";
import styled from "styled-components";
import { ActionButton } from "../../../../components/ActionButton";
import { ExpandingButton } from "../../../../components/buttons/CollapsingButton";
import { useListsPicker } from "../../../../components/ListsPicker/ListsPicker";
import { OverpageLink } from "../../../../components/Overpage/OverpageLink";
import { typographyCSS } from "../../../../theme";
import { Header } from "../ui";

const LIST_SIZE_LIMIT = 2;

interface ListsSectionProps {
  className?: string;
  talentId: string;
  lists: {
    id: string;
    title: string;
  }[];
}

const refetchQueries = ["Talent"];

export function ListsSection({
  className,
  lists,
  talentId,
}: ListsSectionProps) {
  const [isExpanded, setExpanded] = useState(false);
  const [isListsPickerOpened, setListsPickerOpened] = useState(false);
  const [getReferenceProps, listsPickerElement] = useListsPicker({
    talentId,
    opened: isListsPickerOpened,
    setOpened: setListsPickerOpened,
    refetchQueries,
  });

  const isExpandable = lists.length > LIST_SIZE_LIMIT;

  const renderedLists =
    isExpandable && !isExpanded ? lists.slice(0, LIST_SIZE_LIMIT) : lists;

  return (
    <div className={className}>
      <Header>
        Lists
        <ActionButton icon="plus" {...getReferenceProps()} />
      </Header>
      {lists.length > 0 ? (
        <List>
          {renderedLists.map((list) => (
            <ListItem key={list.id}>
              <OverpageLink to={`/lists/${list.id}`}>{list.title}</OverpageLink>
            </ListItem>
          ))}
        </List>
      ) : (
        <EmptyMessage>No lists</EmptyMessage>
      )}
      {isExpandable && (
        <ExpandingButton
          isExpanded={isExpanded}
          setExpanded={setExpanded}
          expandText="All lists"
          collapseText="Less lists"
        />
      )}
      {listsPickerElement}
    </div>
  );
}

const List = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  list-style: none;
  margin: 0 0 8px;
  gap: 8px;
  display: flex;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const EmptyMessage = styled.div`
  color: ${(p) => p.theme.colors.text.grey};
  ${(p) => typographyCSS(p.theme.typo.body2)};
`;
