import { FloatingTree } from "@floating-ui/react";
import { useCallback } from "react";
import {
  Control,
  Controller,
  FieldError,
  UseFormRegister,
} from "react-hook-form";
import styled from "styled-components/macro";
import { ErrorMsg, FormInput, Row, Textarea } from "../../../components/FormUI";
import { NumberControl } from "../../../components/NumberControl";
import { DeletePositionButton } from "./DeletePositionButton";
import { FormData } from "./ListForm";
import { SkillsControl } from "./SkillsControl";

export interface PositionFormData {
  title: string;
  description: string;
}

interface PositionSubformProps {
  index: number;
  removable: boolean;
  errors:
    | {
        [key in keyof PositionFormData]?: FieldError;
      }
    | undefined;
  register: UseFormRegister<FormData>;
  control: Control<FormData>;
  removePosition: (index: number) => void;
}

export function PositionSubform({
  index,
  removable,
  errors,
  register,
  control,
  removePosition,
}: PositionSubformProps) {
  const handleRemove = useCallback(() => {
    removePosition(index);
  }, [removePosition, index]);
  return (
    <Container>
      <Row>
        <FormInput
          {...register(`positions.${index}.title`)}
          autoComplete="off"
          placeholder="Position name"
        />
        {errors?.title && (
          <ErrorMsg>{errors.title.message ?? "Fill position name"}</ErrorMsg>
        )}
      </Row>
      <Row>
        <Textarea
          placeholder="Description"
          {...register(`positions.${index}.description`)}
          minRows={2}
          maxRows={10}
        />
      </Row>
      <Row>
        <Controller
          name={`positions.${index}.skills`}
          control={control}
          render={({ field, fieldState }) => (
            <FloatingTree>
              <SkillsControl
                skills={field.value}
                onChange={field.onChange}
                fieldState={fieldState}
              />
            </FloatingTree>
          )}
        />
      </Row>
      <BottomRow>
        <Controller
          name={`positions.${index}.count`}
          control={control}
          render={({ field }) => (
            <NumberControl
              value={field.value}
              minValue={1}
              onChange={field.onChange}
            />
          )}
        />
        {removable && (
          <DeletePositionButton
            onConfirm={handleRemove}
            index={index}
            control={control}
          />
        )}
      </BottomRow>
    </Container>
  );
}

const Container = styled.div`
  background: ${(p) => p.theme.colors.backgrounds.grey};
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
`;

const BottomRow = styled(Row)`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;
