import styled from "styled-components/macro";

export const WorkSlot = styled.div`
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  border-radius: 16px;

  box-sizing: border-box;
  width: 100%;
  height: 0;
  padding: 0 0 75%;
  position: relative;
`;

export const NoPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0 20px;
  color: ${(p) => p.theme.colors.text.grey};

  svg {
    display: block;
    fill: currentColor;
  }
`;
