import {
  Control,
  useForm,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";
import styled from "styled-components/macro";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ActionButton } from "../../../components/ActionButton";
import {
  MainFormPart,
  FormData as MainFormData,
  schema as mainFormSchema,
} from "../../../components/TalentForm/MainFormPart";
import {
  SecondFormPart,
  FormData as SecondFormData,
  schema as secondFormSchema,
} from "../../../components/TalentForm/SecondFormPart";
import { typographyCSS } from "../../../theme";
import { ProfileUrlExtraction } from "../../../types";
import { Button } from "../../../components/Button";
import { useBackButton } from "../../../components/Overpage/BackButton";
import { useCreateTalentMutation } from "../../../graphql";
import { useNavigate } from "react-router-dom";
import { useGeneralLocationState } from "../../../hooks/useGeneralLocationState";
import { gql } from "graphql.macro";

interface FormData extends MainFormData, SecondFormData {}

interface FormStepProps {
  extraction: ProfileUrlExtraction | null;
  onBack: () => void;
}

const schema = yup
  .object({
    ...mainFormSchema,
    ...secondFormSchema,
  })
  .required();

export function FormStep({ extraction, onBack }: FormStepProps) {
  const profile = extraction?.profile;
  const handleBackClick = useBackButton({ defaultTo: "/talents" });
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: profile?.name ?? "",
      location: profile?.location ?? "",
      price: "",
      priceUnit: "",
      priceComment: "",
      contacts: [
        ...(profile?.contacts?.map((c) => ({ value: c.url })) ?? []),
        { value: "" },
      ],
      categories: [],
      bio: profile?.about ?? "",
      notes: "",
      skills: [],
    },
  });

  const [locationState] = useGeneralLocationState();
  const navigate = useNavigate();
  const [createTalent] = useCreateTalentMutation();

  const handleFormSubmit = handleSubmit((data) => {
    return createTalent({
      variables: {
        input: {
          image: data.avatarImage?.id ?? null,
          name: data.name,
          location: data.location,
          price: data.price,
          pricePeriod: data.priceUnit,
          priceComment: data.priceComment,
          contacts: data.contacts.map((contact) => contact.value),
          categories: data.categories.map((category) => category.id),
          comment: null,
          about: data.bio,
          notes: data.notes,
          tags: data.skills,
          clientMutationId: null,
          source: "manual",
          link: extraction?.url ?? null,
          imageUrl: null,
          labels: null,
          works: null,
        },
      },
      refetchQueries: ["CardList"],
      update(cache) {
        for (let i = 0; i < data.categories?.length; i++) {
          cache.updateFragment(
            {
              id: cache.identify({
                id: data.categories[i].id,
                __typename: "Category",
              }),
              fragment: gql`
                fragment MyCategory on Category {
                  id
                  cardsCount
                }
              `,
            },
            (categoryData) => ({
              ...categoryData,
              cardsCount:
                typeof categoryData.cardsCount === "number"
                  ? categoryData.cardsCount + 1
                  : categoryData.cardsCount,
            })
          );
        }
      },
      onCompleted(data) {
        const talentId = data.createCard?.card?.id;
        if (!talentId) return;
        const backgroundLocation = locationState?.backgroundLocation;

        navigate(`/talents/${talentId}`, {
          state: { backgroundLocation },
          replace: true,
        });
      },
    });
  });

  return (
    <>
      <Box>
        <FormMode>
          <Profile>
            <Label>Profile:</Label>{" "}
            {extraction ? (
              <a href={extraction.url} target="_blank" rel="noreferrer">
                {extraction.url}
              </a>
            ) : (
              "Manual mode"
            )}
          </Profile>
          <ActionButton icon="pencil" onClick={onBack} />
        </FormMode>
      </Box>
      <form onSubmit={handleFormSubmit}>
        <MainFormPart
          profileAvatarUrl={profile?.avatarUrl ?? null}
          hasBio={profile?.about ? true : false}
          register={register as any as UseFormRegister<MainFormData>}
          control={control as any as Control<MainFormData>}
          getValues={getValues as any as UseFormGetValues<MainFormData>}
        />
        <SecondFormPart
          register={register as any as UseFormRegister<SecondFormData>}
          control={control as any as Control<SecondFormData>}
        />
        <Buttons>
          <Button $size="xl" $kind="contained" disabled={isSubmitting}>
            Save
          </Button>
          <Button
            type="button"
            $size="l"
            $kind="text"
            onClick={handleBackClick}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </Buttons>
      </form>
    </>
  );
}

const Box = styled.div`
  padding: 20px 24px;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  margin-bottom: 24px;
`;

const FormMode = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Profile = styled.div`
  color: ${(p) => p.theme.colors.text.grey};

  a {
    color: inherit;
  }
`;

const Label = styled.label`
  ${(p) => typographyCSS(p.theme.typo.highlighted)};
  color: ${(p) => p.theme.colors.text.black};
`;

const Buttons = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
