const FILTER_SKILL_SORT_IDX_MATCH = 1;
const FILTER_SKILL_SORT_IDX_BEGIN_LINE = 2;
const FILTER_SKILL_SORT_IDX_BEGIN_WORD = 3;
const FILTER_SKILL_SORT_IDX_REST = 4;

export function filterSkills(
  skills: string[],
  filterInput: string
): string[] {
  if (filterInput.length === 0) {
    return skills;
  }

  const result = [] as {
    skill: string;
    sortIdx: number;
  }[];

  skills.forEach((skill) => {
    const occurPosition = skill.indexOf(filterInput);
    if (occurPosition < 0) {
      return;
    }

    let sortIdx = FILTER_SKILL_SORT_IDX_REST;

    if (filterInput === skill) {
      sortIdx = FILTER_SKILL_SORT_IDX_MATCH;
    } else if (occurPosition === 0) {
      sortIdx = FILTER_SKILL_SORT_IDX_BEGIN_LINE;
    } else if (new RegExp(`\\b${escapeRegExp(filterInput)}`).test(skill)) {
      sortIdx = FILTER_SKILL_SORT_IDX_BEGIN_WORD;
    }

    result.push({ skill, sortIdx });
  });

  result.sort((a, b) => a.sortIdx - b.sortIdx);

  return result.map((res) => res.skill);
}

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
