import { ContactIconKind } from "../components/ContactIcon/ContactIcon";
import {tryURL} from "./tryURL";
import { validateEmail } from "./validateEmail";

export interface WebContact {
  url: string;
  icon: ContactIconKind;
}

export interface Contact {
  icon: ContactIconKind;
  title: string;
  url?: string;
}

interface WebsiteSpec {
  regexp: RegExp;
  icon: ContactIconKind;
}

interface ContactUrlSpec {
  regexp: RegExp;
  icon: ContactIconKind;
  getTitle: (url: string) => string | null;
}

const supportedSites: WebsiteSpec[] = [
  {
    regexp: /^https?:\/\/(www\.)?behance\.(net|com)\//i,
    icon: "behance",
  },
  {
    regexp: /^https?:\/\/([^.]+\.)?artstation\.com/i,
    icon: "artstation",
  },
  {
    regexp: /^https?:\/\/(www\.)?dribbble\.com\//i,
    icon: "dribbble",
  },
  {
    regexp: /^https?:\/\/([^.]+\.)?facebook\.com\//i,
    icon: "facebook",
  },
  {
    regexp: /^https?:\/\/(www\.)?github\.com\//i,
    icon: "github",
  },
  {
    regexp: /^https?:\/\/(www\.)?instagram\.com\//i,
    icon: "instagram",
  },
  {
    regexp: /^https?:\/\/([^.]+\.)?linkedin\.com\//i,
    icon: "linkedin",
  },
  {
    regexp: /^https?:\/\/(www\.)?linktr\.ee\//i,
    icon: "linktree",
  },
  {
    regexp: /^https?:\/\/(www\.)?pinterest\.(com|\w\w|co\.\w\w)\//i,
    icon: "pinterest",
  },
  {
    regexp: /^https?:\/\/(www\.)?twitch\.(tv|com)\//i,
    icon: "twitch",
  },
  {
    regexp: /^https?:\/\/(www\.)?twitter\.com\//i,
    icon: "twitter",
  },
  {
    regexp: /^https?:\/\/(www\.)?vimeo\.com\//i,
    icon: "vimeo",
  },
  {
    regexp: /^https?:\/\/(www\.)?vimeo\.com\//i,
    icon: "vimeo",
  },
  {
    regexp: /^https?:\/\/(www\.)?vk\.com\//i,
    icon: "vk",
  },
  {
    regexp: /^https?:\/\/(www\.)?youtube\.com\//i,
    icon: "youtube",
  },
];

const supportedContactUrls: ContactUrlSpec[] = [
  {
    regexp: /^https?:\/\/t\.me\//i,
    icon: "telegram",
    getTitle: (url) => {
      let matches = url.match(/https?:\/\/t\.me\/([^/?#]+)/i);
      return matches ? '@' + matches[1] : null;
    },
  },
];

export function prepareContacts(
  plainContacts: string[]
): [WebContact[], Contact[]] {
  const web = [] as WebContact[];
  const contacts = [] as Contact[];

  for (let contact of plainContacts) {
    if (contact.length === 0) {
      continue;
    }

    const contactURL = tryURL(contact);

    if (contactURL.isURL && isHTTP(contactURL.url.protocol)) {
      const site = supportedSites.find((site) => site.regexp.test(contact));
      if (site) {
        web.push({ url: contact, icon: site.icon });
        continue;
      }

      const contactUrl = supportedContactUrls.find((site) =>
        site.regexp.test(contact)
      );

      if (contactUrl) {
        let title = contactUrl.getTitle(contact);
        if (title) {
          contacts.push({
            icon: contactUrl.icon,
            title,
            url: contact,
          });
          continue;
        }
      }

      web.push({ url: contact, icon: "website" });
    } else if (contactURL.isURL && contactURL.url.protocol === "mailto:") {
      contacts.push({
        icon: "email",
        title: contactURL.url.pathname,
        url: contact,
      });
    } else if (contactURL.isURL && contactURL.url.protocol === "tel:") {
      contacts.push({
        icon: "phone",
        title: contactURL.url.pathname.trim(),
        url: contact,
      });
    } else if (validateEmail(contact)) {
      contacts.push({
        icon: "email",
        title: contact,
        url: `mailto:${contact}`,
      });
    } else {
      contacts.push({
        icon: "contact",
        title: contact,
      });
    }
  }

  return [web, contacts];
}

function isHTTP(protocol: string): boolean {
  return protocol === "http:" || protocol === "https:";
}
