import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import React, { ReactElement, ReactNode, useCallback, useState } from "react";
import styled from "styled-components/macro";

interface RenderProps {
  close: () => void;
  getContentProps: (
    props?: React.HTMLProps<HTMLElement>
  ) => Record<string, unknown>;
}

interface BaseModalProps {
  opened: boolean;
  setOpened: (value: boolean) => void;
  trigger?: (
    getReferenceProps: (
      props?: React.HTMLProps<Element>
    ) => Record<string, unknown>
  ) => ReactNode;
  render: (renderProps: RenderProps) => ReactElement;
}

interface ControlledModalProps {
  opened: boolean;
  setOpened: (value: boolean) => void;
  render: (renderProps: RenderProps) => ReactElement;
}

export interface ModalProps {
  trigger: (
    getReferenceProps: (
      props?: React.HTMLProps<Element>
    ) => Record<string, unknown>
  ) => ReactNode;
  render: (renderProps: RenderProps) => ReactElement;
}

export function Modal({ trigger, render }: ModalProps) {
  const [opened, setOpened] = useState(false);

  return BaseModal({ opened, setOpened, trigger, render });
}

export function ControlledModal({
  opened,
  setOpened,
  render,
}: ControlledModalProps) {
  return BaseModal({ opened, setOpened, render });
}

function BaseModal({ opened, setOpened, trigger, render }: BaseModalProps) {
  const { reference, floating, context } = useFloating({
    open: opened,
    onOpenChange: setOpened,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context),
  ]);

  const close = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  const getTriggerProps = useCallback(
    (props?: React.HTMLProps<Element>) =>
      getReferenceProps({
        ...props,
        ref: reference,
      }),
    [getReferenceProps, reference]
  );

  const getContentProps = useCallback(
    (props?: React.HTMLProps<Element>) =>
      getFloatingProps({
        ...props,
        ref: floating,
      }),
    [getFloatingProps, floating]
  );

  return (
    <>
      {trigger?.(getTriggerProps)}
      <FloatingPortal>
        {opened && (
          <Overlay lockScroll>
            <FloatingFocusManager context={context}>
              {render({ close, getContentProps })}
            </FloatingFocusManager>
          </Overlay>
        )}
      </FloatingPortal>
    </>
  );
}

const Overlay = styled(FloatingOverlay)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.service.overlay};
  z-index: ${(p) => p.theme.layers.overlay};
`;
