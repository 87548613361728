import { forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";
import { useGeneralLocationState } from "../../hooks/useGeneralLocationState";

export const OverpageLink = forwardRef<HTMLAnchorElement, LinkProps>(
  function OverpageLink(props, ref) {
    const [locationState, location] = useGeneralLocationState();

    return (
      <Link
        {...props}
        state={{
          ...props.state,
          backgroundLocation: locationState?.backgroundLocation ?? location,
        }}
        ref={ref}
      />
    );
  }
);
