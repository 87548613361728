export function waitFor(cond: () => boolean, fn: () => void, timeout: number) {
  if (cond()) {
    fn();
    return;
  }

  if (timeout <= 0) {
    return;
  }

  setTimeout(() => {
    waitFor(cond, fn, timeout - 1000);
  }, 1000);
}
