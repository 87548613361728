import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { useAuthGate } from "../../../components/context/auth_gate";
import { Logo } from "../../../components/Logo/Logo";
import { AccountKindStage } from "./AccountKindStage";
import { EmailStage } from "./EmailStage";
import { ImportStage } from "./ImportStage";

import { ReactComponent as BrikkLogoSvg } from "./logos/Brikk.svg";
import { ReactComponent as INDGLogoSvg } from "./logos/INDG.svg";
import { ReactComponent as MALogoSvg } from "./logos/MA.svg";
import { ReactComponent as MetaxilasisLogoSvg } from "./logos/metaxilasis.svg";
import { ReactComponent as WARLogoSvg } from "./logos/WAR.svg";
import { OrgSetupStage } from "./OrgSetupStage";
import { PersonalInfoStage } from "./PersonalInfoStage";
import { Survey } from "./Survey";

export enum SignUpStage {
  Email,
  PersonalInfo,
  AccountKind,
  OrgSetup,
  Survey,
  Import,
}

interface SignUpProps {
  initialStage?: SignUpStage;
}

export function SignUp({ initialStage = SignUpStage.Email }: SignUpProps) {
  const viewer = useAuthGate();
  const [stage, setStage] = useState(initialStage);
  const [email, setEmail] = useState("");

  const setPersonalInfoStage = useCallback((email: string) => {
    setEmail(email);
    setStage(SignUpStage.PersonalInfo);
  }, []);

  const setAccountKindStage = useCallback(() => {
    setStage(SignUpStage.AccountKind);
  }, []);

  const setOrgSetupStage = useCallback(() => {
    setStage(SignUpStage.OrgSetup);
  }, []);

  const setSurveyStage = useCallback(() => {
    setStage(SignUpStage.Survey);
  }, []);

  const setImportStage = useCallback(() => {
    setStage(SignUpStage.Import);
  }, []);

  const handleFinal = useCallback(() => {
    document.location = "/";
  }, []);

  return (
    <Container>
      <Panel>
        <LogoLink href="/">
          <Logo />
        </LogoLink>
        {viewer ? (
          <div />
        ) : (
          <SignInSection>
            <span>Already have an account? </span>
            <PanelLink to="/auth/signin">Sign in</PanelLink>
          </SignInSection>
        )}
      </Panel>
      <Content>
        {stage === SignUpStage.Email && (
          <EmailStage email={email} onComplete={setPersonalInfoStage} />
        )}
        {stage === SignUpStage.PersonalInfo && (
          <PersonalInfoStage email={email} onComplete={setAccountKindStage} />
        )}
        {stage === SignUpStage.AccountKind && (
          <AccountKindStage
            onIndividual={setSurveyStage}
            onOrganization={setOrgSetupStage}
          />
        )}
        {stage === SignUpStage.OrgSetup && (
          <OrgSetupStage onComplete={setSurveyStage} />
        )}
        {stage === SignUpStage.Survey && <Survey onComplete={setImportStage} />}
        {stage === SignUpStage.Import && (
          <ImportStage onComplete={handleFinal} />
        )}
      </Content>
      <Footer>
        <span>Baselance trusted by</span>
        <Logos>
          <BrikkLogoSvg width={100} />
          <WARLogoSvg width={100} />
          <MALogoSvg width={100} />
          <MetaxilasisLogoSvg width={100} />
          <INDGLogoSvg width={100} />
        </Logos>
      </Footer>
    </Container>
  );
}

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Panel = styled.div`
  flex: 0 0 ${(p) => p.theme.panelHeight};
  height: ${(p) => p.theme.panelHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

const LogoLink = styled.a`
  svg {
    display: block;
  }
`;

const SignInSection = styled.div`
  @media screen and (max-width: 440px) {
    span {
      display: none;
    }
  }
`;

const PanelLink = styled(Link)`
  color: ${(p) => p.theme.colors.ui.purple};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Content = styled.div`
  flex: 1 0 auto;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Footer = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  color: ${(p) => p.theme.colors.text.grey};
  padding-bottom: 20px;
`;

const Logos = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  padding: 20px;
  justify-content: center;
  flex-wrap: wrap;

  svg {
    display: block;
  }
`;
