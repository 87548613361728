import { useCallback, useEffect } from "react";
import { useChatQuery } from "../../graphql";

const BACKGROUND_MODE_DELAY = 30_000;
const ACTIVE_MODE_DELAY = 2_000;

const emptyArray = [] as never[];

export function useChatFetcher(chatId: string) {
  let { data, loading, startPolling, stopPolling } = useChatQuery({
    variables: {
      chatId,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    startPolling(BACKGROUND_MODE_DELAY);

    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const setActiveMode = useCallback(() => {
    stopPolling();
    startPolling(ACTIVE_MODE_DELAY);
  }, [stopPolling, startPolling]);

  const setBackgroundMode = useCallback(() => {
    stopPolling();
    startPolling(BACKGROUND_MODE_DELAY);
  }, [stopPolling, startPolling]);

  const messages = data?.chat.messages ?? emptyArray;

  return [messages, loading, setActiveMode, setBackgroundMode] as const;
}
