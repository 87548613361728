import { useCallback } from "react";
import styled from "styled-components/macro";
import { typographyCSS } from "../../theme";
import { Channel } from "./types";

export interface ChannelListItemProps {
  channel: Channel;
  onClick: (channel: Channel) => void;
}

export function ChannelListItem({ channel, onClick }: ChannelListItemProps) {
  const handleClick = useCallback(() => {
    onClick(channel);
  }, [channel, onClick]);

  const lastMessage = channel.lastMessage;

  return (
    <Container onClick={handleClick}>
      <AvatarCol>{channel.renderAvatar("s")}</AvatarCol>
      <Main>
        <Name>{channel.name}</Name>
        {lastMessage ? (
          <LastMessage>
            {lastMessage.sender && (
              <AuthorName>{lastMessage.sender.name}: </AuthorName>
            )}
            {lastMessage.text}
          </LastMessage>
        ) : (
          <div />
        )}
      </Main>
    </Container>
  );
}
const Container = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  align-items: start;
  cursor: pointer;
  padding: 8px 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    background-color: ${(p) => p.theme.colors.service.hover};
    pointer-events: none;
  }
`;

const AvatarCol = styled.div`
  flex: 0 0 auto;
`;

const Main = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Name = styled.h3`
  ${(p) => typographyCSS(p.theme.typo.h3)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LastMessage = styled.div`
  ${(p) => typographyCSS(p.theme.typo.body2)};
  color: ${(p) => p.theme.colors.text.grey};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AuthorName = styled.span`
  color: ${(p) => p.theme.colors.text.black};
`;
