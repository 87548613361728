import { useCallback } from "react";
import { ActionButton } from "../../../components/ActionButton";
import { Button } from "../../../components/Button";
import { Dialog } from "../../../components/Dialog";
import { Modal } from "../../../components/Modal";
import { useBackButton } from "../../../components/Overpage/BackButton";
import { ListStatus, useSetListStatusMutation } from "../../../graphql";

interface ArchiveListButtonProps {
  list: {
    id: string;
    title: string;
  };
}

export function ArchiveListButton({ list }: ArchiveListButtonProps) {
  const [setListStatus, { loading }] = useSetListStatusMutation();

  const handleArchiveClick = useCallback(() => {
    return setListStatus({
      variables: {
        input: {
          listId: list.id,
          status: ListStatus.Archived,
        },
      },
      refetchQueries: ["Lists"],
    });
  }, [setListStatus, list]);

  return (
    <Modal
      trigger={(getTriggerProps) => (
        <ActionButton icon="archive" text="Archive" {...getTriggerProps()} />
      )}
      render={({ getContentProps, close }) => (
        <Dialog
          title="Archive list"
          content={
            <>
              Are you sure you want to archive <strong>"{list.title}"</strong> list?
            </>
          }
          buttons={
            <>
              <Button $kind="text" onClick={close} disabled={loading}>
                Cancel
              </Button>
              <Button
                $kind="contained"
                onClick={handleArchiveClick}
                disabled={loading}
              >
                Archive list
              </Button>
            </>
          }
          close={close}
          {...getContentProps()}
        />
      )}
    />
  );
}
