import { isSameDay } from "date-fns";
import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useAvailabilityPicker } from "../../../../components/pickers/AvailabilityPicker";
import { HighlightColorKind } from "../../../../theme";
import { AvailabilityKindType } from "../../../../types";
import { CalendarDay } from "../../../../utils/calendar";
import { DateRange } from "./types";

enum RangeTileKind {
  Start = 1,
  Middle,
  End,
  SingleDay,
}

function getTileKind(date: Date, startDate: Date, endDate: Date) {
  let isStart = isSameDay(startDate, date);
  let isEnd = isSameDay(endDate, date);

  if (isStart && isEnd) return RangeTileKind.SingleDay;
  if (isStart) return RangeTileKind.Start;
  if (isEnd) return RangeTileKind.End;

  return RangeTileKind.Middle;
}

interface DayViewProps {
  day: CalendarDay;
  ranges: DateRange[];
  selectionRange: [Date, Date] | null;
  availableRange: [Date | null, Date | null];
  onSelectionChange: (date: Date) => void;
  onSelectionStart: (date: Date) => void;
  onSelectionDone: () => void;
  onKindSelected: (kind: AvailabilityKindType) => void;
}

export function DayView({
  day,
  ranges,
  selectionRange,
  availableRange,
  onSelectionChange,
  onSelectionStart,
  onSelectionDone,
  onKindSelected,
}: DayViewProps) {
  let matchedRange = useMemo(() => {
    return ranges.find((range) =>
      isDateInRange(day.date, range.startDate, range.endDate)
    );
  }, [ranges, day.date]);

  const isSelectionStarted = !!selectionRange;
  const isInSelection = selectionRange
    ? isDateInRange(day.date, ...selectionRange)
    : false;
  const isInAvailableRange = isDateInRange(day.date, ...availableRange);
  const isActive = !matchedRange && (!isSelectionStarted || isInAvailableRange);

  const handleClick = useCallback(() => {
    if (isActive && !isSelectionStarted) {
      onSelectionStart(day.date);
    }
  }, [day.date, onSelectionStart, isActive, isSelectionStarted]);

  const handleMouseEnter = useCallback(() => {
    if (isActive && isSelectionStarted) {
      onSelectionChange(day.date);
    }
  }, [day.date, onSelectionChange, isActive, isSelectionStarted]);

  const [availabilityPickerElement, getReferenceProps, setReference] =
    useAvailabilityPicker({
      enabled: isActive && isSelectionStarted,
      onSelected: onKindSelected,
      onClose: onSelectionDone,
    });

  return (
    <>
      <MonthDay
        key={day.dayOfYear}
        ref={setReference}
        $today={day.today}
        {...getReferenceProps({
          onMouseEnter: handleMouseEnter,
          onClick: handleClick,
        })}
      >
        {matchedRange && (
          <RangeTile
            $kind={getTileKind(
              day.date,
              matchedRange.startDate,
              matchedRange.endDate
            )}
            $color={matchedRange.kind.color}
          />
        )}
        {isInSelection && selectionRange && (
          <SelectionRangeTile
            $kind={getTileKind(day.date, ...selectionRange)}
          />
        )}
        {day.num}
      </MonthDay>
      {availabilityPickerElement}
    </>
  );
}

const MonthDay = styled.button<{ $today: boolean }>`
  position: relative;
  border: none;
  background: none;
  height: 32px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: ${(p) => (p.$today ? "bold" : "normal")};

  &:focus {
    outline: none;
  }
`;

const BaseRangeTile = styled.span<{
  $kind: RangeTileKind;
}>`
  position: absolute;
  z-index: -1;
  top: 1px;
  bottom: 1px;
  left: 0;
  right: 0;
  border-top-left-radius: ${(p) =>
    p.$kind === RangeTileKind.Start || p.$kind === RangeTileKind.SingleDay
      ? "17px"
      : "unset"};
  border-top-right-radius: ${(p) =>
    p.$kind === RangeTileKind.End || p.$kind === RangeTileKind.SingleDay
      ? "17px"
      : "unset"};
  border-bottom-right-radius: ${(p) =>
    p.$kind === RangeTileKind.End || p.$kind === RangeTileKind.SingleDay
      ? "17px"
      : "unset"};
  border-bottom-left-radius: ${(p) =>
    p.$kind === RangeTileKind.Start || p.$kind === RangeTileKind.SingleDay
      ? "17px"
      : "unset"};
`;

const RangeTile = styled(BaseRangeTile)<{
  $kind: RangeTileKind;
  $color: HighlightColorKind;
}>`
  background-color: ${(p) => p.theme.colors.highlights[p.$color].bgAlt};
`;

const SelectionRangeTile = styled(BaseRangeTile)`
  background-color: ${(p) => p.theme.colors.service.hover};
`;

function isDateInRange(
  target: Date,
  startDate: Date | null,
  endDate: Date | null
) {
  return (!startDate || target >= startDate) && (!endDate || target <= endDate);
}
