import format from "date-fns/fp/format";
import styled from "styled-components/macro";
import { isGranted } from "../../../../acl";
import { useViewer } from "../../../../components/context/auth_gate";
import { UserAvatar } from "../../../../components/Avatar";
import { UIIcon, UIIconKind } from "../../../../components/UIIcon/UIIcon";
import { typographyCSS } from "../../../../theme";
import { ReviewType } from "../../../../types";
import { DeleteReviewButton } from "./DeleteReviewButton";

interface ReviewItemProps {
  review: ReviewType;
}

export function ReviewItem({ review }: ReviewItemProps) {
  const viewer = useViewer();
  const canDelete =
    isGranted(viewer, "review.deleteAny") || viewer.id === review.creator.id;
  const stars = starTemplates[review.evaluation] || [];

  return (
    <Container>
      <Header>
        <Avatar>
          <UserAvatar size="s" user={review.creator} />
        </Avatar>
        <Info>
          <FirstRow>
            <Name>{review.creator.name}</Name>
            <ReviewDate>
              {format("d MMM yyyy", new Date(review.createdAt))}
            </ReviewDate>
          </FirstRow>
          <SecondRow>
            <Stars>
              {stars.map((starIcon, index) => (
                <UIIcon key={index} name={starIcon} />
              ))}
            </Stars>
            {canDelete && <DeleteReviewButton review={review} />}
          </SecondRow>
        </Info>
      </Header>
      {review.comment && <ReviewText>{review.comment}</ReviewText>}
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 24px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const Avatar = styled.div`
  flex: 0 0 auto;
`;

const Info = styled.div`
  flex: 1 1 auto;
`;

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const SecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  min-height: 28px;
`;

const Name = styled.div`
  ${(p) => typographyCSS(p.theme.typo.highlighted)};
`;

const ReviewDate = styled.div`
  ${(p) => typographyCSS(p.theme.typo.body2)};
  color: ${(p) => p.theme.colors.text.grey};
`;

const Stars = styled.div`
  display: flex;
  alig-items: center;
`;

const ReviewText = styled.div`
  margin-top: 4px;
  white-space: pre-wrap;
`;

const starTemplates: UIIconKind[][] = [
  [],
  [
    "starsmall",
    "starsmalloutline",
    "starsmalloutline",
    "starsmalloutline",
    "starsmalloutline",
  ],
  [
    "starsmall",
    "starsmall",
    "starsmalloutline",
    "starsmalloutline",
    "starsmalloutline",
  ],
  [
    "starsmall",
    "starsmall",
    "starsmall",
    "starsmalloutline",
    "starsmalloutline",
  ],
  ["starsmall", "starsmall", "starsmall", "starsmall", "starsmalloutline"],
  ["starsmall", "starsmall", "starsmall", "starsmall", "starsmall"],
];
