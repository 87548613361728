import { RefObject, useCallback, useRef } from "react";
import styled from "styled-components/macro";
import { ActionButton } from "../../../components/ActionButton";
import { AvatarPlaceholder, TalentAvatar } from "../../../components/Avatar";
import { useOverpageHandler } from "../../../components/Overpage/useOverpageHandler";
import { UIIcon, UIIconKind } from "../../../components/UIIcon/UIIcon";
import { typographyCSS } from "../../../theme";
import { AvatarImageData } from "../../../types";
import { wordChars } from "../../../utils/wordChars";

export interface CandidateActionPayload {
  positionId: string;
  talentId: string;
  hook?: (start: boolean) => void;
}

interface TalentListItemProps {
  talent: {
    id: string;
    name: string;
    image: AvatarImageData | null;
  };
  position: {
    id: string;
    title: string;
  };
  actionIcon: UIIconKind;
  parentRef: RefObject<HTMLDivElement>;
  onAction: (payload: CandidateActionPayload) => void;
}

export function TalentListItem({
  talent,
  position,
  actionIcon,
  parentRef,
  onAction,
}: TalentListItemProps) {
  const containerRef = useRef<HTMLLIElement>(null);
  const handleActionClick = useCallback(() => {
    onAction({
      positionId: position.id,
      talentId: talent.id,
      hook: (start) => {
        const value = start ? "2" : "auto";
        if (containerRef.current) {
          containerRef.current.style.zIndex = value;
        }
        if (parentRef.current) {
          parentRef.current.style.zIndex = value;
        }
      },
    });
  }, [talent.id, position.id, onAction, parentRef]);
  const talentLinkHandler = useOverpageHandler({
    to: `/talents/${talent.id}`,
  });

  return (
    <Container
      ref={containerRef}
      style={
        {
          viewTransitionName: `candidate-${wordChars(position.id)}-${wordChars(
            talent.id
          )}`,
        } as any
      }
    >
      <AvatarLink {...talentLinkHandler}>
        <TalentAvatar talent={talent} size="s" />
      </AvatarLink>
      <Info>
        <NameRow>
          <Name {...talentLinkHandler}>{talent.name}</Name>
          <ActionContainer>
            <ActionButton icon={actionIcon} onClick={handleActionClick} />
          </ActionContainer>
        </NameRow>
        <PositionTitle>{position.title}</PositionTitle>
      </Info>
    </Container>
  );
}

interface TalentListPlaceholderProps {
  position: {
    id: string;
    title: string;
  };
  index: number;
}

export function TalentListPlaceholder({
  position,
  index,
}: TalentListPlaceholderProps) {
  return (
    <Container
      style={
        {
          viewTransitionName: `candidate-placeholder-${wordChars(
            position.id
          )}-${index}`,
        } as any
      }
    >
      <AvatarPlaceholder size="s">
        <UIIcon name="user" />
      </AvatarPlaceholder>
      <PositionTitle>{position.title}</PositionTitle>
    </Container>
  );
}

const ActionContainer = styled.div`
  flex: 0 0 auto;
  display: none;
`;

const Container = styled.li`
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  contain: paint;
  position: relative;

  &:hover {
    ${ActionContainer} {
      display: block;
    }
  }
`;

const AvatarLink = styled.a`
  display: block;
  text-decoration: none;
`;

const PositionTitle = styled.div`
  color: ${(p) => p.theme.colors.text.grey};
`;

const Info = styled.div`
  overflow: hidden;

  ${PositionTitle} {
    ${(p) => typographyCSS(p.theme.typo.body2)};
  }
`;

const NameRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 28px;
`;

const Name = styled.a`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
`;
