import { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Button } from "../../../components/Button";
import { ExpandingButton } from "../../../components/buttons/CollapsingButton";
import { useFillableListStorage } from "../../../components/context/fillable_list";
import { SkillsList } from "../../../components/skills/SkillsList";
import {
  CandidateStatus,
  useSetListPositionSkillsMutation,
} from "../../../graphql";
import { typographyCSS } from "../../../theme";
import { ListDetailsType, PositionType } from "../../../types";
import { wordChars } from "../../../utils/wordChars";
import { CandidateCard } from "./CandidateCard";

const emptyArray = [] as any[];

interface PositionBoxProps {
  position: PositionType;
  list: ListDetailsType;
  onCandidateStatusChange: (
    positionId: string,
    talentId: string,
    status: CandidateStatus,
    hook?: (start: boolean) => void
  ) => void;
}

export function PositionBox({
  position,
  list,
  onCandidateStatusChange,
}: PositionBoxProps) {
  const containerRef = useRef<HTMLLIElement>(null);
  const [isDetailsBlockExpanded, setDetailsBlockExpanded] = useState(false);
  const [setListPositionSkills] = useSetListPositionSkillsMutation();
  const { setFillableList } = useFillableListStorage();

  const handleSearchClick = useCallback(() => {
    setFillableList(list.id, position.id);
  }, [list.id, position.id, setFillableList]);

  const handleSkillsChange = useCallback(
    (nextSkills: string[]) => {
      setListPositionSkills({
        variables: {
          input: {
            listId: list.id,
            positionId: position.id,
            skills: nextSkills,
          },
        },
        optimisticResponse() {
          return {
            setListPositionSkills: {
              __typename: "SetListPositionSkillsPayload",
              list: {
                ...list,
                positions: list.positions.map((p) =>
                  p.id === position.id ? { ...p, skills: nextSkills } : p
                ),
              },
            },
          };
        },
      });
    },
    [setListPositionSkills, position.id, list]
  );

  const candidates = position.candidates.filter(
    (candidate) => candidate.status === CandidateStatus.Candidate
  );

  const approvedCandidates = position.candidates.filter(
    (candidate) => candidate.status === CandidateStatus.Approved
  );

  return (
    <Container
      ref={containerRef}
      style={
        { viewTransitionName: `position-box-${wordChars(position.id)}` } as any
      }
    >
      <HeadRow>
        <Title>{position.title}</Title>
        <div>
          {approvedCandidates.length}/{position.count}
        </div>
      </HeadRow>
      {isDetailsBlockExpanded && (
        <div>
          <Description>{position.description}</Description>
          <Skills>
            <SkillsList
              skills={position.skills || emptyArray}
              addTitle="Add required tags"
              onChange={handleSkillsChange}
              skillsPickerReadonly
            />
          </Skills>
        </div>
      )}
      <ExpandingButton
        isExpanded={isDetailsBlockExpanded}
        setExpanded={setDetailsBlockExpanded}
        expandText="Show details"
        collapseText="Hide details"
      />
      {candidates.length > 0 ? (
        <Candidates>
          {candidates.map((candidate) => (
            <CandidateCard
              key={candidate.talent.id}
              talent={candidate.talent}
              positionId={position.id}
              listId={list.id}
              parentRef={containerRef}
              onCandidateStatusChange={onCandidateStatusChange}
            />
          ))}
        </Candidates>
      ) : (
        <EmptyMessage>
          <span>No candidates for this position</span>
          <Button
            as={Link}
            to={getSearchHref(position)}
            kind="contained"
            onClick={handleSearchClick}
          >
            Search for candidates
          </Button>
        </EmptyMessage>
      )}
    </Container>
  );
}

function getSearchHref(position: PositionType): string {
  const skillsFilter =
    position.skills && position.skills.length > 0
      ? `tags=${encodeURIComponent(position.skills.join(","))}`
      : null;
  const hasParams = !!skillsFilter;

  return "/talents" + (hasParams ? `?${skillsFilter}` : "");
}

const Container = styled.li`
  list-style: none;
  display: block;
  margin: 0 0 16px;
  padding: 16px;
  border-radius: 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  contain: paint;
  position: relative;
`;

const Title = styled.div`
  ${(p) => typographyCSS(p.theme.typo.highlighted)};
  font-weight: 700;
`;

const HeadRow = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const Description = styled.div`
  white-space: pre-wrap;
  margin-bottom: 16px;
`;

const Skills = styled.div`
  margin-bottom: 16px;
`;

const Candidates = styled.ul`
  padding: 0;
  margin: 0;
`;

const EmptyMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 16px;
  color: ${(p) => p.theme.colors.text.grey};
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 16px;
`;
