import styled from "styled-components/macro";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";
import { CategoryListItemType } from "../../types";
import { useCallback, useMemo, useState } from "react";
import { CategoryListItem } from "./CategoryListItem";
import {
  CategoriesListDocument,
  useCreateCategoryMutation,
  useEditCategoryMutation,
  useSetCategoryOrderMutation,
} from "../../graphql";
import { useEmojiPicker } from "../../components/EmojiPicker/EmojiPicker";
import { Button } from "../../components/Button";
import { gql } from "graphql.macro";
import { isGranted } from "../../acl";
import { useViewer } from "../../components/context/auth_gate";

interface PageContentProps {
  categories: CategoryListItemType[];
}

export function PageContent({ categories: allCategories }: PageContentProps) {
  const viewer = useViewer();
  const [openedEmojiFor, setOpenedEmojiFor] = useState<string | null>(null);
  const [isDragActive, setDragActive] = useState(false);
  const [createdCategoryId, setCreatedCategoryId] = useState<string | null>(
    null
  );
  const [setCategoryOrder] = useSetCategoryOrderMutation();
  const [editCategory] = useEditCategoryMutation();
  const [createCategory, { loading: isCategoryBeingCreated }] =
    useCreateCategoryMutation();

  const categories = useMemo(() => {
    return allCategories.filter((c) => !c.systemCode);
  }, [allCategories]);

  const updateCategory = useCallback(
    (category: CategoryListItemType) => {
      editCategory({
        variables: {
          id: category.id,
          name: category.name,
          icon: category.icon,
        },
        optimisticResponse: {
          editCategory: {
            __typename: "EditCategoryPayload",
            category: {
              ...category,
              __typename: "Category",
            },
          },
        },
      });
    },
    [editCategory]
  );

  const handleAddClick = useCallback(() => {
    createCategory({
      variables: {
        icon: "ios",
        name: "New category",
      },
      onCompleted(data) {
        const category = data.createCategory?.category;
        if (category) {
          setCreatedCategoryId(category.id);
        }
      },
      update: (cache, result) => {
        const category = result.data?.createCategory?.category;
        if (!category) {
          return;
        }

        cache.modify({
          fields: {
            categories(existingCategories = []) {
              const newCategoryRef = cache.writeFragment({
                data: {
                  ...category,
                  cardsCount: 0,
                },
                fragment: gql`
                  fragment NewCategory on Category {
                    id
                    name
                    icon
                    systemCode
                    cardsCount
                  }
                `,
              });
              return [...existingCategories, newCategoryRef];
            },
          },
        });
      },
    });
  }, [createCategory]);

  const handleEmojiSelected = useCallback(
    (icon: string) => {
      const category = categories.find(
        (category) => category.id === openedEmojiFor
      );
      if (category && icon !== category.icon) {
        updateCategory({ ...category, icon });
      }
      setOpenedEmojiFor(null);
    },
    [setOpenedEmojiFor, openedEmojiFor, categories, updateCategory]
  );

  const [getEmojiReferenceProps, emojiPickerElement] = useEmojiPicker({
    opened: !!openedEmojiFor,
    placement: "bottom",
    onClose() {
      setOpenedEmojiFor(null);
    },
    onEmojiSelected: handleEmojiSelected,
  });

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      setDragActive(false);
      if (!result.destination) return;

      const sourceIdx = result.source.index;
      const destinationIdx = result.destination.index;
      if (sourceIdx === destinationIdx) {
        return;
      }

      const reordered = allCategories.slice();
      const [category] = reordered.splice(sourceIdx, 1);
      reordered.splice(destinationIdx, 0, category);
      const reorderedIds = reordered
        .filter((c) => !c.systemCode)
        .map((c) => c.id);

      setCategoryOrder({
        variables: {
          input: {
            order: reorderedIds,
            clientMutationId: null,
          },
        },
        optimisticResponse: {
          setCategoryOrder: {
            __typename: "SetCategoryOrderPayload",
            categories: reordered,
          },
        },
        update(cache, { data }) {
          const categories = data?.setCategoryOrder?.categories;
          if (!categories) return;

          cache.writeQuery({
            query: CategoriesListDocument,
            data: {
              categories,
            },
          });
        },
      });
    },
    [allCategories, setCategoryOrder]
  );

  const handleDragStart = useCallback(() => {
    setDragActive(true);
  }, []);

  const canArrange = isGranted(viewer, "category.arrange");

  return (
    <Container>
      <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <Droppable droppableId="CatManList">
          {(dropProvided, dropSnapshot) => (
            <List
              {...dropProvided.droppableProps}
              ref={dropProvided.innerRef}
              $isDragginOver={dropSnapshot.isDraggingOver}
            >
              {categories.map((category, index) => (
                <Draggable
                  isDragDisabled={!canArrange}
                  key={category.id}
                  draggableId={category.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <CategoryListItem
                      category={category}
                      dragProvided={provided}
                      dragSnapshot={snapshot}
                      isDragActive={isDragActive}
                      onEmojiClick={setOpenedEmojiFor}
                      updateCategory={updateCategory}
                      autoFocus={category.id === createdCategoryId}
                      getEmojiReferenceProps={
                        category.id === openedEmojiFor
                          ? getEmojiReferenceProps
                          : undefined
                      }
                    />
                  )}
                </Draggable>
              ))}
              {dropProvided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      {isGranted(viewer, "category.add") && (
        <Row>
          <Button onClick={handleAddClick} disabled={isCategoryBeingCreated}>
            Add category
          </Button>
        </Row>
      )}
      {emojiPickerElement}
    </Container>
  );
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 700px;
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  padding: 20px 24px;
  border-radius: 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
`;

const List = styled.ul<{ $isDragginOver: boolean }>`
  margin: 0;
  padding: 0;
  max-width: 356px;
`;

const Row = styled.div`
  margin-top: 24px;
`;
