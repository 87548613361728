import { useCallback, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { isGranted } from "../../../acl";
import { ActionLink } from "../../../components/ActionButton";
import { useViewer } from "../../../components/context/auth_gate";
import { NoAccess } from "../../../components/NoAccess";
import { BackButton } from "../../../components/Overpage/BackButton";
import { useOverpageHandler } from "../../../components/Overpage/useOverpageHandler";
import {
  CandidateStatus,
  ListStatus,
  useListDetailsQuery,
  useUpdateListCandidateStatusMutation,
} from "../../../graphql";
import { typographyCSS } from "../../../theme";
import {
  getRenderWaitPromise,
  resolveRenderWaitPromise,
} from "../../../utils/renderPromise";
import { wait } from "../../../utils/waitPromise";
import { DeleteListButton } from "./DeleteListButton";
import { PositionBox } from "./PositionBox";
import { Sidebar } from "./Sidebar";
import { ListsChatControllerProvider } from "./ListChatController";
import { ChatSection } from "./ChatSection";
import { ArchiveListButton } from "./ArchiveListButton";

export function ListDetails() {
  const viewer = useViewer();
  const { listId } = useParams();
  const { data, error } = useListDetailsQuery({
    skip: !listId,
    variables: {
      listId: listId ?? "",
    },
  });
  const list = data?.list;

  const editListHandler = useOverpageHandler({
    to: `/lists/${listId}/edit`,
  });

  const [updateCandidateStatus] = useUpdateListCandidateStatusMutation();
  const handleCandidateStatusChange = useCallback(
    (
      positionId: string,
      talentId: string,
      status: CandidateStatus,
      hook?: (start: boolean) => void
    ) => {
      if (!list) return;

      const execMutation = () =>
        updateCandidateStatus({
          variables: {
            input: {
              listId: list.id,
              positionId,
              talentId: talentId,
              status,
            },
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateListCandidateStatus: {
              __typename: "UpdateListCandidateStatusPayload",
              list: {
                ...list,
                positions: list.positions.map((position) =>
                  position.id === positionId
                    ? {
                      ...position,
                      candidates: position.candidates.map((candidate) =>
                        candidate.talent.id === talentId
                          ? {
                            ...candidate,
                            status,
                          }
                          : candidate
                      ),
                    }
                    : position
                ),
              },
            },
          },
        });

      if ((document as any).startViewTransition) {
        hook?.(true);

        const transition = (document as any).startViewTransition(async () => {
          const renderPromise = getRenderWaitPromise();
          execMutation();
          await Promise.race([renderPromise, wait(500)]);
        });

        if (hook) {
          transition.finished.finally(() => hook(false));
        }
      } else {
        execMutation();
      }
    },
    [list, updateCandidateStatus]
  );

  useEffect(() => {
    resolveRenderWaitPromise();
  });

  if (!listId) {
    return <Navigate to="/lists" replace />;
  }

  if (error && error.message === "Not permitted") {
    return <Navigate to="/lists" replace />;
  }

  if (error && error.message === "No access") {
    return <NoAccess backTo="/lists" />;
  }

  const canEdit = isGranted(viewer, "list.edit");

  return (
    <Container>
      <TopPanel>
        <BackButton defaultTo="/lists" />
        {list && (
          <Actions>
            <ActionLink
              icon="backward"
              iconTransform="flipH"
              text="Export to CSV"
              href={`/dl/list/export/${listId}`}
            />
            {canEdit && (
              <ActionLink {...editListHandler} icon="pencil" text="Edit" />
            )}
            {isGranted(viewer, "list.archive") && list.status !== ListStatus.Archived && (
              <ArchiveListButton list={list} />
            )}
            {isGranted(viewer, "list.delete") && (
              <DeleteListButton list={list} />
            )}
          </Actions>
        )}
      </TopPanel>
      {list ? (
        <ListsChatControllerProvider list={list}>
          <Content>
            <Sidebar
              list={list}
              onCandidateStatusChange={handleCandidateStatusChange}
            />
            <Main>
              {list.overview && (
                <Section>
                  <Title>Overview</Title>
                  <Overview>{list.overview}</Overview>
                </Section>
              )}
              <TwoPanelView>
                <Positions>
                  {list.positions.map((position) => (
                    <PositionBox
                      key={position.id}
                      position={position}
                      list={list}
                      onCandidateStatusChange={handleCandidateStatusChange}
                    />
                  ))}
                </Positions>
                <ChatSection list={list} />
              </TwoPanelView>
            </Main>
          </Content>
        </ListsChatControllerProvider>
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 2678px;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  padding-bottom: 80px;
`;

const TopPanel = styled.div`
  height: 56px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: 1394px;
  padding: 0 24px;
  display: flex;
  align-items: stretch;
`;

const Main = styled.div`
  flex: 1 1 auto;
  margin-left: 48px;
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const Title = styled.div`
  ${(p) => typographyCSS(p.theme.typo.highlighted)};
  font-weight: 700;
  margin-bottom: 8px;
`;

const Overview = styled.div`
  white-space: pre-wrap;
`;

const Actions = styled.div`
  display: flex;
  position: relative;
  gap: 8px;
  align-items: center;
`;

const TwoPanelView = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  align-items: start;
  height: 100%;

  @media screen and (max-width: 1439px) {
    display: block;
    padding-right: 180px;
  }
`;

const Positions = styled.ul`
  margin: 0;
  padding: 0;
  overflow: hidden;
  min-width: 0;
`;
