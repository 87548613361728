import { useCallback } from "react";
import { ControllerFieldState } from "react-hook-form";
import { ActionButton } from "../ActionButton";
import { Button } from "../Button";
import { SelectedSkill } from "../skills/Skill";
import { useSkillsPicker } from "../skills/SkillsPicker/SkillsPicker";
import { ChipList, ErrorMsg, Hint, NoBreak, Row } from "./ui";

interface SkillsControlProps {
  fieldState: ControllerFieldState;
  skills: string[];
  onChange: (skills: string[]) => void;
}

export function SkillsControl({
  skills,
  fieldState,
  onChange,
}: SkillsControlProps) {
  const handleSkillsSelectionDone = useCallback(
    (newSkills: string[]) => {
      if (newSkills.length > 0) {
        onChange([...skills, ...newSkills]);
      }
    },
    [skills, onChange]
  );

  const [getReferenceProps, skillsPopoverElement] = useSkillsPicker({
    selectedSkills: skills,
    onDone: handleSkillsSelectionDone,
  });

  const handleSkillRemove = useCallback(
    (removedSkill: string) => {
      onChange(skills.filter((skill) => skill !== removedSkill));
    },
    [skills, onChange]
  );

  const lastIndex = skills.length - 1;

  return (
    <>
      <Row>
        {skills.length > 0 ? (
          <ChipList>
            {skills.map((skill, index) =>
              index === lastIndex ? (
                <NoBreak key={skill}>
                  <SelectedSkill name={skill} onRemove={handleSkillRemove} />
                  <ActionButton
                    type="button"
                    icon="plus"
                    {...getReferenceProps()}
                  />
                  {skillsPopoverElement}
                </NoBreak>
              ) : (
                <SelectedSkill
                  key={skill}
                  name={skill}
                  onRemove={handleSkillRemove}
                />
              )
            )}
          </ChipList>
        ) : (
          <>
            <Button type="button" {...getReferenceProps()}>
              Select tags
            </Button>
            {skillsPopoverElement}
          </>
        )}
      </Row>
      {fieldState.error && <ErrorMsg>{fieldState.error.message}</ErrorMsg>}
      {skills.length === 0 && (
        <Hint>Add tags for quick and efficient search</Hint>
      )}
    </>
  );
}
