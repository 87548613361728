const FILTER_SORT_IDX_MATCH = 1;
const FILTER_SORT_IDX_BEGIN_LINE = 2;
const FILTER_SORT_IDX_BEGIN_WORD = 3;
const FILTER_SORT_IDX_REST = 4;

export function filterList<T>(
  list: T[],
  filterInput: string,
  getter: (item: T) => string
): T[] {
  if (filterInput.length === 0) {
    return list;
  }

  const result = [] as {
    item: T;
    sortIdx: number;
  }[];

  list.forEach((item) => {
    const strValue = getter(item);
    const occurPosition = strValue.toLowerCase().indexOf(filterInput);
    if (occurPosition < 0) {
      return;
    }

    let sortIdx = FILTER_SORT_IDX_REST;

    if (filterInput === strValue) {
      sortIdx = FILTER_SORT_IDX_MATCH;
    } else if (occurPosition === 0) {
      sortIdx = FILTER_SORT_IDX_BEGIN_LINE;
    } else if (new RegExp(`\\b${escapeRegExp(filterInput)}`).test(strValue)) {
      sortIdx = FILTER_SORT_IDX_BEGIN_WORD;
    }

    result.push({ item, sortIdx });
  });

  result.sort((a, b) => a.sortIdx - b.sortIdx);

  return result.map((res) => res.item);
}

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
