import { compareDesc, format, isSameDay, parseISO, startOfDay, subDays } from "date-fns/fp";
import {
  Context,
  Fragment,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components/macro";
import { typographyCSS } from "../../theme";
import { ChatMessageType } from "../../types";
import { ActionButton } from "../ActionButton";
import { ChannelListItem } from "./ChannelListItem";
import { ChatMessage } from "./ChatMessage";
import { SendForm } from "./SendForm";
import { Channel, ChatController } from "./types";

interface ChatProps {
  className?: string;
  controllerContext: Context<ChatController | null>;
  renderHeader?: () => JSX.Element;
}

export function Chat({
  controllerContext,
  renderHeader,
  className,
}: ChatProps) {
  let controller = useContext(controllerContext);
  if (controller === null) {
    throw new Error("ChatController is missing in context");
  }

  const contentRef = useRef<HTMLDivElement>(null);
  const [replyToMessage, setReplyToMessage] = useState<ChatMessageType | null>(
    null
  );
  const isPinnedRef = useRef(true);
  const {
    chatId,
    loading,
    messages,
    channels,
    hasChannels,
    selectedChannel,
    setActiveMode,
    setBackgroundMode,
    setSelectedChannel,
  } = controller;

  const handleContentScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
      const scrollBottom = scrollTop + clientHeight;
      isPinnedRef.current = scrollHeight - scrollBottom <= 1;
    },
    []
  );

  const clearReplyToMessage = useCallback(() => {
    setReplyToMessage(null);
  }, []);

  const handleChannelBack = useCallback(() => {
    setSelectedChannel(null);
  }, [setSelectedChannel]);

  const handleInputFocus = useCallback(() => {
    setActiveMode.call(null);
  }, [setActiveMode]);

  const handleInputBlur = useCallback(() => {
    setBackgroundMode.call(null);
  }, [setBackgroundMode]);

  const handleChannelClick = useCallback(
    (channel: Channel) => {
      setSelectedChannel(channel);
    },
    [setSelectedChannel]
  );

  const handleMessageSent = useCallback(() => {
    isPinnedRef.current = true;
    if (contentRef.current) {
      contentRef.current.scrollTo(0, contentRef.current.scrollHeight);
    }
  }, []);

  const sortedChannels = useMemo(() => {
    return channels.sort((a, b) => compareDesc(
      b.lastMessage ? parseISO(b.lastMessage.sentAt) : 0,
      a.lastMessage ? parseISO(a.lastMessage.sentAt) : 0,
    ));
  }, [channels])

  useLayoutEffect(() => {
    isPinnedRef.current = true;
  }, [selectedChannel]);

  useLayoutEffect(() => {
    if (contentRef.current && isPinnedRef.current) {
      contentRef.current.scrollTo(0, contentRef.current.scrollHeight);
    }
  }, [messages]);

  const messagesByDay = useMemo(() => {
    return messages.reduce((memo, msg) => {
      const sentDate = parseISO(msg.sentAt);
      if (memo.length === 0 || !isSameDay(memo[memo.length - 1][0], sentDate)) {
        memo.push([startOfDay(sentDate), []]);
      }
      memo[memo.length - 1][1].push(msg);
      return memo;
    }, [] as [Date, ChatMessageType[]][]);
  }, [messages]);

  const isChannelsView = hasChannels && !selectedChannel;

  return (
    <Container className={className}>
      <Header>{renderHeader?.()}</Header>
      <ContentWrapper>
        {isChannelsView ? (
          <Content>
            {loading && messages.length === 0 && <Loading>Loading...</Loading>}
            {sortedChannels.map((channel) => (
              <ChannelListItem
                key={channel.id ?? "__NULL__"}
                channel={channel}
                onClick={handleChannelClick}
              />
            ))}
          </Content>
        ) : (
          <>
            {!!selectedChannel && (
              <ChannelHeader>
                <ActionButton icon="arrowleft" onClick={handleChannelBack} />
                {selectedChannel.renderAvatar("xxs")}
                <ChannelHeaderTitle>{selectedChannel.name}</ChannelHeaderTitle>
              </ChannelHeader>
            )}
            <Content ref={contentRef} onScroll={handleContentScroll}>
              {loading && messages.length === 0 && (
                <Loading>Loading...</Loading>
              )}
              {messagesByDay.map(([day, messagesOfDay]) => (
                <Fragment key={day.getTime()}>
                  <DaySeparator>
                    <DaySeparatorPill>{formatDaySeparator(day)}</DaySeparatorPill>
                  </DaySeparator>
                  {messagesOfDay.map((message) => (
                    <ChatMessage
                      key={message.id}
                      message={message}
                      allMessages={messages}
                      setReplyToMessage={setReplyToMessage}
                    />
                  ))}
                </Fragment>
              ))}
            </Content>
          </>
        )}
      </ContentWrapper>
      <SendForm
        disabled={loading || isChannelsView}
        chatId={chatId}
        channel={selectedChannel ? selectedChannel.id : null}
        replyToMessage={replyToMessage}
        clearReplyToMessage={clearReplyToMessage}
        onInputFocus={handleInputFocus}
        onInputBlur={handleInputBlur}
        onSent={handleMessageSent}
      />
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  flex: 0 0 51px;
  height: 51px;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 16px 16px 0 0;
  padding: 8px 16px;
  box-sizing: border-box;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
`;

const ContentWrapper = styled.div`
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-width: 0 1px 0 1px;
  flex: 1 1 auto;
  padding-bottom: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
`;

const Content = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`;

const Loading = styled.div`
  color: ${(p) => p.theme.colors.text.grey};
  text-align: center;
`;

const ChannelHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-bottom: 1px solid ${(p) => p.theme.colors.borders.separator};
`;

const ChannelHeaderTitle = styled.h4`
  ${(p) => typographyCSS(p.theme.typo.h4)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DaySeparator = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;

  &::after, &::before {
    content: '';
    border-top: 1px solid ${(p) => p.theme.colors.borders.separator};
    height: 0;
    flex: 1;
  }
`;

const DaySeparatorPill = styled.div`
  flex: 0 0 auto;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 16px;
  padding: 4px 16px;
  ${(p) => typographyCSS(p.theme.typo.body2)};
`;

function formatDaySeparator(day: Date) {
  const now = new Date();

  if (isSameDay(day, now)) {
    return "Today";
  }

  if (isSameDay(day, subDays(1, now))) {
    return "Yesterday";
  }

  return format("EEEE, LLLL do", day);
}
