import styled from "styled-components/macro";
import { isGranted } from "../../../acl";
import { useViewer } from "../../../components/context/auth_gate";

import { Button } from "../../../components/Button";
import { useOverpageHandler } from "../../../components/Overpage/useOverpageHandler";
import { AccessLevel, ListsOwnership, ListStatus, useListsQuery } from "../../../graphql";
import { rangeArray } from "../../../utils/rangeArray";
import { ListCard, ListCardPlaceholder } from "./ListCard";
import { SelectionFilter, SelectionKind } from "./SelectionFilter";
import { useCallback, useMemo, useState } from "react";

const PLACEHOLDER_ROWS_COUNT = 2;
const MAX_GRID_COLS = 4;
const emptyArray = [] as any[];

const placeholdersRange = rangeArray(PLACEHOLDER_ROWS_COUNT * MAX_GRID_COLS);

export function ListsPage() {
  const viewer = useViewer();
  const addListHandler = useOverpageHandler({ to: "/lists/add" });
  let hasViewerAll = viewer.accessLevel === AccessLevel.Full || viewer.accessLevel === AccessLevel.Admin;
  const [selectionKind, setSelectionkind] = useState(() =>
    hasViewerAll ? SelectionKind.All : SelectionKind.My
  );

  const queryVars = useMemo(() => {
    switch (selectionKind) {
      case SelectionKind.All:
        return { status: ListStatus.Active, ownership: ListsOwnership.All };
      case SelectionKind.My:
        return { status: ListStatus.Active, ownership: ListsOwnership.My };
      case SelectionKind.Archived:
        return { status: ListStatus.Archived, ownership: null };
    }
  }, [selectionKind]);

  const { data, loading } = useListsQuery({
    variables: queryVars,
    fetchPolicy: "cache-and-network",
  });

  const handleKindSelected = useCallback((kind: SelectionKind) => {
    setSelectionkind(kind);
  }, []);

  const lists = data?.lists || emptyArray;

  return (
    <Container>
      <Header>
        <MainHeaderPart>
          <h1>Lists</h1>
          <SelectionFilter
            hasAll={hasViewerAll}
            selected={selectionKind}
            onSelected={handleKindSelected}
          />
        </MainHeaderPart>
        <Actions>
          {isGranted(viewer, "list.add") && (
            <Button as="a" $kind="contained" {...addListHandler}>
              Add list
            </Button>
          )}
        </Actions>
      </Header>
      <ListsList>
        {!loading && lists.length === 0 && (
          <EmptyMsg>There are no lists to show</EmptyMsg>
        )}
        {loading &&
          lists.length === 0 &&
          placeholdersRange.map((idx) => <ListCardPlaceholder key={idx} />)}
        {lists.map((list) => (
          <ListCard key={list.id} list={list} />
        ))}
      </ListsList>
    </Container>
  );
}

const Container = styled.div`
  min-height: 100%;
  padding: 24px 0 80px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin: 0 auto 12px;
`;

const MainHeaderPart = styled.div`
  display: flex;
  align-items: baseline;
  gap: 16px;
`;

const Actions = styled.div``;

const ListsList = styled.ul`
  padding: 0 12px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
`;

const EmptyMsg = styled.div`
  width: 100%;
  text-align: center;
`;
