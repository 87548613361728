import { useEffect } from "react";
import Chatra, { ChatraConfig } from "@chatra/chatra";

import { useAuthGate } from "../context/auth_gate";
import appConfig from "../../appConfig";

interface ChatraIntegrationProps {
  children: JSX.Element;
}

export function ChatraIntegration({ children }: ChatraIntegrationProps) {
  let viewer = useAuthGate();
  const isDisabled =
    !appConfig.chatra.enabled || (viewer && viewer.shadowedSession);

  useEffect(() => {
    if (isDisabled || !appConfig.chatra.id) {
      return;
    }

    let chatraConfig: ChatraConfig = {
      ID: appConfig.chatra.id,
      setup: {
        clientId: viewer && viewer.chatraId,
        groupId: appConfig.chatra.groupId,
        buttonPosition: "br",
      },
    };

    if (viewer) {
      chatraConfig.integration = {
        name: viewer.name!,
        email: viewer.email,
        location: viewer.location,
      };
    }

    Chatra("init", chatraConfig);
  }, [viewer, isDisabled]);

  return children;
}
