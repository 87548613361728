import styled from "styled-components/macro";
import { typographyCSS } from "../../../theme";

export const Separator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.colors.borders.separator};
  height: 0;
  max-height: 0;
  margin: 24px auto;
`;

export const Header = styled.div`
  ${(p) => typographyCSS(p.theme.typo.highlighted)};
  margin-bottom: 8px;
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const Hint = styled.div`
  margin-top: 8px;
  color: ${(p) => p.theme.colors.text.grey};
  ${(p) => typographyCSS(p.theme.typo.body2)};
`;

export const Actions = styled.div`
  display: flex;
  position: relative;
  gap: 8px;
  align-items: center;
`;
