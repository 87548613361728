import ReactPlayer from "react-player";
import styled from "styled-components/macro";
import { UIIcon } from "../../../../components/UIIcon/UIIcon";
import { WorkType } from "../../../../types";
import { ReactComponent as PlayCircleSvg } from "../assets/playcircle.svg";
import { ReactComponent as ImagePlaceholderSvg } from "../assets/image.svg";
import { NoPreview, WorkSlot as BaseWorkSlot } from "./ui";
import { NotAnURL, tryURL } from "../../../../utils/tryURL";
import { DeleteWorkAction } from "./DeleteWorkAction";
import { nowww } from "../../../../utils/nowww";
import { typographyCSS } from "../../../../theme";
import { isGranted } from "../../../../acl";
import { useViewer } from "../../../../components/context/auth_gate";

interface WorkItemProps {
  work: WorkType;
  index: number;
  onClick: (index: number) => void;
}

export function WorkItem({ work, index, onClick }: WorkItemProps) {
  const viewer = useViewer();
  const workURL = work.link ? tryURL(work.link) : NotAnURL;

  return (
    <WorkSlot>
      <WorkButton key={work.id} onClick={() => onClick(index)}>
        {work.image ? (
          <WorkImage
            src={work.image.preview400.src}
            srcSet={work.image.preview400.srcSet}
            alt={"Work Image"}
          />
        ) : (
          <NoPreview>
            <ImagePlaceholderSvg />
            <div>No preview</div>
          </NoPreview>
        )}
        {work.link && ReactPlayer.canPlay(work.link) && (
          <VideoBadge>
            <PlayCircleSvg />
          </VideoBadge>
        )}
      </WorkButton>
      {workURL.isURL && (
        <WorkLink href={work.link!} target="_blank" rel="noreferrer">
          <span>{nowww(workURL.url.host)}</span>
          <UIIcon name="smallexternal" />
        </WorkLink>
      )}
      {isGranted(viewer, "work.delete") && (
        <DeleteWorkAction
          workId={work.id}
          render={(getReferenceProps) => (
            <DeleteButton {...getReferenceProps()}>
              <UIIcon name="smallcross" />
            </DeleteButton>
          )}
        />
      )}
    </WorkSlot>
  );
}

const DeleteButton = styled.button`
  display: block;
  position: absolute;
  top: 8px;
  right: 8px;
  border: 0 none;
  border-radius: 6px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  cursor: pointer;
  padding: 5px;
  opacity: 0;
  transition: opacity 0.2s;

  &:focus-visible {
    outline: 3px solid ${(p) => p.theme.colors.service.focus};
    outline-offset: 0;
  }

  &:hover,
  &:active {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 8px;
    }
  }

  &:hover::after {
    background-color: ${(p) => p.theme.colors.service.hover};
  }

  &:active::after {
    background-color: ${(p) => p.theme.colors.service.selected};
  }

  &:focus-visible {
    outline: 3px solid ${(p) => p.theme.colors.service.focus};
    outline-offset: 0;
  }
`;

const WorkLink = styled.a`
  text-decoration: none;
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  padding: 1px 4px 1px 6px;
  display: flex;
  align-items: center;
  gap: 2px;
  border-radius: 6px;
  opacity: 0;
  transition: opacity 0.1s;
  ${(p) => typographyCSS(p.theme.typo.body2)};

  &:hover::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.service.hover};
  }

  &:focus-visible {
    outline: 3px solid ${(p) => p.theme.colors.service.focus};
    outline-offset: 0;
  }
`;

export const WorkSlot = styled(BaseWorkSlot)`
  &:hover, &:focus-within {
    ${DeleteButton}, ${WorkLink} {
      opacity: 1;
  }
`;

const WorkButton = styled.button`
  position: relative;
  display: block;
  position: absolute;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  height: 100%;
  border: 0 none;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;

  &:focus-visible {
    outline: 3px solid ${(p) => p.theme.colors.service.focus};
    outline-offset: 0;
  }
`;

const WorkImage = styled.img`
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 0;
  object-fit: cover;
`;

const VideoBadge = styled.div`
  position: absolute;
  left: 8px;
  bottom: 8px;

  svg {
    display: block;
  }
`;
