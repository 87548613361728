import { useCallback } from "react";
import styled from "styled-components/macro";
import { ActionButton } from "./ActionButton";

interface NumberControlProps {
  value: number;
  minValue?: number;
  onChange: (value: number) => void;
}

export function NumberControl({
  value,
  minValue,
  onChange,
}: NumberControlProps) {
  const handleMinusClick = useCallback(() => {
    onChange(value - 1);
  }, [value, onChange]);

  const handlePlusClick = useCallback(() => {
    onChange(value + 1);
  }, [value, onChange]);

  return (
    <Container>
      <ActionButton
        type="button"
        icon="minus"
        disabled={minValue !== undefined && value <= minValue}
        onClick={handleMinusClick}
      />
      <Value>{value}</Value>
      <ActionButton type="button" icon="plus" onClick={handlePlusClick} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Value = styled.div`
  min-width: 20px;
  text-align: center;
`;
