import { useCallback } from "react";
import { ActionButton } from "../../../components/ActionButton";
import { Button } from "../../../components/Button";
import { Dialog } from "../../../components/Dialog";
import { Modal } from "../../../components/Modal";
import { useBackButton } from "../../../components/Overpage/BackButton";
import { List, useDeleteListMutation } from "../../../graphql";

interface DeleteListButtonProps {
  list: {
    id: string;
    title: string;
  };
}

export function DeleteListButton({ list }: DeleteListButtonProps) {
  const [deleteList, { loading }] = useDeleteListMutation();
  const handleNavigateBack = useBackButton({
    defaultTo: `/lists`,
  });

  const handleDeleteClick = useCallback(() => {
    return deleteList({
      variables: {
        input: {
          listId: list.id,
        },
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: list.id,
          __typename: "List",
        } as Partial<List>);

        cache.evict({ id: normalizedId });
        cache.gc();
      },
      onCompleted() {
        handleNavigateBack();
      },
    });
  }, [handleNavigateBack, deleteList, list]);

  return (
    <Modal
      trigger={(getTriggerProps) => (
        <ActionButton icon="trashbin" text="Delete" {...getTriggerProps()} />
      )}
      render={({ getContentProps, close }) => (
        <Dialog
          title="Delete list"
          content={
            <>
              Are you sure you want to delete <strong>"{list.title}"</strong>{" "}
              list? This operation CAN NOT be undone.
            </>
          }
          buttons={
            <>
              <Button $kind="text" onClick={close} disabled={loading}>
                Cancel
              </Button>
              <Button
                $kind="contained"
                onClick={handleDeleteClick}
                disabled={loading}
              >
                Delete list
              </Button>
            </>
          }
          close={close}
          {...getContentProps()}
        />
      )}
    />
  );
}
