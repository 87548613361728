import styled, { keyframes } from "styled-components";

const spinnerRotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div<{ $size: number }>`
  display: inline-block;
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;

  &:after {
    content: " ";
    display: block;
    width: 75%;
    height: 75%;
    margin: 12.5%;
    border-radius: 50%;
    border: 2px solid ${(p) => p.theme.colors.ui.purple};
    box-sizing: border-box;
    border-color: ${(p) => p.theme.colors.ui.purple} transparent
      ${(p) => p.theme.colors.ui.purple} transparent;
    animation: ${spinnerRotateAnimation} 1.2s linear infinite;
  }
`;
