import { Navigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { NoAccess } from "../../../components/NoAccess";
import {
  BackButton,
  useBackButton,
} from "../../../components/Overpage/BackButton";
import { Spinner } from "../../../components/Spinner";
import { useListDetailsQuery, useUpdateListMutation } from "../../../graphql";
import { FormData, ListForm } from "./ListForm";

export function EditListForm() {
  const { listId } = useParams();
  const handleNavigateBack = useBackButton({
    defaultTo: `/lists/${listId}`,
  });
  const [updateList] = useUpdateListMutation();
  const { data, error } = useListDetailsQuery({
    skip: !listId,
    variables: {
      listId: listId ?? "",
    },
  });

  if (!listId) {
    return <Navigate to="/lists" replace />;
  }

  if (error && error.message === "Not permitted") {
    return <Navigate to="/lists" replace />;
  }

  if (error && error.message === "No access") {
    return <NoAccess backTo="/lists" />;
  }

  const list = data?.list;

  const handleFormSubmit = (data: FormData) => {
    return updateList({
      variables: {
        input: {
          listId,
          title: data.title,
          client: data.client,
          overview: data.overview,
          positions: data.positions.map((position) => ({
            id: position.id ?? null,
            title: position.title,
            description: position.description,
            skills: position.skills.length > 0 ? position.skills : null,
            count: position.count,
          })),
          team: data.team.map((member) => member.id),
        },
      },
      onCompleted() {
        handleNavigateBack();
      },
    });
  };

  return (
    <Container>
      <TopPanel>
        <BackButton defaultTo="/lists" />
      </TopPanel>
      <Title>Edit {list ? ` "${list.title}"` : null} list</Title>
      <Content>
        {list ? (
          <ListForm
            onSubmit={handleFormSubmit}
            onCancel={handleNavigateBack}
            list={list}
          />
        ) : (
          <SpinnerContainer>
            <Spinner $size={64} />
          </SpinnerContainer>
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  margin: 0 auto;
  padding-bottom: 80px;
  box-sizing: border-box;
  max-width: 2678px;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  min-height: 100%;
`;

const TopPanel = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 24px;
`;

const Content = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
