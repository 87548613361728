import { ReactNode, useCallback } from "react";
import { Button } from "../../../../components/Button";
import { Dialog } from "../../../../components/Dialog";
import { Modal } from "../../../../components/Modal";
import { useDeleteWorkMutation } from "../../../../graphql";

interface DeleteWorkActionProps {
  workId: string;
  render: (
    getReferenceProps: (
      props?: React.HTMLProps<Element>
    ) => Record<string, unknown>
  ) => ReactNode;
}

export function DeleteWorkAction({ workId, render }: DeleteWorkActionProps) {
  const [deleteWork, { loading }] = useDeleteWorkMutation();

  const handleDeleteClick = useCallback(() => {
    deleteWork({
      variables: {
        input: {
          id: workId,
          clientMutationId: null,
        },
      },
      optimisticResponse: {
        deleteWork: {
          __typename: "DeleteWorkPayload",
          deletedId: workId,
        },
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: workId,
          __typename: "TalentWork",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  }, [deleteWork, workId]);

  return (
    <Modal
      key={workId}
      trigger={render}
      render={({ getContentProps, close }) => (
        <Dialog
          title="Delete work"
          content={
            <>
              Are you sure you want to delete work? This operation CAN NOT be
              undone.
            </>
          }
          buttons={
            <>
              <Button $kind="text" onClick={close} disabled={loading}>
                Cancel
              </Button>
              <Button
                $kind="contained"
                onClick={handleDeleteClick}
                disabled={loading}
              >
                Delete work
              </Button>
            </>
          }
          close={close}
          {...getContentProps()}
        />
      )}
    />
  );
}
