interface GlobalState {
  promise: Promise<void>;
  resolveFn: () => void;
}
type ResolveFn = (() => void) | null;

let globalState: GlobalState | null = null;

export function getRenderWaitPromise() {
  if (!globalState) {
    let resolveFn: ResolveFn = null;
    const promise = new Promise<void>((resolve) => {
      resolveFn = resolve;
    });

    globalState = {
      promise,
      resolveFn: resolveFn!,
    }
  }

  return globalState.promise;
}

export function resolveRenderWaitPromise() {
  if (globalState) {
    globalState.resolveFn();
    globalState = null;
  }
}
