export function track(event: string, params?: any) {
  const { heap } = window as any;

  if (!heap) {
    console.log("TRACK", event, params);
    return;
  }

  heap.track(event, params);
}
