import { useCallback, useState } from "react";
import styled from "styled-components/macro";
import { BackButton } from "../../../components/Overpage/BackButton";
import { ProfileUrlExtraction } from "../../../types";
import { FormStep } from "./FormStep";
import { ParserStep } from "./ParserStep";

enum WizardStep {
  Parser,
  Form,
}

export function AddTalentPage() {
  const [step, setStep] = useState(WizardStep.Parser);
  const [extraction, setExtraction] = useState<ProfileUrlExtraction | null>(null);

  const handleProfileParsed = useCallback((extraction: ProfileUrlExtraction) => {
    setExtraction(extraction);
    setStep(WizardStep.Form);
  }, []);

  const handleManuallyChoosed = useCallback(() => {
    setExtraction(null);
    setStep(WizardStep.Form);
  }, []);

  const handleFormBack = useCallback(() => {
    setStep(WizardStep.Parser);
  }, []);

  return (
    <Container>
      <TopPanel>
        <BackButton defaultTo="/talents" />
      </TopPanel>
      <Title>Add new talent</Title>
      <Content>
        {step === WizardStep.Parser ? (
          <ParserStep
            onParsed={handleProfileParsed}
            onManually={handleManuallyChoosed}
          />
        ) : (
          <FormStep extraction={extraction} onBack={handleFormBack} />
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  margin: 0 auto;
  padding-bottom: 80px;
  box-sizing: border-box;
  max-width: 2678px;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  min-height: 100%;
`;

const TopPanel = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 24px;
`;

const Content = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;
