import styled from "styled-components/macro";
import { BackButton } from "../../../components/Overpage/BackButton";
import { Navigate, useParams } from "react-router-dom";
import { useTalentQuery } from "../../../graphql";
import { EditTalentForm } from "./EditTalentForm";

export function EditTalentPage() {
  const { talentId } = useParams();
  const { data, error } = useTalentQuery({
    skip: !talentId,
    variables: {
      id: talentId ?? "",
    },
  });

  if (!talentId) {
    return <Navigate to="/talents" replace />;
  }

  if (error && error.message === "Not permitted") {
    return <Navigate to="/talents" replace />;
  }
  const talent = data?.talent;

  return (
    <Container>
      <TopPanel>
        <BackButton defaultTo="/talents" />
      </TopPanel>
      <Title>Edit talent</Title>
      {talent && <EditTalentForm talent={talent} />}
    </Container>
  );
}

const Container = styled.div`
  margin: 0 auto;
  padding-bottom: 80px;
  box-sizing: border-box;
  max-width: 2678px;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  min-height: 100%;
`;

const TopPanel = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 24px;
`;
