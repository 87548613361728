import {
  createContext,
  useContext,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";

type StateReturnType = [
  FillableListData | null,
  Dispatch<SetStateAction<FillableListData | null>>
];

const Context = createContext<StateReturnType | null>(null);
const LIST_ID_KEY = "FILLABLE_LIST_LID";
const POSITION_ID_KEY = "FILLABLE_LIST_PID";

export interface FillableListData {
  listId: string;
  positionId: string | null | undefined;
}

export function useFillableListStorage() {
  const contextValue = useContext(Context);

  if (contextValue === null) {
    throw new Error("FillableListStorageProvider is missing");
  }

  const [fillableListData, setFillableListData] = contextValue;

  const resetFillableList = useCallback(() => {
    localStorage.removeItem(LIST_ID_KEY);
    localStorage.removeItem(POSITION_ID_KEY);
    setFillableListData(null);
  }, [setFillableListData]);

  const setFillableList = useCallback(
    (listId: string, positionId: string) => {
      localStorage.setItem(LIST_ID_KEY, listId);
      localStorage.setItem(POSITION_ID_KEY, positionId);
      setFillableListData({
        listId,
        positionId,
      });
    },
    [setFillableListData]
  );

  return { fillableListData, setFillableList, resetFillableList };
}

interface FillableListStorageProps {
  children: JSX.Element;
}

export function FillableListStorageProvider({
  children,
}: FillableListStorageProps) {
  const state = useState<FillableListData | null>(() => {
    const listId = localStorage.getItem(LIST_ID_KEY);
    if (!listId) {
      return null;
    }

    return {
      listId,
      positionId: localStorage.getItem(POSITION_ID_KEY),
    };
  });

  return <Context.Provider value={state}>{children}</Context.Provider>;
}
