import { FloatingTree } from "@floating-ui/react";
import {
  Control,
  Controller,
  UseFormRegister,
  useFormState,
} from "react-hook-form";
import * as yup from "yup";
import { CategoryData } from "../../types";
import { Box, ErrorMsg, FormInput, Hint, InputRow, Section, Title } from "./ui";
import { SkillsControl } from "./SkillsControl";
import { CategoriesControl } from "./CategoriesControl";

export interface FormData {
  categories: CategoryData[];
  notes: string;
  skills: string[];
}

export const schema = {
  categories: yup.array().min(1, "Choose at least one category").required(),
  notes: yup.string(),
  skills: yup.array().min(0, "Choose at least one tag").required(),
};

interface SecondFormPartProps {
  register: UseFormRegister<FormData>;
  control: Control<FormData>;
}

export function SecondFormPart({ register, control }: SecondFormPartProps) {
  const { errors } = useFormState({ control });

  return (
    <Box>
      <Title>Categories</Title>
      <Section>
        <Controller
          name="categories"
          control={control}
          render={({ field }) => (
            <FloatingTree>
              <CategoriesControl
                categories={field.value}
                onChange={field.onChange}
              />
            </FloatingTree>
          )}
        />
        {errors.categories && (
          <ErrorMsg>{errors.categories.message ?? "Incorrect value"}</ErrorMsg>
        )}
      </Section>
      <Title>Tags 💡</Title>
      <Section>
        <Controller
          name="skills"
          control={control}
          render={({ field, fieldState }) => (
            <FloatingTree>
              <SkillsControl
                skills={field.value}
                onChange={field.onChange}
                fieldState={fieldState}
              />
            </FloatingTree>
          )}
        />
      </Section>
      <Title>Notes</Title>
      <Section>
        <InputRow>
          <FormInput
            {...register("notes")}
            placeholder="Drop a line about this talent"
            autoComplete="off"
          />
        </InputRow>
        <Hint>
          These notes will appear on the talent’s card on the list view
        </Hint>
      </Section>
    </Box>
  );
}
