import { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";
import { isGranted } from "../../../../acl";
import { ActionButton } from "../../../../components/ActionButton";
import { Button } from "../../../../components/Button";
import { useViewer } from "../../../../components/context/auth_gate";
import { Input } from "../../../../components/Input";
import { useOverpageHandler } from "../../../../components/Overpage/useOverpageHandler";
import { useDebouncedCallback } from "../../../../hooks/useDebouncedCallback";
import { SkillAggregation } from "../../../../types";
import { DeleteSamplesButton } from "./DeleteSamplesButton";

import { TagsFilter } from "./SkillsFilter";

interface FilterBarProps {
  skills: SkillAggregation[];
  untaggedCount: number;
}

export function getArray(
  searchParams: URLSearchParams,
  name: string
): string[] {
  let selectedTags = searchParams.get(name);
  if (!selectedTags) {
    return [];
  }

  return selectedTags.split(",");
}

function setArray<T>(searchParams: URLSearchParams, name: string, arr: T[]) {
  if (arr.length > 0) {
    searchParams.set(name, arr.join(","));
  } else {
    searchParams.delete(name);
  }
}

export const TAGS_KEY = "tags";

export function FilterBar({ skills, untaggedCount }: FilterBarProps) {
  const viewer = useViewer();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get("q") ?? "");

  const selectedTagsString = searchParams.get(TAGS_KEY);
  const selectedTags = useMemo(() => {
    return selectedTagsString ? selectedTagsString.split(",") : [];
  }, [selectedTagsString]);
  const wantsFavorite = searchParams.get("fav") === "1";
  const wantsUntagged = searchParams.get("untagged") === "1";

  const addTalentHandler = useOverpageHandler({ to: "/talents/add" });

  const updateQParam = useDebouncedCallback((value: string) => {
    const filteredValue = value && value.length >= 2 ? value : "";
    const currentQ = searchParams.get("q") ?? "";

    if (filteredValue === currentQ) {
      return;
    }

    if (filteredValue) {
      searchParams.set("q", filteredValue);
    } else {
      searchParams.delete("q");
    }
    setSearchParams(searchParams);
  }, 500);

  const handleFavClick = useCallback(() => {
    if (wantsFavorite) {
      searchParams.delete("fav");
    } else {
      searchParams.set("fav", "1");
    }
    setSearchParams(searchParams);
  }, [wantsFavorite, searchParams, setSearchParams]);

  const handleTagToggle = useCallback(
    (tag: string) => {
      let selectedTags = getArray(searchParams, TAGS_KEY);
      let newTags = selectedTags.includes(tag)
        ? selectedTags.filter((t) => t !== tag)
        : [...selectedTags, tag];

      setArray(searchParams, TAGS_KEY, newTags);
      searchParams.delete("untagged")
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleUntaggedToggle = useCallback(
    () => {
      if (wantsUntagged) {
        searchParams.delete("untagged")
      } else {
        searchParams.set("untagged", "1")
      }
      setSearchParams(searchParams);
    },
    [wantsUntagged, searchParams, setSearchParams]
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchTerm(value);
      updateQParam(value);
    },
    [updateQParam]
  );

  return (
    <Container>
      <TagsFilter
        skills={skills}
        selectedTags={selectedTags}
        wantsUntagged={wantsUntagged}
        untaggedCount={untaggedCount}
        onTagToggle={handleTagToggle}
        onUntaggedToggle={handleUntaggedToggle}
      />
      <FavoriteButton
        icon={wantsFavorite ? "heartfilled" : "heart"}
        $active={wantsFavorite}
        onClick={handleFavClick}
      />
      <SearchContainer>
        <Input
          type="text"
          icon="magnifier"
          value={searchTerm}
          placeholder="Search contacts"
          onValueChange={handleSearchChange}
          kind="filled"
          clearable
        />
      </SearchContainer>
      <ButtonsContainer>
        {isGranted(viewer, "talent.delete") && <DeleteSamplesButton />}
        {isGranted(viewer, "talent.add") && (
          <Button as="a" {...addTalentHandler} $kind="contained">
            Add talent
          </Button>
        )}
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.div`
  background: white;
  border-bottom: 1px solid ${(p) => p.theme.colors.borders.separator};
  height: 65px;
  box-sizing: border-box;
  position: sticky;
  top: ${(p) => p.theme.panelHeight};
  z-index: 10;
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto max-content max-content max-content;
  gap: 8px;
`;

const SearchContainer = styled.div`
  flex: 0 0 auto;

  label {
    width: 200px;
  }
`;

const ButtonsContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FavoriteButton = styled(ActionButton) <{ $active: boolean }>`
  color: ${(p) => (p.$active ? "inherit" : p.theme.colors.ui.greyFilled)};
`;
