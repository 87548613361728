import { yupResolver } from "@hookform/resolvers/yup";
import {
  Control,
  useForm,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";
import styled from "styled-components/macro";
import * as yup from "yup";
import {
  MainFormPart,
  FormData as MainFormData,
  schema as mainFormSchema,
} from "../../../components/TalentForm/MainFormPart";
import {
  SecondFormPart,
  FormData as SecondFormData,
  schema as secondFormSchema,
} from "../../../components/TalentForm/SecondFormPart";
import { Button } from "../../../components/Button";
import { useBackButton } from "../../../components/Overpage/BackButton";
import { TalentType } from "../../../types";
import { useUpdateTalentMutation } from "../../../graphql";

interface FormData extends MainFormData, SecondFormData {}

const schema = yup
  .object({
    ...mainFormSchema,
    ...secondFormSchema,
  })
  .required();

interface EditTalentFormProps {
  talent: TalentType;
}

export function EditTalentForm({ talent }: EditTalentFormProps) {
  const handleNavigateBack = useBackButton({
    defaultTo: `/talents/${talent.id}`,
  });
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: talent.name,
      location: talent.location ?? "",
      price: talent.price ?? "",
      priceUnit: talent.priceUnit ?? "",
      priceComment: talent.priceComment ?? "",
      contacts: [
        ...(talent.contacts
          ?.filter((value) => value.length > 0)
          .map((value) => ({ value })) ?? []),
        { value: "" },
      ],
      categories: talent.categories ?? [],
      bio: talent.about ?? "",
      notes: talent.notes ?? "",
      skills: talent.skills ?? [],
      avatarImage: talent.image,
    },
  });

  const [updateTalent] = useUpdateTalentMutation();

  const handleFormSubmit = handleSubmit((data) => {
    const talentCategories = talent.categories ?? [];
    const areCategoriesAffected =
      talentCategories.length !== data.categories.length ||
      talentCategories.some((tc) =>
        data.categories.every((dc) => dc.id !== tc.id)
      );

    return updateTalent({
      variables: {
        input: {
          id: talent.id,
          image: data.avatarImage?.id ?? null,
          name: data.name,
          location: data.location,
          price: data.price,
          pricePeriod: data.priceUnit,
          priceComment: data.priceComment,
          contacts: data.contacts
            .map((contact) => contact.value)
            .filter((value) => value.length > 0),
          categories: data.categories.map((category) => category.id),
          about: data.bio,
          notes: data.notes,
          tags: data.skills,
          clientMutationId: null,
        },
      },
      refetchQueries: areCategoriesAffected ? ["CategoriesList"] : undefined,
      onCompleted() {
        handleNavigateBack();
      },
    });
  });

  return (
    <Content>
      <form onSubmit={handleFormSubmit}>
        <MainFormPart
          talentId={talent.id}
          hasBio={talent.about ? true : false}
          register={register as any as UseFormRegister<MainFormData>}
          control={control as any as Control<MainFormData>}
          getValues={getValues as any as UseFormGetValues<MainFormData>}
        />
        <SecondFormPart
          register={register as any as UseFormRegister<SecondFormData>}
          control={control as any as Control<SecondFormData>}
        />
        <Buttons>
          <Button $size="xl" $kind="contained" disabled={isSubmitting}>
            Save
          </Button>
          <Button
            type="button"
            $size="l"
            $kind="text"
            onClick={handleNavigateBack}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </Buttons>
      </form>
    </Content>
  );
}

const Content = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;

const Buttons = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
