import { startOfDay } from "date-fns";
import {
  addDays,
  addWeeks,
  endOfMonth,
  endOfWeekWithOptions,
  format,
  getDate,
  getDayOfYear,
  getWeekWithOptions,
  isEqual,
  startOfWeekWithOptions,
} from "date-fns/fp";

export class Month {
  private year: number;
  private month: number;

  constructor(date?: Date) {
    let monthDate = date ?? new Date();
    this.year = monthDate.getFullYear();
    this.month = monthDate.getMonth();
  }

  public add(num: number) {
    return new Month(new Date(this.year, this.month + num, 1));
  }

  public toDate() {
    return new Date(this.year, this.month, 1);
  }

  public isTodayMonth() {
    const current = new Month();
    return this.year === current.year && this.month === current.month;
  }
}

enum WeekdayFormat {
  Full,
  Compact,
  ExtraCompact,
  SingleChar,
}

export interface GetMonthGridOptions {
  fixedNumberOfWeek?: boolean;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  now?: Date;
  weekdayFormat?: WeekdayFormat;
}

export interface CalendarMonth {
  date: Date;
  month: Month;
  weekdayNames: string[];
  weeks: CalendarWeek[];
}

export interface CalendarWeek {
  date: Date;
  weekNumber: number;
  days: CalendarDay[];
}

export interface CalendarDay {
  date: Date;
  num: number;
  dayOfYear: number;
  today: boolean;
  past: boolean;
  currentMonth: boolean;
  prevMonth: boolean;
  nextMonth: boolean;
}

export function getMonthGrid(
  month: Month,
  options?: GetMonthGridOptions
): CalendarMonth {
  const now = options?.now ?? new Date();
  const weekStartsOn = options?.weekStartsOn;
  const today = startOfDay(now);
  const monthDate = month.toDate();
  const startDate = startOfWeekWithOptions({ weekStartsOn }, monthDate);
  const endOfMonthDate = endOfMonth(monthDate);
  const endDate = endOfWeekWithOptions(
    { weekStartsOn: options?.weekStartsOn },
    endOfMonthDate
  );
  const fixedNumberOfWeek = options?.fixedNumberOfWeek ?? false;

  const weeks = [] as CalendarWeek[];

  for (let w = 0; w < 6; w++) {
    const weekStartDate = addWeeks(w, startDate);
    if (!fixedNumberOfWeek && weekStartDate > endDate) break;
    const days = [] as CalendarDay[];
    for (let dow = 0; dow < 7; dow++) {
      const dayDate = addDays(dow, weekStartDate);
      const prevMonth = dayDate < monthDate;
      const nextMonth = dayDate > endOfMonthDate;

      days.push({
        date: dayDate,
        num: getDate(dayDate),
        dayOfYear: getDayOfYear(dayDate),
        today: isEqual(dayDate, today),
        past: dayDate < today,
        prevMonth: dayDate < monthDate,
        nextMonth: dayDate > endOfMonthDate,
        currentMonth: !prevMonth && !nextMonth,
      });
    }
    weeks.push({
      date: weekStartDate,
      weekNumber: getWeekWithOptions({ weekStartsOn }, weekStartDate),
      days,
    });
  }

  const monthStruct: CalendarMonth = {
    date: monthDate,
    month,
    weeks,
    weekdayNames: weeks[0].days.map((day) =>
      format(getWeekdayFormat(options?.weekdayFormat), day.date)
    ),
  };

  return monthStruct;
}

function getWeekdayFormat(format: WeekdayFormat | undefined): string {
  switch (format ?? WeekdayFormat.ExtraCompact) {
    case WeekdayFormat.Full:
      return "EEEE";
    case WeekdayFormat.Compact:
      return "EEE";
    case WeekdayFormat.ExtraCompact:
      return "EEEEEE";
    case WeekdayFormat.SingleChar:
      return "EEEEE";
  }
}
