import { MutationBaseOptions } from "@apollo/client/core/watchQueryOptions";
import {
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  UseFloatingReturn,
  useFloatingTree,
  useInteractions,
  useListNavigation,
} from "@floating-ui/react";
import {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components/macro";
import { ListStatus, useAddListCandidateMutation, useListsQuery } from "../../graphql";
import { ListPreviewType } from "../../types";
import { stickyTop } from "../../utils/stickyTop";
import { useFillableListStorage } from "../context/fillable_list";
import { FillableListMenu } from "./FillableListMenu";
import { filterLists } from "./filterLists";
import { usePositionsMenu } from "./PositionsMenu";

const MAX_LISTS_COUNT = 30;

interface UseListsPickerOptions {
  talentId: string;
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  refetchQueries?: MutationBaseOptions["refetchQueries"];
}

const emptyArray = Array<any>();

export function useListsPicker({
  talentId,
  opened,
  setOpened,
  refetchQueries,
}: UseListsPickerOptions) {
  const topMemoRef = useRef<number | null>(null);
  const tree = useFloatingTree();
  const listRef = useRef([] as (HTMLElement | null)[]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { fillableListData, resetFillableList } = useFillableListStorage();

  if (!tree) {
    throw new Error("<FloatingTree /> is missing for Lists picker");
  }

  const listsQuery = useListsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      ownership: null,
      status: ListStatus.Active,
    }
  });
  const lists = listsQuery.data?.lists;

  const fillableList = useMemo(() => {
    if (!lists || !fillableListData) {
      return undefined;
    }

    return lists.find((list) => list.id === fillableListData.listId);
  }, [lists, fillableListData]);

  const close = useCallback(() => {
    topMemoRef.current = null;
    setOpened(false);
  }, [setOpened]);

  const handleOpenChange = useCallback(
    (willBeOpened: boolean) => {
      if (willBeOpened) {
        setOpened(true);
      } else {
        close();
      }
    },
    [close, setOpened]
  );
  const nodeId = useFloatingNodeId();

  const floatingReturn = useFloating({
    nodeId,
    open: opened,
    onOpenChange: handleOpenChange,
    placement: "bottom",
    strategy: "fixed",
    middleware: [
      offset(4),
      shift({ crossAxis: true, padding: 5 }),
      stickyTop({ topMemoRef }),
    ],
  });

  const interactionsReturn = useInteractions([
    useClick(floatingReturn.context),
    useDismiss(floatingReturn.context, { bubbles: false }),
    useListNavigation(floatingReturn.context, {
      enabled: !!fillableList,
      listRef,
      activeIndex,
      onNavigate: setActiveIndex,
      loop: true,
    }),
  ]);

  const listsPopoverElement = opened ? (
    <FloatingPortal>
      <FloatingNode id={nodeId}>
        <Overlay lockScroll>
          <ListsPicker
            fillableList={fillableList}
            fillablePositionId={fillableListData?.positionId}
            resetFillableList={resetFillableList}
            targetTalentId={talentId}
            floatingReturn={floatingReturn}
            interactionsReturn={interactionsReturn}
            allLists={lists ?? emptyArray}
            listRef={listRef}
            closePicker={close}
            refetchQueries={refetchQueries}
          />
        </Overlay>
      </FloatingNode>
    </FloatingPortal>
  ) : null;
  const getReferenceProps = useCallback(
    (props?: React.HTMLProps<Element>) =>
      interactionsReturn.getReferenceProps.call(null, {
        ...props,
        ref: floatingReturn.reference,
      }),
    [interactionsReturn.getReferenceProps, floatingReturn.reference]
  );

  return [getReferenceProps, listsPopoverElement] as [
    typeof getReferenceProps,
    typeof listsPopoverElement
  ];
}

interface ListsPickerProps {
  fillableList: ListPreviewType | null | undefined;
  fillablePositionId: string | null | undefined;
  resetFillableList: () => void;
  targetTalentId: string;
  floatingReturn: UseFloatingReturn;
  interactionsReturn: ReturnType<typeof useInteractions>;
  allLists: ListPreviewType[];
  refetchQueries?: MutationBaseOptions["refetchQueries"];
  listRef: MutableRefObject<(HTMLElement | null)[]>;
  closePicker: () => void;
}

function ListsPicker({
  allLists,
  floatingReturn,
  interactionsReturn,
  fillableList,
  fillablePositionId,
  resetFillableList,
  listRef,
  targetTalentId,
  refetchQueries,
  closePicker,
}: ListsPickerProps) {
  const [addListCandidate] = useAddListCandidateMutation();
  const handlePositionSelected = useCallback(
    (listId: string, positionId: string) => {
      return addListCandidate({
        variables: {
          input: {
            listId,
            positionId,
            talentId: targetTalentId,
          },
        },
        refetchQueries,
        onCompleted() {
          closePicker();
        },
      });
    },
    [targetTalentId, addListCandidate, closePicker, refetchQueries]
  );

  return fillableList ? (
    <FillableListMenu
      targetTalentId={targetTalentId}
      floatingReturn={floatingReturn}
      interactionsReturn={interactionsReturn}
      fillableList={fillableList}
      fillablePositionId={fillablePositionId}
      listRef={listRef}
      onCancel={resetFillableList}
      onSelected={handlePositionSelected}
    />
  ) : (
    <AllListsPicker
      targetTalentId={targetTalentId}
      allLists={allLists}
      floatingReturn={floatingReturn}
      interactionsReturn={interactionsReturn}
      closePicker={closePicker}
      onSelected={handlePositionSelected}
    />
  );
}

interface AllListsPickerProps {
  targetTalentId: string;
  floatingReturn: UseFloatingReturn;
  interactionsReturn: ReturnType<typeof useInteractions>;
  allLists: ListPreviewType[];
  closePicker: () => void;
  onSelected: (listId: string, positionId: string) => void;
}

function AllListsPicker({
  targetTalentId,
  floatingReturn,
  interactionsReturn,
  allLists,
  closePicker,
  onSelected,
}: AllListsPickerProps) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [openedMenuIndex, setOpenedMenuIndex] = useState(-1);
  const [filterInput, setFilterInput] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const listItemRefs = useRef([] as (HTMLElement | null)[]);
  const { x, y, floating, strategy, update } = floatingReturn;
  const { getFloatingProps } = interactionsReturn;

  const filterString = useMemo(() => filterInput.toLowerCase(), [filterInput]);
  const lists = useMemo(() => {
    return filterLists(allLists, filterString).slice(0, MAX_LISTS_COUNT);
  }, [filterString, allLists]);

  const openedList = openedMenuIndex >= 0 ? lists[openedMenuIndex] : null;

  const focusInput = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const setActiveIndexAndScroll = useCallback((newIndex: number) => {
    setActiveIndex(newIndex);
    const element = listItemRefs.current[newIndex];
    if (element) {
      element.scrollIntoView({
        block: "center",
        inline: "nearest",
      });
    }
  }, []);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFilterInput(e.target.value);
  }, []);

  const handlePositionSelected = useCallback(
    (positionId: string) => {
      if (!openedList) {
        return;
      }

      onSelected(openedList.id, positionId);
    },
    [onSelected, openedList]
  );

  const [getReferenceProps, positionsMenuElement] = usePositionsMenu({
    targetTalentId,
    listPositions: openedList?.positions ?? null,
    onClose: useCallback(() => {
      setOpenedMenuIndex(-1);
    }, []),
    onSelected: handlePositionSelected,
  });

  useLayoutEffect(() => {
    if (filterInput.length === 0) {
      setActiveIndexAndScroll(-1);
    } else if (lists.length === 0) {
      setActiveIndexAndScroll(-1);
    } else {
      setActiveIndexAndScroll(0);
    }
  }, [filterInput, lists.length, setActiveIndexAndScroll]);

  useLayoutEffect(() => {
    update();
  }, [lists, update]);

  return (
    <>
      <Container
        ref={floating}
        style={{
          position: strategy,
          transform: `translate(${x ?? 0}px, ${y ?? 0}px)`,
        }}
        {...getFloatingProps({
          onFocus(event) {
            if (event.target !== inputRef.current) {
              focusInput();
            }
          },
          onKeyDown(event) {
            switch (event.key) {
              case "Enter":
                if (lists[activeIndex]) {
                  setOpenedMenuIndex(activeIndex);
                } else if (filterString.length === 0 && activeIndex < 0) {
                  closePicker();
                }
                break;
              case "ArrowRight":
                const inputEl = inputRef.current;
                if (
                  lists[activeIndex] &&
                  inputEl &&
                  inputEl.selectionStart === inputEl.value.length
                ) {
                  event.preventDefault();
                  setOpenedMenuIndex(activeIndex);
                }
                break;
              case "ArrowDown":
                event.preventDefault();
                if (lists.length > 0) {
                  setActiveIndexAndScroll((activeIndex + 1) % lists.length);
                }
                break;
              case "ArrowUp":
                event.preventDefault();
                if (lists.length > 0) {
                  setActiveIndexAndScroll(
                    (lists.length + activeIndex - 1) % lists.length
                  );
                }
                break;
            }
          },
        })}
      >
        <InputZone onClick={focusInput}>
          <Input
            ref={inputRef}
            value={filterInput}
            onChange={handleInputChange}
            placeholder="Enter a list title to search..."
            autoFocus
          />
        </InputZone>
        <MainZone>
          <Lists>
            {lists.map((list, index) => (
              <ListItem key={list.id}>
                <ListItemElement
                  $active={activeIndex === index}
                  ref={(node) => {
                    listItemRefs.current[index] = node;
                  }}
                  {...(openedMenuIndex === index ? getReferenceProps() : {})}
                  onMouseMove={() => setActiveIndex(index)}
                  onMouseEnter={() => setActiveIndex(index)}
                  onClick={() => {
                    setOpenedMenuIndex(index);
                  }}
                >
                  <div>{list.title}</div>
                  {list.positions.some((p) =>
                    p.candidates.some((c) => c.talentId === targetTalentId)
                  ) && <Indicator />}
                </ListItemElement>
              </ListItem>
            ))}
            {lists.length === 0 && (
              <ListItem>
                <InactiveListElement>
                  <EmptyHint>
                    {filterString.length > 0
                      ? "No match found"
                      : "You have no lists"}
                  </EmptyHint>
                </InactiveListElement>
              </ListItem>
            )}
          </Lists>
        </MainZone>
      </Container>
      {positionsMenuElement}
    </>
  );
}

const Overlay = styled(FloatingOverlay)`
  z-index: ${(p) => p.theme.layers.overlay};
`;

const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 400px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: ${(p) => p.theme.layers.menu};
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25),
    0px 4px 10px rgba(144, 143, 147, 0.25);
  border-radius: 8px;
  overflow: hidden;

  &:focus {
    outline: 0 none;
  }
`;

const InputZone = styled.div`
  cursor: text;
  position: relative;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;
  padding: 8px;
  min-height: 28px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const Input = styled.input`
  flex: 1 1 60px;
  min-width: 60px;
  padding: 0 4px;
  border: 0 none;
`;

const MainZone = styled.div`
  margin-top: -16px;
  padding-top: 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-top-width: 0;
  border-radius: 8px;
`;

const Lists = styled.ul`
  margin: 0;
  padding: 8px;
  max-height: 220px;
  overflow: auto;
  border-bottom: 1px solid ${(p) => p.theme.colors.borders.separator};
`;

const ListItem = styled.li`
  position: relative;
  list-style: none;
  padding: 0px;
`;

const ListItemElement = styled.button<{ $active: boolean }>`
  background: ${(p) =>
    p.$active ? p.theme.colors.service.selected : "transparent"};
  border: 0 none;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 6px 8px 8px;
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:focus {
    outline: 0 none;
  }
`;

const InactiveListElement = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 8px 6px 8px 8px;
  gap: 8px;
  cursor: default;
  text-align: center;
`;

const EmptyHint = styled.span`
  color: ${(p) => p.theme.colors.text.grey};
`;

const Indicator = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${(p) => p.theme.colors.ui.purple};
  border-radius: 50%;
`;
