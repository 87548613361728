import {
  Control,
  Controller,
  useFieldArray,
  UseFormGetValues,
  UseFormRegister,
  useFormState,
} from "react-hook-form";
import styled from "styled-components/macro";
import * as yup from "yup";
import { AvatarImageData } from "../../types";
import { AvatarControl } from "./AvatarControl";
import { Box, ErrorMsg, FormInput, Hint, InputRow, Section, Title } from "./ui";
import { Textarea } from "../FormUI";

export interface FormData {
  name: string;
  location: string;
  price: string;
  priceUnit: string;
  priceComment: string;
  bio: string;
  contacts: { value: string }[];
  avatarImage: AvatarImageData | null;
}

export const schema = {
  name: yup.string().required(),
  location: yup.string(),
  price: yup.string(),
  priceUnit: yup.string(),
  priceComment: yup.string(),
  bio: yup.string(),
};

interface MainFormPartProps {
  talentId?: string;
  hasBio: boolean;
  profileAvatarUrl?: string | null;
  register: UseFormRegister<FormData>;
  control: Control<FormData>;
  getValues: UseFormGetValues<FormData>;
}

export function MainFormPart({
  talentId,
  hasBio,
  profileAvatarUrl,
  register,
  control,
  getValues,
}: MainFormPartProps) {
  const {
    fields: contactFields,
    append: appendContact,
    remove: removeContact,
  } = useFieldArray<FormData>({
    control,
    name: "contacts",
  });
  const { touchedFields, errors } = useFormState({ control });

  return (
    <Box>
      <Section>
        <Details>
          <div>
            <Title>Details</Title>

            <InputRow>
              <FormInput
                autoComplete="off"
                placeholder="Full name"
                {...register("name")}
              />
              {errors.name && (
                <ErrorMsg>{errors.name.message ?? "Fill name"}</ErrorMsg>
              )}
            </InputRow>
            <InputRow>
              <FormInput
                {...register("location")}
                autoComplete="off"
                placeholder="Location"
              />
            </InputRow>
            <InputRow>
              <InputPair>
                <FormInput
                  {...register("price")}
                  autoComplete="off"
                  placeholder="Price"
                />
                <FormInput
                  {...register("priceUnit")}
                  autoComplete="off"
                  placeholder="Time / project"
                />
              </InputPair>
            </InputRow>
            <InputRow>
              <FormInput
                {...register("priceComment")}
                autoComplete="off"
                placeholder="Price comment"
              />
            </InputRow>
          </div>
          <Controller
            name="avatarImage"
            control={control}
            render={({ field }) => (
              <AvatarControl
                talentId={talentId}
                profileAvatarUrl={profileAvatarUrl}
                formControl={control}
                image={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Details>
      </Section>
      <Title>Contacts</Title>
      <Section>
        {contactFields.map((field, contactIndex) => (
          <InputRow key={field.id}>
            <Controller
              name={`contacts.${contactIndex}.value`}
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  autoComplete="off"
                  placeholder="Phone, email, URL"
                  onChange={(e) => {
                    field.onChange(e);
                    const value = e.target.value;
                    const formValues = getValues();
                    const isLast =
                      contactIndex === formValues.contacts.length - 1;
                    if (isLast && value.length > 0) {
                      appendContact({ value: "" }, { shouldFocus: false });
                    }
                  }}
                  onBlur={(e) => {
                    field.onBlur();
                    const value = e.target.value;
                    const formValues = getValues();
                    const isLast =
                      contactIndex === formValues.contacts.length - 1;
                    if (!isLast && value.length === 0) {
                      removeContact(contactIndex);
                    }
                  }}
                />
              )}
            />
            {touchedFields.contacts?.[contactIndex]?.value &&
              errors.contacts?.[contactIndex]?.value?.message && (
                <ErrorMsg>
                  {errors.contacts[contactIndex]?.value?.message ??
                    "Incorrect value"}
                </ErrorMsg>
              )}
          </InputRow>
        ))}
        {contactFields.length <= 1 && (
          <Hint>Add contacts and additional profile or portfolio URLs</Hint>
        )}
      </Section>
      <Title>Bio</Title>
      <Section>
        <InputRow>
          <Textarea
            {...register("bio")}
            minRows={2}
            maxRows={10}
            placeholder="Paste talent's bio here"
          />
        </InputRow>
      </Section>
    </Box>
  );
}

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

const InputPair = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;
