import styled from "styled-components/macro";

interface FilenameProps {
  className?: string;
  name: string;
}

const END_PART_LEN = 8;

export function Filename({ name, className }: FilenameProps) {
  return (
    <Container className={className}>
      {name.length > END_PART_LEN ? (
        <>
          <Truncated>{name.slice(0, -END_PART_LEN)}</Truncated>
          <span>{name.slice(-END_PART_LEN)}</span>
        </>
      ) : (
        <span>{name}</span>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  max-width: 100%;
  overflow: hidden;
`;

const Truncated = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;
