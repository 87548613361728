import styled from "styled-components/macro";
import { UserAvatar } from "../../../components/Avatar";
import { useOverpageHandler } from "../../../components/Overpage/useOverpageHandler";
import { Pie } from "../../../components/Pie";
import { CandidateStatus } from "../../../graphql";
import { ListPreviewType } from "../../../types";
import { rowRangeSelector } from "../../../utils/rowRangeSelector";

interface ListCardProps {
  list: ListPreviewType;
}

export const PLACEHOLDER_ROWS_COUNT = 3;
export const MAX_GRID_COLS = 5;

export function ListCard({ list }: ListCardProps) {
  const listLinkHandler = useOverpageHandler({
    to: `/lists/${list.id}`,
  });

  let totalPositions = 0;
  let totalApproved = 0;

  for (let position of list.positions) {
    let approvedCount = position.candidates.filter(
      (c) => c.status === CandidateStatus.Approved
    ).length;
    let positionSize = Math.max(position.count, approvedCount);
    totalPositions += positionSize;
    totalApproved += approvedCount;
  }

  const fillFraction = totalPositions > 0 ? totalApproved / totalPositions : 0;

  return (
    <Container>
      <CardContent>
        <BackgroundLink {...listLinkHandler} />
        <Info>
          <InfoContent>
            <h3>{list.title}</h3>
            <div>{list.client}</div>
          </InfoContent>
          <Actions></Actions>
        </Info>
        <Footer>
          <Owner>
            <UserAvatar size="xxs" user={list.creator} />
            <span>{list.creator.name}</span>
          </Owner>
          <Progress>
            <span>
              {totalApproved}/{totalPositions}
            </span>
            <Pie fraction={fillFraction} radius={10} />
          </Progress>
        </Footer>
      </CardContent>
    </Container>
  );
}

export function ListCardPlaceholder() {
  return <Container></Container>;
}

const Container = styled.li`
  list-style: none;
  margin: 0;
  padding: 12px;
  box-sizing: border-box;
  width: 100%;

  &._placeholder {
    display: none;
  }

  &._placeholder_init {
    &${rowRangeSelector(1, MAX_GRID_COLS)} {
      opacity: 0.7;
    }

    &${rowRangeSelector(2, MAX_GRID_COLS)} {
      opacity: 0.4;
    }
  }

  @media screen and (max-width: 973px) {
    &._placeholder {
      &:nth-of-type(${MAX_GRID_COLS}n + 1) {
        display: block;
      }
    }
  }

  @media screen and (min-width: 972px) and (max-width: 1445px) {
    width: ${100 / 2}%;

    &._placeholder {
      &:nth-of-type(${MAX_GRID_COLS}n + 1),
      &:nth-of-type(${MAX_GRID_COLS}n + 2) {
        display: block;
      }
    }
  }

  @media screen and (min-width: 1446px) and (max-width: 1919px) {
    width: ${100 / 3}%;

    &._placeholder {
      &:nth-of-type(${MAX_GRID_COLS}n + 1),
      &:nth-of-type(${MAX_GRID_COLS}n + 2),
      &:nth-of-type(${MAX_GRID_COLS}n + 3) {
        display: block;
      }
    }
  }

  @media screen and (min-width: 1920px) and (max-width: 2393px) {
    width: ${100 / 4}%;

    &._placeholder {
      &:nth-of-type(${MAX_GRID_COLS}n + 1),
      &:nth-of-type(${MAX_GRID_COLS}n + 2),
      &:nth-of-type(${MAX_GRID_COLS}n + 3),
      &:nth-of-type(${MAX_GRID_COLS}n + 4) {
        display: block;
      }
    }
  }

  @media screen and (min-width: 2394px) {
    width: ${100 / 5}%;

    &._placeholder {
      display: block;
    }
  }
`;

const CardContent = styled.div`
  position: relative;
  padding: 16px;
  box-sizing: border-box;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border-radius: 16px;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  height: 100%;
  width: 100%;
`;

const BackgroundLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 16px;
`;

const Info = styled.div`
  min-height: 156px;
  display: flex;
  align-items: start;
  gap: 16px;
`;

const InfoContent = styled.div`
  flex: 1;
`;

const Actions = styled.div`
  flex: 0 0 auto;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Owner = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Progress = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
