import { DraggableProvided, DraggableStateSnapshot } from "@hello-pangea/dnd";
import { useCallback } from "react";
import styled from "styled-components/macro";
import { isGranted } from "../../acl";
import { useViewer } from "../../components/context/auth_gate";
import { CategoryIcon } from "../../components/CategoryIcon/CategoryIcon";
import { UseEmojiPickerReturn } from "../../components/EmojiPicker/EmojiPicker";
import { UIIcon } from "../../components/UIIcon/UIIcon";
import { CategoryListItemType } from "../../types";
import { DeleteButton } from "./DeleteButton";
import { NameInput } from "./NameInput";

interface CategoryListItemProps {
  category: CategoryListItemType;
  isDragActive: boolean;
  dragSnapshot: DraggableStateSnapshot;
  dragProvided: DraggableProvided;
  updateCategory: (category: CategoryListItemType) => void;
  autoFocus: boolean;
  onEmojiClick: (categoryId: string) => void;
  getEmojiReferenceProps?: UseEmojiPickerReturn[0];
}

export function CategoryListItem({
  category,
  isDragActive,
  dragProvided,
  dragSnapshot,
  updateCategory,
  autoFocus,
  onEmojiClick,
  getEmojiReferenceProps,
}: CategoryListItemProps) {
  const viewer = useViewer();
  const handleEmojiClick = useCallback(() => {
    onEmojiClick(category.id);
  }, [onEmojiClick, category.id]);

  return (
    <ListItem
      $isDragging={dragSnapshot.isDragging}
      $muted={isDragActive}
      ref={dragProvided.innerRef}
      {...dragProvided.draggableProps}
    >
      {isGranted(viewer, "category.arrange") && (
        <Handle {...dragProvided.dragHandleProps}>
          <UIIcon name="handle" />
        </Handle>
      )}
      <IconButton
        {...getEmojiReferenceProps?.()}
        onClick={handleEmojiClick}
        disabled={!isGranted(viewer, "category.edit")}
      >
        <CategoryIcon name={category.icon} />
      </IconButton>
      {isGranted(viewer, "category.edit") ? (
        <NameInput
          autoFocus={autoFocus}
          category={category}
          updateCategory={updateCategory}
        />
      ) : (
        <Name>{category.name}</Name>
      )}
      {isGranted(viewer, "category.delete") && (
        <StyledDeleteButton category={category} />
      )}
    </ListItem>
  );
}

const Handle = styled.div`
  flex: 0 0 auto;
  color: ${(p) => p.theme.colors.ui.greyFilled};
  cursor: grab;
`;

const StyledDeleteButton = styled(DeleteButton)`
  opacity: 0;
`;

const ListItem = styled.li<{ $muted: boolean; $isDragging: boolean }>`
  list-style: none;
  margin: 0;
  padding: 3px;
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid
    ${(p) =>
      p.$isDragging
        ? p.theme.colors.borders.separator
        : p.theme.colors.backgrounds.main};
  border-radius: 8px;
  box-shadow: ${(p) =>
    p.$isDragging
      ? "1px 1px 2px rgba(255, 255, 255, 0.25), 0px 4px 10px rgba(144, 143, 147, 0.25)"
      : "none"};

  pointer-events: ${(p) => (p.$muted ? "none" : "all")};

  &:hover {
    ${StyledDeleteButton} {
      opacity: 1;
    }
  }
`;

const Name = styled.div`
  padding: 9px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const IconButton = styled.button`
  display: block;
  flex: 0 0 auto;
  padding: 0;
  margin: 0;
  border: 0 none;
  background-color: transparent;
  padding: 8px;
  border-radius: 8px;

  &:enabled {
    cursor: pointer;

    &:hover {
      background-color: ${(p) => p.theme.colors.service.hover};
    }

    &:active,
    &:focus {
      background-color: ${(p) => p.theme.colors.service.selected};
    }

    &:focus-visible {
      outline: 3px solid ${(p) => p.theme.colors.service.focus};
      outline-offset: 0;
    }
  }
`;
