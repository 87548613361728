import { useCallback } from "react";
import { ActionButton } from "../../components/ActionButton";
import { Button } from "../../components/Button";
import { Dialog } from "../../components/Dialog";
import { Modal } from "../../components/Modal";
import { useDeleteCategoryMutation } from "../../graphql";
import { CategoryListItemType } from "../../types";

interface DeleteButtonProps {
  className?: string;
  category: CategoryListItemType;
}

export function DeleteButton({ category, className }: DeleteButtonProps) {
  const [deleteCategory, {loading}] = useDeleteCategoryMutation();

  const handleDeleteClick = useCallback(() => {
    return deleteCategory({
      variables: { id: category.id },
      optimisticResponse: {
        deleteCategory: {
          __typename: "DeleteCategoryPayload",
          id: category.id,
        },
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: category.id,
          __typename: "Category",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  }, [category.id, deleteCategory]);

  return (
    <Modal
      trigger={(getTriggerProps) => (
        <ActionButton
          className={className}
          icon="trashbin"
          {...getTriggerProps()}
        />
      )}
      render={({ getContentProps, close }) => (
        <Dialog
          title={`Delete category "${category.name}"`}
          content={
            category.cardsCount > 0 ? (
              <>
                Category "{category.name}" contains{" "}
                <strong>{category.cardsCount}</strong> talents. Talents will be
                deleted along with the category. Are you sure you want to delete
                this category?
              </>
            ) : (
              <>
                Are you sure you want to delete category{" "}
                <strong>"{category.name}"</strong>?
              </>
            )
          }
          buttons={
            <>
              <Button $kind="text" onClick={close} disabled={loading}>
                Cancel
              </Button>
              <Button $kind="contained" onClick={handleDeleteClick} disabled={loading}>
                {category.cardsCount > 0
                  ? "Delete category & talents"
                  : "Delete category"}
              </Button>
            </>
          }
          close={close}
          {...getContentProps()}
        />
      )}
    />
  );
}
