import { useCallback } from "react";
import { ActionButton } from "../../../../components/ActionButton";
import { Button } from "../../../../components/Button";
import { Dialog } from "../../../../components/Dialog";
import { Modal } from "../../../../components/Modal";
import { useDeleteReviewMutation } from "../../../../graphql";
import { ReviewType } from "../../../../types";

interface DeleteButtonProps {
  className?: string;
  review: ReviewType;
}

export function DeleteReviewButton({ review, className }: DeleteButtonProps) {
  const [deleteReview, { loading }] = useDeleteReviewMutation();

  const handleDeleteClick = useCallback(() => {
    return deleteReview({
      variables: { input: { id: review.id, clientMutationId: null } },
      update(cache) {
        const normalizedId = cache.identify({
          id: review.id,
          __typename: "Review",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  }, [review.id, deleteReview]);

  return (
    <Modal
      trigger={(getTriggerProps) => (
        <ActionButton
          className={className}
          icon="trashbin"
          {...getTriggerProps()}
        />
      )}
      render={({ getContentProps, close }) => (
        <Dialog
          title="Delete review"
          content="Are you sure you want to delete this review?"
          buttons={
            <>
              <Button $kind="text" onClick={close} disabled={loading}>
                Cancel
              </Button>
              <Button
                $kind="contained"
                onClick={handleDeleteClick}
                disabled={loading}
              >
                Delete review
              </Button>
            </>
          }
          close={close}
          {...getContentProps()}
        />
      )}
    />
  );
}
