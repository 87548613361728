import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import Image404Src from "./assets/404@2x.png";

export function NotFound() {
  return (
    <Container>
      <Content>
        <img src={Image404Src} alt="Page not found" width={436} height={243} />
        <h1>Page not found</h1>
        <h3>
          The link you clicked may be broken or the page may have been removed.
        </h3>
        <p>
          Visit the <Link to="/">homepage</Link> or{" "}
          <a href="mailto:hi@baselance.co" target="_blank" rel="noreferrer">
            contact us
          </a>{" "}
          about the problem
        </p>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding-top: 96px;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
`;

const Content = styled.div`
  width: 480px;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`;
