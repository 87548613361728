import { useEffect, useRef } from "react";
import { Link, NavLink, Route, Routes } from "react-router-dom";
import styled from "styled-components/macro";
import { AddTalentPage } from "../../pages/talents/AddTalentPage/AddTalentPage";
import { CategoryManagementPage } from "../../pages/CategoryManagement/CategoryManagementPage";
import { EditTalentPage } from "../../pages/talents/EditTalentPage/EditTalentPage";
import { ListDetails } from "../../pages/lists/ListDetails/ListDetails";

import { TalentPage } from "../../pages/talents/TalentPage/TalentPage";
import { Overpage } from "../Overpage/Overpage";
import { Account } from "./Account";

import LogoSrc from "./logo.svg";
import { AddListForm } from "../../pages/lists/forms/AddListForm";
import { EditListForm } from "../../pages/lists/forms/EditListForm";
import { useViewer } from "../context/auth_gate";
import { LockedAccount } from "../../pages/LockedAccount/LockedAccount";

interface LayoutProps {
  hasOverlay?: boolean;
  children: React.ReactNode;
}

export function Layout({ children, hasOverlay = false }: LayoutProps) {
  const viewer = useViewer();
  const mainRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (hasOverlay) {
      mainRef.current?.setAttribute("inert", "");
    } else {
      mainRef.current?.removeAttribute("inert");
    }
  }, [hasOverlay]);

  return (
    <Container>
      <Panel>
        <LogoLink to="/talents">
          <img src={LogoSrc} alt="Baselance" />
        </LogoLink>
        <PanelContent>
          <Menu>
            <MenuLink to="/talents">Talents</MenuLink>
            <MenuLink to="/lists">Lists</MenuLink>
          </Menu>
        </PanelContent>
        <RightContent>
          <Account />
        </RightContent>
      </Panel>
      <Main ref={mainRef}>{children}</Main>
      {hasOverlay && (
        <Overpage>
          <Routes>
            <Route
              path="/talents/add"
              element={viewer.features.app ? <AddTalentPage /> : <LockedAccount />}
            />
            <Route path="/talents/:talentId" element={<TalentPage />} />
            <Route
              path="/talents/:talentId/edit"
              element={viewer.features.app ? <EditTalentPage /> : <LockedAccount />}
            />
            <Route
              path="/talents/manage-categories"
              element={viewer.features.app ? <CategoryManagementPage /> : <LockedAccount />}
            />
            {viewer.features.lists && (
              <>
                <Route path="/lists/add" element={<AddListForm />} />
                <Route path="/lists/:listId" element={<ListDetails />} />
                <Route path="/lists/:listId/edit" element={<EditListForm />} />
              </>
            )}
          </Routes>
        </Overpage>
      )}
    </Container>
  );
}

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Panel = styled.div`
  position: sticky;
  top: 0;
  box-sizing: border-box;
  height: ${(p) => p.theme.panelHeight};
  padding: 0 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border-bottom: 1px solid ${(p) => p.theme.colors.borders.separator};
  display: flex;
  align-items: center;
  z-index: ${(p) => p.theme.layers.panel};
`;

const LogoLink = styled(Link)`
  text-decoration: none;
  flex: 0 0 auto;
  margin-right: 32px;

  img {
    display: block;
  }
`;

const PanelContent = styled.div`
  flex: 1;
`;

const Main = styled.main`
  flex: 1 1 0;

  & > div {
    min-height: calc(100vh - ${(p) => p.theme.panelHeight});
    box-sizing: border-box;
  }
`;

const Menu = styled.nav`
  display: flex;
  gap: 32px;
  align-items: center;
`;

const MenuLink = styled(NavLink)`
  text-decoration: none;
  color: ${(p) => p.theme.colors.text.grey};

  &.active {
    color: ${(p) => p.theme.colors.text.black};
    font-weight: 700;
  }
`;

const RightContent = styled.div`
  display: flex;
  height: 100%;
  flex: 0 0 auto;
  align-items: center;
`;
