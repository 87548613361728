import { useCallback, useRef, useState } from "react";
import styled from "styled-components/macro";
import {
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
} from "@floating-ui/react";
import { useViewer } from "../../../components/context/auth_gate";
import { UIIcon } from "../../../components/UIIcon/UIIcon";

export enum SelectionKind {
  All,
  My,
  Archived,
}

interface SelectionFilterProps {
  hasAll: boolean,
  selected: SelectionKind;
  onSelected: (kind: SelectionKind) => void;
}

export function SelectionFilter({ hasAll, selected, onSelected }: SelectionFilterProps) {
  const [opened, setOpened] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { x, y, reference, floating, strategy, context } = useFloating({
    open: opened,
    onOpenChange: setOpened,
    placement: "bottom-start",
    middleware: [offset(4)],
  });

  let listIndex = 0;
  const listRef = useRef([] as (HTMLElement | null)[]);
  const listItemRef = function listItemRef<T extends HTMLElement>(
    node: T | null
  ) {
    listRef.current[listIndex++] = node;
  };
  const closeMenu = useCallback(() => {
    setOpened(false);
  }, []);

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context),
      useDismiss(context),
      useListNavigation(context, {
        listRef,
        activeIndex,
        onNavigate: setActiveIndex,
        loop: true,
      }),
    ]
  );

  return (
    <Container>
      <Dropdown {...getReferenceProps({ ref: reference })}>
        {getKindName(selected)} <UIIcon name="smalldropdown" />
      </Dropdown>
      {opened && (
        <Menu
          ref={floating}
          style={{
            position: strategy,
            transform: `translate(${x ?? 0}px, ${y ?? 0}px)`,
          }}
          {...getFloatingProps()}
        >
          {hasAll && <MenuListItem>
            <MenuElement
              {...getItemProps({
                ref: listItemRef,
                onClick: () => {
                  onSelected(SelectionKind.All);
                  closeMenu();
                },
              })}
            >
              All
            </MenuElement>
          </MenuListItem>}
          <MenuListItem>
            <MenuElement
              {...getItemProps({
                ref: listItemRef,
                onClick: () => {
                  onSelected(SelectionKind.My);
                  closeMenu();
                },
              })}
            >
              My
            </MenuElement>
          </MenuListItem>
          <MenuListItem>
            <MenuElement
              {...getItemProps({
                ref: listItemRef,
                onClick: () => {
                  onSelected(SelectionKind.Archived);
                  closeMenu();
                },
              })}
            >
              Archived
            </MenuElement>
          </MenuListItem>
        </Menu>
      )}
    </Container>
  );
}

function getKindName(kind: SelectionKind) {
  switch (kind) {
    case SelectionKind.All:
      return "All";
    case SelectionKind.My:
      return "My";
    case SelectionKind.Archived:
      return "Archived"
  }
}

const Container = styled.div`
  position: relative;
`;

const Dropdown = styled.button`
  padding: 0;
  border: 0 none;
  cursor: pointer;
  overflow: hidden;
  background: none;
  display: flex;
  gap: 4px;
  align-items: center;

  &:focus-visible {
    outline: 3px solid ${(p) => p.theme.colors.service.focus};
    outline-offset: 0;
  }
`;

const Menu = styled.ul`
  margin: 0;
  padding: 8px 0;
  min-width: 200px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;
  top: 0;
  left: 0;
  z-index: ${(p) => p.theme.layers.menu};

  &:focus {
    outline: 0 none;
  }
`;

const MenuListItem = styled.li`
  list-style: none;
  padding: 0;
`;

const MenuElement = styled.button`
  border: 0 none;
  background: transparent;
  padding: 0 16px;
  height: 40px;
  width: 100%;
  text-align: initial;

  &:hover {
    background-color: ${(p) => p.theme.colors.service.hover};
  }

  &:focus {
    outline: 0 none;
  }

  &:active,
  &:focus-visible {
    background-color: ${(p) => p.theme.colors.service.selected};
  }
`;

