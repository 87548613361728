import React, { useContext } from "react";

import { useAuthQuery } from "../../graphql";
import { AuthViewer } from "../../types";

interface AuthGateProps {
  children: JSX.Element;
}

const AuthContext = React.createContext<AuthViewer | null>(null);

export function useAuthGate(): AuthViewer | null {
  return useContext(AuthContext);
}

export function useViewer(): AuthViewer {
  const viewer = useAuthGate();

  if (!viewer) {
    throw new Error("Calling useViewer in non-authenticated context");
  }

  return viewer;
}

export function AuthGate({ children }: AuthGateProps) {
  const { loading, error, data } = useAuthQuery();

  if (loading) {
    return null;
  }

  if (error) {
    return <p>Error :(</p>;
  }

  const viewer = data?.viewer ?? null;

  return <AuthContext.Provider value={viewer}>{children}</AuthContext.Provider>;
}
