import { FloatingTree } from "@floating-ui/react";
import { useCallback, useContext } from "react";
import styled from "styled-components/macro";
import { Button } from "../../../components/Button";
import { Chat } from "../../../components/Chat/Chat";
import { useIsInOverpage } from "../../../components/Overpage/Overpage";
import { ListDetailsType } from "../../../types";
import { ChatHeader } from "./ChatHeader";
import {
  ListChatControllerContext,
  useListChatController,
} from "./ListChatController";

export interface ChatSectionProps {
  list: ListDetailsType;
}

export function ChatSection({ list }: ChatSectionProps) {
  let controller = useContext(ListChatControllerContext);
  if (controller === null) {
    throw new Error("ListChatController is missing in context");
  }

  const { isHidden, setHidden } = useListChatController();

  const isInOverpage = useIsInOverpage();

  const handleChatClose = useCallback(() => {
    setHidden(true);
  }, [setHidden]);

  const handleChatToggle = useCallback(() => {
    setHidden((value) => !value);
  }, [setHidden]);

  return (
    <ChatColumn $isInOverpage={isInOverpage}>
      <DiscussButton icon="bubble" kind="contained" onClick={handleChatToggle}>
        Discuss
      </DiscussButton>
      <ChatContainer $isHidden={isHidden}>
        <Chat
          controllerContext={ListChatControllerContext}
          renderHeader={() => (
            <FloatingTree>
              <ChatHeader list={list} onChatClose={handleChatClose} />
            </FloatingTree>
          )}
        />
      </ChatContainer>
    </ChatColumn>
  );
}

const ChatColumn = styled.div<{ $isInOverpage: boolean }>`
  position: sticky;
  top: calc(${(p) => (p.$isInOverpage ? "0px" : p.theme.panelHeight)} + 16px);
  min-width: 0;
  min-height: 400px;
  height: calc(100vh - 300px);

  @media screen and (max-width: 1439px) {
    position: fixed;
    top: auto;
    bottom: 8px;
    right: 24px;
    min-height: 0;
    height: auto;
    max-height: calc(100vh - 300px);
  }

  @media screen and (max-width: 542px) {
    display: none;
  }
`;

const ChatContainer = styled.div<{ $isHidden: boolean }>`
  @media screen and (min-width: 1440px) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 1439px) {
    margin-bottom: 10px;
    position: absolute;
    bottom: 100%;
    right: 0;
    width: 510px;
    height: 67vh;
    min-height: 400px;
    display: ${(p) => (p.$isHidden ? "none" : "block")};
  }
`;

const DiscussButton = styled(Button)`
  @media screen and (min-width: 1440px) {
    display: none;
  }
`;
