import { Reference } from "@apollo/client";
import { FloatingTree } from "@floating-ui/react";
import { format, addDays, parse, formatISO } from "date-fns/fp";
import {
  useCallback,
  useMemo,
  useRef,
  useState,
  MouseEvent,
  useEffect,
} from "react";
import styled from "styled-components/macro";
import { ActionButton } from "../../../../components/ActionButton";
import {
  AvailabilityKindsDocument,
  AvailabilityKindsQuery,
  AvailabilityRange,
  AvailabilityRangeFragmentFragmentDoc,
  useCreateAvailabilityRangeMutation,
  useDeleteAvailabilityRangeMutation,
} from "../../../../graphql";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import { normalizeHighlightColor } from "../../../../theme";
import { AvailabilityKindType, TalentType } from "../../../../types";
import { getMonthGrid, Month } from "../../../../utils/calendar";
import { Legend } from "./Legend";
import { MonthView } from "./MonthView";
import { DateRange } from "./types";

const referenceDate = new Date(2023, 1, 1, 0, 0, 0, 0);

interface AvailabilityProps {
  talent: {
    id: string;
    availability: AvailabilityRange[];
  };
}

export function Availability({ talent }: AvailabilityProps) {
  const legendRef = useRef<HTMLDivElement | null>(null);
  const [month, setMonth] = useState(() => new Month());
  const [selectionStartDate, setSelectionStartDate] = useState<Date | null>(
    null
  );
  const [hoverDate, setHoverDate] = useState<Date | null>(null);
  const [isLegendHidden, setLegendHidden] = useState(true);

  const month1Grid = useMemo(
    () => getMonthGrid(month, { weekStartsOn: 1 }),
    [month]
  );
  const month2Grid = useMemo(
    () => getMonthGrid(month.add(1), { weekStartsOn: 1 }),
    [month]
  );

  const handlePrevClick = useCallback(
    () => setMonth((month) => month.add(-1)),
    []
  );
  const handleNextClick = useCallback(
    () => setMonth((month) => month.add(1)),
    []
  );

  useOutsideClick(
    legendRef,
    () => {
      setLegendHidden(true);
    },
    !isLegendHidden
  );

  const ranges: DateRange[] = useMemo(
    () =>
      talent.availability
        .map((range) => ({
          ...range,
          startDate: parse(referenceDate, "yyyy-MM-dd", range.startDate),
          endDate: parse(referenceDate, "yyyy-MM-dd", range.endDate),
          kind: {
            ...range.kind,
            color: normalizeHighlightColor(range.kind.color),
          },
        }))
        .sort((a, b) => a.startDate.getTime() - b.startDate.getTime()),
    [talent.availability]
  );

  const selectionRange: [Date, Date] | null = useMemo(() => {
    if (!selectionStartDate || !hoverDate) {
      return null;
    }

    return selectionStartDate < hoverDate
      ? [selectionStartDate, hoverDate]
      : [hoverDate, selectionStartDate];
  }, [selectionStartDate, hoverDate]);

  const availableRange: [Date | null, Date | null] = useMemo(() => {
    if (!selectionStartDate) {
      return [null, null];
    }

    const [minLimit, maxLimit] = ranges.reduce<[Date | null, Date | null]>(
      ([minLimit, maxLimit], { startDate, endDate }) => [
        endDate < selectionStartDate && (!minLimit || endDate > minLimit)
          ? endDate
          : minLimit,
        startDate > selectionStartDate && (!maxLimit || startDate < maxLimit)
          ? startDate
          : maxLimit,
      ],
      [null, null]
    );

    return [
      minLimit && addDays(1, minLimit),
      maxLimit && addDays(-1, maxLimit),
    ];
  }, [ranges, selectionStartDate]);

  const [createAvailabilityRange] = useCreateAvailabilityRangeMutation();
  const [deleteAvailabilityRange] = useDeleteAvailabilityRangeMutation();

  const handleKindSelected = useCallback(
    (kind: AvailabilityKindType) => {
      if (!selectionRange) {
        return;
      }

      createAvailabilityRange({
        variables: {
          input: {
            startDate: format("yyyy-MM-dd", selectionRange[0]),
            endDate: format("yyyy-MM-dd", selectionRange[1]),
            kindName: kind.name,
            kindColor: kind.color,
            talentId: talent.id,
          },
        },
        optimisticResponse({ input }) {
          return {
            createAvailabilityRange: {
              talentId: talent.id,
              range: {
                id: "created",
                startDate: input.startDate,
                endDate: input.endDate,
                createdAt: formatISO(new Date()),
                kind: {
                  name: input.kindName,
                  color: input.kindColor,
                  __typename: "AvailabilityRangeKind",
                },
                __typename: "AvailabilityRange",
              },
              __typename: "CreateAvailabilityRangePayload",
            },
          };
        },
        update(cache, { data }) {
          const range = data?.createAvailabilityRange.range;
          if (!range) {
            return;
          }

          cache.modify({
            id: cache.identify({
              __typename: "Card",
              id: talent.id,
            } as Partial<TalentType>),
            fields: {
              availability(existingMessageRefs = [], { readField }) {
                const newRangeRef = cache.writeFragment({
                  data: range,
                  fragment: AvailabilityRangeFragmentFragmentDoc,
                });

                const alreadyExists = existingMessageRefs.some(
                  (ref: Reference) => readField("id", ref) === range.id
                );

                return alreadyExists
                  ? existingMessageRefs
                  : [...existingMessageRefs, newRangeRef];
              },
            },
          });

          cache.updateQuery<AvailabilityKindsQuery>(
            { query: AvailabilityKindsDocument },
            (kindsData) => {
              const availabilityKinds = kindsData?.availabilityKinds;
              if (!availabilityKinds) {
                return kindsData;
              }
              const isAlreadyExist = availabilityKinds.some(
                (kind) => kind.name === range.kind.name
              );
              if (isAlreadyExist) {
                return kindsData;
              }

              return {
                ...kindsData,
                availabilityKinds: [...availabilityKinds, range.kind],
              };
            }
          );
        },
      });
    },
    [selectionRange, talent.id, createAvailabilityRange]
  );

  const handleRangeDelete = useCallback(
    (rangeId: string) => {
      deleteAvailabilityRange({
        variables: {
          input: { rangeId },
        },
        optimisticResponse: {
          __typename: "Mutation",
          deleteAvailabilityRange: {
            __typename: "DeleteAvailabilityRangePayload",
            range: {
              __typename: "AvailabilityRange",
              id: rangeId,
            },
          },
        },
        update(cache) {
          const normalizedId = cache.identify({
            id: rangeId,
            __typename: "AvailabilityRange",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    },
    [deleteAvailabilityRange]
  );

  const handleSelectionChange = useCallback(
    (date: Date) => {
      if (selectionStartDate) {
        setHoverDate(date);
      }
    },
    [selectionStartDate]
  );

  const handleSelectionStart = useCallback(
    (date: Date) => {
      if (selectionStartDate) {
        return;
      }

      setSelectionStartDate(date);
      setHoverDate(date);
    },
    [selectionStartDate]
  );

  const handleSelectionDone = useCallback(() => {
    setSelectionStartDate(null);
    setHoverDate(null);
  }, []);

  const handleLegendButtonClick = useCallback(() => {
    setLegendHidden((value) => !value);
  }, []);

  const handleLegendContainerClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (event.target === event.currentTarget) {
        setLegendHidden(true);
      }
    },
    []
  );

  useEffect(() => {
    if (ranges.length === 0) {
      setLegendHidden(true);
    }
  }, [ranges.length]);

  return (
    <FloatingTree>
      <Container>
        <MonthView
          ranges={ranges}
          grid={month1Grid}
          selectionRange={selectionRange}
          availableRange={availableRange}
          onSelectionChange={handleSelectionChange}
          onSelectionStart={handleSelectionStart}
          onSelectionDone={handleSelectionDone}
          onKindSelected={handleKindSelected}
          onPrev={month.isTodayMonth() ? undefined : handlePrevClick}
        />
        <MonthView
          ranges={ranges}
          grid={month2Grid}
          selectionRange={selectionRange}
          availableRange={availableRange}
          onSelectionChange={handleSelectionChange}
          onSelectionStart={handleSelectionStart}
          onSelectionDone={handleSelectionDone}
          onKindSelected={handleKindSelected}
          onNext={handleNextClick}
        />
        {ranges.length > 0 && (
          <LegendContainer ref={legendRef} onClick={handleLegendContainerClick}>
            <LegendButton
              icon="list"
              onClick={handleLegendButtonClick}
              text="Legend"
            />
            <LegendContent $hidden={isLegendHidden}>
              <Legend ranges={ranges} onRangeDelete={handleRangeDelete} />
            </LegendContent>
          </LegendContainer>
        )}
      </Container>
    </FloatingTree>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 40px;
  align-items: flex-start;
  margin-top: 24px;
  max-width: 100%;
`;

const LegendContainer = styled.div`
  min-width: 0;
  width: 500px;

  @media screen and (max-width: 1349px) {
    display: flex;
    justify-content: flex-end;
    min-width: auto;
    position: absolute;
    right: 0;
    top: -50px;
  }
`;

const LegendButton = styled(ActionButton)`
  @media screen and (min-width: 1350px) {
    display: none;
  }
`;

const LegendContent = styled.div<{ $hidden: boolean }>`
  @media screen and (max-width: 1349px) {
    display: ${(p) => (p.$hidden ? "none" : "block")};
    position: absolute;
    background-color: ${(p) => p.theme.colors.backgrounds.main};
    right: 0;
    top: 100%;
    margin-top: 8px;
    max-width: 500px;
    border: 1px solid ${(p) => p.theme.colors.borders.separator};
    border-radius: 8px;
    padding: 8px 16px;
  }
`;
