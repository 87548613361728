import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Box, Buttons, ErrorMsg, FormInput, InputRow, Msg } from "./ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "../../components/Button";
import { useChangePasswordMutation } from "../../graphql";
import { useTimeoutFlag } from "../../hooks/useTimeoutFlag";

export interface FormData {
  currentPassword: string;
  password: string;
  confirmation: string;
}

export const schema = yup.object({
  currentPassword: yup.string().required("Fill current password"),
  password: yup.string().required("Fill strong password"),
  confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords do not match")
    .required("Enter your password again"),
});

export function PasswordForm() {
  const [isDone, setDone] = useTimeoutFlag(5000);
  const {
    reset,
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      currentPassword: "",
      password: "",
      confirmation: "",
    },
  });

  const [changePassword] = useChangePasswordMutation();
  const handleFormSubmit = handleSubmit((data) => {
    setDone(false);
    return changePassword({
      variables: {
        input: {
          password: data.currentPassword,
          newPassword: data.password,
          clientMutationId: null,
        },
      },
      onCompleted(data) {
        if (data.changePassword?.result) {
          setDone(true);
          reset();
        } else {
          setError(
            "currentPassword",
            {
              type: "custom",
              message: "Invalid password",
            },
            { shouldFocus: true }
          );
        }
      },
    });
  });

  return (
    <Box>
      <form onSubmit={handleFormSubmit}>
        <InputRow>
          <FormInput
            {...register("currentPassword")}
            type="password"
            placeholder="Current password"
            autoComplete="off"
          />
          {errors.currentPassword && (
            <ErrorMsg>
              {errors.currentPassword.message ?? "Fill current password"}
            </ErrorMsg>
          )}
        </InputRow>
        <InputRow>
          <FormInput
            {...register("password")}
            type="password"
            placeholder="New password"
            autoComplete="off"
          />
          {errors.password && (
            <ErrorMsg>
              {errors.password.message ?? "Fill new password"}
            </ErrorMsg>
          )}
        </InputRow>
        <InputRow>
          <FormInput
            {...register("confirmation")}
            type="password"
            placeholder="Confirm new password"
            autoComplete="off"
          />
          {errors.confirmation && (
            <ErrorMsg>
              {errors.confirmation.message ?? "Enter new password"}
            </ErrorMsg>
          )}
        </InputRow>
        <Buttons>
          <Button $size="m" $kind="contained" disabled={isSubmitting}>
            Save
          </Button>
          {isDone && <Msg>Done!</Msg>}
        </Buttons>
      </form>
    </Box>
  );
}
