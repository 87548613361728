import { useCallback, useRef, useState } from "react";
import styled from "styled-components/macro";
import {
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
} from "@floating-ui/react";
import { Link } from "react-router-dom";
import { UserAvatar } from "../Avatar";
import { useViewer } from "../context/auth_gate";
import { typographyCSS } from "../../theme";

export function Account() {
  const viewer = useViewer();
  const [opened, setOpened] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { x, y, reference, floating, strategy, context } = useFloating({
    open: opened,
    onOpenChange: setOpened,
    placement: "bottom-end",
    middleware: [offset(4)],
  });

  let listIndex = 0;
  const listRef = useRef([] as (HTMLElement | null)[]);
  const listItemRef = function listItemRef<T extends HTMLElement>(
    node: T | null
  ) {
    listRef.current[listIndex++] = node;
  };
  const closeMenu = useCallback(() => {
    setOpened(false);
  }, []);

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context),
      useDismiss(context),
      useListNavigation(context, {
        listRef,
        activeIndex,
        onNavigate: setActiveIndex,
        loop: true,
      }),
    ]
  );

  return (
    <Container>
      <AvatarButton {...getReferenceProps({ ref: reference })}>
        <UserAvatar size="xs" user={viewer} />
      </AvatarButton>
      {opened && (
        <Menu
          ref={floating}
          style={{
            position: strategy,
            transform: `translate(${x ?? 0}px, ${y ?? 0}px)`,
          }}
          {...getFloatingProps()}
        >
          <MenuHeader>
            <Name>{viewer.name}</Name>
            <Email>{viewer.email}</Email>
          </MenuHeader>
          <MenuSeparator />
          <MenuListItem>
            <MenuElement
              as={Link}
              to="/profile"
              {...getItemProps({
                ref: listItemRef,
                onClick: closeMenu,
              })}
            >
              View profile
            </MenuElement>
          </MenuListItem>
          <MenuListItem>
            <MenuElement
              as={Link}
              to="/auth/signout"
              reloadDocument
              {...getItemProps({
                ref: listItemRef,
                onClick: closeMenu,
              })}
            >
              Sign out
            </MenuElement>
          </MenuListItem>
          <MenuSeparator />
          <MenuListItem>
            <MenuElement
              as="a"
              href="https://roadmap.baselance.co/"
              target="_blank"
              {...getItemProps({
                ref: listItemRef,
                onClick: closeMenu,
              })}
            >
              Roadmap
            </MenuElement>
          </MenuListItem>
          <MenuListItem>
            <MenuElement
              as="a"
              href="https://chrome.google.com/webstore/detail/baselanceco/mojgobodabcbfajonllbabhacbpfplkb"
              target="_blank"
              {...getItemProps({
                ref: listItemRef,
                onClick: closeMenu,
              })}
            >
              Chrome extension
            </MenuElement>
          </MenuListItem>
          <MenuSeparator />
          <MenuListItem>
            <MenuElement
              as="a"
              href="https://www.baselance.co/privacy"
              target="_blank"
              {...getItemProps({
                ref: listItemRef,
                onClick: closeMenu,
              })}
            >
              Privacy Policy
            </MenuElement>
          </MenuListItem>
          <MenuListItem>
            <MenuElement
              as="a"
              href="https://www.baselance.co/terms"
              target="_blank"
              {...getItemProps({
                ref: listItemRef,
                onClick: closeMenu,
              })}
            >
              Terms of Service
            </MenuElement>
          </MenuListItem>
        </Menu>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const AvatarButton = styled.button`
  padding: 0;
  border: 0 none;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;

  &:hover {
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${(p) => p.theme.colors.service.hover};
    }
  }

  &:focus-visible {
    outline: 3px solid ${(p) => p.theme.colors.service.focus};
    outline-offset: 0;
  }
`;

const Menu = styled.ul`
  margin: 0;
  padding: 8px 0;
  min-width: 200px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;
  top: 0;
  left: 0;
  z-index: ${(p) => p.theme.layers.menu};

  &:focus {
    outline: 0 none;
  }
`;

const MenuListItem = styled.li`
  list-style: none;
  padding: 0;
`;

const MenuHeader = styled.li`
  list-style: none;
  padding: 0 16px;
  margin: 0;
`;

const Name = styled.div`
  ${(p) => typographyCSS(p.theme.typo.h3)};
`;

const Email = styled.div`
  color: ${(p) => p.theme.colors.text.grey};
  ${(p) => typographyCSS(p.theme.typo.body2)};
`;

const MenuElement = styled.button`
  border: 0 none;
  background: transparent;
  padding: 0 16px;
  height: 40px;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    background-color: ${(p) => p.theme.colors.service.hover};
  }

  &:focus {
    outline: 0 none;
  }

  &:active,
  &:focus-visible {
    background-color: ${(p) => p.theme.colors.service.selected};
  }
`;

const MenuSeparator = styled.li`
  list-style: none;
  border-bottom: 1px solid ${(p) => p.theme.colors.borders.separator};
  height: 0;
  margin: 8px 0;
  padding: 0;
`;
