import { CategoryData } from "../../types";

const FILTER_SORT_IDX_MATCH = 1;
const FILTER_SORT_IDX_BEGIN_LINE = 2;
const FILTER_SORT_IDX_BEGIN_WORD = 3;
const FILTER_SORT_IDX_REST = 4;

export function filterCategories(
  categories: CategoryData[],
  filterInput: string
): CategoryData[] {
  if (filterInput.length === 0) {
    return categories;
  }

  const result = [] as {
    category: CategoryData;
    sortIdx: number;
  }[];

  categories.forEach((category) => {
    const occurPosition = category.name.toLowerCase().indexOf(filterInput);
    if (occurPosition < 0) {
      return;
    }

    let sortIdx = FILTER_SORT_IDX_REST;

    if (filterInput === category.name) {
      sortIdx = FILTER_SORT_IDX_MATCH;
    } else if (occurPosition === 0) {
      sortIdx = FILTER_SORT_IDX_BEGIN_LINE;
    } else if (new RegExp(`\\b${escapeRegExp(filterInput)}`).test(category.name)) {
      sortIdx = FILTER_SORT_IDX_BEGIN_WORD;
    }

    result.push({ category, sortIdx });
  });

  result.sort((a, b) => a.sortIdx - b.sortIdx);

  return result.map((res) => res.category);
}

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
