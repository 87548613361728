import {
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  UseFloatingReturn,
  useFloatingTree,
  useInteractions,
} from "@floating-ui/react";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components/macro";
import { useTeamQuery } from "../../graphql";
import { typographyCSS } from "../../theme";
import { MemberType } from "../../types";
import { stickyTop } from "../../utils/stickyTop";
import { filterMembers } from "./filterMembers";
import { SelectedMember } from "./SelectedMember";
import { UserAvatar } from "../Avatar";

const MAX_TEAM_SIZE = 30;

interface UseTeamPickerOptions {
  selectedMembers?: MemberType[];
  onDone: (categories: MemberType[]) => void;
}

const emptyArray = Array<any>();

export function useTeamPicker({
  selectedMembers,
  onDone,
}: UseTeamPickerOptions) {
  const [opened, setOpened] = useState(false);
  const [pickedMembers, setPickedMembers] = useState<MemberType[]>([]);
  const topMemoRef = useRef<number | null>(null);
  const tree = useFloatingTree();

  if (!tree) {
    throw new Error("<FloatingTree /> is missing for TeamPopover");
  }

  const teamQuery = useTeamQuery();

  const close = useCallback(() => {
    topMemoRef.current = null;
    onDone(pickedMembers);
    setPickedMembers([]);
    setOpened(false);
  }, [pickedMembers, onDone]);

  const handleOpenChange = useCallback(
    (willBeOpened: boolean) => {
      setOpened(willBeOpened);
      if (willBeOpened) {
        setOpened(willBeOpened);
      } else {
        close();
      }
    },
    [close]
  );
  const nodeId = useFloatingNodeId();

  const floatingReturn = useFloating({
    nodeId,
    open: opened,
    onOpenChange: handleOpenChange,
    placement: "bottom",
    strategy: "fixed",
    middleware: [
      offset(4),
      shift({ crossAxis: true, padding: 5 }),
      stickyTop({ topMemoRef }),
    ],
  });

  const interactionsReturn = useInteractions([
    useClick(floatingReturn.context),
    useDismiss(floatingReturn.context, { bubbles: false }),
  ]);

  const teamPickerElement = (
    <FloatingPortal>
      {opened ? (
        <FloatingNode id={nodeId}>
          <Overlay lockScroll>
            <TeamPicker
              floatingReturn={floatingReturn}
              interactionsReturn={interactionsReturn}
              selectedMembers={selectedMembers ?? emptyArray}
              pickedMembers={pickedMembers}
              setPickedMembers={setPickedMembers}
              team={teamQuery.data?.team ?? emptyArray}
              closePicker={close}
            />
          </Overlay>
        </FloatingNode>
      ) : null}
    </FloatingPortal>
  );
  const getReferenceProps = useCallback(
    (props?: React.HTMLProps<Element>) =>
      interactionsReturn.getReferenceProps.call(null, {
        ...props,
        ref: floatingReturn.reference,
      }),
    [interactionsReturn.getReferenceProps, floatingReturn.reference]
  );

  return [getReferenceProps, teamPickerElement] as [
    typeof getReferenceProps,
    typeof teamPickerElement
  ];
}

type VirtualFocusType = { kind: "NONE" } | { kind: "ITEM"; index: number };

const VirtualFocus = {
  NoFocus: { kind: "NONE" } as VirtualFocusType,
  Item(index: number): VirtualFocusType {
    return { kind: "ITEM", index };
  },
};

interface TeamPickerProps {
  floatingReturn: UseFloatingReturn;
  interactionsReturn: ReturnType<typeof useInteractions>;
  team: MemberType[];
  selectedMembers: MemberType[];
  pickedMembers: MemberType[];
  setPickedMembers: Dispatch<SetStateAction<MemberType[]>>;
  closePicker: () => void;
}

function TeamPicker({
  floatingReturn,
  interactionsReturn,
  team,
  selectedMembers,
  pickedMembers,
  setPickedMembers,
  closePicker,
}: TeamPickerProps) {
  const [virtualFocus, setVirtualFocus] = useState(VirtualFocus.NoFocus);
  const [filterInput, setFilterInput] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const teamListRef = useRef([] as (HTMLElement | null)[]);
  const { x, y, floating, strategy, update } = floatingReturn;
  const { getFloatingProps } = interactionsReturn;

  const lowercaseFilter = useMemo(
    () => filterInput.toLowerCase(),
    [filterInput]
  );
  const members = useMemo(() => {
    const filteredMembers = team.filter(
      (memeber) =>
        pickedMembers.every((pickedMember) => pickedMember.id !== memeber.id) &&
        selectedMembers.every(
          (selectedMember) => selectedMember.id !== memeber.id
        )
    );
    return filterMembers(filteredMembers, lowercaseFilter).slice(
      0,
      MAX_TEAM_SIZE
    );
  }, [lowercaseFilter, pickedMembers, selectedMembers, team]);
  const matchedSelectedMember =
    selectedMembers.find(
      (memeber) => memeber.name.toLowerCase() === lowercaseFilter
    ) ||
    pickedMembers.find(
      (memeber) => memeber.name.toLowerCase() === lowercaseFilter
    );

  const focusInput = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const setVirtualFocusAndScroll = useCallback(
    (virtualFocus: VirtualFocusType) => {
      setVirtualFocus(virtualFocus);
      const element =
        virtualFocus.kind === "ITEM"
          ? teamListRef.current[virtualFocus.index]
          : null;
      if (element) {
        element.scrollIntoView({
          block: "center",
          inline: "nearest",
        });
      }
    },
    []
  );

  const reset = useCallback(() => {
    setFilterInput("");
    setVirtualFocus(VirtualFocus.NoFocus);
    focusInput();
  }, [focusInput]);

  const unselectMember = useCallback(
    (unselectedMember: MemberType) => {
      setPickedMembers((members) =>
        members.filter((member) => member.id !== unselectedMember.id)
      );
      focusInput();
    },
    [setPickedMembers, focusInput]
  );

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFilterInput(e.target.value);
  }, []);

  useLayoutEffect(() => {
    if (filterInput.length === 0 || members.length === 0) {
      setVirtualFocusAndScroll(VirtualFocus.NoFocus);
    } else {
      setVirtualFocusAndScroll(VirtualFocus.Item(0));
    }
  }, [filterInput, members.length, setVirtualFocusAndScroll]);

  useLayoutEffect(() => {
    update();
  }, [pickedMembers, members, update]);
  const hasAlreadyUsed = matchedSelectedMember && members.length === 0;
  const hasEmptyList = members.length === 0 && !matchedSelectedMember;
  const inputPlaceholder = "Enter a name to search...";

  return (
    <>
      <Container
        ref={floating}
        style={{
          position: strategy,
          transform: `translate(${x ?? 0}px, ${y ?? 0}px)`,
        }}
        {...getFloatingProps({
          onFocus(event) {
            if (event.target !== inputRef.current) {
              focusInput();
            }
          },
          onKeyDown(event) {
            switch (event.key) {
              case "Enter":
                if (virtualFocus.kind === "NONE") {
                  closePicker();
                } else if (members[virtualFocus.index]) {
                  setPickedMembers((pickedMembers) => [
                    ...pickedMembers,
                    members[virtualFocus.index],
                  ]);
                  reset();
                }
                break;
              case "ArrowDown":
                event.preventDefault();
                if (virtualFocus.kind !== "ITEM") {
                  if (members.length > 0) {
                    setVirtualFocusAndScroll(VirtualFocus.Item(0));
                  }
                  return;
                } else if (virtualFocus.index === members.length - 1) {
                  setVirtualFocusAndScroll(VirtualFocus.Item(0));
                } else {
                  setVirtualFocusAndScroll(
                    VirtualFocus.Item(virtualFocus.index + 1)
                  );
                }
                break;
              case "ArrowUp":
                event.preventDefault();
                if (virtualFocus.kind === "NONE" || virtualFocus.index === 0) {
                  setVirtualFocusAndScroll(
                    VirtualFocus.Item(members.length - 1)
                  );
                } else {
                  setVirtualFocusAndScroll(
                    VirtualFocus.Item(virtualFocus.index - 1)
                  );
                }
                break;
              case "Backspace":
                if (filterInput.length === 0 && pickedMembers.length > 0) {
                  setPickedMembers((pickedMembers) =>
                    pickedMembers.slice(0, -1)
                  );
                }
                break;
            }
          },
        })}
      >
        <InputZone onClick={focusInput}>
          {pickedMembers.map((member) => (
            <SelectedMember
              key={member.id}
              member={member}
              onClick={unselectMember}
            />
          ))}
          <Input
            ref={inputRef}
            value={filterInput}
            onChange={handleInputChange}
            placeholder={
              pickedMembers.length === 0 ? inputPlaceholder : undefined
            }
            autoFocus
          />
        </InputZone>
        <MainZone>
          {(members.length > 0 || hasAlreadyUsed || hasEmptyList) && (
            <MemberList>
              {members.map((member, index) => (
                <MemberListItem key={member.id}>
                  <MemberListElement
                    $active={
                      virtualFocus.kind === "ITEM" &&
                      virtualFocus.index === index
                    }
                    ref={(node) => {
                      teamListRef.current[index] = node;
                    }}
                    onMouseMove={() =>
                      setVirtualFocus(VirtualFocus.Item(index))
                    }
                    onClick={() => {
                      setPickedMembers((pickedMembers) => [
                        ...pickedMembers,
                        member,
                      ]);
                      reset();
                    }}
                  >
                    <UserAvatar size="xxxs" user={member} />
                    <MemberName>{member.name}</MemberName>
                  </MemberListElement>
                </MemberListItem>
              ))}
              {hasAlreadyUsed && (
                <MemberListItem>
                  <InactiveMemberListElement>
                    <UserAvatar size="xxxs" user={matchedSelectedMember} />
                    <MemberName>{matchedSelectedMember.name}</MemberName>
                    <AlreadySelectedMemberHint>
                      already selected
                    </AlreadySelectedMemberHint>
                  </InactiveMemberListElement>
                </MemberListItem>
              )}
              {hasEmptyList && (
                <MemberListItem>
                  <InactiveMemberListElement>
                    <EmptyListHint>
                      {filterInput.length > 0
                        ? "No match found"
                        : "No members to display"}
                    </EmptyListHint>
                  </InactiveMemberListElement>
                </MemberListItem>
              )}
            </MemberList>
          )}
        </MainZone>
      </Container>
    </>
  );
}

const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 400px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: ${(p) => p.theme.layers.menu};
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25),
    0px 4px 10px rgba(144, 143, 147, 0.25);
  border-radius: 8px;
  overflow: hidden;

  &:focus {
    outline: 0 none;
  }
`;

const InputZone = styled.div`
  cursor: text;
  position: relative;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;
  padding: 8px;
  min-height: 28px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const Input = styled.input`
  flex: 1 1 60px;
  min-width: 60px;
  padding: 0 4px;
  border: 0 none;
`;

const MainZone = styled.div`
  margin-top: -16px;
  padding-top: 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-top-width: 0;
  border-radius: 8px;
`;

const MemberList = styled.ul`
  margin: 0;
  padding: 8px;
  max-height: 200px;
  overflow: auto;
  border-bottom: 1px solid ${(p) => p.theme.colors.borders.separator};
`;

const MemberListItem = styled.li`
  position: relative;
  list-style: none;
  padding: 0px;
`;

const MemberListElement = styled.button<{ $active: boolean }>`
  background: ${(p) =>
    p.$active ? p.theme.colors.service.selected : "transparent"};
  border: 0 none;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  gap: 8px;

  &:focus {
    outline: 0 none;
  }
`;

const MemberName = styled.div`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  cursor: inherit;
`;

const AlreadySelectedMemberHint = styled.span`
  color: ${(p) => p.theme.colors.text.grey};
  ${(p) => typographyCSS(p.theme.typo.body2)};
`;

const InactiveMemberListElement = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 8px 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: default;

  ${MemberName} {
    flex: 0 0 auto;
    align-self: baseline;
  }

  ${AlreadySelectedMemberHint} {
    align-self: baseline;
  }
`;

const EmptyListHint = styled.span`
  color: ${(p) => p.theme.colors.text.grey};
`;

const Overlay = styled(FloatingOverlay)`
  z-index: ${(p) => p.theme.layers.overlay};
`;
