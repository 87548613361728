import { useCallback, useState } from "react";
import styled from "styled-components/macro";
import { isGranted } from "../../../../acl";
import { ActionButton } from "../../../../components/ActionButton";
import { useViewer } from "../../../../components/context/auth_gate";
import { TalentType } from "../../../../types";
import { Hint } from "../ui";
import { ReviewForm } from "./ReviewForm";
import { ReviewItem } from "./ReviewItem";

interface ReviewsSectionProps {
  talent: TalentType;
}

export function ReviewsSection({ talent }: ReviewsSectionProps) {
  const viewer = useViewer();
  const [isAddActive, setAddActive] = useState(false);
  const canAdd = isGranted(viewer, "review.add");
  const hasReviews = talent.reviews && talent.reviews.length > 0;

  const handleAddClick = useCallback(() => {
    setAddActive(true);
  }, []);

  const handleAddDone = useCallback(() => {
    setAddActive(false);
  }, []);

  return (
    <Container>
      {canAdd &&
        (isAddActive ? (
          <ReviewForm talentId={talent.id} onDone={handleAddDone} />
        ) : (
          <ActionButton
            icon="plus"
            text={hasReviews ? "Leave review" : "Leave first review"}
            onClick={handleAddClick}
          />
        ))}
      {hasReviews ? (
        <Reviews>
          {talent.reviews?.map((review) => (
            <ReviewItem key={review.id} review={review} />
          ))}
        </Reviews>
      ) : (
        <Hint>Evaluate your talents for more efficient work</Hint>
      )}
    </Container>
  );
}

const Container = styled.div`
  max-width: 440px;
`;

const Reviews = styled.div`
  margin-top: 32px;
`;
