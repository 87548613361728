import { useCallback } from "react";
import styled from "styled-components/macro";

import { ContactIcon as BaseContactIcon } from "../../../../components/ContactIcon/ContactIcon";
import { UIIcon } from "../../../../components/UIIcon/UIIcon";
import { useTimeoutFlag } from "../../../../hooks/useTimeoutFlag";
import { useUnmounted } from "../../../../hooks/useUnmounted";
import { typographyCSS } from "../../../../theme";
import { copyToClipboard } from "../../../../utils/copyToClipboard";
import { Contact } from "../../../../utils/prepareContacts";

interface ContactItemProps {
  contact: Contact;
}

export function ContactItem({ contact }: ContactItemProps) {
  const [copiedHint, setCopiedHint] = useTimeoutFlag(3000);
  const getUnmounted = useUnmounted();

  const handleCopyClick = useCallback(() => {
    copyToClipboard(contact.title).then((succeed) => {
      if (succeed && !getUnmounted()) {
        setCopiedHint(true);
      }
    });
  }, [contact.title, getUnmounted, setCopiedHint]);

  return (
    <Container key={contact.url}>
      {contact.url ? (
        <ContactLink href={contact.url} target="_blank" title={contact.title}>
          <ContactIcon name={contact.icon} />
          <ContactTitle>{contact.title}</ContactTitle>
        </ContactLink>
      ) : (
        <ContactLink as="div" title={contact.title}>
          <ContactIcon name={contact.icon} />
          <ContactTitle>{contact.title}</ContactTitle>
        </ContactLink>
      )}
      <ContactCopyButton title="Copy to clipboard" onClick={handleCopyClick}>
        <UIIcon name="copy" />
        {copiedHint && <CopiedHint>Copied!</CopiedHint>}
      </ContactCopyButton>
    </Container>
  );
}

const ContactCopyButton = styled.button`
  background: none transparent;
  border: 0 none;
  cursor: pointer;
  flex: 0 0 auto;
  margin: -4px 0 -4px 4px;
  position: relative;
  padding: 4px;
  border-radius: 50%;

  svg {
    transition: opacity 0.1s ease-in-out;
    opacity: 0;
  }

  &:hover {
    background-color: ${(p) => p.theme.colors.backgrounds.grey};
  }
`;

const Container = styled.li`
  list-style: none;
  margin: 0 0 8px;
  display: flex;
  align-items: center;

  &:hover ${ContactCopyButton} svg {
    opacity: 1;
  }
`;

const ContactLink = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  min-width: 0;

  a&:hover {
    text-decoration: underline;
  }
`;

const ContactIcon = styled(BaseContactIcon)`
  flex: 0 0 auto;
  color: ${(p) => p.theme.colors.ui.greyFilled};
`;

const ContactTitle = styled.div`
  flex: 0 1 auto;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CopiedHint = styled.div`
  position: absolute;
  background-color: ${(p) => p.theme.colors.backgrounds.inverse};
  color: ${(p) => p.theme.colors.text.white};
  ${(p) => typographyCSS(p.theme.typo.body2)};
  padding: 6px 8px;
  border-radius: 8px;
  white-space: nowrap;
  margin-bottom: 4px;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);
`;
