import { useCallback, useRef, useState } from "react";
import styled from "styled-components/macro";
import { Input } from "../../components/Input";
import { CategoryListItemType } from "../../types";

interface NameInputProps {
  autoFocus: boolean;
  category: CategoryListItemType;
  updateCategory: (category: CategoryListItemType) => void;
}

export function NameInput({
  autoFocus,
  category,
  updateCategory,
}: NameInputProps) {
  const skipSaveOnBlurRef = useRef(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState(category.name);

  const save = useCallback(() => {
    if (name === category.name) {
      return;
    }

    if (name.length === 0) {
      setName(category.name);
      return;
    }
    updateCategory({ ...category, name });
  }, [name, category, updateCategory]);

  const handleInputFocus = useCallback(() => {
    skipSaveOnBlurRef.current = false;
  }, []);

  const handleInputBlur = useCallback(() => {
    const skipSaveOnBlur = skipSaveOnBlurRef.current;
    skipSaveOnBlurRef.current = false;

    if (!skipSaveOnBlur) {
      save();
    }
  }, [save]);

  const handleNameKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case "Enter":
          save();
          skipSaveOnBlurRef.current = true;
          event.currentTarget.blur();
          break;

        case "Escape":
          setName(category.name);
          skipSaveOnBlurRef.current = true;
          event.currentTarget.blur();
          break;
      }
    },
    [category.name, save]
  );
  return (
    <StyledInput
      ref={inputRef}
      autoFocus={autoFocus}
      value={name}
      kind="empty"
      onValueChange={setName}
      onKeyDown={handleNameKeyDown}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
    />
  );
}

const StyledInput = styled(Input)`
  flex: 1;

  input {
    text-overflow: ellipsis;
  }
`;
