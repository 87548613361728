import React from "react";
import styled from "styled-components/macro";
import { WorkType } from "../../../../types";
import { track } from "../../../../utils/track";
import { ReactComponent as ImagePlaceholderSvg } from "../assets/image.svg";
import { NoPreview } from "./ui";

const normalizeInRange = (value: number, minValue: number, maxValue: number) =>
  Math.max(Math.min(value, maxValue), minValue);

interface ThumbnailsProps {
  activeIndex: number;
  works: WorkType[];
  onChange: (index: number) => void;
}

class Thumbnails extends React.Component<ThumbnailsProps> {
  componentDidMount() {
    this.updateThumbnailsPosition();
  }

  componentDidUpdate(prevProps: ThumbnailsProps) {
    const { activeIndex } = this.props;

    if (activeIndex !== prevProps.activeIndex) {
      this.updateThumbnailsPosition();
    }
  }

  containerRef = React.createRef<HTMLDivElement>();

  currentThumbRef = React.createRef<HTMLButtonElement>();

  lastOffset = 0;

  thumbnailClickHandlers = this.props.works.map((_, index) => () => {
    track("Click on thumbnail in Gallery");
    this.props.onChange(index);
  });

  updateThumbnailsPosition() {
    const container = this.containerRef.current;
    const currentThumb = this.currentThumbRef.current;

    if (!container || !currentThumb) {
      return;
    }

    const containerBBox = container.getBoundingClientRect();
    const thumbBBox = currentThumb.getBoundingClientRect();

    const innerOffset = (containerBBox.width - thumbBBox.width) / 2;
    const requiredOffset = this.lastOffset + innerOffset - thumbBBox.x;
    const maxOffset = container.scrollWidth - containerBBox.width;
    const finalOffset = normalizeInRange(requiredOffset, -maxOffset, 0);

    this.lastOffset = finalOffset;
    container.style.transform = `translate3d(${finalOffset}px, 0, 0)`;
  }

  render() {
    const { works, activeIndex } = this.props;

    return (
      <Container>
        <InnerContainer ref={this.containerRef}>
          {works.map((work, index) => (
            <Thumbnail
              tabIndex={-1}
              key={work.id}
              ref={index === activeIndex ? this.currentThumbRef : undefined}
              $highlighted={index === activeIndex}
              onClick={this.thumbnailClickHandlers[index]}
            >
              {work.image ? (
                <ThumbImg
                  src={work.image.preview200.src}
                  srcSet={work.image.preview200.srcSet}
                  alt="Work image"
                />
              ) : (
                <NoPreview>
                  <ImagePlaceholderSvg />
                  <div>No preview</div>
                </NoPreview>
              )}
            </Thumbnail>
          ))}
        </InnerContainer>
      </Container>
    );
  }
}

export default Thumbnails;

const Container = styled.div`
  flex: 0 0 129px;
  margin-top: 4px;
  padding: 0 1px;
`;

const InnerContainer = styled.div`
  display: flex;
  transition: transform 0.33s ease-out;
`;

const Thumbnail = styled.button<{ $highlighted: boolean }>`
  background: none transparent;
  border: 0 none;

  display: block;
  height: 128px;
  border: 3px solid;
  border-color: ${(p) =>
    p.$highlighted ? p.theme.colors.ui.purple : "transparent"};
  padding: 1px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`;

const ThumbImg = styled.img`
  display: block;
  height: 120px;
`;
