import { useCallback } from "react";
import { ControllerFieldState } from "react-hook-form";
import { ActionButton } from "../../../components/ActionButton";
import { SelectedSkill } from "../../../components/skills/Skill";
import { useSkillsPicker } from "../../../components/skills/SkillsPicker/SkillsPicker";
import { ChipList, ErrorMsg, NoBreak } from "../../../components/FormUI";

interface SkillsControlProps {
  fieldState: ControllerFieldState;
  skills: string[];
  onChange: (skills: string[]) => void;
}

export function SkillsControl({
  skills,
  fieldState,
  onChange,
}: SkillsControlProps) {
  const handleSkillsSelectionDone = useCallback(
    (newSkills: string[]) => {
      if (newSkills.length > 0) {
        onChange([...skills, ...newSkills]);
      }
    },
    [skills, onChange]
  );

  const [getReferenceProps, skillsPopoverElement] = useSkillsPicker({
    selectedSkills: skills,
    onDone: handleSkillsSelectionDone,
    readonly: true,
  });

  const handleSkillRemove = useCallback(
    (removedSkill: string) => {
      onChange(skills.filter((skills) => skills !== removedSkill));
    },
    [skills, onChange]
  );

  const lastIndex = skills.length - 1;

  return (
    <>
      {skills.length > 0 ? (
        <ChipList>
          {skills.map((skill, index) =>
            index === lastIndex ? (
              <NoBreak key={skill}>
                <SelectedSkill name={skill} onRemove={handleSkillRemove} />
                <ActionButton
                  type="button"
                  icon="plus"
                  {...getReferenceProps()}
                />
                {skillsPopoverElement}
              </NoBreak>
            ) : (
              <SelectedSkill
                key={skill}
                name={skill}
                onRemove={handleSkillRemove}
              />
            )
          )}
        </ChipList>
      ) : (
        <>
          <ActionButton
            type="button"
            icon="plus"
            text="Add required tags"
            {...getReferenceProps()}
          />
          {skillsPopoverElement}
        </>
      )}
      {fieldState.error && <ErrorMsg>{fieldState.error.message}</ErrorMsg>}
    </>
  );
}
