import { useEffect, useRef, useState } from "react";
import { useUnmounted } from "./useUnmounted";

export function useTimeoutFlag(time: number) {
  const timeRef = useRef(time);
  const [flag, setFlag] = useState(false);
  const isUnmounted = useUnmounted();

  useEffect(() => {
    if (flag) {
      const id = setTimeout(() => {
        if (isUnmounted()) return;
        setFlag(false);
      }, timeRef.current);

      return () => {
        clearTimeout(id);
      };
    }
  }, [flag, isUnmounted]);

  return [flag, setFlag] as const;
}
