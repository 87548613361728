import { useLayoutEffect, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import { subscribe } from "subscribe-ui-event";

import { SelectedSkill, SkillSwitch } from "../../../../components/skills/Skill";
import { useDelayedMouseEnter } from "../../../../hooks/useDelayedMouseEnter";
import { SkillAggregation } from "../../../../types";

interface SkillsPopupProps {
  selectedTags: string[];
  skills: SkillAggregation[];
  untaggedCount: number;
  wantsUntagged: boolean;
  onTagToggle: (tag: string) => void;
  onUntaggedToggle: () => void;
}

export function TagsFilter({
  selectedTags,
  skills,
  untaggedCount,
  wantsUntagged,
  onTagToggle,
  onUntaggedToggle,
}: SkillsPopupProps) {
  const [overflowed, setOverflowed] = useState(false);
  const [expanded, mouseHandlers] = useDelayedMouseEnter();
  const popoverRef = useRef<HTMLDivElement>(null);
  let restSkills = skills.filter(
    (skillAgg) => !selectedTags.includes(skillAgg.skill.name)
  );
  const selectedSkills = selectedTags.map((tag) => {
    const skillAgg = skills.find((skillAgg) => skillAgg.skill.name === tag);
    return skillAgg ? skillAgg.skill : { name: tag, color: "black" };
  });

  useLayoutEffect(() => {
    setOverflowed(isOverflowed(popoverRef.current));
  }, [selectedTags, skills]);

  useLayoutEffect(() => {
    const subscription = subscribe(
      "resize",
      () => {
        setOverflowed(isOverflowed(popoverRef.current));
      },
      { useRAF: true }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);
  const untaggedIsAvaialble = selectedTags.length === 0 && (untaggedCount > 0 || wantsUntagged);
  const hideTags = untaggedIsAvaialble && wantsUntagged;

  return (
    <Container $expandable={overflowed} {...mouseHandlers}>
      <Popover ref={popoverRef} className={expanded ? "__expanded" : undefined}>
        {untaggedIsAvaialble && (
          wantsUntagged ? (
            <SelectedUntagged
              as={SelectedSkill}
              name="no tag"
              onRemove={onUntaggedToggle}
            />) : (
            <Untagged
              as={SkillSwitch}
              name="no tag"
              onClick={onUntaggedToggle}
            />
          ))}
        {selectedSkills.map((skill) => (
          <InlineSkill
            as={SelectedSkill}
            key={skill.name}
            name={skill.name}
            onRemove={onTagToggle}
          />
        ))}
        {!hideTags && restSkills.map((skillAgg) => (
          <InlineSkill
            as={SkillSwitch}
            key={skillAgg.skill.name}
            name={skillAgg.skill.name}
            onClick={onTagToggle}
          />
        ))}
      </Popover>
    </Container>
  );
}

const Popover = styled.div`
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 9px;
`;

const Container = styled.div<{ $expandable: boolean }>`
  flex: 1;
  max-height: 28px;
  height: 28px;
  min-width: 0;
  margin-left: -9px;
  color: ${(p) => p.theme.colors.text.grey};

  ${(p) =>
    p.$expandable
      ? css`
          &:hover ${Popover}.__expanded, &:focus-within ${Popover} {
            height: auto;
            white-space: normal;
            padding: 8px 8px 4px;
            transform: translateY(-9px);
            background: ${p.theme.colors.backgrounds.main};
            border: 1px solid ${p.theme.colors.borders.separator};

            box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25),
              0px 4px 10px rgba(144, 143, 147, 0.25);
            border-radius: 8px;
          }
        `
      : ""}
`;

const InlineSkill = styled.div`
  display: inline-flex;
  margin-right: 4px;
  margin-bottom: 4px;
`;

const Untagged = styled(InlineSkill)`
  color: ${p => p.theme.colors.highlights.red.fg};

  &:hover {
    color: ${p => p.theme.colors.highlights.red.fg};
    background-color: ${p => p.theme.colors.highlights.red.bg};
  }
`;

const SelectedUntagged = styled(Untagged)`
    background-color: ${p => p.theme.colors.highlights.red.bg};
`;

function isOverflowed<T extends HTMLElement>(element: T | null) {
  if (!element) return false;

  const childrenCount = element.children.length;
  if (childrenCount <= 1) return false;

  const firstChild = element.children[0];
  const lastChild = element.children[childrenCount - 1];

  const firstRect = firstChild.getBoundingClientRect();
  const lastRect = lastChild.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return elementRect.right < lastRect.right || firstRect.top !== lastRect.top;
}
