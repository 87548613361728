import { Location, useLocation } from "react-router-dom";

export type GeneralLocationState = {
  backgroundLocation?: Location;
  email?: string;
} | null | undefined;

export function useGeneralLocationState() {
  let location = useLocation();

  return [location.state as GeneralLocationState, location] as [
    GeneralLocationState,
    Location
  ];
}
