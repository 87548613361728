import { ApolloError } from "@apollo/client";
import { useLayoutEffect, useMemo, useState } from "react";
import styled, { keyframes } from "styled-components/macro";
import { Button } from "../../../components/Button";
import {
  ContactIcon,
  ContactIconKind,
} from "../../../components/ContactIcon/ContactIcon";
import { Input } from "../../../components/Input";
import {
  ProfileUrlExtractionQuery,
  useProfileUrlExtractionQuery,
} from "../../../graphql";
import { typographyCSS } from "../../../theme";
import { ProfileUrlExtraction } from "../../../types";
import { MaybeURL, tryURL } from "../../../utils/tryURL";

const supportedServices: ContactIconKind[] = [
  "behance",
  "artstation",
  "vimeo",
  "linkedin",
  "github",
  "instagram",
];

interface ParserStepProps {
  onParsed: (profile: ProfileUrlExtraction) => void;
  onManually: () => void;
}

export function ParserStep({ onParsed, onManually }: ParserStepProps) {
  const [input, setInput] = useState("");

  const url = useMemo(() => {
    if (input.length === 0) {
      return null;
    } else {
      return tryURL(input);
    }
  }, [input]);

  const { data, loading, error } = useProfileUrlExtractionQuery({
    skip: !url || !url.isURL,
    variables: {
      url: input,
    },
    fetchPolicy: "network-only",
  });

  const errorMsg = url && !loading ? getErroMsg(url, data, error) : null;

  useLayoutEffect(() => {
    if (url && !loading && data?.profileUrlExtraction?.profile && !errorMsg) {
      onParsed(data.profileUrlExtraction);
    }
  }, [url, data, loading, errorMsg, onParsed]);

  return (
    <Container>
      <Title>Paste talent profile URL</Title>
      <Hint>
        Just paste talent profile URL and we will pull all information
        automatically &#128156;
      </Hint>
      <URLInput
        value={input}
        onValueChange={setInput}
        placeholder="https://behance.net/baselance"
        autoFocus
      />
      {errorMsg && <Error>{errorMsg}</Error>}
      <SupportedServices>
        {supportedServices.map((serviceName) => (
          <Service key={serviceName}>
            <ContactIcon name={serviceName} />
          </Service>
        ))}
      </SupportedServices>
      {loading ? (
        <Loading>
          <Spinner />
          Extracting profile data...
        </Loading>
      ) : (
        <>
          <Separator>
            <SeparatorLine />
            <SeparatorText>or</SeparatorText>
            <SeparatorLine />
          </Separator>
          <ButtonLine>
            <Button onClick={onManually}>Add contact manually</Button>
          </ButtonLine>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 32px;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
`;

const Title = styled.h2`
  text-align: center;
`;

const Hint = styled.div`
  margin-top: 8px;
  ${(p) => typographyCSS(p.theme.typo.body1)};
  text-align: center;
`;

const URLInput = styled(Input)`
  padding: 12px 16px;
  margin: 0 auto;
  width: 576px;
  margin-top: 24px;
`;

const Error = styled.div`
  color: ${(p) => p.theme.colors.statuses.error};
  ${(p) => typographyCSS(p.theme.typo.body2)};
  text-align: center;
  width: 544px;
  margin: 8px auto 0;

  a {
    color: inherit;
  }
`;

const Service = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.service.selected};
`;

const SupportedServices = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 4px;
  justify-content: center;
`;

const Separator = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const SeparatorLine = styled.div`
  height: 0;
  width: 24px;
  border-top: 1px solid ${(p) => p.theme.colors.borders.separator};
`;

const SeparatorText = styled.div`
  color: ${(p) => p.theme.colors.text.grey};
`;

const ButtonLine = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const Loading = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
`;

const spinnerRotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  display: block;
  width: 64px;
  height: 64px;

  &:after {
    content: " ";
    display: block;
    box-sizing: border-box;
    width: 80%;
    height: 80%;
    margin: 10%;
    border-radius: 50%;
    border: 2px solid;
    border-color: ${(p) => p.theme.colors.ui.purple} transparent
      ${(p) => p.theme.colors.ui.purple} transparent;
    animation: ${spinnerRotateAnimation} 1.2s linear infinite;
  }
`;

function getErroMsg(
  url: MaybeURL,
  data: ProfileUrlExtractionQuery | undefined,
  error: ApolloError | undefined
): string | JSX.Element | null {
  if (
    !url.isURL ||
    (url.url.protocol !== "http:" && url.url.protocol !== "https:")
  ) {
    return "URL must begin with http:// or https://";
  }

  if (!data?.profileUrlExtraction || error) {
    return "Server request failed";
  }

  const result = data.profileUrlExtraction;

  if (result.status === "IGNORED") {
    return "It looks like it is not a profile page.";
  }

  if (!result.extractable) {
    return (
      <>
        For now we don’t support parsing from "
        {url.url.host || url.url.toString()}". Use our{" "}
        <a
          href="https://chrome.google.com/webstore/detail/baselanceco/mojgobodabcbfajonllbabhacbpfplkb"
          target="_blank"
          rel="noreferrer"
        >
          chrome extension
        </a>{" "}
        to parse any website.
      </>
    );
  }

  if (result.status !== "DONE") {
    if (result.source?.beta) {
      return `${result?.source?.title} support is in beta stage and this
            attempt was unsuccessful.`;
    } else {
      return "Failed to extract profile data";
    }
  }

  if (!result.profile) {
    return "Server request failed";
  }

  return null;
}
