import { useEffect } from "react";

import { useAuthGate } from "../context/auth_gate";
import appConfig from "../../appConfig";
import { waitFor } from "../../utils/waitFor";

interface HeapIntegrationProps {
  children: JSX.Element;
}

export function HeapIntegration({ children }: HeapIntegrationProps) {
  let viewer = useAuthGate();

  useEffect(() => {
    if (
      !appConfig.heap.enabled ||
      !viewer ||
      !viewer.heapId ||
      viewer.shadowedSession
    ) {
      return;
    }

    waitFor(
      () => {
        const { heap } = window as any;
        return !!(heap && heap.identify);
      },
      () => {
        const { heap } = window as any;
        heap.identify(viewer!.heapId);
      },
      60000
    );
  }, [viewer]);

  return children;
}
