import {
  useInteractions,
  UseFloatingReturn,
} from "@floating-ui/react";
import { MutableRefObject, useMemo } from "react";
import styled from "styled-components/macro";
import { CandidateStatus } from "../../graphql";
import { ListPreviewType, PositionPreviewType } from "../../types";
import { UIIcon } from "../UIIcon/UIIcon";

interface FillableListMenuProps {
  fillableList: ListPreviewType;
  fillablePositionId: string | null | undefined;
  targetTalentId: string;
  floatingReturn: UseFloatingReturn;
  interactionsReturn: ReturnType<typeof useInteractions>;
  listRef: MutableRefObject<(HTMLElement | null)[]>;
  onSelected: (listId: string, positionId: string) => void;
  onCancel: () => void;
}

export function FillableListMenu({
  targetTalentId,
  fillableList,
  fillablePositionId,
  floatingReturn,
  interactionsReturn,
  listRef,
  onSelected,
  onCancel,
}: FillableListMenuProps) {
  const { x, y, floating, strategy } = floatingReturn;
  const { getFloatingProps, getItemProps } = interactionsReturn;
  const positions = fillableList.positions;

  const fillablePosition = useMemo(() => {
    if (!fillablePositionId) {
      return null;
    }

    return positions.find((pos) => pos.id === fillablePositionId);
  }, [fillablePositionId, positions]);

  const restPositions = useMemo(() => {
    if (!fillablePositionId) {
      return positions;
    }

    return positions.filter((pos) => pos.id !== fillablePositionId);
  }, [fillablePositionId, positions]);

  return (
    <Container
      ref={floating}
      style={{
        position: strategy,
        transform: `translate(${x ?? 0}px, ${y ?? 0}px)`,
      }}
      {...getFloatingProps()}
    >
      {!!fillablePosition && (
        <PositionMenuItem
          fillableList={fillableList}
          position={fillablePosition}
          index={0}
          targetTalentId={targetTalentId}
          listRef={listRef}
          getItemProps={getItemProps}
          onSelected={onSelected}
        />
      )}
      {restPositions.map((position, index) => (
        <PositionMenuItem
          key={position.id}
          fillableList={fillableList}
          position={position}
          index={index + (fillablePosition ? 1 : 0)}
          targetTalentId={targetTalentId}
          listRef={listRef}
          getItemProps={getItemProps}
          onSelected={onSelected}
        />
      ))}
      <MenuSeparator />
      <MenuListItem>
        <MenuElement
          {...getItemProps({
            ref: (node) => (listRef.current[positions.length] = node),
            onClick: () => {
              onCancel();
            },
          })}
        >
          Choose another list
        </MenuElement>
      </MenuListItem>
    </Container>
  );
}

interface PositionMenuItemProps {
  position: PositionPreviewType;
  index: number;
  fillableList: ListPreviewType;
  targetTalentId: string;
  listRef: MutableRefObject<(HTMLElement | null)[]>;
  getItemProps: ReturnType<typeof useInteractions>["getItemProps"];
  onSelected: (listId: string, positionId: string) => void;
}

function PositionMenuItem({
  position,
  index,
  targetTalentId,
  listRef,
  getItemProps,
  fillableList,
  onSelected,
}: PositionMenuItemProps) {
  const targetCandidate = position.candidates.find(
    (c) => c.talentId === targetTalentId
  );
  const candidateStatus = targetCandidate?.status;

  return (
    <MenuListItem>
      <MenuElement
        {...getItemProps({
          ref: (node) => (listRef.current[index] = node),
          onClick: () => {
            if (!targetCandidate) {
              onSelected(fillableList.id, position.id);
            }
          },
        })}
      >
        <Name>
          {fillableList.title} •{" "}
          <span title={position.title}>{position.title}</span>
        </Name>
        {candidateStatus === CandidateStatus.Approved && (
          <ApprovedIcon name="check" />
        )}
        {candidateStatus === CandidateStatus.Rejected && (
          <RejectedIcon name="smallcross" />
        )}
        {candidateStatus === CandidateStatus.Candidate && (
          <CandidateIndicator />
        )}
      </MenuElement>
    </MenuListItem>
  );
}

const Container = styled.div`
  margin: 0;
  padding: 8px 0;
  width: 400px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: ${(p) => p.theme.layers.menu};
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25),
    0px 4px 10px rgba(144, 143, 147, 0.25);
  border-radius: 8px;
  overflow: hidden;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};

  &:focus {
    outline: 0 none;
  }
`;

const MenuListItem = styled.li`
  list-style: none;
  padding: 0 8px;
`;

const MenuElement = styled.button`
  border: 0 none;
  background-color: transparent;
  padding: 8px;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background-color: ${(p) => p.theme.colors.service.hover};
  }

  &:focus {
    outline: 0 none;
  }

  &:active {
    background-color: ${(p) => p.theme.colors.service.selected};
  }
`;

const MenuSeparator = styled.li`
  list-style: none;
  border-bottom: 1px solid ${(p) => p.theme.colors.borders.separator};
  height: 0;
  margin: 8px 0;
  padding: 0;
`;

const Name = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ApprovedIcon = styled(UIIcon)`
  color: ${(p) => p.theme.colors.statuses.success};
`;

const RejectedIcon = styled(UIIcon)`
  color: ${(p) => p.theme.colors.statuses.error};
`;

const CandidateIndicator = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.ui.greyFilled};
  flex: 0 0 auto;
`;
