import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ActionButton } from "../../../components/ActionButton";
import { Button } from "../../../components/Button";
import { ListDetailsType, MemberType } from "../../../types";
import { useCallback, useMemo } from "react";
import { PositionSubform } from "./PositionSubform";
import {
  Box,
  Buttons,
  ErrorMsg,
  FormInput,
  InputRow,
  Section,
  Textarea,
  Title,
} from "../../../components/FormUI";
import { FloatingTree } from "@floating-ui/react";
import { TeamControl } from "./TeamControl";
import {useViewer} from "../../../components/context/auth_gate";

export interface FormData {
  title: string;
  client: string;
  overview: string;
  positions: {
    id?: string;
    title: string;
    description: string;
    count: number;
    skills: string[];
    candidatesCount?: number;
  }[];
  team: MemberType[];
}

const schema = yup
  .object({
    title: yup.string().required("Fill title"),
    client: yup.string(),
    positions: yup
      .array()
      .min(1)
      .of(
        yup.object({
          title: yup.string().required("Fill position name"),
          description: yup.string(),
        })
      ),
  })
  .required();

interface ListFormProps {
  list?: ListDetailsType;
  onSubmit: (data: FormData) => void;
  onCancel: () => void;
}

export function ListForm({ list, onSubmit, onCancel }: ListFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    formState: { isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: list?.title ?? "",
      client: list?.client ?? "",
      positions: list?.positions.map((position) => ({
        id: position.id,
        title: position.title,
        description: position.description,
        count: position.count,
        skills: position.skills ? position.skills : [],
        candidatesCount: position.candidates.length,
      })) ?? [{ title: "", description: "", count: 1, skills: [] }],
      team: list?.team ?? [],
    },
  });
  const {
    fields: positionFields,
    append: appendPosition,
    remove: removePosition,
  } = useFieldArray<FormData>({
    control,
    name: "positions",
  });
  const hasMinPositions = positionFields.length <= 1;

  const viewer = useViewer();
  const pinnedMembers = useMemo(
    () => (list ? [list.creator] : [viewer]),
    [list, viewer]
  );

  const handleAddPositionClick = useCallback(() => {
    appendPosition(
      { title: "", description: "", count: 1, skills: [] },
      { shouldFocus: true }
    );
  }, [appendPosition]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Section>
          <Title>Caption</Title>

          <InputRow>
            <FormInput
              autoComplete="off"
              placeholder="Title"
              {...register("title")}
            />
            {errors.title && (
              <ErrorMsg>{errors.title.message ?? "Fill title"}</ErrorMsg>
            )}
          </InputRow>
          <InputRow>
            <FormInput
              {...register("client")}
              autoComplete="off"
              placeholder="Client"
            />
          </InputRow>
        </Section>
      </Box>
      <Box>
        <Section>
          <Title>Overview</Title>
          <InputRow>
            <Textarea
              {...register("overview")}
              minRows={2}
              maxRows={10}
              placeholder="Drop a line about this list"
            />
          </InputRow>
        </Section>
        <Section>
          <Title>Positions</Title>
          {positionFields.map((field, positionIndex) => (
            <InputRow key={field.id}>
              <PositionSubform
                index={positionIndex}
                removable={!hasMinPositions}
                register={register}
                control={control}
                errors={errors.positions?.[positionIndex]}
                removePosition={removePosition}
              />
            </InputRow>
          ))}
          <ActionButton
            type="button"
            icon="plus"
            text="Add position"
            onClick={handleAddPositionClick}
          />
        </Section>
      </Box>
      <Box>
        <Section>
          <Title>Team</Title>
          <Controller
            name="team"
            control={control}
            render={({ field }) => (
              <FloatingTree>
                <TeamControl
                  pinnedMembers={pinnedMembers}
                  team={field.value}
                  onChange={field.onChange}
                />
              </FloatingTree>
            )}
          />
        </Section>
      </Box>
      <Buttons>
        <Button $size="xl" $kind="contained" disabled={isSubmitting}>
          Save
        </Button>
        <Button
          type="button"
          $size="l"
          $kind="text"
          onClick={onCancel}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
      </Buttons>
    </form>
  );
}
