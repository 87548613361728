import { useCallback, useState } from "react";
import styled from "styled-components/macro";
import { UIIcon } from "../../../../components/UIIcon/UIIcon";

const starsRange = [1, 2, 3, 4, 5];

interface StarsControlProps {
  value: number;
  onChange: (value: number) => void;
}

export function StarsControl({ value, onChange }: StarsControlProps) {
  const [isHoverMode, setHoverMode] = useState(false);
  const [hoverValue, setHoverValue] = useState(0);
  const displayValue = isHoverMode ? hoverValue : value;

  const handleMouseEnter = useCallback(() => {
    setHoverMode(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoverMode(false);
  }, []);

  return (
    <Container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {starsRange.map((num) => (
        <StarButton
          key={num}
          type="button"
          onMouseMove={() => {
            setHoverValue(num);
          }}
          onClick={() => onChange(num)}
        >
          {displayValue >= num ? (
            <UIIcon name="star24" />
          ) : (
            <UIIcon name="star24outline" />
          )}
        </StarButton>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;

const StarButton = styled.button`
  border: 0 none;
  background: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: ${(p) => p.theme.colors.ui.greyFilled};
`;
