import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import appConfig from "../../appConfig";
import { waitFor } from "../../utils/waitFor";
import { useAuthGate } from "../context/auth_gate";

interface IntercomIntegrationProps {
  children: JSX.Element;
}
export function IntercomIntegration({ children }: IntercomIntegrationProps) {
  let viewer = useAuthGate();
  const location = useLocation();
  const isDisabled =
    !appConfig.intercom.enabled || (viewer && viewer.shadowedSession);

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    waitFor(
      () => {
        const { Intercom } = window as any;
        return !!Intercom;
      },
      () => {
        const { Intercom } = window as any;

        const viewerData =
          viewer && viewer.intercomId
            ? {
                user_id: viewer.intercomId,
                email: viewer.email,
                name: viewer.name,
              }
            : {};

        Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: appConfig.intercom.appId,
          hide_default_launcher: !!viewer,
          ...viewerData,
        });
      },
      60000
    );
  }, [viewer, isDisabled]);

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    const { Intercom } = window as any;
    Intercom("update");
  }, [location, isDisabled]);

  return children;
}
