import {
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  UseFloatingReturn,
  useFloatingTree,
  useInteractions,
} from "@floating-ui/react";
import { gql } from "graphql.macro";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { keyframes } from "styled-components/macro";
import { useCategoriesQuery, useCreateCategoryMutation } from "../../graphql";
import { typographyCSS } from "../../theme";
import { CategoryData } from "../../types";
import { stickyTop } from "../../utils/stickyTop";
import { CategoryIcon } from "../CategoryIcon/CategoryIcon";
import { UIIcon } from "../UIIcon/UIIcon";
import { useEmojiPicker } from "../EmojiPicker/EmojiPicker";
import { filterCategories } from "./filterCategories";
import { SelectedCategory } from "./SelectedCategory";
import { useViewer } from "../context/auth_gate";
import { isGranted } from "../../acl";

const MAX_CATEGORY_COUNT = 50;

interface UseCategoryPickerOptions {
  selectedCategories?: CategoryData[];
  onDone: (categories: CategoryData[]) => void;
}

const emptyArray = Array<any>();

export function useCategoryPicker({
  selectedCategories,
  onDone,
}: UseCategoryPickerOptions) {
  const viewer = useViewer();
  const [opened, setOpened] = useState(false);
  const [pickedCategories, setPickedCategories] = useState<CategoryData[]>([]);
  const topMemoRef = useRef<number | null>(null);
  const tree = useFloatingTree();
  const canCreate = isGranted(viewer, "category.add");

  if (!tree) {
    throw new Error("<FloatingTree /> is missing for CategoriesPopover");
  }

  const categoriesQuery = useCategoriesQuery();

  const close = useCallback(() => {
    topMemoRef.current = null;
    onDone(pickedCategories);
    setPickedCategories([]);
    setOpened(false);
  }, [pickedCategories, onDone]);

  const handleOpenChange = useCallback(
    (willBeOpened: boolean) => {
      setOpened(willBeOpened);
      if (willBeOpened) {
        setOpened(willBeOpened);
      } else {
        close();
      }
    },
    [close]
  );
  const nodeId = useFloatingNodeId();

  const floatingReturn = useFloating({
    nodeId,
    open: opened,
    onOpenChange: handleOpenChange,
    placement: "bottom",
    strategy: "fixed",
    middleware: [
      offset(4),
      shift({ crossAxis: true, padding: 5 }),
      stickyTop({ topMemoRef }),
    ],
  });

  const interactionsReturn = useInteractions([
    useClick(floatingReturn.context),
    useDismiss(floatingReturn.context, { bubbles: false }),
  ]);

  const categoryPickerElement = (
    <FloatingPortal>
      {opened ? (
        <FloatingNode id={nodeId}>
          <Overlay lockScroll>
            <CategoryPicker
              floatingReturn={floatingReturn}
              interactionsReturn={interactionsReturn}
              selectedCategories={selectedCategories ?? emptyArray}
              pickedCategories={pickedCategories}
              setPickedCategories={setPickedCategories}
              allCategories={categoriesQuery.data?.categories ?? emptyArray}
              closePicker={close}
              canCreate={canCreate}
            />
          </Overlay>
        </FloatingNode>
      ) : null}
    </FloatingPortal>
  );
  const getReferenceProps = useCallback(
    (props?: React.HTMLProps<Element>) =>
      interactionsReturn.getReferenceProps.call(null, {
        ...props,
        ref: floatingReturn.reference,
      }),
    [interactionsReturn.getReferenceProps, floatingReturn.reference]
  );

  return [getReferenceProps, categoryPickerElement] as [
    typeof getReferenceProps,
    typeof categoryPickerElement
  ];
}

type VirtualFocusType =
  | { kind: "NONE" }
  | { kind: "CREATE" }
  | { kind: "ITEM"; index: number };

const VirtualFocus = {
  NoFocus: { kind: "NONE" } as VirtualFocusType,
  Create: { kind: "CREATE" } as VirtualFocusType,
  Item(index: number): VirtualFocusType {
    return { kind: "ITEM", index };
  },
};

interface CategoryPickerProps {
  floatingReturn: UseFloatingReturn;
  interactionsReturn: ReturnType<typeof useInteractions>;
  allCategories: CategoryData[];
  selectedCategories: CategoryData[];
  pickedCategories: CategoryData[];
  setPickedCategories: Dispatch<SetStateAction<CategoryData[]>>;
  canCreate: boolean;
  closePicker: () => void;
}

function CategoryPicker({
  floatingReturn,
  interactionsReturn,
  allCategories,
  selectedCategories,
  pickedCategories,
  setPickedCategories,
  canCreate,
  closePicker,
}: CategoryPickerProps) {
  const [isInCreatingMode, setCreatingMode] = useState(false);
  const [virtualFocus, setVirtualFocus] = useState(VirtualFocus.NoFocus);
  const [isEmojiOpened, setEmojiOpened] = useState<boolean>(false);
  const [filterInput, setFilterInput] = useState("");
  const [creatingCategoryData, setCreatingCategoryData] = useState<{
    name: string;
    icon: string;
  } | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const categoryListRef = useRef([] as (HTMLElement | null)[]);
  const { x, y, floating, strategy, update } = floatingReturn;
  const { getFloatingProps } = interactionsReturn;

  const lowercaseFilter = useMemo(
    () => filterInput.toLowerCase(),
    [filterInput]
  );
  const categories = useMemo(() => {
    const filteredCategories = allCategories.filter(
      (category) =>
        pickedCategories.every(
          (pickedCategory) => pickedCategory.id !== category.id
        ) &&
        selectedCategories.every(
          (selectedCategory) => selectedCategory.id !== category.id
        ) &&
        !category.systemCode
    );
    return filterCategories(filteredCategories, lowercaseFilter).slice(
      0,
      MAX_CATEGORY_COUNT
    );
  }, [lowercaseFilter, pickedCategories, selectedCategories, allCategories]);
  const matchedSelectedCategory =
    selectedCategories.find(
      (category) => category.name.toLowerCase() === lowercaseFilter
    ) ||
    pickedCategories.find(
      (category) => category.name.toLowerCase() === lowercaseFilter
    );
  const isFullMatch =
    categories.some(
      (category) => category.name.toLowerCase() === lowercaseFilter
    ) || !!matchedSelectedCategory;

  const focusInput = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const setVirtualFocusAndScroll = useCallback(
    (virtualFocus: VirtualFocusType) => {
      setVirtualFocus(virtualFocus);
      const element =
        virtualFocus.kind === "ITEM"
          ? categoryListRef.current[virtualFocus.index]
          : null;
      if (element) {
        element.scrollIntoView({
          block: "center",
          inline: "nearest",
        });
      }
    },
    []
  );

  const reset = useCallback(() => {
    setFilterInput("");
    setVirtualFocus(VirtualFocus.NoFocus);
    focusInput();
  }, [focusInput]);

  const unselectCategory = useCallback(
    (unselectedCategory: CategoryData) => {
      setPickedCategories((categories) =>
        categories.filter((category) => category.id !== unselectedCategory.id)
      );
      focusInput();
    },
    [setPickedCategories, focusInput]
  );

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFilterInput(e.target.value);
  }, []);

  const [createCategory, { loading: isCategoryBeingCreated }] =
    useCreateCategoryMutation();

  const handleIconSelected = useCallback(
    (icon: string) => {
      reset();
      setEmojiOpened(false);
      setCreatingCategoryData({ name: filterInput, icon });

      createCategory({
        variables: {
          name: filterInput,
          icon,
        },
        onCompleted(data) {
          const category = data.createCategory?.category;
          if (!category) {
            return;
          }
          setPickedCategories((categories) => [...categories, category]);
        },
        update: (cache, result) => {
          const category = result.data?.createCategory?.category;
          if (!category) {
            return;
          }

          cache.modify({
            fields: {
              categories(existingCategories = []) {
                const newCategoryRef = cache.writeFragment({
                  data: {
                    ...category,
                    cardsCount: 0,
                  },
                  fragment: gql`
                    fragment NewCategory on Category {
                      id
                      name
                      icon
                      systemCode
                      cardsCount
                    }
                  `,
                });
                return [...existingCategories, newCategoryRef];
              },
            },
          });
        },
      });
    },
    [createCategory, filterInput, setPickedCategories, reset]
  );

  const [getReferenceProps, emojiPickerElement] = useEmojiPicker({
    opened: isEmojiOpened,
    nested: true,
    onClose() {
      setEmojiOpened(false);
    },
    onEmojiSelected: handleIconSelected,
  });

  const handleCreateClick = useCallback(() => {
    if (filterInput.length > 0) {
      setEmojiOpened(true);
    } else {
      setCreatingMode(true);
    }
  }, [filterInput.length]);

  const handleCreateMouseMove = useCallback(() => {
    setVirtualFocus(VirtualFocus.Create);
  }, []);

  const isCreateAvailable =
    !isCategoryBeingCreated && canCreate && !isFullMatch;
  const isCreateActive =
    isCreateAvailable &&
    (filterInput.length > 0 || categories.length > 0) &&
    !isInCreatingMode;

  useLayoutEffect(() => {
    setCreatingMode(false);

    if (filterInput.length === 0) {
      setVirtualFocusAndScroll(VirtualFocus.NoFocus);
    } else if (categories.length === 0) {
      if (isCreateAvailable) {
        setVirtualFocusAndScroll(VirtualFocus.Create);
      } else {
        setVirtualFocusAndScroll(VirtualFocus.NoFocus);
      }
    } else {
      setVirtualFocusAndScroll(VirtualFocus.Item(0));
    }
  }, [
    filterInput,
    categories.length,
    setVirtualFocusAndScroll,
    isCreateAvailable,
  ]);

  useLayoutEffect(() => {
    update();
  }, [pickedCategories, categories, update]);

  const hasAlreadyUsed = matchedSelectedCategory && categories.length === 0;
  const hasCreateHint = isCreateAvailable && !isCreateActive;
  const hasNoMatch =
    categories.length === 0 &&
    filterInput.length > 0 &&
    !matchedSelectedCategory &&
    !isCreateActive;
  const inputPlaceholder = canCreate
    ? "Enter a category name to search or create..."
    : "Enter a category name to search...";

  return (
    <>
      <Container
        ref={floating}
        style={{
          position: strategy,
          transform: `translate(${x ?? 0}px, ${y ?? 0}px)`,
        }}
        {...getFloatingProps({
          onFocus(event) {
            if (event.target !== inputRef.current) {
              focusInput();
            }
          },
          onKeyDown(event) {
            switch (event.key) {
              case "Enter":
                if (virtualFocus.kind === "NONE") {
                  if (filterInput.length === 0) {
                    closePicker();
                  }
                } else if (virtualFocus.kind === "CREATE") {
                  handleCreateClick();
                } else if (categories[virtualFocus.index]) {
                  setPickedCategories((selectedCategories) => [
                    ...selectedCategories,
                    categories[virtualFocus.index],
                  ]);
                  reset();
                }
                break;
              case "ArrowDown":
                event.preventDefault();
                if (virtualFocus.kind !== "ITEM") {
                  if (categories.length > 0) {
                    setVirtualFocusAndScroll(VirtualFocus.Item(0));
                  } else if (isCreateActive) {
                    setVirtualFocus(VirtualFocus.Create);
                  }
                  return;
                } else if (virtualFocus.index === categories.length - 1) {
                  if (isCreateActive) {
                    setVirtualFocus(VirtualFocus.Create);
                  } else {
                    setVirtualFocusAndScroll(VirtualFocus.Item(0));
                  }
                } else {
                  setVirtualFocusAndScroll(
                    VirtualFocus.Item(virtualFocus.index + 1)
                  );
                }
                break;
              case "ArrowUp":
                event.preventDefault();
                if (virtualFocus.kind === "CREATE") {
                  if (categories.length > 0) {
                    setVirtualFocusAndScroll(
                      VirtualFocus.Item(categories.length - 1)
                    );
                  }
                } else if (
                  virtualFocus.kind === "NONE" ||
                  virtualFocus.index === 0
                ) {
                  if (isCreateActive) {
                    setVirtualFocus(VirtualFocus.Create);
                  } else {
                    setVirtualFocusAndScroll(
                      VirtualFocus.Item(categories.length - 1)
                    );
                  }
                } else {
                  setVirtualFocusAndScroll(
                    VirtualFocus.Item(virtualFocus.index - 1)
                  );
                }
                break;
              case "Backspace":
                if (filterInput.length === 0 && pickedCategories.length > 0) {
                  setPickedCategories((categories) => categories.slice(0, -1));
                }
                break;
            }
          },
        })}
      >
        <InputZone onClick={focusInput}>
          {pickedCategories.map((category) => (
            <SelectedCategory
              key={category.id}
              category={category}
              onClick={unselectCategory}
            />
          ))}
          {isCategoryBeingCreated && creatingCategoryData && (
            <Chip>
              <CategoryIcon size="s" name={creatingCategoryData.icon} />
              <CategoryName>{creatingCategoryData.name}</CategoryName>
              <Spinner />
            </Chip>
          )}
          <Input
            ref={inputRef}
            value={filterInput}
            onChange={handleInputChange}
            placeholder={
              pickedCategories.length === 0 ? inputPlaceholder : undefined
            }
            autoFocus
          />
        </InputZone>
        <MainZone>
          {!isInCreatingMode &&
            (categories.length > 0 || hasAlreadyUsed || hasNoMatch) && (
              <CategoryList>
                {categories.map((category, index) => (
                  <CategoryListItem key={category.id}>
                    <CategoryListElement
                      $active={
                        virtualFocus.kind === "ITEM" &&
                        virtualFocus.index === index
                      }
                      ref={(node) => {
                        categoryListRef.current[index] = node;
                      }}
                      onMouseMove={() =>
                        setVirtualFocus(VirtualFocus.Item(index))
                      }
                      onClick={() => {
                        setPickedCategories((selectedCategories) => [
                          ...selectedCategories,
                          category,
                        ]);
                        reset();
                      }}
                    >
                      <CategoryIcon size="s" name={category.icon} />
                      <CategoryName>{category.name}</CategoryName>
                    </CategoryListElement>
                  </CategoryListItem>
                ))}
                {hasAlreadyUsed && (
                  <CategoryListItem>
                    <InactiveCategoryListElement>
                      <CategoryIcon
                        size="s"
                        name={matchedSelectedCategory.icon}
                      />
                      <CategoryName>
                        {matchedSelectedCategory.name}
                      </CategoryName>
                      <AlreadyUsedCategoryHint>
                        already used
                      </AlreadyUsedCategoryHint>
                    </InactiveCategoryListElement>
                  </CategoryListItem>
                )}
                {hasNoMatch && (
                  <CategoryListItem>
                    <InactiveCategoryListElement>
                      <NoMatchHint>No match found</NoMatchHint>
                    </InactiveCategoryListElement>
                  </CategoryListItem>
                )}
              </CategoryList>
            )}
          {isCreateActive && (
            <CreateButton
              $active={virtualFocus.kind === "CREATE" || isEmojiOpened}
              {...getReferenceProps()}
              onMouseMove={handleCreateMouseMove}
              onClick={handleCreateClick}
            >
              <UIIcon name="plus" />
              <span>Create</span>
              {filterInput.length > 0 && (
                <Chip>
                  <CategoryName>{filterInput}</CategoryName>
                </Chip>
              )}
            </CreateButton>
          )}
          {hasCreateHint && (
            <CreateHint>💡 Start typing to create a new category</CreateHint>
          )}
        </MainZone>
      </Container>
      {emojiPickerElement}
    </>
  );
}

const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 400px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: ${(p) => p.theme.layers.menu};
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25),
    0px 4px 10px rgba(144, 143, 147, 0.25);
  border-radius: 8px;
  overflow: hidden;

  &:focus {
    outline: 0 none;
  }
`;

const InputZone = styled.div`
  cursor: text;
  position: relative;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;
  padding: 8px;
  min-height: 28px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const Input = styled.input`
  flex: 1 1 60px;
  min-width: 60px;
  padding: 0 4px;
  border: 0 none;
`;

const MainZone = styled.div`
  margin-top: -16px;
  padding-top: 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-top-width: 0;
  border-radius: 8px;
`;

const CategoryList = styled.ul`
  margin: 0;
  padding: 8px;
  max-height: 200px;
  overflow: auto;
  border-bottom: 1px solid ${(p) => p.theme.colors.borders.separator};
`;

const CategoryListItem = styled.li`
  position: relative;
  list-style: none;
  padding: 0px;
`;

const CategoryListElement = styled.button<{ $active: boolean }>`
  background: ${(p) =>
    p.$active ? p.theme.colors.service.selected : "transparent"};
  border: 0 none;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  gap: 8px;

  &:focus {
    outline: 0 none;
  }
`;

const CategoryName = styled.div`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  cursor: inherit;
`;

const Chip = styled.div`
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  padding: 2px 8px;
  box-sizing: border-box;
  max-width: 250px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: default;
`;

const AlreadyUsedCategoryHint = styled.span`
  color: ${(p) => p.theme.colors.text.grey};
  ${(p) => typographyCSS(p.theme.typo.body2)};
`;

const InactiveCategoryListElement = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 8px 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: default;

  ${CategoryName} {
    flex: 0 0 auto;
    align-self: baseline;
  }

  ${AlreadyUsedCategoryHint} {
    align-self: baseline;
  }
`;

const NoMatchHint = styled.span`
  color: ${(p) => p.theme.colors.text.grey};
`;

const CreateButton = styled.button<{ $active: boolean }>`
  cursor: pointer;
  background: ${(p) =>
    p.$active
      ? p.theme.colors.service.selected
      : p.theme.colors.backgrounds.main};
  border: 0 none;
  display: block;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 4px;

  &:focus {
    outline: 0 none;
  }

  & > span {
    margin-right: 4px;
  }
`;

const CreateHint = styled.div`
  padding: 8px 16px;
  ${(p) => typographyCSS(p.theme.typo.body2)};
  color: ${(p) => p.theme.colors.text.grey};
`;

const Overlay = styled(FloatingOverlay)`
  z-index: ${(p) => p.theme.layers.overlay};
`;

const spinnerRotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;

  &:after {
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    margin: 2px;
    border-radius: 50%;
    border: 2px solid ${(p) => p.theme.colors.ui.purple};
    box-sizing: border-box;
    border-color: ${(p) => p.theme.colors.ui.purple} transparent
      ${(p) => p.theme.colors.ui.purple} transparent;
    animation: ${spinnerRotateAnimation} 1.2s linear infinite;
  }
`;
