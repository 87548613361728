import { useState } from "react";
import styled from "styled-components/macro";
import { UIIcon } from "../../../../components/UIIcon/UIIcon";

import { TalentType } from "../../../../types";
import { rangeArray } from "../../../../utils/rangeArray";
import { Gallery } from "./Gallery";
import { Header, Hint } from "../ui";
import { WorkSlot } from "./ui";
import { WorkItem } from "./WorkItem";
import { ActionButton } from "../../../../components/ActionButton";
import { useDropzone } from "react-dropzone";
import { useUnmounted } from "../../../../hooks/useUnmounted";
import {
  useCreateWorkMutation,
  useUploadWorkMutation,
} from "../../../../graphql";
import { preloadImage } from "../../../../utils/preloadImage";
import { UploadSlot } from "./UploadSlot";
import { useSlotty } from "../../../../hooks/useSlotty";
import { isGranted } from "../../../../acl";
import { useViewer } from "../../../../components/context/auth_gate";

interface WorksSectionProps {
  talent: TalentType | null | undefined;
  editable: boolean;
  loading: boolean;
}

const placeholdersRange = rangeArray(6);

interface WorkUpload {
  filename: string;
  error: boolean;
}

export function WorksSection({ talent, loading, editable }: WorksSectionProps) {
  const viewer = useViewer();
  const getUnmounted = useUnmounted();
  const [galleryActiveIndex, setGalleryActiveIndex] = useState<number | null>(
    null
  );
  const works = talent?.works ?? [];

  const [uploadSlots, { createSlot, updateSlot, dropSlot }] =
    useSlotty<WorkUpload>();

  const [createWork] = useCreateWorkMutation();
  const [uploadWork] = useUploadWorkMutation();

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    open: openFileDialog,
  } = useDropzone({
    disabled: loading || !isGranted(viewer, "talent.edit"),
    noClick: true,
    multiple: true,
    accept: {
      "image/*": [],
    },
    onDrop(files) {
      if (!talent) return;

      for (const file of files) {
        const slottyId = createSlot({ filename: file.name, error: false });
        uploadWork({
          variables: { file },
          onCompleted(data) {
            if (getUnmounted()) return;

            const image = data.uploadImage?.image;

            if (!image) {
              dropSlot(slottyId);
              return;
            }

            preloadImage(image.preview400);
            createWork({
              variables: {
                input: {
                  cardId: talent.id,
                  image: image.id,
                  imageUrl: null,
                  clientMutationId: null,
                },
              },
              onCompleted() {
                dropSlot(slottyId);
              },
              onError() {
                updateSlot(slottyId, (data) => ({ ...data, error: true }));
              },
            });
          },
          onError() {
            updateSlot(slottyId, (data) => ({ ...data, error: true }));
          },
        });
      }
    },
  });

  const slotsCount = works.length + uploadSlots.length;

  return (
    <Container {...getRootProps()}>
      {!loading && slotsCount === 0 && <Header>Portfolio</Header>}
      <input {...getInputProps()} />
      {(loading || slotsCount > 0) && (
        <Content>
          {loading ? (
            placeholdersRange.map((idx) => <WorkSlot key={idx} />)
          ) : (
            <>
              {uploadSlots.map((upload) => (
                <UploadSlot key={upload.id} {...upload.data} />
              ))}
              {works.slice(0, 6 - uploadSlots.length).map((work, index) => (
                <WorkItem
                  key={work.id}
                  work={work}
                  index={index}
                  onClick={setGalleryActiveIndex}
                />
              ))}
            </>
          )}
          {slotsCount > 6 && works.length > 0 && (
            <GalleryButton onClick={() => setGalleryActiveIndex(0)}>
              <span>View all</span>
              <UIIcon name="more" />
            </GalleryButton>
          )}
        </Content>
      )}
      {!loading && isGranted(viewer, "talent.edit") && (
        <ActionButton icon="plus" text="Add work" onClick={openFileDialog} />
      )}
      {!loading && slotsCount === 0 && editable && (
        <Hint>
          Pull visual portfolio from connected sources automatically or add it
          manually. Upload any images or video files.
        </Hint>
      )}
      {works.length > 0 && (
        <Gallery
          activeIndex={galleryActiveIndex}
          setActiveIndex={setGalleryActiveIndex}
          works={works}
        />
      )}
      <DndCover active={isDragAccept}>
        <DndCoverContent>
          <p>Drop image here</p>
          <span>png, jpeg, gif, tiff, webp</span>
        </DndCoverContent>
      </DndCover>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const DndCover = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  pointer-events: none;
  opacity: ${(p) => (p.active ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
`;

const DndCoverContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${(p) => p.theme.colors.highlights.purple.bg};
  border: 1px dashed ${(p) => p.theme.colors.highlights.purple.fg};
  border-radius: 16px;
  color: ${(p) => p.theme.colors.highlights.purple.fg};

  p {
    margin: 0 0 8px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
  }
`;

const Content = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;
const GalleryButton = styled.button`
  position: absolute;
  right: 8px;
  bottom: 8px;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.backgrounds.inverse};
  padding: 4px 8px 4px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(p) => p.theme.colors.text.white};
  cursor: pointer;
  &:hover,
  &:active {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 8px;
    }
  }

  &:hover::after {
    background-color: ${(p) => p.theme.colors.service.hover};
  }

  &:active::after {
    background-color: ${(p) => p.theme.colors.service.selected};
  }

  &:focus-visible {
    outline: 3px solid ${(p) => p.theme.colors.service.focus};
    outline-offset: 0;
  }
`;
