import {
  format,
  isSameYear,
  isSameDay,
  isSameMonth,
  parseISO,
} from "date-fns/fp";
import { useCallback } from "react";
import styled from "styled-components/macro";
import { ActionButton } from "../../../../components/ActionButton";
import { HighlightColorKind, typographyCSS } from "../../../../theme";
import { DateRange } from "./types";

interface LegendProps {
  ranges: DateRange[];
  onRangeDelete: (rangeId: string) => void;
}

export function Legend({ ranges, onRangeDelete }: LegendProps) {
  return (
    <Container>
      {ranges.map((range) => (
        <Row key={range.id}>
          <ColorCircle $color={range.kind.color} />
          <Name>{range.kind.name}</Name>
          <Dates>{formatRange(range.startDate, range.endDate)}</Dates>
          <CreatedAt>
            ({format("dd.MM.yy", parseISO(range.createdAt))})
          </CreatedAt>
          <AnimatedDeleteButton range={range} onDelete={onRangeDelete} />
        </Row>
      ))}
    </Container>
  );
}

interface DeleteButtonProps {
  className?: string;
  range: {
    id: string;
  };
  onDelete: (rangeId: string) => void;
}

function DeleteButton({ className, range, onDelete }: DeleteButtonProps) {
  const handleClick = useCallback(() => {
    onDelete(range.id);
  }, [onDelete, range.id]);

  return (
    <ActionButton className={className} icon="cross" onClick={handleClick} />
  );
}

function formatRange(startDate: Date, endDate: Date) {
  if (isSameDay(startDate, endDate)) {
    return format("dd MMM yyyy", startDate);
  }

  if (isSameMonth(startDate, endDate)) {
    return `${format("dd", startDate)}—${format("dd MMM yyyy", endDate)}`;
  }

  if (isSameYear(startDate, endDate)) {
    return `${format("dd MMM", startDate)} — ${format("dd MMM yyyy", endDate)}`;
  }

  return `${format("dd MMM yyyy", startDate)} — ${format(
    "dd MMM yyyy",
    endDate
  )}`;
}

const Container = styled.div``;

const AnimatedDeleteButton = styled(DeleteButton)`
  opacity: 0;
  transition: opacity 0.2s;
`;

const Name = styled.div`
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Dates = styled.div`
  flex: 0 0 auto;
`;

const CreatedAt = styled.div`
  flex: 0 0 auto;
  color: ${(p) => p.theme.colors.text.grey};
  ${(p) => typographyCSS(p.theme.typo.body2)};
`;

const Row = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;

  &:hover {
    ${AnimatedDeleteButton} {
      opacity: 1;
    }
  }
`;

const ColorCircle = styled.div<{ $color: HighlightColorKind }>`
  flex: 0 0 auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.highlights[p.$color].fg};
`;
