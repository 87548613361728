import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components/macro";
import * as yup from "yup";
import { Button } from "../../../components/Button";
import { Select } from "../../../components/Select";
import { useAnswerSurveyMutation } from "../../../graphql";
import { OneLineSelect } from "./OneLineSelect";
import { ServiceSelect } from "./ServiceSelect";

const SUBMISSION = "__SUBMISSION__";

const TeamSizeValues = ["1—5", "6—10", "11—20", "20+"];
const DBSizeValues = ["1—20", "21—50", "50—100", "100+"];

const Industries = [
  "Graphics & Design",
  "Digital Marketing",
  "Writing & Translation",
  "Video & Animation",
  "Music & Audio",
  "Programming & Tech",
  "Photography",
  "Lifestyle",
  "Business",
];

const Roles = [
  "CEO",
  "Producer",
  "Recruting Manager",
  "Art Director",
  "Creative Director",
];

enum FormSection {
  Industry,
  Role,
  TeamSize,
  DatabaseSize,
  CurrentTool,
  Submit,
}

export interface FormData {
  industry: string;
  role: string;
  teamSize: string;
  dbSize: string;
  currentTool: string;
  [SUBMISSION]: void;
}

const schema = yup
  .object({
    industry: yup.string().required(),
    role: yup.string().required(),
    teamSize: yup.string().required(),
    dbSize: yup.string().required(),
    currentTool: yup.string().required(),
  })
  .required();

interface SurveyProps {
  onComplete: () => void;
}

export function Survey({ onComplete }: SurveyProps) {
  const roleSectionRef = useRef<HTMLDivElement>(null);
  const teamSizeSectionRef = useRef<HTMLDivElement>(null);
  const databaseSizeSectionRef = useRef<HTMLDivElement>(null);
  const currentToolSectionRef = useRef<HTMLDivElement>(null);
  const submitSectionRef = useRef<HTMLDivElement>(null);

  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      industry: "",
      role: "",
      teamSize: "",
      dbSize: "",
      currentTool: "",
    },
  });

  const [answerSurvey] = useAnswerSurveyMutation();

  const handleFormSubmit = async (data: FormData) => {
    clearErrors(SUBMISSION);

    return answerSurvey({
      variables: {
        input: {
          ...data,
        },
      },
      onCompleted: onComplete,
      onError() {
        setError(SUBMISSION, {
          type: "submit",
          message: "Form submission failed. Please, try again later",
        });
      },
    });
  };

  const data = watch();
  const activeSection = getActiveSection(data);

  useEffect(() => {
    let element;
    switch (activeSection) {
      case FormSection.Role:
        element = roleSectionRef.current;
        break;
      case FormSection.TeamSize:
        element = teamSizeSectionRef.current;
        break;
      case FormSection.DatabaseSize:
        element = databaseSizeSectionRef.current;
        break;
      case FormSection.CurrentTool:
        element = currentToolSectionRef.current;
        break;
      case FormSection.Submit:
        element = submitSectionRef.current;
        break;
    }
    element?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [activeSection]);

  return (
    <Container>
      <h1>Set up your talents base</h1>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Section>
          <h4>What industry do you work in?</h4>
          <Select {...register("industry")} required>
            <option value="" disabled hidden>
              Select industry
            </option>
            {Industries.map((industry, idx) => (
              <option key={idx} value={industry}>
                {industry}
              </option>
            ))}
            <option value="Other">Other</option>
          </Select>
        </Section>
        <Section ref={roleSectionRef} $hidden={data.industry.length === 0}>
          <h4>What's your role in your company</h4>
          <Select {...register("role")} required>
            <option value="" disabled hidden>
              Select role
            </option>
            {Roles.map((role, idx) => (
              <option key={idx} value={role}>
                {role}
              </option>
            ))}
            <option value="Other">Other</option>
          </Select>
        </Section>
        <Section ref={teamSizeSectionRef} $hidden={data.role.length === 0}>
          <h4>How big is your team</h4>
          <Controller
            name="teamSize"
            control={control}
            render={({ field }) => (
              <OneLineSelect
                values={TeamSizeValues}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Section>
        <Section
          ref={databaseSizeSectionRef}
          $hidden={data.teamSize.length === 0}
        >
          <h4>How many freelancers does your personal database include?</h4>
          <Controller
            name="dbSize"
            control={control}
            render={({ field }) => (
              <OneLineSelect
                values={DBSizeValues}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Section>
        <Section ref={currentToolSectionRef} $hidden={data.dbSize.length === 0}>
          <h4>How do you maintain your talent database now</h4>
          <Controller
            name="currentTool"
            control={control}
            render={({ field }) => (
              <ServiceSelect value={field.value} onChange={field.onChange} />
            )}
          />
        </Section>
        <Section ref={submitSectionRef} $hidden={data.currentTool.length === 0}>
          <Button
            $kind="contained"
            $size="l"
            $fullWidth
            disabled={isSubmitting}
          >
            Continue
          </Button>
          {errors[SUBMISSION] && (
            <ErrorMsg>{errors[SUBMISSION].message}</ErrorMsg>
          )}
        </Section>
      </form>
    </Container>
  );
}

function getActiveSection(data: FormData) {
  if (data.industry.length === 0) {
    return FormSection.Industry;
  }

  if (data.role.length === 0) {
    return FormSection.Role;
  }

  if (data.teamSize.length === 0) {
    return FormSection.TeamSize;
  }

  if (data.dbSize.length === 0) {
    return FormSection.DatabaseSize;
  }

  if (data.currentTool.length === 0) {
    return FormSection.CurrentTool;
  }

  return FormSection.Submit;
}

const Container = styled.div`
  width: 400px;
  margin-bottom: 80px;

  h1 {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 440px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;

    h1 {
      text-align: center;
    }
  }
`;

const Section = styled.div<{ $hidden?: boolean }>`
  margin-bottom: 24px;
  position: ${(p) => (p.$hidden ? "absolute" : "static")};
  width: ${(p) => (p.$hidden ? "100px" : "auto")};
  left: ${(p) => (p.$hidden ? "-9999px" : "0")};
  opacity: ${(p) => (p.$hidden ? "0" : "1")};
  transition: opacity 0.3s ease-in-out;

  h4 {
    margin-bottom: 8px;
  }

  label {
    width: 100%;
  }
`;

const ErrorMsg = styled.div`
  margin-top: 4px;
  text-align: center;
  color: ${(p) => p.theme.colors.statuses.error};
`;
