import { useCallback } from "react";
import styled from "styled-components";
import { UIIcon } from "../UIIcon/UIIcon";

interface ExpandingButtonProps {
  isExpanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  collapseText: string;
  expandText: string;
}

export function ExpandingButton({
  isExpanded,
  setExpanded,
  collapseText,
  expandText,
}: ExpandingButtonProps) {
  const handleClick = useCallback(() => {
    setExpanded((value) => !value);
  }, [setExpanded]);

  return (
    <Button onClick={handleClick} $isExpanded={isExpanded}>
      <span>{isExpanded ? collapseText : expandText}</span>
      <UIIcon name="smalldropdown" />
    </Button>
  );
}

const Button = styled.button<{ $isExpanded: boolean }>`
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0 0 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  svg {
    transform: rotate(${(p) => (p.$isExpanded ? "180deg" : "0")});
  }
`;
