import {useMemo, useState} from "react";

let slottyCounter = 0;

interface SlottySlot<D> {
  id: number;
  data: D;
}

export function useSlotty<D = any>() {
  const [slots, setSlots] = useState([] as SlottySlot<D>[]);
  const api = useMemo(
    () => ({
      createSlot(data: D) {
        const id = (slottyCounter += 1);
        setSlots((slots) => [...slots, { id, data }]);

        return id;
      },
      dropSlot(id: number) {
        setSlots((slots) => slots.filter((slot) => slot.id !== id));
      },
      updateSlot(id: number, updateFn: (data: D) => D) {
        setSlots((slots) =>
          slots.map((slot) =>
            slot.id === id ? { id, data: updateFn(slot.data) } : slot
          )
        );
      },
    }),
    []
  );

  return [slots, api] as const;
}

