import { useCallback, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { AvatarPlaceholder, TalentAvatar } from "../../../components/Avatar";
import { TalentPreviewType } from "../../../types";
import { notEmpty } from "../../../utils/notEmpty";
import { typographyCSS } from "../../../theme";
import { useOverpageHandler } from "../../../components/Overpage/useOverpageHandler";
import { useDelayedMouseEnter } from "../../../hooks/useDelayedMouseEnter";
import { UIIcon } from "../../../components/UIIcon/UIIcon";
import { prepareContacts } from "../../../utils/prepareContacts";
import { ContactIcon } from "../../../components/ContactIcon/ContactIcon";
import { BaseButton } from "../../../components/Button";
import { rowRangeSelector } from "../../../utils/rowRangeSelector";
import { isEmpty } from "../../../utils/isEmpty";
import { useListsPicker } from "../../../components/ListsPicker/ListsPicker";
import { useViewer } from "../../../components/context/auth_gate";

export const MAX_GRID_COLS = 4;
export const PLACEHOLDER_ROWS_COUNT = 3;

interface GridCardProps {
  talent: TalentPreviewType;
}

export function GridItemCard({ talent }: GridCardProps) {
  const viewer = useViewer();
  const [webContacts] = useMemo(
    () => prepareContacts(talent?.contacts ?? []),
    [talent]
  );

  const [cardHovered, cardHoverHandlers] = useDelayedMouseEnter();
  const [previewHovered, previewHoverHandlers] = useDelayedMouseEnter();
  const workImages = talent.works?.filter(notEmpty).filter((w) => !!w.image);
  const hasWorks = !!workImages && workImages.length > 0;
  const hasDetails = !isEmpty(webContacts) || !isEmpty(talent.notes);
  const talentLinkHandler = useOverpageHandler({
    to: `/talents/${talent.id}`,
  });

  const [isListsPickerOpened, setListsPickerOpened] = useState(false);
  const [getReferenceProps, listsPickerElement] = useListsPicker({
    talentId: talent.id,
    opened: isListsPickerOpened,
    setOpened: setListsPickerOpened,
  });

  return (
    <Container>
      <CardContent
        {...cardHoverHandlers}
        className={cardHovered ? "__hovered" : undefined}
      >
        <BackgroundLink {...talentLinkHandler} />
        <Preview
          withDetails={hasDetails}
          {...previewHoverHandlers}
          className={previewHovered ? "__hovered" : undefined}
        >
          <WorksPreview tabIndex={-1} {...talentLinkHandler}>
            <WorksBackground>
              <WorkSlot>
                {workImages?.[0] && (
                  <WorkImg
                    src={workImages[0].image?.preview200.src || undefined}
                    srcSet={workImages[0].image?.preview200.srcSet || undefined}
                    alt={workImages[0].title || undefined}
                  />
                )}
              </WorkSlot>
              <WorkSlot>
                {workImages?.[1] && (
                  <WorkImg
                    src={workImages[1].image?.preview200.src || undefined}
                    srcSet={workImages[1].image?.preview200.srcSet || undefined}
                    alt={workImages[1].title || undefined}
                  />
                )}
              </WorkSlot>
              <WorkSlot>
                {workImages?.[2] && (
                  <WorkImg
                    src={workImages[2].image?.preview200.src || undefined}
                    srcSet={workImages[2].image?.preview200.srcSet || undefined}
                    alt={workImages[2].title || undefined}
                  />
                )}
              </WorkSlot>
            </WorksBackground>
          </WorksPreview>

          {hasDetails && (
            <DetailsPreview
              pinned={!hasWorks}
              className={previewHovered ? "__hovered" : undefined}
            >
              <BackgroundLink tabIndex={-1} {...talentLinkHandler} />
              {webContacts.length > 0 && (
                <WebContacts>
                  {webContacts.map((contact) => (
                    <WebContactLink
                      key={contact.url}
                      href={contact.url}
                      target="_blank"
                    >
                      <WebContactIcon name={contact.icon} url={contact.url} />
                    </WebContactLink>
                  ))}
                </WebContacts>
              )}
              <NotesPreview
                $placeholder={!talent.notes}
                tabIndex={-1}
                {...talentLinkHandler}
              >
                {(() => {
                  if (talent.notes) {
                    return talent.notes;
                  }
                  if (!hasWorks) {
                    return "No artworks or notes";
                  }
                  return "No notes";
                })()}
              </NotesPreview>
            </DetailsPreview>
          )}
          {!hasWorks && !hasDetails && (
            <BlankPreview tabIndex={-1} {...talentLinkHandler}>
              No portfolio yet
            </BlankPreview>
          )}
        </Preview>
        <MainContainer>
          <AvatarLink {...talentLinkHandler} tabIndex={-1}>
            <TalentAvatar talent={talent} size="s" />
          </AvatarLink>
          <InfoCol>
            {(talent.name || talent.location) && (
              <>
                <NameRowLink {...talentLinkHandler} tabIndex={-1}>
                  <Name>{talent.name ? talent.name : "—"}</Name>
                  {talent.isSample && <Badge>Sample</Badge>}
                </NameRowLink>
                <SecondaryDataLink {...talentLinkHandler} tabIndex={-1}>
                  {!talent.location && !talent.price ? (
                    "—"
                  ) : (
                    <>
                      {talent.location && (
                        <SecondaryItem>{talent.location}</SecondaryItem>
                      )}
                      {talent.price && (
                        <SecondaryItem>
                          {talent.price}
                          {talent.priceUnit ? ` / ${talent.priceUnit}` : ""}
                        </SecondaryItem>
                      )}
                    </>
                  )}
                </SecondaryDataLink>
              </>
            )}
          </InfoCol>
          <RatingCol>
            {talent.rating ? talent.rating.toFixed(1) : "—"}
            {talent.rating && talent.rating < 2.5 ? (
              <UIIcon name="shitcolor" />
            ) : (
              <UIIcon name="star" />
            )}
            {talent.favorite && <UIIcon name="heartfilled" />}
          </RatingCol>
        </MainContainer>
        <CardActions $active={isListsPickerOpened}>
          {viewer.features.lists && (
            <CardActionButton {...getReferenceProps()}>
              <UIIcon name="folderplus" />
            </CardActionButton>
          )}
        </CardActions>
        {listsPickerElement}
      </CardContent>
    </Container>
  );
}

interface GridItemPlaceholderProps {
  more?: boolean;
}

export function GridItemPlaceholder({
  more = false,
}: GridItemPlaceholderProps) {
  const talentLinkProps = {
    href: undefined,
    onClick: useCallback((event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
    }, []),
  };

  return (
    <Container
      as="div"
      className={`_placeholder ${
        more ? "_placeholder_more" : "_placeholder_init"
      }`}
    >
      <CardContent>
        <Preview {...talentLinkProps} withDetails={false} tabIndex={-1}>
          <WorksPreview>
            <WorksBackground>
              <WorkSlot $placeholder />
              <WorkSlot $placeholder />
              <WorkSlot $placeholder />
            </WorksBackground>
          </WorksPreview>
        </Preview>
        <MainContainer>
          <AvatarPlaceholder size="s" />
          <InfoCol>
            <NamePlaceholder />
            <LocationPlaceholder />
          </InfoCol>
        </MainContainer>
      </CardContent>
    </Container>
  );
}

const Container = styled.li`
  list-style: none;
  margin: 0;
  padding: 8px;
  box-sizing: border-box;
  width: 100%;

  &._placeholder {
    display: none;
  }

  &._placeholder_init {
    &${rowRangeSelector(1, MAX_GRID_COLS)} {
      opacity: 0.7;
    }

    &${rowRangeSelector(2, MAX_GRID_COLS)} {
      opacity: 0.4;
    }
  }

  @media screen and (max-width: 1099px) {
    &._placeholder {
      &:nth-of-type(${MAX_GRID_COLS}n + 1) {
        display: block;
      }
    }
  }

  @media screen and (min-width: 1100px) and (max-width: 1890px) {
    width: ${100 / 2}%;

    &._placeholder {
      &:nth-of-type(${MAX_GRID_COLS}n + 1),
      &:nth-of-type(${MAX_GRID_COLS}n + 2) {
        display: block;
      }
    }
  }

  @media screen and (min-width: 1891px) and (max-width: 2628px) {
    width: ${100 / 3}%;

    &._placeholder {
      &:nth-of-type(${MAX_GRID_COLS}n + 1),
      &:nth-of-type(${MAX_GRID_COLS}n + 2),
      &:nth-of-type(${MAX_GRID_COLS}n + 3) {
        display: block;
      }
    }
  }

  @media screen and (min-width: 2629px) {
    width: ${100 / 4}%;

    &._placeholder {
      display: block;
    }
  }
`;

const CardActions = styled.div<{ $active: boolean }>`
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: ${(p) => (p.$active ? 1 : 0)};
  display: flex;
  align-items: center;
  gap: 4px;
  transition: opacity 0.1s ease-in-out;
`;

const CardActionButton = styled(BaseButton)`
  color: white;
  background-color: ${(p) => p.theme.colors.backgrounds.inverse};
  padding: 4px;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;
`;

const CardContent = styled.div`
  position: relative;
  padding: 16px;
  box-sizing: border-box;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border-radius: 16px;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  height: 100%;
  width: 100%;
  transition: all 0.1s linear;

  &:hover.__hovered ${CardActions} {
    opacity: 1;
  }
`;

const BackgroundLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 16px;
`;

interface DetailsPreviewProps {
  pinned: boolean;
}

const DetailsPreview = styled.div<DetailsPreviewProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 8px;
  opacity: ${(p) => (p.pinned ? "1" : "0")};
  border-radius: 8px;
  white-space: pre-wrap;
  transition: opacity 0.2s;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  align-items: center;

  &::before {
    content: "";
    margin: auto 0 0;
  }

  &::after {
    content: "";
    margin: 0 0 auto;
  }
`;

const WebContacts = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  margin-left: -4px;
`;

const WebContactIcon = styled(ContactIcon)`
  padding: 4px;
  width: 20px;
  height: 20px;
  color: ${(p) => p.theme.colors.text.black};
`;

const WebContactLink = styled(BaseButton.withComponent("a"))`
  display: block;
  background-color: ${(p) => p.theme.colors.service.selected};
  border-radius: 50%;
`;

const NotesPreview = styled.a<{ $placeholder: boolean }>`
  display: block;
  max-width: 100%;
  text-decoration: none;
  overflow: hidden;
  overflow-wrap: break-word;
  text-align: center;
  ${(p) => typographyCSS(p.theme.typo.body2)};
  color: ${(p) =>
    p.$placeholder ? p.theme.colors.text.grey : p.theme.colors.text.black};
`;

const BlankPreview = styled.a`
  display: block;
  text-decoration: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  color: #817a85;
  background: ${(p) => p.theme.colors.backgrounds.grey};
  border-radius: 8px;
  transition: opacity 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WorksPreview = styled.a`
  display: block;
  overflow: hidden;
  position: relative;
  transition: opacity 0.2s;
`;

interface PreviewProps {
  withDetails: boolean;
}

const Preview = styled.div<PreviewProps>`
  position: relative;
  display: block;

  &:focus-within ${DetailsPreview} {
    opacity: 1;
  }

  &:hover.__hovered ${DetailsPreview} {
    opacity: 1;
  }

  &:hover.__hovered ${WorksPreview} {
    opacity: ${(p) => (p.withDetails ? 0 : 1)};
  }
`;

const WorksBackground = styled.div`
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  grid-column-gap: 4px;
  position: relative;
  background: white;
`;

const WorkSlot = styled.div<{ $placeholder?: boolean }>`
  align-items: center;
  background: ${(p) =>
    p.$placeholder ? p.theme.colors.backgrounds.grey : "transparent"};
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  position: relative;
  border-radius: 8px;
`;

const WorkImg = styled.img`
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
  object-fit: cover;
  top: 0;
`;

const MainContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const AvatarLink = styled.a`
  flex: 0 0 auto;
  position: relative;
  background: ${(p) => p.theme.colors.backgrounds.grey};
  border-radius: 50%;
  display: block;
  line-height: 0;
  overflow: hidden;
  text-decoration: none;
`;

const InfoCol = styled.div`
  flex: 1;
  padding-left: 12px;
  overflow: hidden;
`;

const RatingCol = styled.div`
  flex: 0 0 auto;
  align-self: end;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const NameRowLink = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
`;

const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => p.theme.colors.text.black};
  ${(p) => typographyCSS(p.theme.typo.highlighted)};
  margin-right: 2px;
`;

const Badge = styled.div`
  box-sizing: border-box;
  height: 16px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme.colors.ui.greyFilled};
  border-radius: 8px;
  color: ${(p) => p.theme.colors.text.white};
  ${(p) => typographyCSS(p.theme.typo.micro)};
`;

const SecondaryDataLink = styled.a`
  display: block;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => p.theme.colors.text.grey};
  ${(p) => typographyCSS(p.theme.typo.body2)};
  transition: color 0.2s;
  position: relative;
`;

const SecondaryItem = styled.span`
  &:before {
    display: inline;
    content: " • ";
  }

  &:first-child {
    &:before {
      all: initial;
    }
  }
`;

const NamePlaceholder = styled.div`
  width: 180px;
  height: 16px;
  background: ${(p) => p.theme.colors.backgrounds.grey};
  border-radius: 4px;
  margin: 2px 0 6px;
`;

const LocationPlaceholder = styled.div`
  width: 124px;
  height: 14px;
  background: ${(p) => p.theme.colors.backgrounds.grey};
  border-radius: 4px;
  margin: 0 0 2px;
`;
