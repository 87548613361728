import { useCallback, useRef, useState } from "react";

interface MouseHandlers {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export function useDelayedMouseEnter() {
  const timeoutRef = useRef<number | null>(null);
  const [expanded, setExpanded] = useState(false);

  const handleMouseEnter = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      setExpanded(true);
    }, 100);
  }, []);

  const handleMouseLeave = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setExpanded(false);
  }, []);

  return [
    expanded,
    {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    },
  ] as [boolean, MouseHandlers];
}
