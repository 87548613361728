import { css, DefaultTheme, TypographyTheme } from "styled-components/macro";
import Color from "color";

const basicPallete = {
  black: Color("#232029"),
  white: Color("#ffffff"),
  grey: Color("#7E7A85"),
  purple: Color("#5D18CE"),
  gold: Color("#F1C93B"),
  pink: Color("#EC69A8"),
  green: Color("#259F56"),
  red: Color("#CA203E"),
};

const highlightsPallete = {
  black: basicPallete.black,
  blue: Color("#3D27C3"),
  green: Color("#259F56"),
  orange: Color("#D95923"),
  red: Color("#CA203E"),
  purple: Color("#5D18CE"),
};

export type HighlightColorKind = keyof typeof highlightsPallete;
export const highlightColors = Object.keys(
  highlightsPallete
) as HighlightColorKind[];

export function normalizeHighlightColor(color: string): HighlightColorKind {
  return highlightColors.includes(color as any)
    ? (color as HighlightColorKind)
    : "black";
}

const servicePallete = {
  selected: basicPallete.grey.alpha(0.15),
  hover: basicPallete.grey.alpha(0.1),
  focus: basicPallete.purple.alpha(0.25),
  overlay: basicPallete.black.alpha(0.3),
  error: basicPallete.red.alpha(0.2),
};

function mapPallete<K extends string, T>(
  pallete: { [key in K]: Color },
  fn: (color: Color) => T
) {
  let res = {} as { [key in K]: T };

  for (let key in pallete) {
    res[key] = fn(pallete[key]);
  }

  return res;
}

function lighten(color: Color, value: number): Color {
  const newColor = color.hsl();
  const hsl = newColor as any as { color: [number, number, number] };
  hsl.color[2] = Math.min(100, hsl.color[2] + value);

  return newColor;
}

export function typographyCSS(typo: TypographyTheme) {
  return css`
    font-size: ${typo.fontSize};
    font-weight: ${typo.fontWeight};
    line-height: ${typo.lineHeight};
  `;
}

export const theme: DefaultTheme = {
  panelHeight: "65px",
  typo: {
    h1: {
      fontSize: "32px",
      fontWeight: "700",
      lineHeight: "40px",
    },
    h2: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "36px",
    },
    h3: {
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "28px",
    },
    h4: {
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "24px",
    },
    body1: {
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
    },
    body2: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
    },
    micro: {
      fontSize: "10px",
      fontWeight: "400",
      lineHeight: "12px",
    },
    highlighted: {
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "24px",
    },
    buttonM: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "18px",
    },
    buttonL: {
      fontSize: "20px",
      fontWeight: "500",
      lineHeight: "24px",
    },
    link: {
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
    },
  },
  layers: {
    page: 1,
    overpage: 100,
    panel: 200,
    overlay: 300,
    menu: 400,
  },
  colors: {
    borders: {
      separator: lighten(basicPallete.grey, 35).hex(),
      border: lighten(basicPallete.grey, 25).hex(),
    },
    backgrounds: {
      grey: lighten(basicPallete.grey, 45).hex(),
      main: basicPallete.white.hex(),
      inverse: basicPallete.black.hex(),
    },
    text: {
      black: basicPallete.black.hex(),
      grey: basicPallete.grey.hex(),
      white: basicPallete.white.hex(),
    },
    ui: {
      purple: basicPallete.purple.hex(),
      greyFilled: lighten(basicPallete.grey, 15).hex(),
      greyOutline: basicPallete.grey.hex(),
      black: basicPallete.black.hex(),
      gold: basicPallete.gold.hex(),
      pink: basicPallete.pink.hex(),
    },
    service: mapPallete(servicePallete, (c) => c.rgb().string()),
    serviceBlended: {
      white: mapPallete(servicePallete, (c) =>
        basicPallete.white.mix(c.alpha(1), c.alpha()).rgb().string()
      ),
    },
    statuses: {
      success: basicPallete.green.hex(),
      error: basicPallete.red.hex(),
    },
    highlights: mapPallete(highlightsPallete, (color) => ({
      fg: color.rgb().string(),
      bg: color.alpha(0.1).rgb().string(),
      bgAlt: color.alpha(0.15).rgb().string(),
      bgHover: color.alpha(0.2).rgb().string(),
    })),
    avatarBg: [...Object.values(highlightsPallete)].map((c) =>
      c.rgb().string()
    ),
  },
};
