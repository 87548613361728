import React, { forwardRef, useCallback, useId, useRef } from "react";
import styled from "styled-components/macro";

import { ReactComponent as CrossIcon } from "../assets/cross.svg";
import { mergeRefs } from "../utils/mergeRefs";
import { UIIcon, UIIconKind } from "./UIIcon/UIIcon";

type InputKind = "filled" | "outline" | "empty";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  clearable?: boolean;
  kind?: InputKind;
  icon?: UIIconKind;
  hasError?: boolean;
  onValueChange?: (
    value: string,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      icon,
      clearable = false,
      kind = "outline",
      hasError = false,
      className,
      onValueChange,
      onChange,
      ...inputProps
    },
    outterRef
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const formId = useId();

    const handleClearClick = useCallback(() => {
      const input = innerRef.current;
      if (onValueChange) {
        onValueChange("");
      } else if (input) {
        input.value = "";
      }
      input?.focus();
    }, [onValueChange]);

    const handleInputChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event);
        onValueChange?.(event.target.value, event);
      },
      [onValueChange, onChange]
    );

    return (
      <Container
        htmlFor={formId}
        $kind={kind}
        className={className}
        $hasError={hasError}
      >
        {icon && (
          <IconContainer>
            <UIIcon name={icon} />
          </IconContainer>
        )}
        <InputElement
          {...inputProps}
          id={formId}
          onChange={handleInputChange}
          ref={mergeRefs([outterRef, innerRef])}
        />
        {clearable && (
          <ClearButton tabIndex={-1} type="button" onClick={handleClearClick}>
            <CrossIcon />
          </ClearButton>
        )}
      </Container>
    );
  }
);

interface ContainerProps {
  $kind?: InputKind;
  $hasError?: boolean;
}

const ClearButton = styled.button`
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: none;
  margin: 0 -5px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;

  &:hover {
    background-color: ${(p) => p.theme.colors.service.hover};
  }

  &:active {
    background-color: ${(p) => p.theme.colors.service.selected};
  }

  & svg {
    display: block;
  }
`;

const Container = styled.label<ContainerProps>`
  cursor: text;
  background-color: ${(p) =>
    p.$kind === "filled"
      ? p.theme.colors.backgrounds.grey
      : p.theme.colors.backgrounds.main};
  border-width: 1px;
  border-style: solid;
  border-color: ${(p) => {
    if (p.$hasError) {
      return p.theme.colors.statuses.error;
    } else {
      switch (p.$kind) {
        case "filled":
          return p.theme.colors.backgrounds.grey;
        case "outline":
          return p.theme.colors.borders.border;
        case "empty":
          return p.theme.colors.backgrounds.main;
      }
    }
  }};
  width: 250px;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  outline: 3px solid transparent;
  transition: background-color 200ms ease, outline 200ms ease, color 200ms ease,
    box-shadow 200ms ease;

  &:hover {
    border-color: ${(p) =>
      p.$hasError
        ? p.theme.colors.statuses.error
        : p.theme.colors.borders.border};
    background: ${(p) => p.theme.colors.backgrounds.main};
    box-shadow: ${(p) => {
      if (p.$hasError) {
        return `0px 0px 0px 3px ${p.theme.colors.service.error}`;
      } else if (p.$kind === "empty") {
        return "none";
      } else {
        return `0px 0px 0px 3px ${p.theme.colors.service.focus}`;
      }
    }};

    ${ClearButton} {
      display: flex;
    }
  }

  &:focus-within {
    border-color: ${(p) =>
      p.$hasError ? p.theme.colors.statuses.error : p.theme.colors.ui.purple};
    background: ${(p) => p.theme.colors.backgrounds.main};
    box-shadow: 0px 0px 0px 3px
      ${(p) =>
        p.$hasError
          ? p.theme.colors.service.error
          : p.theme.colors.service.focus};
  }
`;

const IconContainer = styled.div`
  flex: 0 0 auto;
  color: ${(p) => p.theme.colors.text.grey};
  & svg {
    display: block;
  }
`;

const InputElement = styled.input`
  border: 0 none;
  background: transparent none;
  padding: 0;
  margin: 0;
  display: block;
  flex: 1 1 auto;
  overflow: hidden;
  width: 100%;

  &:placeholder {
    color: ${(p) => p.theme.colors.text.grey};
  }

  &:focus {
    outline: 0 none;
  }

  &:-webkit-autofill,
  &:-internal-autofill-selected {
    &,
    &:focus {
      transition: background-color 1s ease-in-out 5000s;
    }
  }
`;
