import { AuthViewer } from "./types";

const FULL_ONLY: Role[] = ["FULL"];
const EVERYONE: Role[] = ["FULL", "FILLER", "READONLY"];

const ACL: Record<string, readonly Role[]> = {
  "talent.add": ["FULL", "FILLER"],
  "talent.edit": FULL_ONLY,
  "talent.delete": FULL_ONLY,
  "category.add": FULL_ONLY,
  "category.edit": FULL_ONLY,
  "category.delete": FULL_ONLY,
  "category.arrange": FULL_ONLY,
  "work.delete": FULL_ONLY,
  "review.read": EVERYONE,
  "review.add": EVERYONE,
  "review.deleteAny": FULL_ONLY,
  "list.read": EVERYONE,
  "list.add": FULL_ONLY,
  "list.edit": FULL_ONLY,
  "list.delete": FULL_ONLY,
  "list.archive": FULL_ONLY,
};

export type Operation = keyof typeof ACL;
export type Role = "ADMIN" | "FULL" | "FILLER" | "READONLY";

export function isGrantedAll(viewer: AuthViewer, ...operations: Operation[]) {
  return operations.every((operation) => isGranted(viewer, operation));
}

export function isGrantedAny(viewer: AuthViewer, ...operations: Operation[]) {
  return operations.some((operation) => isGranted(viewer, operation));
}

export function isGranted(viewer: AuthViewer, operation: Operation): boolean {
  let accessLevel = viewer.accessLevel as Role;

  if (accessLevel === "ADMIN") {
    return true;
  }

  return ACL[operation].includes(accessLevel);
}
