import {
  Middleware,
  Placement,
  ElementProps,
  FloatingContext,
} from "@floating-ui/react";
import { MutableRefObject, useLayoutEffect, useMemo, useRef } from "react";

type ClickPlacementMiddleware = Middleware & {
  placementRef: MutableRefObject<Placement | null>;
};

export function useClickPlacementMiddleware(): ClickPlacementMiddleware {
  const placementRef = useRef<Placement | null>(null);

  return useMemo(
    () => ({
      name: "clickPlacement",
      fn({ placement }) {
        return { reset: { placement: placementRef.current ?? placement } };
      },
      placementRef,
    }),
    [placementRef]
  );
}

interface UseClickPlacementOptions {
  enabled?: boolean;
  middleware: ClickPlacementMiddleware;
}

export function useClickPlacement(
  context: FloatingContext,
  options: UseClickPlacementOptions
): ElementProps {
  const { open, refs } = context;
  const {
    enabled = true,
    middleware: { placementRef },
  } = options;

  useLayoutEffect(() => {
    if (!open) {
      placementRef.current = null;
      refs.setPositionReference(refs.domReference.current);
    }
  }, [open, placementRef, refs]);

  return useMemo(
    () =>
      enabled
        ? {
            reference: {
              onClick(event) {
                const pointerId: number = (event.nativeEvent as any).pointerId;
                if (pointerId < 0) {
                  return;
                }
                placementRef.current = "bottom";
                refs.setPositionReference({
                  contextElement: refs.domReference.current ?? undefined,
                  getBoundingClientRect() {
                    let x = event.clientX;
                    let y = event.clientY;
                    let width = 0;
                    let height = 0;

                    return {
                      width,
                      height,
                      x,
                      y,
                      top: y,
                      right: x + width,
                      bottom: y + height,
                      left: x,
                    };
                  },
                } as any);
              },
            },
          }
        : {},
    [refs, placementRef, enabled]
  );
}
