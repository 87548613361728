import { useCallback } from "react";
import { CategoryData } from "../../types";
import { ActionButton } from "../ActionButton";
import { Button } from "../Button";
import { useCategoryPicker } from "../CategoryPicker/CategoryPicker";
import { SelectedCategory } from "../CategoryPicker/SelectedCategory";
import { ChipList, NoBreak, Row } from "./ui";

interface CategoriesControlProps {
  categories: CategoryData[];
  onChange: (categories: CategoryData[]) => void;
}

export function CategoriesControl({
  categories,
  onChange,
}: CategoriesControlProps) {
  const handleCategorySelectionDone = useCallback(
    (newCategories: CategoryData[]) => {
      if (newCategories.length > 0) {
        onChange([...categories, ...newCategories]);
      }
    },
    [categories, onChange]
  );

  const [getReferenceProps, categoryPickerElement] = useCategoryPicker({
    selectedCategories: categories,
    onDone: handleCategorySelectionDone,
  });

  const handleCategoryRemove = useCallback(
    (removedCategory: CategoryData) => {
      onChange(
        categories.filter((category) => category.id !== removedCategory.id)
      );
    },
    [categories, onChange]
  );

  const lastIndex = categories.length - 1;

  return (
    <>
      <Row>
        {categories.length > 0 ? (
          <ChipList>
            {categories.map((category, index) =>
              index === lastIndex ? (
                <NoBreak key={category.id}>
                  <SelectedCategory
                    category={category}
                    onClick={handleCategoryRemove}
                  />
                  <ActionButton
                    type="button"
                    icon="plus"
                    {...getReferenceProps()}
                  />
                  {categoryPickerElement}
                </NoBreak>
              ) : (
                <SelectedCategory
                  key={category.id}
                  category={category}
                  onClick={handleCategoryRemove}
                />
              )
            )}
          </ChipList>
        ) : (
          <>
            <Button type="button" {...getReferenceProps()}>
              Select categories
            </Button>
            {categoryPickerElement}
          </>
        )}
      </Row>
    </>
  );
}
