import { ListPreviewType } from "../../types";

const FILTER_LIST_SORT_IDX_MATCH = 1;
const FILTER_LIST_SORT_IDX_BEGIN_LINE = 2;
const FILTER_LIST_SORT_IDX_BEGIN_WORD = 3;
const FILTER_LIST_SORT_IDX_REST = 4;

export function filterLists(
  lists: ListPreviewType[],
  filterInput: string
): ListPreviewType[] {
  if (filterInput.length === 0) {
    return lists;
  }

  const result = [] as {
    list: ListPreviewType;
    sortIdx: number;
  }[];

  lists.forEach((list) => {
    const listTitle = list.title.toLowerCase();
    const occurPosition = listTitle.indexOf(filterInput);
    if (occurPosition < 0) {
      return;
    }

    let sortIdx = FILTER_LIST_SORT_IDX_REST;

    if (filterInput === listTitle) {
      sortIdx = FILTER_LIST_SORT_IDX_MATCH;
    } else if (occurPosition === 0) {
      sortIdx = FILTER_LIST_SORT_IDX_BEGIN_LINE;
    } else if (new RegExp(`\\b${escapeRegExp(filterInput)}`).test(listTitle)) {
      sortIdx = FILTER_LIST_SORT_IDX_BEGIN_WORD;
    }

    result.push({ list, sortIdx });
  });

  result.sort((a, b) => a.sortIdx - b.sortIdx);

  return result.map((res) => res.list);
}

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
