import { format, isSameDay, isSameYear, parseISO } from "date-fns/fp";
import { useCallback, useMemo } from "react";
import styled from "styled-components/macro";
import { ChatMessageKind } from "../../graphql";
import { typographyCSS } from "../../theme";
import { ChatMessageType } from "../../types";
import { ActionButton } from "../ActionButton";
import { UserAvatar } from "../Avatar";

interface ChatMessageProps {
  message: ChatMessageType;
  allMessages: ChatMessageType[];
  setReplyToMessage: (message: ChatMessageType) => void;
}

export function ChatMessage({
  message,
  allMessages,
  setReplyToMessage,
}: ChatMessageProps) {
  const replyToMessage = useMemo(
    () =>
      message.replyTo
        ? allMessages.find((msg) => msg.id === message.replyTo)
        : null,
    [message.replyTo, allMessages]
  );

  const handleReplyClick = useCallback(() => {
    setReplyToMessage(message);
  }, [message, setReplyToMessage]);

  if (message.kind !== ChatMessageKind.User || !message.sender) {
    return null;
  }

  return (
    <Container>
      <AvatarCol>
        <UserAvatar size="s" user={message.sender} />
      </AvatarCol>
      <Main>
        <Header>
          <HeaderContent>
            <Name>{message.sender.name}</Name>
            <SentAt>{format("p", parseISO(message.sentAt))}</SentAt>
          </HeaderContent>
          <Actions>
            <ActionButton title="Reply" icon="backward" onClick={handleReplyClick} />
          </Actions>
        </Header>
        <Content>
          {replyToMessage && (
            <ReplyToMessage>{replyToMessage.text}</ReplyToMessage>
          )}
          <div>{message.text}</div>
        </Content>
      </Main>
    </Container>
  );
}

const Actions = styled.div`
  flex: 0 0 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Container = styled.div`
  display: flex;
  gap: 12px;
  align-items: start;
  padding: 8px 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover ${Actions} {
    flex-basis: auto;
  }
`;

const AvatarCol = styled.div`
  flex: 0 0 auto;
`;

const Main = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Header = styled.div`
  display: flex;
  align-items: start;
  gap: 4px;
`;

const HeaderContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: baseline;
  gap: 4px;
`;

const Name = styled.div`
  flex: 0 1 auto;
  ${(p) => typographyCSS(p.theme.typo.highlighted)};
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SentAt = styled.div`
  flex: 0 0 auto;
  ${(p) => typographyCSS(p.theme.typo.body2)};
  color: ${(p) => p.theme.colors.text.grey};
`;

const Content = styled.div``;

const ReplyToMessage = styled.div`
  margin-bottom: 12px;
  border-left: 4px solid ${(p) => p.theme.colors.borders.separator};
  padding-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
