import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import {
  BackButton,
  useBackButton,
} from "../../../components/Overpage/BackButton";
import { useCreateListMutation } from "../../../graphql";
import { useGeneralLocationState } from "../../../hooks/useGeneralLocationState";
import { FormData, ListForm } from "./ListForm";

export function AddListForm() {
  const handleNavigateBack = useBackButton({
    defaultTo: `/lists`,
  });
  const [locationState] = useGeneralLocationState();
  const navigate = useNavigate();
  const [createList] = useCreateListMutation();

  const handleFormSubmit = (data: FormData) => {
    return createList({
      variables: {
        input: {
          title: data.title,
          client: data.client,
          overview: data.overview,
          positions: data.positions.map((position) => ({
            id: null,
            title: position.title,
            description: position.description,
            skills: position.skills.length > 0 ? position.skills : null,
            count: position.count,
          })),
          team: data.team.map((member) => member.id),
        },
      },
      refetchQueries: ["Lists"],
      onCompleted(data) {
        const listId = data.createList.list.id;
        if (!listId) return;
        const backgroundLocation = locationState?.backgroundLocation;

        navigate(`/lists/${listId}`, {
          state: { backgroundLocation },
          replace: true,
        });
      },
    });
  };

  return (
    <Container>
      <TopPanel>
        <BackButton defaultTo="/talents" />
      </TopPanel>
      <Title>Add new list</Title>
      <Content>
        <ListForm onSubmit={handleFormSubmit} onCancel={handleNavigateBack} />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  margin: 0 auto;
  padding-bottom: 80px;
  box-sizing: border-box;
  max-width: 2678px;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  min-height: 100%;
`;

const TopPanel = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 24px;
`;

const Content = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;
