import styled, { DefaultTheme } from "styled-components/macro";
import { ImageSrc } from "../graphql";
import { AvatarImageData } from "../types";

export type AvatarSize = "xxxs" | "xxs" | "xs" | "s" | "m" | "l";

interface TalentAvatarProps {
  talent: {
    id: string;
    name: string;
    image: AvatarImageData | null;
  };
  className?: string;
  size: AvatarSize;
}

export function TalentAvatar({ size, talent, className }: TalentAvatarProps) {
  const avatarSrc = talent.image && getAvatarSrc(talent.image, size);
  return avatarSrc ? (
    <AvatarImg
      as="img"
      className={className}
      src={avatarSrc.src}
      srcSet={avatarSrc.srcSet}
      alt={talent.name}
      size={size}
    />
  ) : (
    <NameAvatar
      size={size}
      name={talent.name}
      hash={talent.id + talent.name}
      className={className}
    />
  );
}

interface UserAvatarProps {
  user: {
    name: string;
    avatar?: AvatarImageData | null;
  };
  className?: string;
  size: AvatarSize;
}

export function UserAvatar({ size, user, className }: UserAvatarProps) {
  const avatarSrc = user.avatar && getAvatarSrc(user.avatar, size);
  return avatarSrc ? (
    <AvatarImg
      as="img"
      className={className}
      src={avatarSrc.src}
      srcSet={avatarSrc.srcSet}
      alt={user.name}
      size={size}
    />
  ) : (
    <NameAvatar size={size} name={user.name} className={className} />
  );
}

interface NameAvatarProps {
  size: AvatarSize;
  name: string;
  hash?: string;
  className?: string;
}

export function NameAvatar({ size, name, hash, className }: NameAvatarProps) {
  return (
    <AvatarNoImage
      size={size}
      colorIndex={simpleHash(hash ?? name)}
      className={className}
    >
      {getInitials(name)}
    </AvatarNoImage>
  );
}

interface AvatarCircleProps {
  size: AvatarSize;
}

const AvatarCircle = styled.div<AvatarCircleProps>`
  height: ${(p) => getDiameter(p.size)};
  width: ${(p) => getDiameter(p.size)};
  border-radius: 50%;
  display: block;
  border: 0;
  flex-shrink: 0;
`;

const AvatarImg = styled(AvatarCircle)``;

interface AvatarNoImageProps {
  colorIndex: number;
}

export const AvatarNoImage = styled(AvatarCircle)<AvatarNoImageProps>`
  display: flex;
  background-color: ${(p) => getAvatarBgColor(p.colorIndex, p.theme)};
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: ${(p) => getFontSize(p.size)};
  line-height: ${(p) => getLineHeight(p.size)};
`;

export const AvatarPlaceholder = styled(AvatarCircle)`
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.colors.ui.greyOutline};
`;

function getFontSize(size: AvatarSize): string {
  switch (size) {
    case "xxxs":
    case "xxs":
      return "10px";
    case "xs":
    case "s":
      return "16px";
    case "m":
      return "36px";
    case "l":
      return "40px";
  }
}

function getLineHeight(size: AvatarSize): string {
  switch (size) {
    case "xxxs":
    case "xxs":
      return "16px";
    case "xs":
    case "s":
      return "24px";
    case "m":
      return "40px";
    case "l":
      return "48px";
  }
}

function getDiameter(size: AvatarSize): string {
  switch (size) {
    case "xxxs":
      return "20px";
    case "xxs":
      return "32px";
    case "xs":
      return "40px";
    case "s":
      return "48px";
    case "m":
      return "100px";
    case "l":
      return "128px";
  }
}

function getAvatarSrc(image: AvatarImageData, size: AvatarSize): ImageSrc {
  switch (size) {
    case "xxxs":
    case "xxs":
    case "xs":
    case "s":
      return image.avatar48;
    case "m":
    case "l":
      return image.avatar128;
  }
}

function simpleHash(str: string): number {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  return Math.abs(hash);
}

const stopChars = ["(", "["];

function getInitials(name: string): string {
  const words = name
    .split(" ")
    .filter((part) => part.length > 0 && !stopChars.includes(part[0]));

  if (words.length > 1) {
    const firstWordChar = words[0].charAt(0);
    const lastWordChar = words[words.length - 1].charAt(0);

    return `${firstWordChar}${lastWordChar}`.toUpperCase();
  }

  if (words.length === 1 && words[0].length > 0) {
    return words[0].charAt(0).toUpperCase();
  }

  if (name.length > 0) {
    return name.charAt(0).toUpperCase();
  }

  return "";
}

function getAvatarBgColor(index: number, theme: DefaultTheme): string {
  const count = theme.colors.avatarBg.length;

  return theme.colors.avatarBg[index % count];
}
