import { useCallback } from "react";
import { Button } from "../../../components/Button";
import { Dialog } from "../../../components/Dialog";
import { Modal, ModalProps } from "../../../components/Modal";
import { useRemoveListCandidateMutation } from "../../../graphql";

interface DeleteCandidatemodalProps {
  talent: {
    id: string;
    name: string;
  };
  positionId: string;
  listId: string;
  trigger: ModalProps["trigger"];
}

export function DeleteCandidateModal({
  talent,
  positionId,
  listId,
  trigger,
}: DeleteCandidatemodalProps) {
  const [removeListCandidate, { loading }] = useRemoveListCandidateMutation();

  const handleDeleteClick = useCallback(() => {
    return removeListCandidate({
      variables: {
        input: {
          listId: listId,
          positionId: positionId,
          talentId: talent.id,
        },
      },
    });
  }, [removeListCandidate, positionId, listId, talent.id]);

  return (
    <Modal
      trigger={trigger}
      render={({ getContentProps, close }) => (
        <Dialog
          title="Delete candidate"
          content={
            <>
              Are you sure you want to delete <strong>{talent.name}</strong>{" "}
              from this list? It is better to use <strong>Decline</strong>{" "}
              button if the candidate does not meet the conditions.
            </>
          }
          buttons={
            <>
              <Button $kind="text" onClick={close} disabled={loading}>
                Cancel
              </Button>
              <Button
                $kind="contained"
                onClick={handleDeleteClick}
                disabled={loading}
              >
                Delete candidate
              </Button>
            </>
          }
          close={close}
          {...getContentProps()}
        />
      )}
    />
  );
}
