import { useCallback } from "react";
import styled from "styled-components";
import { BaseButton } from "../../../components/Button";

interface OneLineSelectProps {
  values: string[];
  value: string;
  onChange: (value: string) => void;
}

export function OneLineSelect({
  values,
  value: currentValue,
  onChange,
}: OneLineSelectProps) {
  return (
    <Container>
      {values.map((value) => (
        <SelectVariant
          key={value}
          value={value}
          selected={value === currentValue}
          onChange={onChange}
        />
      ))}
    </Container>
  );
}

interface SelectVariantProps {
  value: string;
  selected: boolean;
  onChange: (value: string) => void;
}

function SelectVariant({ value, selected, onChange }: SelectVariantProps) {
  const handleClick = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <VariantButton onClick={handleClick} $selected={selected} type="button">
      {value}
    </VariantButton>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const VariantButton = styled(BaseButton)<{ $selected: boolean }>`
  flex: 1 1 auto;
  border: 1px solid
    ${(p) =>
      p.$selected
        ? p.theme.colors.ui.purple
        : p.theme.colors.borders.separator};
  padding: 11px 0;
  border-radius: 8px;
  cursor: pointer;
  background: ${(p) =>
    p.$selected ? p.theme.colors.highlights.purple.bgAlt : "transparent"};
  color: ${(p) =>
    p.$selected ? p.theme.colors.ui.purple : p.theme.colors.text.black};
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    color 0.15s ease-in-out;
`;
