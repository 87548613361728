import { Control, useWatch } from "react-hook-form";
import { ActionButton } from "../../../components/ActionButton";
import { Button } from "../../../components/Button";
import { Dialog } from "../../../components/Dialog";
import { Modal } from "../../../components/Modal";
import { FormData } from "./ListForm";

interface DeleteButtonProps {
  index: number;
  control: Control<FormData>;
  onConfirm: () => void;
}

export function DeletePositionButton({
  index,
  control,
  onConfirm,
}: DeleteButtonProps) {
  const [title, candidatesCount] = useWatch({
    control,
    name: [`positions.${index}.title`, `positions.${index}.candidatesCount`],
  });

  if (!candidatesCount || candidatesCount === 0) {
    return <ActionButton type="button" icon="trashbin" onClick={onConfirm} />;
  }

  return (
    <Modal
      trigger={(getTriggerProps) => (
        <ActionButton type="button" icon="trashbin" {...getTriggerProps()} />
      )}
      render={({ getContentProps, close }) => (
        <Dialog
          title={`Delete position ${title}`}
          content={
            <>
              Position "{title}" contains <strong>{candidatesCount}</strong>{" "}
              candidates. Are you sure you want to delete this position?
            </>
          }
          buttons={
            <>
              <Button $kind="text" onClick={close}>
                Cancel
              </Button>
              <Button $kind="contained" onClick={onConfirm}>
                Delete position
              </Button>
            </>
          }
          close={close}
          {...getContentProps()}
        />
      )}
    />
  );
}
