import { createContext, useContext, useEffect } from "react";
import styled from "styled-components/macro";

interface OverpageProps {
  children: React.ReactNode;
}

export const BODY_CLASS_OVERLAY_FLAG = "bf_overpage";

const IsInOverpageContext = createContext(false);

export function useIsInOverpage() {
  return useContext(IsInOverpageContext);
}

export function Overpage({ children }: OverpageProps) {
  useEffect(() => {
    document.body.classList.add(BODY_CLASS_OVERLAY_FLAG);

    return () => {
      document.body.classList.remove(BODY_CLASS_OVERLAY_FLAG);
    };
  }, []);

  return (
    <IsInOverpageContext.Provider value={true}>
      <Container>{children}</Container>
    </IsInOverpageContext.Provider>
  );
}

const Container = styled.div`
  position: fixed;
  top: ${(p) => p.theme.panelHeight};
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background-color: white;
  z-index: ${(p) => p.theme.layers.overpage};
`;
