import { To, useHref, useLinkClickHandler } from "react-router-dom";
import { useGeneralLocationState } from "../../hooks/useGeneralLocationState";

interface UseOverpageHandlerProps {
  to: To;
}

export function useOverpageHandler({ to }: UseOverpageHandlerProps) {
  const [locationState, location] = useGeneralLocationState();

  return {
    href: useHref(to),
    onClick: useLinkClickHandler(to, {
      state: {
        backgroundLocation: locationState?.backgroundLocation ?? location,
      },
    }),
  };
}
