import { forwardRef } from "react";
import styled from "styled-components/macro";
import { BaseButton } from "./Button";
import { UIIcon, UIIconKind, UIIconTransform } from "./UIIcon/UIIcon";

export interface ActionButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: UIIconKind;
  iconTransform?: UIIconTransform;
  text?: string;
}

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ icon, iconTransform, text, ...buttonProps }, ref) => (
    <StyledButton
      {...buttonProps}
      ref={ref}
      $hasText={!!text && text.length > 0}
    >
      <UIIcon name={icon} transform={iconTransform} />
      {text}
    </StyledButton>
  )
);

export interface ActionLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  icon: UIIconKind;
  iconTransform?: UIIconTransform;
  text?: string;
}

export const ActionLink = forwardRef<HTMLAnchorElement, ActionLinkProps>(
  ({ icon, iconTransform, text, ...anchorProps }, ref) => (
    <StyledButton
      as="a"
      {...anchorProps}
      ref={ref}
      $hasText={!!text && text.length > 0}
    >
      <UIIcon name={icon} transform={iconTransform} />
      {text}
    </StyledButton>
  )
);

const StyledButton = styled(BaseButton)<{ $hasText: boolean }>`
  text-decoration: none;
  border: 0 none;
  background: none transparent;
  display: flex;
  gap: 4px;
  padding: 4px ${(p) => (p.$hasText ? "6px" : "4px")} 4px 4px;
  border-radius: ${(p) => (p.$hasText ? "8px" : "50%")};
  align-items: center;
`;
