import { FloatingTree } from "@floating-ui/react";
import { useCallback } from "react";
import styled from "styled-components/macro";
import { ActionButton } from "../ActionButton";
import { Skill } from "./Skill";
import { useSkillsPicker } from "./SkillsPicker/SkillsPicker";

interface SkillsListProps {
  skills: string[];
  onChange: (skills: string[]) => void;
  readonly?: boolean;
  skillsPickerReadonly?: boolean;
  addTitle?: string;
}

export function SkillsList(props: SkillsListProps) {
  return (
    <FloatingTree>
      <SkillsListInner {...props} />
    </FloatingTree>
  );
}

export function SkillsListInner({
  skills,
  readonly = false,
  skillsPickerReadonly = false,
  addTitle = "Add tag",
  onChange,
}: SkillsListProps) {
  const handleSkillsSelectionDone = useCallback(
    (newSkills: string[]) => {
      if (newSkills.length > 0) {
        onChange([...skills, ...newSkills]);
      }
    },
    [skills, onChange]
  );

  const [getReferenceProps, skillsPopoverElement] = useSkillsPicker({
    selectedSkills: skills,
    onDone: handleSkillsSelectionDone,
    readonly: skillsPickerReadonly,
  });

  const handleSkillRemove = useCallback(
    (removedSkill: string) => {
      onChange(skills.filter((skill) => skill !== removedSkill));
    },
    [skills, onChange]
  );

  if (skills.length === 0 && readonly) {
    return null;
  }

  const lastIndex = skills.length - 1;

  return (
    <>
      {skills.length > 0 ? (
        <Skills>
          {skills.map((skill, index) =>
            index === lastIndex && !readonly ? (
              <NoBreak key={skill}>
                <Skill
                  name={skill}
                  onRemove={!readonly ? handleSkillRemove : undefined}
                />
                <ActionButton icon="plus" {...getReferenceProps()} />
              </NoBreak>
            ) : (
              <Skill
                key={skill}
                name={skill}
                onRemove={!readonly ? handleSkillRemove : undefined}
              />
            )
          )}
        </Skills>
      ) : (
        <ActionButton icon="plus" text={addTitle} {...getReferenceProps()} />
      )}
      {skillsPopoverElement}
    </>
  );
}

const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  cursor: default;
  position: relative;
`;

const NoBreak = styled.div`
  display: flex;
  align-items: inherit;
  cursor: inherit;
  gap: inherit;
  flex-wrap: nowrap;
`;
