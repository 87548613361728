import {
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  offset,
  ReferenceType,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  UseFloatingReturn,
  useFloatingTree,
  useInteractions,
  useListNavigation,
} from "@floating-ui/react";
import {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useId,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components/macro";
import { typographyCSS } from "../../theme";
import { UIIcon } from "../UIIcon/UIIcon";
import { useStickyTop } from "../../utils/stickyTop";
import { AvailabilityKindType } from "../../types";
import { useAvailabilityKindsQuery } from "../../graphql";
import { filterList } from "../../utils/filterList";
import { useHighlightColorsPicker } from "./HighlightColorsPicker";
import {
  useClickPlacement,
  useClickPlacementMiddleware,
} from "../../utils/floating-ui/clickPlacement";

const MAX_CATEGORY_COUNT = 50;

interface UseAvailabilityPickerOptions {
  enabled?: boolean;
  onSelected: (kind: AvailabilityKindType) => void;
  onClose: () => void;
}

const emptyArray = Array<any>();

export function useAvailabilityPicker({
  enabled = true,
  onSelected,
  onClose,
}: UseAvailabilityPickerOptions) {
  const [opened, setOpened] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const tree = useFloatingTree();
  const stickyTop = useStickyTop();
  const clickPlacement = useClickPlacementMiddleware();

  const listRef = useRef<Array<HTMLElement | null>>([]);

  if (!tree) {
    throw new Error("<FloatingTree /> is missing for CategoriesPopover");
  }

  const kindsQuery = useAvailabilityKindsQuery();

  const handleOpenChange = useCallback(
    (willBeOpened: boolean) => {
      if (!willBeOpened) {
        stickyTop.handleClose();
        onClose();
      }
      setOpened(willBeOpened);
    },
    [stickyTop, onClose]
  );

  const closePicker = useCallback(() => {
    handleOpenChange(false);
  }, [handleOpenChange]);

  const nodeId = useFloatingNodeId();

  const floatingReturn = useFloating({
    nodeId,
    open: opened,
    onOpenChange: handleOpenChange,
    placement: "bottom",
    strategy: "fixed",
    middleware: [
      offset(4),
      shift({ crossAxis: true, padding: 5 }),
      stickyTop.middleware,
      clickPlacement,
    ],
  });

  const interactionsReturn = useInteractions([
    useClick(floatingReturn.context, { enabled }),
    useDismiss(floatingReturn.context, { enabled, bubbles: false }),
    useClickPlacement(floatingReturn.context, {
      enabled,
      middleware: clickPlacement,
    }),
  ]);

  const listInteractionsReturn = useInteractions([
    useListNavigation(floatingReturn.context, {
      enabled,
      listRef,
      onNavigate: opened ? setActiveIndex : undefined,
      activeIndex,
      loop: true,
      allowEscape: false,
      focusItemOnOpen: true,
      virtual: true,
    }),
  ]);

  const getFloatingProps = (props?: React.HTMLProps<HTMLElement>) =>
    interactionsReturn.getFloatingProps(
      listInteractionsReturn.getFloatingProps(props)
    );

  const setReference = useCallback(
    (node: ReferenceType | null) => {
      floatingReturn.refs.setReference(node);
      floatingReturn.refs.setPositionReference(node);
    },
    [floatingReturn.refs]
  );

  const availabilityPickerElement = opened ? (
    <FloatingPortal>
      <FloatingNode id={nodeId}>
        <Overlay lockScroll>
          <AvailabilityKindPicker
            floatingReturn={floatingReturn}
            getFloatingProps={getFloatingProps}
            getInputProps={listInteractionsReturn.getReferenceProps}
            getItemProps={listInteractionsReturn.getItemProps}
            kinds={kindsQuery.data?.availabilityKinds ?? emptyArray}
            canCreate
            listRef={listRef}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            closePicker={closePicker}
            onSelected={onSelected}
          />
        </Overlay>
      </FloatingNode>
    </FloatingPortal>
  ) : null;

  return [
    availabilityPickerElement,
    interactionsReturn.getReferenceProps,
    setReference,
  ] as const;
}

interface AvailabilityKindPickerProps {
  floatingReturn: UseFloatingReturn;
  getFloatingProps: (
    props?: React.HTMLProps<HTMLElement>
  ) => Record<string, unknown>;
  getInputProps: (props?: React.HTMLProps<Element>) => Record<string, unknown>;
  getItemProps: (
    props?: React.HTMLProps<HTMLElement>
  ) => Record<string, unknown>;
  kinds: AvailabilityKindType[];
  canCreate: boolean;
  listRef: MutableRefObject<Array<HTMLElement | null>>;
  activeIndex: number | null;
  setActiveIndex: Dispatch<SetStateAction<number | null>>;
  closePicker: () => void;
  onSelected: (kind: AvailabilityKindType) => void;
}

function AvailabilityKindPicker({
  floatingReturn,
  getFloatingProps,
  getItemProps,
  getInputProps,
  kinds,
  canCreate,
  activeIndex,
  setActiveIndex,
  listRef,
  closePicker,
  onSelected,
}: AvailabilityKindPickerProps) {
  const uniqId = useId();
  const [isInCreatingMode, setCreatingMode] = useState(false);
  const [filterInput, setFilterInput] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const { x, y, refs, strategy, update } = floatingReturn;
  const isInputEmpty = filterInput.length === 0;

  const lowercaseFilter = useMemo(
    () => filterInput.toLowerCase(),
    [filterInput]
  );
  const filteredKinds = useMemo(() => {
    return filterList(kinds, lowercaseFilter, (kind) => kind.name).slice(
      0,
      MAX_CATEGORY_COUNT
    );
  }, [lowercaseFilter, kinds]);
  const isFullMatch = filteredKinds.some(
    (category) => category.name.toLowerCase() === lowercaseFilter
  );

  const focusInput = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length === 0) {
      setCreatingMode(false);
    }
    setFilterInput(inputValue);
  }, []);

  const handleColorSelected = useCallback(
    (color: string) => {
      onSelected({ name: filterInput, color });
      closePicker();
    },
    [filterInput, onSelected, closePicker]
  );

  const [colorsPickerElement, getReferenceProps, setReference] =
    useHighlightColorsPicker({
      enabled: !isInputEmpty,
      nested: true,
      onColorSelected: handleColorSelected,
    });

  const handleEmptyCreateClick = useCallback(() => {
    setCreatingMode(true);
  }, []);

  useLayoutEffect(() => {
    setActiveIndex(0);
  }, [lowercaseFilter, setActiveIndex]);

  useLayoutEffect(() => {
    update();
  }, [filteredKinds, update]);

  const isCreateAvailable = canCreate && !isFullMatch;
  const isCreateActive =
    isCreateAvailable && (!isInCreatingMode || !isInputEmpty);
  const createButtonIndex = isInCreatingMode ? 0 : filteredKinds.length;
  const hasCreateHint = isCreateAvailable && isInCreatingMode && isInputEmpty;

  const createButtonRef = useCallback(
    (node: HTMLElement | null) => {
      setReference(node);
      listRef.current[createButtonIndex] = node;
    },
    [listRef, createButtonIndex, setReference]
  );

  const hasNoMatch =
    filteredKinds.length === 0 && !isInputEmpty && !isCreateActive;
  const inputPlaceholder = canCreate
    ? "Enter a name to search or create..."
    : "Enter a name to search...";

  return (
    <>
      <Container
        ref={refs.setFloating}
        style={{
          position: strategy,
          transform: `translate(${x ?? 0}px, ${y ?? 0}px)`,
        }}
        {...getFloatingProps({
          onFocus(event) {
            if (event.target !== inputRef.current) {
              focusInput();
            }
          },
          onKeyDown(event) {
            if (event.key === "Enter" && activeIndex !== null) {
              listRef.current[activeIndex]?.click();
            }
          },
        })}
        aria-activedescendant={undefined}
      >
        <InputZone onClick={focusInput}>
          <Input
            ref={inputRef}
            value={filterInput}
            onChange={handleInputChange}
            placeholder={inputPlaceholder}
            autoFocus
            {...getInputProps({
              onChange: handleInputChange,
            })}
          />
        </InputZone>
        <MainZone>
          {!isInCreatingMode && (filteredKinds.length > 0 || hasNoMatch) && (
            <KindList>
              {filteredKinds.map((kind, index) => (
                <KindListItem key={index}>
                  <KindListElement
                    id={`${uniqId}-${index}`}
                    ref={(node) => {
                      listRef.current[index] = node;
                    }}
                    $active={activeIndex === index}
                    {...getItemProps({
                      onClick: () => {
                        onSelected(kind);
                        closePicker();
                      },
                    })}
                  >
                    <KindName>{kind.name}</KindName>
                  </KindListElement>
                </KindListItem>
              ))}
              {hasNoMatch && (
                <KindListItem>
                  <InactiveKindListElement>
                    <NoMatchHint>No match found</NoMatchHint>
                  </InactiveKindListElement>
                </KindListItem>
              )}
            </KindList>
          )}
          {isCreateActive && (
            <CreateButton
              id={`${uniqId}-create`}
              ref={createButtonRef}
              $active={activeIndex === createButtonIndex}
              {...getItemProps(
                isInputEmpty
                  ? {
                      onClick: handleEmptyCreateClick,
                    }
                  : getReferenceProps()
              )}
            >
              <UIIcon name="plus" />
              <span>Create</span>
              {!isInputEmpty && (
                <Chip>
                  <KindName>{filterInput}</KindName>
                </Chip>
              )}
            </CreateButton>
          )}
          {hasCreateHint && (
            <CreateHint>💡 Start typing to create a new item</CreateHint>
          )}
        </MainZone>
      </Container>
      {colorsPickerElement}
    </>
  );
}

const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 400px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: ${(p) => p.theme.layers.menu};
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25),
    0px 4px 10px rgba(144, 143, 147, 0.25);
  border-radius: 8px;
  overflow: hidden;

  &:focus {
    outline: 0 none;
  }
`;

const InputZone = styled.div`
  cursor: text;
  position: relative;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;
  padding: 8px;
  min-height: 28px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const Input = styled.input`
  flex: 1 1 60px;
  min-width: 60px;
  padding: 0 4px;
  border: 0 none;
`;

const MainZone = styled.div`
  margin-top: -16px;
  padding-top: 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-top-width: 0;
  border-radius: 8px;
`;

const KindList = styled.ul`
  margin: 0;
  padding: 8px;
  max-height: 200px;
  overflow: auto;
  border-bottom: 1px solid ${(p) => p.theme.colors.borders.separator};
`;

const KindListItem = styled.li`
  position: relative;
  list-style: none;
  padding: 0px;
`;

const KindListElement = styled.button<{ $active: boolean }>`
  background: ${(p) =>
    p.$active ? p.theme.colors.service.selected : "transparent"};
  border: 0 none;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  gap: 8px;

  &:focus {
    outline: 0 none;
  }
`;

const KindName = styled.div`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  cursor: inherit;
`;

const InactiveKindListElement = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 8px 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: default;

  ${KindName} {
    flex: 0 0 auto;
    align-self: baseline;
  }
`;

const Chip = styled.div`
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  padding: 2px 8px;
  box-sizing: border-box;
  max-width: 250px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: default;
`;

const NoMatchHint = styled.span`
  color: ${(p) => p.theme.colors.text.grey};
`;

const CreateButton = styled.button<{ $active: boolean }>`
  cursor: pointer;
  background: ${(p) =>
    p.$active
      ? p.theme.colors.service.selected
      : p.theme.colors.backgrounds.main};
  border: 0 none;
  display: block;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 4px;

  &:focus {
    outline: 0 none;
  }

  & > span {
    margin-right: 4px;
  }
`;

const CreateHint = styled.div`
  padding: 8px 16px;
  ${(p) => typographyCSS(p.theme.typo.body2)};
  color: ${(p) => p.theme.colors.text.grey};
`;

const Overlay = styled(FloatingOverlay)`
  z-index: ${(p) => p.theme.layers.overlay};
`;
