import { useCallback } from "react";
import { Navigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import { isGranted } from "../../../acl";
import { useSetTalentTagsMutation, useTalentQuery } from "../../../graphql";

import { ActionLink } from "../../../components/ActionButton";
import { useViewer } from "../../../components/context/auth_gate";
import { BackButton } from "../../../components/Overpage/BackButton";
import { useOverpageHandler } from "../../../components/Overpage/useOverpageHandler";
import { SkillsList } from "../../../components/skills/SkillsList";

import { Availability } from "./Availability/Availability";
import { DeleteButton } from "./DeleteButton";
import { ReviewsSection } from "./review/ReviewsSection";
import { Sidebar } from "./Sidebar/Sidebar";
import { Actions, Header, Hint, Separator } from "./ui";
import { WorksSection } from "./WorksSection/WorksSection";

const emptyList = Array<any>();

export function TalentPage() {
  const viewer = useViewer();
  const { talentId } = useParams();
  const [setTalentSkills] = useSetTalentTagsMutation();
  const { data, loading, error } = useTalentQuery({
    skip: !talentId,
    variables: {
      id: talentId ?? "",
    },
  });

  const handleSkillsChange = useCallback(
    (nextSkills: string[]) => {
      if (talentId) {
        setTalentSkills({
          variables: {
            input: {
              clientMutationId: null,
              id: talentId,
              tags: nextSkills,
            },
          },
          optimisticResponse() {
            return {
              setCardTags: {
                __typename: "SetCardTagsPayload",
                card: {
                  __typename: "Card",
                  id: talentId,
                  skills: nextSkills,
                },
              },
            };
          },
        });
      }
    },
    [setTalentSkills, talentId]
  );

  const editTalentHandler = useOverpageHandler({
    to: `/talents/${talentId}/edit`,
  });

  if (!talentId) {
    return <Navigate to="/talents" replace />;
  }

  if (error && error.message === "Not permitted") {
    return <Navigate to="/talents" replace />;
  }

  const talent = data?.talent;
  const skills = talent?.skills ?? emptyList;
  const canEdit = isGranted(viewer, "talent.edit");
  const canViewReviews = isGranted(viewer, "review.read");

  return (
    <Container>
      <TopPanel>
        <BackButton defaultTo="/talents" />
        {talent && (
          <Actions>
            {canEdit && (
              <ActionLink {...editTalentHandler} icon="pencil" text="Edit" />
            )}
            {isGranted(viewer, "talent.delete") && (
              <DeleteButton talent={talent} />
            )}
          </Actions>
        )}
      </TopPanel>
      <Content>
        <Sidebar loading={loading} talent={talent} />
        <MainSection>
          <WorksSection talent={talent} loading={loading} editable={canEdit} />
          {talent?.about && (
            <>
              <Separator />
              <Header>Bio</Header>
              <Summary>{talent.about}</Summary>
            </>
          )}
          {(!loading || skills.length > 0) && (
            <>
              <Separator />
              <Header>Tags</Header>
              <SkillsList
                skills={skills}
                readonly={!canEdit}
                onChange={handleSkillsChange}
              />
              {!loading && skills.length === 0 && (
                <Hint>Add tags for quick and efficient search</Hint>
              )}
            </>
          )}
          <Separator />
          <Header>Availability</Header>
          {talent && <Availability talent={talent} />}
          {talent && canViewReviews && (
            <>
              <Separator />
              <Header>Reviews</Header>
              <ReviewsSection talent={talent} />
            </>
          )}
        </MainSection>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 2678px;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  padding-bottom: 80px;
`;

const TopPanel = styled.div`
  height: 56px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: 1384px;
  padding: 0 24px;
  display: flex;
  align-items: flex-start;
`;

const MainSection = styled.div`
  flex: 1 1 auto;
  margin-left: 48px;
  min-width: 0;
`;

const Summary = styled.div`
  white-space: pre-wrap;
  margin-bottom: 8px;
`;
