import { forwardRef } from "react";
import styled from "styled-components/macro";

import { UIIconKind, UIIcons } from "./images/all";

export type { UIIconKind };

export type UIIconTransform =
  | "flipH"
  | "flipV"
  | "rotateLeft"
  | "rotateRight"
  | "rotateSemi";

interface UIIconProps {
  name: UIIconKind;
  transform?: UIIconTransform;
  className?: string;
}

export const UIIcon = forwardRef<SVGSVGElement, UIIconProps>(
  ({ className, name, transform }, ref) => {
    return (
      <SvgIcon
        as={UIIcons[name]}
        className={className}
        ref={ref}
        $transform={transform}
      />
    );
  }
);

const SvgIcon = styled.svg<{ $transform?: UIIconTransform }>`
  display: block;
  flex: 0 0 auto;
  ${(p) => transform(p.$transform)};
`;

function transform(iconTransform: UIIconTransform | undefined) {
  if (!iconTransform) return "";

  let transform;
  switch (iconTransform) {
    case "flipH":
      transform = "rotateY(180deg)";
      break;
    case "flipV":
      transform = "rotateX(180deg)";
      break;
    case "rotateSemi":
      transform = "rotate(180deg)";
      break;
    case "rotateLeft":
      transform = "rotate(-90deg)";
      break;
    case "rotateRight":
      transform = "rotate(90deg)";
      break;
  }

  return `transform: ${transform}`;
}
