import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components/macro";
import * as yup from "yup";
import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";
import { useInitAccountAsOrgMutation } from "../../../graphql";

const SUBMISSION = "__SUBMISSION__";

export interface FormData {
  name: string;
  emails: { value: string }[];
  [SUBMISSION]: void;
}

const schema = yup
  .object({
    name: yup.string().required("Fill name"),
    emails: yup.array().of(
      yup.object({
        value: yup.string().email("Incorrect email"),
      })
    ),
  })
  .required();

interface OrgSetupStageProps {
  onComplete: () => void;
}

export function OrgSetupStage({ onComplete }: OrgSetupStageProps) {
  const {
    getValues,
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting, touchedFields },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      emails: [{ value: "" }],
    },
  });
  const {
    fields: contactFields,
    append: appendContact,
    remove: removeContact,
  } = useFieldArray<FormData>({
    control,
    name: "emails",
  });

  const [initAccountAsOrg] = useInitAccountAsOrgMutation();

  const handleFormSubmit = async (data: FormData) => {
    clearErrors(SUBMISSION);
    return initAccountAsOrg({
      variables: {
        input: {
          name: data.name,
          invitationEmails: data.emails
            .map(({ value }) => value)
            .filter((value) => !!value),
        },
      },
      onCompleted() {
        onComplete();
      },
      onError() {
        setError(SUBMISSION, {
          type: "submission",
          message: "Something went wrong. Try again later.",
        });
      },
    });
  };

  return (
    <Container>
      <h1>Setup organization</h1>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <InputRow>
          <Input
            icon="buildings"
            placeholder="Company name"
            hasError={!!errors.name}
            {...register("name")}
          />
          {errors.name && <ErrorMsg>{errors.name.message}</ErrorMsg>}
        </InputRow>
        <h4>Send invitations to your colleagues</h4>
        {contactFields.map((field, emailIndex) => (
          <InputRow key={field.id}>
            <Controller
              name={`emails.${emailIndex}.value`}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  icon="mail"
                  placeholder="Email address"
                  onChange={(e) => {
                    field.onChange(e);
                    const value = e.target.value;
                    const formValues = getValues();
                    const isLast = emailIndex === formValues.emails.length - 1;
                    if (isLast && value.length > 0) {
                      appendContact({ value: "" }, { shouldFocus: false });
                    }
                  }}
                  onBlur={(e) => {
                    field.onBlur();
                    const value = e.target.value;
                    const formValues = getValues();
                    const isLast = emailIndex === formValues.emails.length - 1;
                    if (!isLast && value.length === 0) {
                      removeContact(emailIndex);
                    }
                  }}
                />
              )}
            />
            {touchedFields.emails?.[emailIndex]?.value &&
              errors.emails?.[emailIndex]?.value?.message && (
                <ErrorMsg>
                  {errors.emails[emailIndex]?.value?.message ??
                    "Incorrect value"}
                </ErrorMsg>
              )}
          </InputRow>
        ))}
        <Button $kind="contained" $size="l" $fullWidth disabled={isSubmitting}>
          Continue
        </Button>
        {errors[SUBMISSION] && (
          <ErrorMsg>{errors[SUBMISSION].message}</ErrorMsg>
        )}
      </form>
    </Container>
  );
}

const Container = styled.div`
  width: 400px;
  text-align: center;

  h1 {
    margin-bottom: 24px;
  }

  h4 {
    text-align: left;
    margin-bottom: 8px;
  }
`;

const InputRow = styled.div`
  margin-bottom: 16px;

  label {
    width: 100%;
  }
`;

const ErrorMsg = styled.div`
  margin-top: 4px;
  text-align: center;
  color: ${(p) => p.theme.colors.statuses.error};
`;
