import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import styled from "styled-components/macro";
import * as yup from "yup";
import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";

const SUBMISSION = "__SUBMISSION__";

export interface FormData {
  name: string;
  password: string;
  [SUBMISSION]: void;
}

const schema = yup
  .object({
    name: yup.string().required("Fill name"),
    password: yup.string().required("Fill password"),
  })
  .required();

interface EmailStageProps {
  email: string;
  onComplete: () => void;
}

export function PersonalInfoStage({ email, onComplete }: EmailStageProps) {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      password: "",
    },
  });

  const handleFormSubmit = async (data: FormData) => {
    clearErrors(SUBMISSION);
    const requestParams = new URLSearchParams();
    requestParams.set("email", email);
    requestParams.set("name", data.name);
    requestParams.set("password", data.password);

    try {
      const body = await fetch("/api/signup/", {
        credentials: "same-origin",
        method: "POST",
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: requestParams.toString(),
      });
      const res = await body.json();

      if (res.ok) {
        onComplete();
        return;
      }
    } catch (err) {}

    setError(SUBMISSION, {
      type: "submission",
      message: "Something went wrong. Try again later.",
    });
  };

  return (
    <Container>
      <h1>Welcome to Baselance</h1>
      <Description>Finish registration to start using Baselance!</Description>
      <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
        <InputRow>
          <Input
            icon="user"
            placeholder="Name"
            hasError={!!errors.name}
            {...register("name")}
          />
          {errors.name && <ErrorMsg>{errors.name.message}</ErrorMsg>}
        </InputRow>
        <InputRow>
          <Input
            icon="lock"
            type="password"
            placeholder="Password"
            hasError={!!errors.password}
            {...register("password")}
          />
          {errors.password && <ErrorMsg>{errors.password.message}</ErrorMsg>}
        </InputRow>
        <Button $kind="contained" $size="l" $fullWidth disabled={isSubmitting}>
          Sign up with email
        </Button>
        {errors[SUBMISSION] && (
          <ErrorMsg>{errors[SUBMISSION].message}</ErrorMsg>
        )}
      </form>
    </Container>
  );
}

const Container = styled.div`
  max-width: 400px;
  text-align: center;
`;

const Description = styled.div`
  margin: 8px 0 24px;
`;

const InputRow = styled.div`
  margin-bottom: 16px;

  label {
    width: 100%;
  }
`;

const ErrorMsg = styled.div`
  margin-top: 4px;
  text-align: center;
  color: ${(p) => p.theme.colors.statuses.error};
`;
