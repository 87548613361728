import { forwardRef, SelectHTMLAttributes } from "react";
import styled from "styled-components/macro";
import { UIIcon } from "./UIIcon/UIIcon";

export const Select = forwardRef<
  HTMLSelectElement,
  SelectHTMLAttributes<HTMLSelectElement>
>((props, ref) => {
  return (
    <Container>
      <select ref={ref} {...props} />
      <UIIcon name="smalldropdown" />
    </Container>
  );
});

const Container = styled.div`
  position: relative;
  width: 100%;
  min-width: 15ch;
  cursor: pointer;

  select {
    appearance: none;
    box-sizing: border-box;
    background-color: ${(p) => p.theme.colors.backgrounds.main};
    border: 1px solid ${(p) => p.theme.colors.borders.border};
    border-radius: 8px;
    width: 100%;
    height: 48px;
    padding: 0 28px 0 12px;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;

    &:invalid {
      color: ${(p) => p.theme.colors.text.grey};
    }

    option {
      color: ${(p) => p.theme.colors.text.black};
    }
  }

  svg {
    color: ${(p) => p.theme.colors.text.grey};
    position: absolute;
    top: 19px;
    right: 8px;
  }
`;
