import { useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";
import AddContactImgPath from "./assets/add-contact.png";
import AddContactImg2xPath from "./assets/add-contact@2x.png";
import AddContactImg3xPath from "./assets/add-contact@3x.png";
import EmptyImgPath from "./assets/empty.png";
import EmptyImg2xPath from "./assets/empty@2x.png";
import EmptyImg3xPath from "./assets/empty@3x.png";

export function EmptyList() {
  const [searchParams] = useSearchParams();
  const isFiltering =
    searchParams.has("q") ||
    searchParams.has("fav") ||
    searchParams.has("tags");

  if (isFiltering) {
    return (
      <Container>
        <h2>Oh, there is no results matching your criteria</h2>
        <EmptyImgContainer>
          <img
            alt=""
            src={EmptyImgPath}
            srcSet={`${EmptyImg2xPath} 2x, ${EmptyImg3xPath} 3x`}
          />
        </EmptyImgContainer>
      </Container>
    );
  }

  return (
    <EmptyContainer>
      <h2>Your talent list is empty</h2>
      <p>
        To add new talent, just click the &quot;Add talent&quot; button in the
        upper right corner.
      </p>
      <AddContactImgContainer>
        <img
          alt=""
          src={AddContactImgPath}
          srcSet={`${AddContactImg2xPath} 2x, ${AddContactImg3xPath} 3x`}
        />
      </AddContactImgContainer>
    </EmptyContainer>
  );
}

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmptyContainer = styled.div`
  position: relative;
  margin: 40px 0 0 40px;
  padding-right: 420px;
  min-height: 450px;
`;

const AddContactImgContainer = styled.div`
  position: absolute;
  top: 0;
  right: 120px;
`;

const EmptyImgContainer = styled.div`
  margin-top: 1.25rem;
`;
