import {
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  UseFloatingReturn,
  useFloatingTree,
  useInteractions,
} from "@floating-ui/react";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components/macro";
import { useSkillsQuery } from "../../../graphql";
import { typographyCSS } from "../../../theme";
import { stickyTop } from "../../../utils/stickyTop";
import { SelectedSkill, Skill } from "../Skill";
import { UIIcon } from "../../UIIcon/UIIcon";
import { filterSkills } from "./filterSkills";

const MAX_SKILLS_COUNT = 30;

interface UseSkillsPickerOptions {
  readonly?: boolean;
  selectedSkills?: string[];
  onDone: (skills: string[]) => void;
}

const emptyArray = Array<any>();

export function useSkillsPicker({
  selectedSkills,
  onDone,
  readonly = false,
}: UseSkillsPickerOptions) {
  const [opened, setOpened] = useState(false);
  const [pickedSkills, setPickedSkills] = useState<string[]>([]);
  const topMemoRef = useRef<number | null>(null);
  const tree = useFloatingTree();

  if (!tree) {
    throw new Error("<FloatingTree /> is missing for SkillsPopover");
  }

  const skillsQuery = useSkillsQuery({
    fetchPolicy: "cache-and-network",
  });

  const allSkills = useMemo(
    () =>
      skillsQuery.data?.skills.map((s) => s.name) ?? (emptyArray as string[]),
    [skillsQuery.data]
  );

  const close = useCallback(() => {
    topMemoRef.current = null;
    onDone(pickedSkills);
    setPickedSkills([]);
    setOpened(false);
  }, [pickedSkills, onDone]);

  const handleOpenChange = useCallback(
    (willBeOpened: boolean) => {
      if (willBeOpened) {
        setOpened(true);
      } else {
        close();
      }
    },
    [close]
  );
  const nodeId = useFloatingNodeId();

  const floatingReturn = useFloating({
    nodeId,
    open: opened,
    onOpenChange: handleOpenChange,
    placement: "bottom",
    strategy: "fixed",
    middleware: [
      offset(4),
      shift({ crossAxis: true, padding: 5 }),
      stickyTop({ topMemoRef }),
    ],
  });

  const interactionsReturn = useInteractions([
    useClick(floatingReturn.context),
    useDismiss(floatingReturn.context, { bubbles: false }),
  ]);

  const skillsPopoverElement = (
    <FloatingPortal>
      {opened ? (
        <FloatingNode id={nodeId}>
          <Overlay lockScroll>
            <SkillsPicker
              floatingReturn={floatingReturn}
              interactionsReturn={interactionsReturn}
              selectedSkills={selectedSkills ?? emptyArray}
              pickedSkills={pickedSkills}
              setPickedSkills={setPickedSkills}
              allSkills={allSkills}
              closePicker={close}
              readonly={readonly}
            />
          </Overlay>
        </FloatingNode>
      ) : null}
    </FloatingPortal>
  );
  const getReferenceProps = useCallback(
    (props?: React.HTMLProps<Element>) =>
      interactionsReturn.getReferenceProps.call(null, {
        ...props,
        ref: floatingReturn.reference,
      }),
    [interactionsReturn.getReferenceProps, floatingReturn.reference]
  );

  return [getReferenceProps, skillsPopoverElement] as [
    typeof getReferenceProps,
    typeof skillsPopoverElement
  ];
}

type VirtualFocusType =
  | { kind: "NONE" }
  | { kind: "CREATE" }
  | { kind: "ITEM"; index: number };

const VirtualFocus = {
  NoFocus: { kind: "NONE" } as VirtualFocusType,
  Create: { kind: "CREATE" } as VirtualFocusType,
  Item(index: number): VirtualFocusType {
    return { kind: "ITEM", index };
  },
};

interface SkillsPickerProps {
  floatingReturn: UseFloatingReturn;
  interactionsReturn: ReturnType<typeof useInteractions>;
  allSkills: string[];
  selectedSkills: string[];
  pickedSkills: string[];
  setPickedSkills: Dispatch<SetStateAction<string[]>>;
  closePicker: () => void;
  readonly: boolean;
}

function SkillsPicker({
  floatingReturn,
  interactionsReturn,
  allSkills,
  selectedSkills,
  pickedSkills,
  setPickedSkills,
  closePicker,
  readonly,
}: SkillsPickerProps) {
  const [isInCreatingMode, setCreatingMode] = useState(false);
  const [virtualFocus, setVirtualFocus] = useState(VirtualFocus.NoFocus);
  const [filterInput, setFilterInput] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const skillsListRef = useRef([] as (HTMLElement | null)[]);
  const { x, y, floating, strategy, update } = floatingReturn;
  const { getFloatingProps } = interactionsReturn;

  const filterString = useMemo(() => filterInput.toLowerCase(), [filterInput]);
  const skills = useMemo(() => {
    const filteredSkills = allSkills.filter(
      (skill) =>
        pickedSkills.every((pickedSkill) => pickedSkill !== skill) &&
        selectedSkills.every((selectedSkill) => selectedSkill !== skill)
    );
    return filterSkills(filteredSkills, filterString).slice(
      0,
      MAX_SKILLS_COUNT
    );
  }, [filterString, pickedSkills, selectedSkills, allSkills]);
  const matchedSelectedSkill =
    selectedSkills.find((skill) => skill === filterString) ||
    pickedSkills.find((skill) => skill === filterString);
  const isFullMatch =
    skills.some((skill) => skill === filterString) || !!matchedSelectedSkill;

  const focusInput = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const setVirtualFocusAndScroll = useCallback(
    (virtualFocus: VirtualFocusType) => {
      setVirtualFocus(virtualFocus);
      const element =
        virtualFocus.kind === "ITEM"
          ? skillsListRef.current[virtualFocus.index]
          : null;
      if (element) {
        element.scrollIntoView({
          block: "center",
          inline: "nearest",
        });
      }
    },
    []
  );

  const reset = useCallback(() => {
    setFilterInput("");
    setVirtualFocus(VirtualFocus.NoFocus);
    focusInput();
  }, [focusInput]);

  const unselectSkill = useCallback(
    (unselectedSkill: string) => {
      setPickedSkills((skills) =>
        skills.filter((skill) => skill !== unselectedSkill)
      );
      focusInput();
    },
    [setPickedSkills, focusInput]
  );

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFilterInput(e.target.value);
  }, []);

  const handleCreateClick = useCallback(() => {
    if (filterString.length > 0) {
      setPickedSkills((skills) => [...skills, filterString]);
      reset();
    } else {
      setCreatingMode(true);
    }
  }, [setPickedSkills, filterString, reset]);

  const handleCreateMouseMove = useCallback(() => {
    setVirtualFocus(VirtualFocus.Create);
  }, []);

  const isCreateAvailable = !readonly && !isFullMatch;
  const isCreateActive =
    isCreateAvailable &&
    (filterString.length > 0 || skills.length > 0) &&
    !isInCreatingMode;

  useLayoutEffect(() => {
    setCreatingMode(false);

    if (filterInput.length === 0) {
      setVirtualFocusAndScroll(VirtualFocus.NoFocus);
    } else if (skills.length === 0) {
      if (isCreateAvailable) {
        setVirtualFocusAndScroll(VirtualFocus.Create);
      } else {
        setVirtualFocusAndScroll(VirtualFocus.NoFocus);
      }
    } else {
      setVirtualFocusAndScroll(VirtualFocus.Item(0));
    }
  }, [filterInput, skills.length, setVirtualFocusAndScroll, isCreateAvailable]);

  useLayoutEffect(() => {
    update();
  }, [pickedSkills, skills, update]);

  const hasAlreadyUsed = matchedSelectedSkill && skills.length === 0;
  const hasCreateHint = isCreateAvailable && !isCreateActive;
  const hasNoMatch =
    skills.length === 0 &&
    filterInput.length > 0 &&
    !matchedSelectedSkill &&
    !isCreateActive;
  const inputPlaceholder = !readonly
    ? "Enter a tag to search or create..."
    : "Enter a tag to search...";

  return (
    <>
      <Container
        ref={floating}
        style={{
          position: strategy,
          transform: `translate(${x ?? 0}px, ${y ?? 0}px)`,
        }}
        {...getFloatingProps({
          onFocus(event) {
            if (event.target !== inputRef.current) {
              focusInput();
            }
          },
          onKeyDown(event) {
            switch (event.key) {
              case "Enter":
                if (virtualFocus.kind === "NONE") {
                  if (filterInput.length === 0) {
                    closePicker();
                  }
                } else if (virtualFocus.kind === "CREATE") {
                  handleCreateClick();
                } else if (skills[virtualFocus.index]) {
                  setPickedSkills((selectedSkills) => [
                    ...selectedSkills,
                    skills[virtualFocus.index],
                  ]);
                  reset();
                }
                break;
              case "ArrowDown":
                event.preventDefault();
                if (virtualFocus.kind !== "ITEM") {
                  if (skills.length > 0) {
                    setVirtualFocusAndScroll(VirtualFocus.Item(0));
                  } else if (isCreateActive) {
                    setVirtualFocus(VirtualFocus.Create);
                  }
                  return;
                } else if (virtualFocus.index === skills.length - 1) {
                  if (isCreateActive) {
                    setVirtualFocus(VirtualFocus.Create);
                  } else {
                    setVirtualFocusAndScroll(VirtualFocus.Item(0));
                  }
                } else {
                  setVirtualFocusAndScroll(
                    VirtualFocus.Item(virtualFocus.index + 1)
                  );
                }
                break;
              case "ArrowUp":
                event.preventDefault();
                if (virtualFocus.kind === "CREATE") {
                  if (skills.length > 0) {
                    setVirtualFocusAndScroll(
                      VirtualFocus.Item(skills.length - 1)
                    );
                  }
                } else if (
                  virtualFocus.kind === "NONE" ||
                  virtualFocus.index === 0
                ) {
                  if (isCreateActive) {
                    setVirtualFocus(VirtualFocus.Create);
                  } else {
                    setVirtualFocusAndScroll(
                      VirtualFocus.Item(skills.length - 1)
                    );
                  }
                } else {
                  setVirtualFocusAndScroll(
                    VirtualFocus.Item(virtualFocus.index - 1)
                  );
                }
                break;
              case "Backspace":
                if (filterString.length === 0 && pickedSkills.length > 0) {
                  setPickedSkills((skills) => skills.slice(0, -1));
                }
                break;
            }
          },
        })}
      >
        <InputZone onClick={focusInput}>
          {pickedSkills.map((skill) => (
            <SelectedSkill key={skill} name={skill} onRemove={unselectSkill} />
          ))}
          <Input
            ref={inputRef}
            value={filterInput}
            onChange={handleInputChange}
            placeholder={
              pickedSkills.length === 0 ? inputPlaceholder : undefined
            }
            autoFocus
          />
        </InputZone>
        <MainZone>
          {!isInCreatingMode &&
            (skills.length > 0 || hasAlreadyUsed || hasNoMatch) && (
              <SkillsList>
                {hasAlreadyUsed && (
                  <SkillsListItem>
                    <InactiveSkillListElement>
                      <Skill name={matchedSelectedSkill} />
                      <AlreadyUsedSkillHint>already used</AlreadyUsedSkillHint>
                    </InactiveSkillListElement>
                  </SkillsListItem>
                )}
                {skills.map((skill, index) => (
                  <SkillsListItem key={skill}>
                    <SkillsListElement
                      $active={
                        virtualFocus.kind === "ITEM" &&
                        virtualFocus.index === index
                      }
                      ref={(node) => {
                        skillsListRef.current[index] = node;
                      }}
                      onMouseMove={() =>
                        setVirtualFocus(VirtualFocus.Item(index))
                      }
                      onClick={() => {
                        setPickedSkills((selectedSkills) => [
                          ...selectedSkills,
                          skill,
                        ]);
                        reset();
                      }}
                    >
                      <Skill name={skill} />
                    </SkillsListElement>
                  </SkillsListItem>
                ))}
                {hasNoMatch && (
                  <SkillsListItem>
                    <InactiveSkillListElement>
                      <NoMatchHint>No match found</NoMatchHint>
                    </InactiveSkillListElement>
                  </SkillsListItem>
                )}
              </SkillsList>
            )}
          {isCreateActive && (
            <CreateButton
              $active={virtualFocus.kind === "CREATE"}
              onMouseMove={handleCreateMouseMove}
              onClick={handleCreateClick}
            >
              <UIIcon name="plus" />
              <span>Create</span>
              {filterString.length > 0 && <Skill name={filterString} />}
            </CreateButton>
          )}
          {hasCreateHint && (
            <CreateHint>💡 Start typing to create a new tag</CreateHint>
          )}
        </MainZone>
      </Container>
    </>
  );
}

const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 400px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: ${(p) => p.theme.layers.menu};
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25),
    0px 4px 10px rgba(144, 143, 147, 0.25);
  border-radius: 8px;
  overflow: hidden;

  &:focus {
    outline: 0 none;
  }
`;

const InputZone = styled.div`
  cursor: text;
  position: relative;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;
  padding: 8px;
  min-height: 28px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const Input = styled.input`
  flex: 1 1 60px;
  min-width: 60px;
  padding: 0 4px;
  border: 0 none;
`;

const MainZone = styled.div`
  margin-top: -16px;
  padding-top: 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-top-width: 0;
  border-radius: 8px;
`;

const SkillsList = styled.ul`
  margin: 0;
  padding: 8px;
  max-height: 220px;
  overflow: auto;
  border-bottom: 1px solid ${(p) => p.theme.colors.borders.separator};
`;

const SkillsListItem = styled.li`
  position: relative;
  list-style: none;
  padding: 0px;
`;

const SkillsListElement = styled.button<{ $active: boolean }>`
  background: ${(p) =>
    p.$active ? p.theme.colors.service.selected : "transparent"};
  border: 0 none;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 6px;
  display: flex;
  cursor: pointer;
  border-radius: 8px;

  &:focus {
    outline: 0 none;
  }
`;

const InactiveSkillListElement = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 8px 6px;
  display: flex;
  align-items: baseline;
  gap: 8px;
  cursor: default;
`;

const AlreadyUsedSkillHint = styled.span`
  color: ${(p) => p.theme.colors.text.grey};
  ${(p) => typographyCSS(p.theme.typo.body2)};
`;

const NoMatchHint = styled.span`
  color: ${(p) => p.theme.colors.text.grey};
`;

const CreateButton = styled.button<{ $active: boolean }>`
  cursor: pointer;
  background: ${(p) =>
    p.$active
      ? p.theme.colors.service.selected
      : p.theme.colors.backgrounds.main};
  border: 0 none;
  display: block;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 4px;

  &:focus {
    outline: 0 none;
  }

  & > span {
    margin-right: 4px;
  }
`;

const CreateHint = styled.div`
  padding: 8px 16px;
  ${(p) => typographyCSS(p.theme.typo.body2)};
  color: ${(p) => p.theme.colors.text.grey};
`;

const Overlay = styled(FloatingOverlay)`
  z-index: ${(p) => p.theme.layers.overlay};
`;
