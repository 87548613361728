import styled from "styled-components/macro";
import { BackButton } from "../../components/Overpage/BackButton";
import { useCategoriesListQuery } from "../../graphql";
import { PageContent } from "./PageContent";

export function CategoryManagementPage() {
  const { data } = useCategoriesListQuery();
  const categories = data?.categories ?? null;

  return (
    <Container>
      <TopPanel>
        <BackButton defaultTo="/talents" />
      </TopPanel>
      <Title>Manage categories</Title>
      {categories !== null && <PageContent categories={categories} />}
    </Container>
  );
}

const Container = styled.div`
  margin: 0 auto;
  padding-bottom: 80px;
  box-sizing: border-box;
  max-width: 2678px;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  min-height: 100%;
`;

const TopPanel = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 24px;
`;
