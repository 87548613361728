import {
  offset,
  useFloating,
  shift,
  useInteractions,
  useDismiss,
  useListNavigation,
  UseFloatingReturn,
  FloatingPortal,
  useFloatingNodeId,
  FloatingNode,
  FloatingFocusManager,
  FloatingOverlay,
} from "@floating-ui/react";
import {
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components/macro";
import { CandidateStatus } from "../../graphql";
import { PositionPreviewType } from "../../types";
import { UIIcon } from "../UIIcon/UIIcon";

interface UsePositionMenuOptions {
  targetTalentId: string;
  listPositions: PositionPreviewType[] | null;
  onClose: () => void;
  onSelected: (positionId: string) => void;
}

export function usePositionsMenu({
  targetTalentId,
  listPositions,
  onClose,
  onSelected,
}: UsePositionMenuOptions) {
  const isOpened = !!listPositions;
  const listRef = useRef([] as (HTMLElement | null)[]);
  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  const handleOpenChange = useCallback(
    (willBeOpened: boolean) => {
      if (!willBeOpened) {
        onClose();
      }
    },
    [onClose]
  );

  const nodeId = useFloatingNodeId();

  const floatingReturn = useFloating({
    nodeId,
    open: isOpened,
    onOpenChange: handleOpenChange,
    placement: "right-start",
    strategy: "fixed",
    middleware: [offset(2), shift({ crossAxis: true, padding: 5 })],
  });

  const interactionsReturn = useInteractions([
    useDismiss(floatingReturn.context, { bubbles: false }),
    useListNavigation(floatingReturn.context, {
      nested: true,
      listRef,
      activeIndex,
      onNavigate: setActiveIndex,
      loop: true,
    }),
  ]);

  useLayoutEffect(() => {
    setActiveIndex(null);
  }, [listPositions]);

  const positionsMenuElement = (
    <FloatingNode id={nodeId}>
      <FloatingPortal>
        {isOpened ? (
          <Overlay lockScroll>
            <FloatingFocusManager context={floatingReturn.context}>
              <PositionsMenu
                targetTalentId={targetTalentId}
                floatingReturn={floatingReturn}
                listPositions={listPositions}
                listRef={listRef}
                interactionsReturn={interactionsReturn}
                onSelected={onSelected}
              />
            </FloatingFocusManager>
          </Overlay>
        ) : null}
      </FloatingPortal>
    </FloatingNode>
  );

  const getReferenceProps = useCallback(
    (props?: React.HTMLProps<Element>) =>
      interactionsReturn.getReferenceProps.call(null, {
        ...props,
        ref: floatingReturn.reference,
      }),
    [interactionsReturn.getReferenceProps, floatingReturn.reference]
  );

  return [getReferenceProps, positionsMenuElement] as [
    typeof getReferenceProps,
    typeof positionsMenuElement
  ];
}

interface PostionsMenuProps {
  targetTalentId: string;
  floatingReturn: UseFloatingReturn;
  interactionsReturn: ReturnType<typeof useInteractions>;
  listPositions: PositionPreviewType[];
  listRef: MutableRefObject<(HTMLElement | null)[]>;
  onSelected: (positionId: string) => void;
}

function PositionsMenu({
  targetTalentId,
  floatingReturn,
  interactionsReturn,
  listPositions,
  listRef,
  onSelected,
}: PostionsMenuProps) {
  const { x, y, floating, strategy } = floatingReturn;
  const { getFloatingProps, getItemProps } = interactionsReturn;

  return (
    <Menu
      ref={floating}
      style={{
        position: strategy,
        transform: `translate(${x ?? 0}px, ${y ?? 0}px)`,
      }}
      {...getFloatingProps()}
    >
      {listPositions.map((position, index) => {
        const targetCandidate = position.candidates.find(
          (c) => c.talentId === targetTalentId
        );
        const candidateStatus = targetCandidate?.status;

        return (
          <MenuListItem key={position.id}>
            <MenuElement
              {...getItemProps({
                ref: (node) => (listRef.current[index] = node),
                onClick: () => {
                  if (!candidateStatus) {
                    onSelected(position.id);
                  }
                },
              })}
            >
              <span>{position.title}</span>
              {candidateStatus === CandidateStatus.Approved && (
                <ApprovedIcon name="check" />
              )}
              {candidateStatus === CandidateStatus.Rejected && (
                <RejectedIcon name="smallcross" />
              )}
              {candidateStatus === CandidateStatus.Candidate && (
                <CandidateIndicator />
              )}
            </MenuElement>
          </MenuListItem>
        );
      })}
    </Menu>
  );
}

const Overlay = styled(FloatingOverlay)`
  z-index: ${(p) => p.theme.layers.overlay};
`;

const Menu = styled.ul`
  margin: 0;
  padding: 8px 0;
  min-width: 200px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;
  top: 0;
  left: 0;
  z-index: ${(p) => p.theme.layers.menu};

  &:focus {
    outline: 0 none;
  }
`;

const MenuListItem = styled.li`
  list-style: none;
  padding: 0 8px;
`;

const MenuElement = styled.button`
  border: 0 none;
  background-color: transparent;
  padding: 8px;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background-color: ${(p) => p.theme.colors.service.hover};
  }

  &:focus {
    outline: 0 none;
  }

  &:active {
    background-color: ${(p) => p.theme.colors.service.selected};
  }
`;

const ApprovedIcon = styled(UIIcon)`
  color: ${(p) => p.theme.colors.statuses.success};
`;

const RejectedIcon = styled(UIIcon)`
  color: ${(p) => p.theme.colors.statuses.error};
`;

const CandidateIndicator = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.ui.greyFilled};
`;
