import { useCallback, useState } from "react";
import styled from "styled-components/macro";
import { Button } from "../../../components/Button";
import { useInitAccountAsIndividualMutation } from "../../../graphql";
import { typographyCSS } from "../../../theme";

import IndividualAccountSrc from "./assets/individual_account.jpeg";
import OrgAccountSrc from "./assets/org_account.jpeg";

interface AccountKindStageProps {
  onIndividual: () => void;
  onOrganization: () => void;
}

export function AccountKindStage({
  onIndividual,
  onOrganization,
}: AccountKindStageProps) {
  let [loading, setLoading] = useState(false);
  let [hasError, setHasError] = useState(false);
  let [initAccountAsIndividual] = useInitAccountAsIndividualMutation();
  const handleIndividualClick = useCallback(() => {
    setHasError(false);
    setLoading(true);
    initAccountAsIndividual({
      onCompleted() {
        setLoading(false);
        onIndividual();
      },
      onError() {
        setLoading(false);
        setHasError(true);
      },
    });
  }, [onIndividual, initAccountAsIndividual]);

  const handleTeamClick = useCallback(() => {
    onOrganization();
  }, [onOrganization]);

  return (
    <Container>
      <h1>How are you planing to use Baselance?</h1>
      <Description>Try any plan for free!</Description>
      <Content>
        <Section>
          <Badge>Full featured</Badge>
          <ImageContainer>
            <img src={OrgAccountSrc} alt="Team" />
          </ImageContainer>
          <h4>With my team</h4>
          <SectionHint>
            Invite colleagues, build and discuss lists of talents
          </SectionHint>
          <Button
            $kind="contained"
            $fullWidth
            onClick={handleTeamClick}
            disabled={loading}
          >
            Try for free
          </Button>
        </Section>
        <Section>
          <ImageContainer>
            <img src={IndividualAccountSrc} alt="Individual" />
          </ImageContainer>
          <h4>For myself</h4>
          <SectionHint>
            Organize your talents database and search efficiently
          </SectionHint>
          <Button
            $kind="outlined"
            $fullWidth
            onClick={handleIndividualClick}
            disabled={loading}
          >
            Try for free
          </Button>
        </Section>
      </Content>
      {hasError && <ErrorMsg>Something went wrong. Try again later.</ErrorMsg>}
    </Container>
  );
}

const Container = styled.div`
  max-width: 400px;
  text-align: center;
`;

const Description = styled.div`
  margin: 8px 0 24px;
`;

const Content = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

const Section = styled.div`
  position: relative;
  flex: 1 1 50%;
  box-sizing: border-box;
  padding: 24px 16px 16px;
  background: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;

  &:hover {
    box-shadow: 0px 4px 16px 0px #3c2f471a;
  }

  h4 {
    margin-bottom: 8px;
  }
`;

const ImageContainer = styled.div`
  height: 112px;
  margin-bottom: 24px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const SectionHint = styled.div`
  color: ${(p) => p.theme.colors.text.grey};
  ${(p) => typographyCSS(p.theme.typo.body2)};
  margin-bottom: 24px;
`;

const Badge = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2px 8px;
  background: ${(p) => p.theme.colors.ui.purple};
  color: ${(p) => p.theme.colors.text.white};
  ${(p) => typographyCSS(p.theme.typo.body2)};
  border-radius: 14px;
`;

const ErrorMsg = styled.div`
  margin-top: 4px;
  text-align: center;
  color: ${(p) => p.theme.colors.statuses.error};
`;
