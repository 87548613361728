import { Link, useLinkClickHandler } from "react-router-dom";
import * as yup from "yup";
import styled from "styled-components/macro";
import { Logo } from "../../components/Logo/Logo";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { typographyCSS } from "../../theme";
import { useGeneralLocationState } from "../../hooks/useGeneralLocationState";
import { useState } from "react";

const SUBMISSION = "__SUBMISSION__";

export interface FormData {
  email: string;
  [SUBMISSION]: void;
}

const schema = yup
  .object({
    email: yup.string().email().required("Fill email"),
  })
  .required();

export function ForgotPage() {
  const [locationState] = useGeneralLocationState();
  const initialEmail = locationState?.email;
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: initialEmail ?? "",
    },
  });

  const [isForgotSent, setForgotSent] = useState(false);

  const handleFormSubmit = async (data: FormData) => {
    clearErrors(SUBMISSION);
    const requestParams = new URLSearchParams();
    requestParams.set("email", data.email);

    try {
      const body = await fetch("/api/forgot/", {
        credentials: "same-origin",
        method: "POST",
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: requestParams.toString(),
      });
      const res = await body.json();

      if (res.ok) {
        setForgotSent(true);
        return;
      }

      if (res.error === "NOT_REGISTERED") {
        setError(SUBMISSION, {
          type: "submission",
          message: "This email is not registered",
        });
        return;
      }
    } catch (err) {}

    setError(SUBMISSION, {
      type: "submission",
      message: "Something went wrong. Try again later.",
    });
  };

  let email = watch("email");

  let handleSignInClick = useLinkClickHandler<HTMLButtonElement>(
    "/auth/signin",
    {
      state: { email },
    }
  );

  return (
    <Container>
      <Panel>
        <LogoLink href="/">
          <Logo />
        </LogoLink>
        <SignUpSection>
          <span>Not a member?</span>{" "}
          <PanelLink to="/auth/signup">Sign up now</PanelLink>
        </SignUpSection>
      </Panel>
      <Content>
        <h1>Password recovery</h1>
        {isForgotSent ? (
          <>
            <Info>
              Further instructions have been sent to your e-mail address.
            </Info>
            <ButtonsRow>
              <Button
                $kind="contained"
                $size="l"
                $fullWidth
                onClick={handleSignInClick}
              >
                Back to Sign in
              </Button>
            </ButtonsRow>
          </>
        ) : (
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <InputRow>
              <Input
                icon="mail"
                placeholder="Work email"
                hasError={!!errors.email}
                {...register("email")}
              />
            </InputRow>
            <SecondaryLinkRow>
              <SecondaryLink to="/auth/signin" state={{ email: initialEmail }}>
                Back to Sign in
              </SecondaryLink>
            </SecondaryLinkRow>
            <ButtonsRow>
              <Button
                $kind="contained"
                $size="l"
                $fullWidth
                disabled={isSubmitting}
              >
                Continue
              </Button>
            </ButtonsRow>
            {errors[SUBMISSION] && (
              <ErrorMsg>{errors[SUBMISSION].message}</ErrorMsg>
            )}
          </form>
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Panel = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  flex: 0 0 ${(p) => p.theme.panelHeight};
  height: ${(p) => p.theme.panelHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoLink = styled.a`
  svg {
    display: block;
  }
`;

const SignUpSection = styled.div`
  @media screen and (max-width: 440px) {
    span {
      display: none;
    }
  }
`;

const PanelLink = styled(Link)`
  color: ${(p) => p.theme.colors.ui.purple};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Content = styled.div`
  flex: 1 0 auto;
  padding-top: 40px;
  width: 400px;
  text-align: center;

  h1 {
    margin-bottom: 24px;
  }
`;

const InputRow = styled.div`
  margin-bottom: 8px;

  label {
    width: 100%;
  }
`;

const SecondaryLinkRow = styled.div`
  text-align: right;
`;

const SecondaryLink = styled(Link)`
  ${(p) => typographyCSS(p.theme.typo.body2)};
  color: ${(p) => p.theme.colors.ui.purple};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonsRow = styled.div`
  margin-top: 16px;
`;

const ErrorMsg = styled.div`
  margin-top: 4px;
  text-align: center;
  color: ${(p) => p.theme.colors.statuses.error};
`;

const Info = styled.div`
  margin: 0 0 8px;
`;
