import styled from "styled-components/macro";
import TextareaAutosize from "react-textarea-autosize";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreateReviewMutation } from "../../../../graphql";
import { UIIcon } from "../../../../components/UIIcon/UIIcon";
import { BaseButton } from "../../../../components/Button";
import { StarsControl } from "./StarsControl";
import { typographyCSS } from "../../../../theme";

export interface FormData {
  evaluation: number;
  comment: string;
}

export const schema = yup.object({
  evaluation: yup
    .number()
    .integer()
    .max(5, "Unexpected error")
    .min(1, "Select number of stars")
    .required(),
  comment: yup.string(),
});

interface ReviewFormProps {
  talentId: string;
  onDone: () => void;
}

export function ReviewForm({ talentId, onDone }: ReviewFormProps) {
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      evaluation: 0,
      comment: "",
    },
  });

  const [createReview] = useCreateReviewMutation();
  const handleFormSubmit = handleSubmit((data) => {
    return createReview({
      variables: {
        input: {
          talentId,
          evaluation: data.evaluation,
          comment: data.comment || null,
          clientMutationId: null,
        },
      },
      onCompleted: () => {
        onDone();
      },
    });
  });

  return (
    <Container>
      <form onSubmit={handleFormSubmit}>
        <Row>
          <Textarea
            {...register("comment")}
            placeholder="Leave you comment"
            minRows={2}
            maxRows={5}
            autoFocus
          />
        </Row>
        <Footer>
          <Controller
            name="evaluation"
            control={control}
            render={({ field, fieldState }) => (
              <StarsArea>
                <StarsControl value={field.value} onChange={field.onChange} />
                {fieldState.error?.message && (
                  <ErrMsg>{fieldState.error.message}</ErrMsg>
                )}
              </StarsArea>
            )}
          />
          <SubmitButton disabled={isSubmitting}>
            <UIIcon name="paperplane" />
          </SubmitButton>
        </Footer>
      </form>
    </Container>
  );
}

const Container = styled.div`
  padding: 8px 0;
`;

const Row = styled.div`
  margin-bottom: 8px;
`;

const Textarea = styled(TextareaAutosize)`
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.colors.borders.border};
  border-radius: 8px;
  padding: 8px 12px;
  resize: none;
  background-color: ${(p) => p.theme.colors.backgrounds.main};

  &:hover {
    border-color: ${(p) => p.theme.colors.borders.border};
    background: ${(p) => p.theme.colors.backgrounds.main};
    box-shadow: 0px 0px 0px 3px ${(p) => p.theme.colors.service.focus};
  }

  &:focus {
    outline: 0 none;
    border-color: ${(p) => p.theme.colors.ui.purple};
    background: ${(p) => p.theme.colors.backgrounds.main};
    box-shadow: 0px 0px 0px 3px ${(p) => p.theme.colors.service.focus};
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
`;

const SubmitButton = styled(BaseButton)`
  flex: 0 0 auto;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.ui.purple};
  color: ${(p) => p.theme.colors.text.white};
  padding: 4px;
`;

const StarsArea = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ErrMsg = styled.div`
  color: ${(p) => p.theme.colors.statuses.error};
  ${(p) => typographyCSS(p.theme.typo.body2)};
`;
