import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Control, useWatch } from "react-hook-form";
import styled, { keyframes } from "styled-components/macro";
import {
  useCreateAvatarMutation,
  useUploadAvatarMutation,
} from "../../graphql";
import { useUnmounted } from "../../hooks/useUnmounted";
import { typographyCSS } from "../../theme";
import { AvatarImageData } from "../../types";
import { preloadImage } from "../../utils/preloadImage";
import { TalentAvatar } from "../Avatar";
import { UIIcon } from "../UIIcon/UIIcon";
import { ReactComponent as AvatarPlaceholder } from "./AvatarPlaceholder.svg";
import { FormData as MainFormData } from "./MainFormPart";

interface AvatarControlProps {
  talentId?: string;
  profileAvatarUrl?: string | null;
  formControl: Control<MainFormData>;
  image: AvatarImageData | null;
  onChange: (image: AvatarImageData | null) => void;
}

export function AvatarControl({
  talentId,
  profileAvatarUrl,
  formControl,
  image,
  onChange,
}: AvatarControlProps) {
  const getUnmounted = useUnmounted();
  const [isImageLoading, setImageIsLoading] = useState(false);
  const name = useWatch({ control: formControl, name: "name" });

  const [createAvatar] = useCreateAvatarMutation();
  const [uploadAvatar] = useUploadAvatarMutation();

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    disabled: isImageLoading,
    multiple: false,
    accept: {
      "image/*": [],
    },
    onDrop(files) {
      const file = files[0];
      if (!file) {
        return;
      }

      setImageIsLoading(true);
      uploadAvatar({
        variables: { file },
        onCompleted(data) {
          if (getUnmounted()) return;

          const image = data.uploadImage?.image;
          if (image) {
            preloadImage(image.avatar128)
              .then(() => {
                if (getUnmounted()) return;

                onChange(image);
              })
              .finally(() => {
                if (getUnmounted()) return;

                setImageIsLoading(false);
              });
          }
        },
      });
    },
  });

  useEffect(() => {
    if (profileAvatarUrl) {
      let unmounted = false;
      setImageIsLoading(true);
      createAvatar({
        variables: { url: profileAvatarUrl },
        onCompleted(data) {
          if (unmounted) return;

          const image = data.createImage?.image;
          if (image) {
            preloadImage(image.avatar128)
              .then(() => {
                if (unmounted) return;
                onChange(image);
              })
              .finally(() => {
                if (unmounted) return;
                setImageIsLoading(false);
              });
          }
        },
      });

      return () => {
        unmounted = true;
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveClick = useCallback(() => {
    onChange(null);
  }, [onChange]);

  return (
    <Container>
      <div {...getRootProps()}>
        <AvatarWrapper $loading={isImageLoading} $isDragActive={isDragAccept}>
          <input {...getInputProps()} />
          {name.length === 0 && !image ? (
            <AvatarPlaceholder />
          ) : (
            <TalentAvatar size="m" talent={{ id: talentId ?? "", name, image }} />
          )}
        </AvatarWrapper>
        {isDragAccept && <DndCover>Drop here</DndCover>}
      </div>
      {image && !isImageLoading && (
        <RemoveButton type="button" onClick={handleRemoveClick}>
          <UIIcon name="cross" />
        </RemoveButton>
      )}
      {isImageLoading && <Spinner />}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  flex: 0 0 auto;
  margin-top: 4px;
`;

const AvatarWrapper = styled.div<{ $loading: boolean; $isDragActive: boolean }>`
  position: relative;
  filter: ${(p) => (p.$loading ? "grayscale(1)" : "none")};
  opacity: ${(p) => (p.$isDragActive ? 0 : 1)};
  cursor: pointer;

  svg {
    display: block;
  }
`;

const DndCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px dashed ${(p) => p.theme.colors.ui.purple};
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.highlights.blue.bg};
  color: ${(p) => p.theme.colors.ui.purple};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(p) => typographyCSS(p.theme.typo.highlighted)};
`;

const spinnerRotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);

  &:after {
    content: " ";
    display: block;
    box-sizing: border-box;
    width: 80%;
    height: 80%;
    margin: 10%;
    border-radius: 50%;
    border: 4px solid;
    border-color: ${(p) => p.theme.colors.ui.purple} transparent
      ${(p) => p.theme.colors.ui.purple} transparent;
    animation: ${spinnerRotateAnimation} 1.2s linear infinite;
  }
`;

const RemoveButton = styled.button`
  border: 0;
  padding: 4px;
  margin: 0;
  background-color: transparent;
  position: absolute;
  top: -12px;
  right: -12px;
  border-radius: 50%;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${(p) => p.theme.colors.service.hover};
  }

  &:active {
    background-color: ${(p) => p.theme.colors.service.selected};
  }
`;
