export type MaybeURL = { isURL: true; url: URL } | { isURL: false };

export const NotAnURL = { isURL: false } as const;

export function tryURL(urlStr: string): MaybeURL {
  try {
    let url = new URL(urlStr);
    return { isURL: true, url };
  } catch (err) {}

  return NotAnURL;
}
