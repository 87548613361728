import { Routes, Route, Navigate } from "react-router-dom";

import { TalentsListPage } from "../pages/talents/TalentsListPage/TalentsListPage";
import { TalentPage } from "../pages/talents/TalentPage/TalentPage";
import { Layout } from "./Layout/Layout";
import { useGeneralLocationState } from "../hooks/useGeneralLocationState";
import { AddTalentPage } from "../pages/talents/AddTalentPage/AddTalentPage";
import { EditTalentPage } from "../pages/talents/EditTalentPage/EditTalentPage";
import { CategoryManagementPage } from "../pages/CategoryManagement/CategoryManagementPage";
import { useAuthGate } from "./context/auth_gate";
import { SignInPage } from "../pages/auth/SignInPage";
import { SignUp, SignUpStage } from "../pages/auth/SignUp/SignUp";
import { PasswordResetPage } from "../pages/auth/PasswordResetPage";
import { NotFound } from "../pages/NotFound/NotFound";
import { ProfilePage } from "../pages/ProfilePage/ProfilePage";
import { ListsPage } from "../pages/lists/ListsPage/ListsPage";
import { ListDetails } from "../pages/lists/ListDetails/ListDetails";
import { AddListForm } from "../pages/lists/forms/AddListForm";
import { EditListForm } from "../pages/lists/forms/EditListForm";
import { ForgotPage } from "../pages/auth/ForgotPage";
import { JoinPage } from "../pages/auth/JoinPage";
import { UpgradePlan } from "../pages/UpgradePlan/UpgradePlan";
import { LockedAccount } from "../pages/LockedAccount/LockedAccount";

export function Routing() {
  const viewer = useAuthGate();
  let [locationState, location] = useGeneralLocationState();

  if (!viewer) {
    return (
      <Routes>
        <Route path="/auth/signup" element={<SignUp />} />
        <Route path="/auth/signin" element={<SignInPage />} />
        <Route path="/auth/join/:token" element={<JoinPage />} />
        <Route path="/auth/forgot" element={<ForgotPage />} />
        <Route path="/auth/reset/:token" element={<PasswordResetPage />} />
        <Route path="*" element={<Navigate to="/auth/signin" />} />
      </Routes>
    );
  }

  if (viewer.accountKind === "NONE") {
    return (
      <Routes>
        <Route
          path="/auth/signup"
          element={<SignUp initialStage={SignUpStage.AccountKind} />}
        />
        <Route path="*" element={<Navigate to="/auth/signup" />} />
      </Routes>
    );
  }

  if (viewer.isSurveyRequired) {
    return (
      <Routes>
        <Route
          path="/auth/signup"
          element={<SignUp initialStage={SignUpStage.Survey} />}
        />
        <Route path="*" element={<Navigate to="/auth/signup" />} />
      </Routes>
    );
  }

  return (
    <Layout hasOverlay={!!locationState?.backgroundLocation}>
      <Routes location={locationState?.backgroundLocation ?? location}>
        <Route path="/" element={<Navigate to="/talents" replace />} />

        <Route path="/talents" element={<TalentsListPage />} />
        <Route
          path="/talents/add"
          element={viewer.features.app ? <AddTalentPage /> : <LockedAccount />}
        />
        <Route
          path="/talents/manage-categories"
          element={viewer.features.app ? <CategoryManagementPage /> : <LockedAccount />}
        />
        <Route path="/talents/:talentId" element={<TalentPage />} />
        <Route
          path="/talents/:talentId/edit"
          element={viewer.features.app ? <EditTalentPage /> : <LockedAccount />}
        />

        {viewer.features.lists ? (
          <>
            <Route path="/lists" element={<ListsPage />} />
            <Route path="/lists/add" element={<AddListForm />} />
            <Route path="/lists/:listId" element={<ListDetails />} />
            <Route path="/lists/:listId/edit" element={<EditListForm />} />
          </>
        ) : (
          <Route
            path="/lists"
            element={viewer.features.app ? <UpgradePlan /> : <LockedAccount />}
          />
        )}

        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/auth/*" element={<Navigate to="/" replace />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
}
