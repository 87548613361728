import { ImageSrc } from "../graphql";

export function preloadImage(imageSrc: string | ImageSrc) {
  return new Promise<void>((resolve, reject) => {
    let img = new Image();
    img.onload = () => {
      resolve();
    };
    img.onerror = (err) => {
      reject(err);
    };
    if (typeof imageSrc === "string") {
      img.src = imageSrc;
    } else {
      img.srcset = imageSrc.srcSet;
      img.src = imageSrc.src;
    }
  });
}
