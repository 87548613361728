import { forwardRef } from "react";
import styled from "styled-components/macro";
import { typographyCSS } from "../theme";
import { UIIcon, UIIconKind } from "./UIIcon/UIIcon";

type ButtonSize = "m" | "l" | "xl";
type ButtonKind = "text" | "contained" | "outlined";

const DEFAULT_SIZE: ButtonSize = "m";
const DEFAULT_KIND: ButtonKind = "outlined";

export const BaseButton = styled.button`
  box-sizing: border-box;
  border: 0 none;
  padding: 0;
  position: relative;

  &:enabled,
  a& {
    cursor: pointer;

    &:hover,
    &:active {
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: inherit;
        background-color: ${(p) => p.theme.colors.service.hover};
        pointer-events: none;
      }
    }

    &:hover::after {
      background-color: ${(p) => p.theme.colors.service.hover};
    }

    &:active::after {
      background-color: ${(p) => p.theme.colors.service.selected};
    }

    &:focus-visible {
      outline: 3px solid ${(p) => p.theme.colors.service.focus};
      outline-offset: 0;
    }
  }

  &:focus {
    outline: 0 none;
  }

  &:disabled {
    filter: grayscale(1);
    opacity: 0.3;
  }
`;

interface GeneralButtonProps {
  $kind?: ButtonKind;
  $size?: ButtonSize;
  $fullWidth?: boolean;
  $withIcon?: boolean;
}

const GeneralButton = styled(BaseButton)<GeneralButtonProps>`
  min-width: 100px;
  width: ${(p) => (p.$fullWidth ? "100%" : "auto")};
  text-align: center;
  text-decoration: none;
  border-width: ${({ $kind = DEFAULT_KIND }) =>
    $kind === "outlined" ? "1px" : "0"};
  border-style: solid;
  border-color: ${(p) => p.theme.colors.ui.purple};
  padding: ${getPaddings};
  border-radius: 8px;
  background: ${({ theme, $kind = DEFAULT_KIND }) =>
    $kind === "contained" ? theme.colors.ui.purple : "transparent"};
  color: ${({ theme, $kind = DEFAULT_KIND }) =>
    $kind === "contained" ? theme.colors.text.white : theme.colors.ui.purple};
  ${({ theme, $size = DEFAULT_SIZE }) =>
    typographyCSS($size === "xl" ? theme.typo.buttonL : theme.typo.buttonM)};
  display: ${(p) => (p.$withIcon ? "flex" : "block")};
  gap: 8px;
  align-items: center;
`;

interface ButtonProps extends React.ComponentProps<typeof GeneralButton> {
  kind?: ButtonKind;
  size?: ButtonSize;
  fullWidth?: boolean;
  icon?: UIIconKind;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ icon, kind, size, fullWidth, children, ...buttonProps }, ref) => {
    return (
      <GeneralButton
        ref={ref}
        $kind={kind}
        $size={size}
        $fullWidth={fullWidth}
        $withIcon={!!icon}
        {...buttonProps}
      >
        {icon ? (
          <>
            <UIIcon name={icon} />
            <span>{children}</span>
          </>
        ) : (
          children
        )}
      </GeneralButton>
    );
  }
);

function getPaddings({
  $size = DEFAULT_SIZE,
  $kind = DEFAULT_KIND,
}: GeneralButtonProps) {
  let paddings = [0, 0] as [number, number];
  switch ($size) {
    case "m":
      paddings = [11, 16];
      break;
    case "l":
      paddings = [15, 24];
      break;
    case "xl":
      paddings = [16, 74];
      break;
  }

  const [x, y] = paddings;
  return $kind === "outlined" ? `${x - 1}px ${y - 1}px` : `${x}px ${y}px`;
}
