import { useCallback } from "react";
import { Button } from "../../../../components/Button";
import {useViewer} from "../../../../components/context/auth_gate";
import {
  useDeleteSampleTalentsMutation,
  useHasSampleTalentsQuery,
} from "../../../../graphql";

export function DeleteSamplesButton() {
  const viewer = useViewer();

  const [deleteSampleTalents] = useDeleteSampleTalentsMutation();
  const hasSamplesQuery = useHasSampleTalentsQuery({
    skip: !viewer.hasEnabledTrial,
  });

  const handleDeleteSamplesClick = useCallback(() => {
    deleteSampleTalents({
      onCompleted() {
        document.location = "/talents";
      },
    });
  }, [deleteSampleTalents]);

  const hasSamples = hasSamplesQuery.data?.hasSampleTalents ?? false;

  if (!hasSamples) {
    return null;
  }

  return (
    <Button $kind="outlined" onClick={handleDeleteSamplesClick}>
      Delete samples
    </Button>
  );
}
