import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components/macro";

import { App } from "./components/App";
import { theme } from "./theme";
import reportWebVitals from "./reportWebVitals";
import { apolloClient } from "./apolloClient";
import { AuthGate } from "./components/context/auth_gate";
import { HeapIntegration } from "./components/integrations/HeapIntegration";
import { ChatraIntegration } from "./components/integrations/ChatraIntegration";
import { IntercomIntegration } from "./components/integrations/IntercomIntegration";
import { FillableListStorageProvider } from "./components/context/fillable_list";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AuthGate>
            <HeapIntegration>
              <ChatraIntegration>
                <IntercomIntegration>
                  <FillableListStorageProvider>
                    <App />
                  </FillableListStorageProvider>
                </IntercomIntegration>
              </ChatraIntegration>
            </HeapIntegration>
          </AuthGate>
        </ThemeProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
