import styled from "styled-components/macro";
import { Waypoint } from "react-waypoint";
import { FloatingTree } from "@floating-ui/react";

import {
  GridItemCard,
  GridItemPlaceholder,
  MAX_GRID_COLS,
  PLACEHOLDER_ROWS_COUNT,
} from "./GridItemCard";
import { TalentPreviewType } from "../../../types";
import { rangeArray } from "../../../utils/rangeArray";
import { EmptyList } from "./EmptyList";

interface GridViewProps {
  talents: TalentPreviewType[];
  loading: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
}

const placeholdersRange = rangeArray(PLACEHOLDER_ROWS_COUNT * MAX_GRID_COLS);
const morePlaceholdersRange = rangeArray(MAX_GRID_COLS);

export function GridView({
  talents,
  loading,
  hasMore,
  onLoadMore,
}: GridViewProps) {
  return (
    <Container>
      {!loading && talents.length === 0 && <EmptyList />}
      <GridWrap>
        <FloatingTree>
          <Grid>
            {loading &&
              talents.length === 0 &&
              placeholdersRange.map((idx) => <GridItemPlaceholder key={idx} />)}
            {talents.map((talent) => (
              <GridItemCard key={talent.id} talent={talent} />
            ))}
            {talents.length > 0 &&
              hasMore &&
              morePlaceholdersRange.map((idx) => (
                <GridItemPlaceholder key={idx} more />
              ))}
          </Grid>
        </FloatingTree>
      </GridWrap>
      {!loading && hasMore && (
        <Waypoint
          onEnter={onLoadMore}
          scrollableAncestor="window"
          bottomOffset={-400}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  box-sizing: border-box;
  overflow: hidden;
`;

const GridWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 80px;
`;

const Grid = styled.ul`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  position: relative;
`;
