import { useCallback } from "react";
import { To, useNavigate } from "react-router-dom";
import { useGeneralLocationState } from "../../hooks/useGeneralLocationState";
import { ActionButton, ActionButtonProps } from "../ActionButton";

interface BackButtonProps
  extends Omit<ActionButtonProps, "onClick" | "icon" | "text"> {
  defaultTo: To;
}

interface UseBackButtonOptions {
  defaultTo: To;
}

export function useBackButton({ defaultTo }: UseBackButtonOptions) {
  let navigate = useNavigate();
  let [locationState] = useGeneralLocationState();
  let isOverpage = !!locationState?.backgroundLocation;

  return useCallback(() => {
    if (isOverpage) {
      navigate(-1);
    } else {
      navigate(defaultTo, { replace: true });
    }
  }, [navigate, defaultTo, isOverpage]);
}

export function BackButton({ defaultTo, ...buttonProps }: BackButtonProps) {
  const handleClick = useBackButton({ defaultTo });

  return (
    <ActionButton
      {...buttonProps}
      icon="arrowleft"
      text="Back"
      onClick={handleClick}
    />
  );
}
