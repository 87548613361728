// function nonNull<T>(value: T | null | undefined): T {
//   if (!value) {
//     throw new Error("Unexpected null value");
//   }
//
//   return value;
// }

const appConfig = {
  intercom: {
    enabled: process.env.REACT_APP_INTERCOM_ENABLED === "true",
    appId: process.env.REACT_APP_INTERCOM_APP_ID,
  },
  heap: {
    enabled: process.env.REACT_APP_HEAP_ENABLED === "true",
    projectId: process.env.REACT_APP_HEAP_PROJECT_ID,
  },
  chatra: {
    enabled: process.env.REACT_APP_CHATRA_ENABLED === "true",
    id: process.env.REACT_APP_CHATRA_ID,
    groupId: process.env.REACT_APP_CHATRA_GROUP_ID,
  },
  emoji: {
    set: "twitter",
  } as const
};

export default appConfig;
