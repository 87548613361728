import { useCallback, useMemo } from "react";
import { MemberType } from "../../../types";
import { ActionButton } from "../../../components/ActionButton";
import { Button } from "../../../components/Button";
import { ChipList, Hint, NoBreak, Row } from "../../../components/FormUI";
import { useTeamPicker } from "../../../components/TeamPicker/TeamPicker";
import { SelectedMember } from "../../../components/TeamPicker/SelectedMember";

interface TeamControlProps {
  pinnedMembers?: MemberType[];
  team: MemberType[];
  onChange: (categories: MemberType[]) => void;
}

export function TeamControl({
  team,
  pinnedMembers,
  onChange,
}: TeamControlProps) {
  const handleTeamSelectionDone = useCallback(
    (newMembers: MemberType[]) => {
      if (newMembers.length > 0) {
        onChange([...team, ...newMembers]);
      }
    },
    [team, onChange]
  );

  const selectedMembers = useMemo(
    () => (pinnedMembers ? [...pinnedMembers, ...team] : team),
    [pinnedMembers, team]
  );

  const [getReferenceProps, teamPickerElement] = useTeamPicker({
    selectedMembers: selectedMembers,
    onDone: handleTeamSelectionDone,
  });

  const handleMemberRemove = useCallback(
    (removedMember: MemberType) => {
      onChange(team.filter((member) => member.id !== removedMember.id));
    },
    [team, onChange]
  );

  const lastIndex = team.length - 1;

  return (
    <>
      <Row>
        {team.length > 0 ? (
          <ChipList>
            {team.map((member, index) =>
              index === lastIndex ? (
                <NoBreak key={member.id}>
                  <SelectedMember
                    member={member}
                    onClick={handleMemberRemove}
                  />
                  <ActionButton
                    type="button"
                    icon="plus"
                    {...getReferenceProps()}
                  />
                  {teamPickerElement}
                </NoBreak>
              ) : (
                <SelectedMember
                  key={member.id}
                  member={member}
                  onClick={handleMemberRemove}
                />
              )
            )}
          </ChipList>
        ) : (
          <>
            <Button type="button" {...getReferenceProps()}>
              Select member
            </Button>
            {teamPickerElement}
          </>
        )}
      </Row>
      {team.length === 0 && (
        <Hint>
          Add team members who will participate in the candidates review
        </Hint>
      )}
    </>
  );
}
