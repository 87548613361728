import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Box, Buttons, ErrorMsg, FormInput, InputRow, Msg } from "./ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useViewer } from "../../components/context/auth_gate";
import { Button } from "../../components/Button";
import { useEditProfileMutation } from "../../graphql";
import { useTimeoutFlag } from "../../hooks/useTimeoutFlag";
import { AvatarImageData } from "../../types";
import { AvatarControl } from "./AvatarControl";
import styled from "styled-components";

export interface FormData {
  name: string;
  email: string;
  location: string;
  avatarImage: AvatarImageData | null;
}

export const schema = yup.object({
  name: yup.string().required("Fill name"),
  email: yup.string().email("Unexpected email format").required("Fill email"),
  location: yup.string(),
});

export function ProfileForm() {
  const [isDone, setDone] = useTimeoutFlag(5000);
  const viewer = useViewer();
  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: viewer.name,
      email: viewer.email || "",
      location: viewer.location || "",
      avatarImage: viewer.avatar,
    },
  });

  const [editProfile] = useEditProfileMutation();
  const handleFormSubmit = handleSubmit((data) => {
    setDone(false);
    return editProfile({
      variables: {
        input: {
          name: data.name,
          email: data.email,
          location: data.location,
          avatar: data.avatarImage?.id ?? null,
          clientMutationId: null,
        },
      },
      onCompleted() {
        setDone(true);
      },
    });
  });

  return (
    <Box>
      <form onSubmit={handleFormSubmit}>
        <TwoColumns>
          <div>
            <InputRow>
              <FormInput
                {...register("name")}
                placeholder="Name"
                autoComplete="off"
              />
              {errors.name && (
                <ErrorMsg>
                  {errors.name.message ?? "Fill correct name"}
                </ErrorMsg>
              )}
            </InputRow>
            <InputRow>
              <FormInput
                {...register("email")}
                placeholder="Email"
                autoComplete="off"
              />
              {errors.email && (
                <ErrorMsg>
                  {errors.email.message ?? "Fill correct email"}
                </ErrorMsg>
              )}
            </InputRow>
            <InputRow>
              <FormInput
                {...register("location")}
                placeholder="Location"
                autoComplete="off"
              />
              {errors.location && (
                <ErrorMsg>
                  {errors.location.message ?? "Fill correct location"}
                </ErrorMsg>
              )}
            </InputRow>
          </div>
          <Controller
            name="avatarImage"
            control={control}
            render={({ field }) => (
              <AvatarControl
                formControl={control}
                image={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </TwoColumns>
        <Buttons>
          <Button $size="m" $kind="contained" disabled={isSubmitting}>
            Save
          </Button>
          {isDone && <Msg>Done!</Msg>}
        </Buttons>
      </form>
    </Box>
  );
}

const TwoColumns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;
