import {
  offset,
  useFloating,
  shift,
  useInteractions,
  useDismiss,
  useListNavigation,
  UseFloatingReturn,
  FloatingPortal,
  useFloatingNodeId,
  FloatingNode,
  useClick,
  ReferenceType,
} from "@floating-ui/react";
import { MutableRefObject, useCallback, useRef, useState } from "react";
import styled from "styled-components/macro";
import { HighlightColorKind } from "../../theme";
import {
  useClickPlacement,
  useClickPlacementMiddleware,
} from "../../utils/floating-ui/clickPlacement";
import { useStickyTop } from "../../utils/stickyTop";

const colors: { code: HighlightColorKind; name: string }[] = [
  { code: "black", name: "Black" },
  { code: "red", name: "Red" },
  { code: "orange", name: "Orange" },
  { code: "green", name: "Green" },
  { code: "blue", name: "Blue" },
];

interface UseHighlightColorsPickerOptions {
  enabled?: boolean;
  nested?: boolean;
  onColorSelected: (color: string) => void;
}

export function useHighlightColorsPicker({
  enabled = true,
  nested = false,
  onColorSelected,
}: UseHighlightColorsPickerOptions) {
  const listRef = useRef([] as (HTMLElement | null)[]);
  const [opened, setOpened] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const stickyTop = useStickyTop();
  const clickPlacement = useClickPlacementMiddleware();

  const handleOpenChange = useCallback(
    (willBeOpened: boolean) => {
      if (!willBeOpened) {
        stickyTop.handleClose();
      }
      setOpened(willBeOpened);
    },
    [stickyTop]
  );

  const closePicker = useCallback(() => {
    handleOpenChange(false);
  }, [handleOpenChange]);

  const nodeId = useFloatingNodeId();

  const floatingReturn = useFloating({
    nodeId,
    open: opened,
    onOpenChange: handleOpenChange,
    placement: nested ? "right-start" : "bottom",
    strategy: "fixed",
    middleware: [
      offset(2),
      shift({ crossAxis: true, padding: 5 }),
      stickyTop.middleware,
      clickPlacement,
    ],
  });

  const interactionsReturn = useInteractions([
    useClick(floatingReturn.context, { enabled }),
    useDismiss(floatingReturn.context, { enabled }),
    useListNavigation(floatingReturn.context, {
      enabled,
      listRef,
      nested,
      activeIndex,
      onNavigate: setActiveIndex,
      loop: true,
      allowEscape: false,
    }),
    useClickPlacement(floatingReturn.context, {
      enabled,
      middleware: clickPlacement,
    }),
  ]);

  const setReference = useCallback(
    (node: ReferenceType | null) => {
      floatingReturn.refs.setReference(node);
      floatingReturn.refs.setPositionReference(node);
    },
    [floatingReturn.refs]
  );

  const pickerElement = opened ? (
    <FloatingNode id={nodeId}>
      <FloatingPortal>
        <HighlightColorsPicker
          floatingReturn={floatingReturn}
          listRef={listRef}
          interactionsReturn={interactionsReturn}
          onColorSelected={onColorSelected}
          closePicker={closePicker}
        />
      </FloatingPortal>
    </FloatingNode>
  ) : null;

  return [
    pickerElement,
    interactionsReturn.getReferenceProps,
    setReference,
  ] as const;
}

interface HighlightColorsPickerProps {
  floatingReturn: UseFloatingReturn;
  interactionsReturn: ReturnType<typeof useInteractions>;
  listRef: MutableRefObject<(HTMLElement | null)[]>;
  onColorSelected: (color: string) => void;
  closePicker: () => void;
}

function HighlightColorsPicker({
  floatingReturn,
  interactionsReturn,
  listRef,
  onColorSelected,
  closePicker,
}: HighlightColorsPickerProps) {
  const { x, y, floating, strategy } = floatingReturn;
  const { getFloatingProps, getItemProps } = interactionsReturn;

  return (
    <Menu
      ref={floating}
      style={{
        position: strategy,
        transform: `translate(${x ?? 0}px, ${y ?? 0}px)`,
      }}
      {...getFloatingProps()}
    >
      {colors.map((color, index) => (
        <MenuListItem key={color.code}>
          <MenuElement
            {...getItemProps({
              ref: (node) => (listRef.current[index] = node),
              onClick: () => {
                onColorSelected(color.code);
                closePicker();
              },
            })}
            $selected={false}
          >
            <ColorCircle $color={color.code}>
              {/*color.code === currentColor && <UIIcon name="check" />*/}
            </ColorCircle>
            <span>{color.name}</span>
          </MenuElement>
        </MenuListItem>
      ))}
    </Menu>
  );
}

const Menu = styled.ul`
  margin: 0;
  padding: 8px 0;
  min-width: 200px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 8px;
  top: 0;
  left: 0;
  z-index: ${(p) => p.theme.layers.menu};

  &:focus {
    outline: 0 none;
  }
`;

const MenuListItem = styled.li`
  list-style: none;
  padding: 0 8px;
`;

const MenuElement = styled.button<{ $selected: boolean }>`
  border: 0 none;
  background-color: ${(p) =>
    p.$selected ? p.theme.colors.service.selected : "transparent"};
  padding: 8px;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  gap: 8px;
  align-items: center;
  text-decoration: none;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background-color: ${(p) =>
      p.$selected
        ? p.theme.colors.service.selected
        : p.theme.colors.service.hover};
        };
  }

  &:focus {
    outline: 0 none;
  }

  &:active {
    background-color: ${(p) => p.theme.colors.service.selected};
  }
`;

const ColorCircle = styled.div<{ $color: HighlightColorKind }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.highlights[p.$color].bg};
  color: ${(p) => p.theme.colors.highlights[p.$color].fg};
  display: flex;
  align-items: center;
  justify-content: center;
`;
