import styled from "styled-components/macro";
import { Input } from "../../components/Input";
import { typographyCSS } from "../../theme";

export const Box = styled.div`
  margin-top: 8px;
  padding: 20px 24px;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 16px;
  background-color: ${(p) => p.theme.colors.backgrounds.main};
`;

export const ErrorMsg = styled.p`
  margin: 8px 0 0;
  color: ${(p) => p.theme.colors.statuses.error};
`;

export const Title = styled.div`
  ${(p) => typographyCSS(p.theme.typo.highlighted)};
  margin-bottom: 8px;
`;

export const InputRow = styled.div`
  width: 408px;
  margin-bottom: 8px;
`;

export const Row = styled.div`
  margin-bottom: 8px;
`;

export const FormInput = styled(Input)`
  width: 100%;
`;

export const Buttons = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Msg = styled.div``;
