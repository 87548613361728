import { Dispatch, SetStateAction, useCallback } from "react";
import styled from "styled-components/macro";
import ReactPlayer from "react-player";

import { ActionButton } from "../../../../components/ActionButton";
import { ControlledModal } from "../../../../components/Modal";
import { track } from "../../../../utils/track";
import { WorkType } from "../../../../types";

import Thumbnails from "./Thumbnails";
import { DeleteWorkAction } from "./DeleteWorkAction";
import { NotAnURL, tryURL } from "../../../../utils/tryURL";
import { nowww } from "../../../../utils/nowww";
import { UIIcon } from "../../../../components/UIIcon/UIIcon";
import { isGranted } from "../../../../acl";
import {useViewer} from "../../../../components/context/auth_gate";

interface GalleryProps {
  activeIndex: number | null;
  setActiveIndex: Dispatch<SetStateAction<number | null>>;
  works: WorkType[];
}

export function Gallery({
  activeIndex: rawActiveIndex,
  setActiveIndex,
  works,
}: GalleryProps) {
  const viewer = useViewer();
  const opened = rawActiveIndex !== null;

  const activeIndex =
    works.length > 0 && rawActiveIndex !== null
      ? Math.min(rawActiveIndex, works.length - 1)
      : null;
  const currentWork = activeIndex !== null ? works[activeIndex] : null;
  const currentWorkURL = currentWork?.link
    ? tryURL(currentWork.link)
    : NotAnURL;

  const handlePrevImage = useCallback(() => {
    setActiveIndex((index) =>
      index === null ? null : mod(index - 1, works.length)
    );
  }, [setActiveIndex, works.length]);

  const handleNextImage = useCallback(() => {
    setActiveIndex((index) =>
      index === null ? null : mod(index + 1, works.length)
    );
  }, [setActiveIndex, works.length]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      switch (e.key) {
        case "ArrowLeft":
          handlePrevImage();
          break;
        case "ArrowRight":
          handleNextImage();
          break;
      }
    },
    [handleNextImage, handlePrevImage]
  );

  const handleSetOpened = useCallback(
    (willBeOpened: boolean) =>
      willBeOpened ? setActiveIndex(0) : setActiveIndex(null),
    [setActiveIndex]
  );

  return (
    <ControlledModal
      opened={opened}
      setOpened={handleSetOpened}
      render={({ getContentProps, close }) => (
        <Container
          {...getContentProps({
            onKeyDown: handleKeyDown,
          })}
        >
          <Header>
            <ActionButton icon="arrowleft" text="Back" onClick={close} />
            {currentWork && (
              <Buttons>
                {currentWorkURL.isURL && (
                  <WorkLink
                    href={currentWork.link!}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>{nowww(currentWorkURL.url.host)}</span>
                    <UIIcon name="smallexternal" />
                  </WorkLink>
                )}
                {isGranted(viewer, "work.delete") && (
                  <DeleteWorkAction
                    workId={currentWork.id}
                    render={(getTriggerProps) => (
                      <ActionButton
                        icon="trashbin"
                        text="Delete"
                        {...getTriggerProps()}
                      />
                    )}
                  />
                )}
              </Buttons>
            )}
          </Header>
          <Body>
            <BodyContent>
              <ArrowContainer>
                <ArrowButton icon="left" onClick={handlePrevImage} />
              </ArrowContainer>
              <WorkContent key={currentWork?.id ?? "nothing"}>
                {currentWork &&
                  (() => {
                    if (
                      currentWork.link &&
                      ReactPlayer.canPlay(currentWork.link)
                    ) {
                      return <ReactPlayer url={currentWork.link} controls />;
                    }

                    if (currentWork.image) {
                      return (
                        <WorkImgA
                          href={currentWork.image.originalUrl}
                          target="_blank"
                          rel="noreferrer"
                          title="Click to open original image"
                          onClick={() => track("Click on image in Gallery")}
                        >
                          <WorkImg
                            src={currentWork.image.previewOriginal}
                            alt="Work image"
                          />
                        </WorkImgA>
                      );
                    }

                    return null;
                  })()}
              </WorkContent>
              <ArrowContainer>
                <ArrowButton icon="left" $flip onClick={handleNextImage} />
              </ArrowContainer>
            </BodyContent>
          </Body>
          <Thumbnails
            activeIndex={activeIndex!}
            works={works}
            onChange={setActiveIndex}
          />
        </Container>
      )}
    />
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  background-color: ${(p) => p.theme.colors.backgrounds.grey};
  flex: 1 1 auto;
`;

const Header = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const Body = styled.div`
  position: relative;
  flex: 1 1 auto;
`;

const BodyContent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
`;

const Buttons = styled.div`
  display: flex;
  position: relative;
  gap: 8px;
  align-items: center;
`;

const WorkContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const WorkImg = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const WorkImgA = styled.a`
  display: flex;
  max-width: 100%;
  max-height: 100%;

  &:focus {
    outline: 0 none;
  }
`;

const ArrowContainer = styled.div`
  flex: 0 0 80px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrowButton = styled(ActionButton)<{ $flip?: boolean }>`
  padding: 8px;
  transform: rotateY(${(p) => (p.$flip ? "180deg" : 0)});

  svg {
    width: 40px;
    height: 40px;
  }
`;

const WorkLink = styled.a`
  position: relative;
  text-decoration: none;
  background-color: transparent;
  padding: 1px 4px 1px 6px;
  display: flex;
  align-items: center;
  gap: 2px;
  border-radius: 6px;

  &:hover::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.service.hover};
  }

  &:focus-visible {
    outline: 3px solid ${(p) => p.theme.colors.service.focus};
    outline-offset: 0;
  }
`;

function mod(dividend: number, divider: number): number {
  return ((dividend % divider) + divider) % divider;
}
