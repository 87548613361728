import { format } from "date-fns/fp";
import styled from "styled-components";
import { ActionButton } from "../../../../components/ActionButton";
import { AvailabilityKindType } from "../../../../types";
import { CalendarMonth } from "../../../../utils/calendar";
import { DayView } from "./DayView";
import { DateRange } from "./types";

interface MonthViewProps {
  grid: CalendarMonth;
  ranges: DateRange[];
  selectionRange: [Date, Date] | null;
  availableRange: [Date | null, Date | null];
  onSelectionChange: (date: Date) => void;
  onSelectionStart: (date: Date) => void;
  onSelectionDone: () => void;
  onKindSelected: (kind: AvailabilityKindType) => void;
  onPrev?: () => void;
  onNext?: () => void;
}

export function MonthView({
  grid,
  ranges,
  selectionRange,
  availableRange,
  onSelectionChange,
  onSelectionStart,
  onSelectionDone,
  onKindSelected,
  onNext,
  onPrev,
}: MonthViewProps) {
  return (
    <Container>
      <TitleContainer>
        {onPrev && <PrevButton icon="left" onClick={onPrev} />}
        <Title>{format("LLLL yyyy", grid.date)}</Title>
        {onNext && <NextButton icon="left" onClick={onNext} />}
      </TitleContainer>
      <WeekdaysLine>
        {grid.weekdayNames.map((name, index) => (
          <Weekday key={index}>{name}</Weekday>
        ))}
      </WeekdaysLine>
      <DaysGrid>
        {grid.weeks.flatMap((week) =>
          week.days.map((day) =>
            day.currentMonth ? (
              <DayView
                key={day.dayOfYear}
                day={day}
                ranges={ranges}
                selectionRange={selectionRange}
                availableRange={availableRange}
                onSelectionDone={onSelectionDone}
                onSelectionChange={onSelectionChange}
                onSelectionStart={onSelectionStart}
                onKindSelected={onKindSelected}
              />
            ) : (
              <div key={day.dayOfYear} />
            )
          )
        )}
      </DaysGrid>
    </Container>
  );
}

const Container = styled.div`
  flex: 0 0 252px;
  width: 252px;
`;

const TitleContainer = styled.div`
  position: relative;
`;

const Title = styled.div`
  text-align: center;
  font-weight: bold;
  margin-bottom: 4px;
`;

const PrevButton = styled(ActionButton)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const NextButton = styled(ActionButton)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
`;

const WeekdaysLine = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const Weekday = styled.div`
  text-align: center;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DaysGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;
