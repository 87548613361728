import { Reference } from "@apollo/client";
import { useCallback, useLayoutEffect, useRef } from "react";
import styled from "styled-components/macro";
import {
  usePostChatMessageMutation,
  ChatDocument,
  ChatMessageFragmentDoc,
  Chat,
} from "../../graphql";
import { ChatMessageType } from "../../types";
import { ActionButton } from "../ActionButton";

interface SendFormProps {
  disabled: boolean;
  chatId: string;
  channel: string | null;
  replyToMessage: ChatMessageType | null;
  clearReplyToMessage: () => void;
  onInputFocus?: () => void;
  onInputBlur?: () => void;
  onSent?: () => void;
}

export function SendForm({
  disabled,
  chatId,
  channel,
  replyToMessage,
  clearReplyToMessage,
  onInputFocus,
  onInputBlur,
  onSent,
}: SendFormProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [postMessage, { loading }] = usePostChatMessageMutation();

  const handleSubmit = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      if (!inputRef.current) return;

      const message = inputRef.current.value;

      if (message.length === 0) return;

      inputRef.current.value = "";
      clearReplyToMessage();

      postMessage({
        variables: {
          input: {
            chatId,
            channel,
            replyTo: replyToMessage && replyToMessage.id,
            text: message,
          },
        },
        refetchQueries: [
          {
            query: ChatDocument,
            variables: {
              chatId,
            },
          },
        ],
        update(cache, { data }) {
          const message = data?.postChatMessage.message;
          if (!message) {
            return;
          }

          cache.modify({
            id: cache.identify({
              __typename: "Chat",
              id: chatId,
            } as Partial<Chat>),
            fields: {
              messages(existingMessageRefs = [], { readField }) {
                const newMessageRef = cache.writeFragment({
                  data: message,
                  fragment: ChatMessageFragmentDoc,
                  fragmentName: "ChatMessage",
                });

                const alreadyExists = existingMessageRefs.some(
                  (ref: Reference) => readField("id", ref) === message.id
                );

                return alreadyExists
                  ? existingMessageRefs
                  : [...existingMessageRefs, newMessageRef];
              },
            },
          });
        },
        onCompleted() {
          onSent?.();
        },
      });
    },
    [chatId, channel, postMessage, replyToMessage, clearReplyToMessage, onSent]
  );

  useLayoutEffect(() => {
    if (replyToMessage) {
      inputRef.current?.focus();
    }
  }, [replyToMessage]);

  return (
    <Form onSubmit={handleSubmit}>
      <InputZone $disabled={disabled}>
        {replyToMessage && (
          <ReplyToMessageRow>
            <ReplyToMessage>{replyToMessage.text}</ReplyToMessage>
            <ActionButton
              type="button"
              icon="cross"
              onClick={clearReplyToMessage}
            />
          </ReplyToMessageRow>
        )}
        <InputRow>
          <Input
            ref={inputRef}
            placeholder="Write a message..."
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            disabled={disabled}
          />
          <ActionButton icon="paperplane" disabled={disabled || loading} />
        </InputRow>
      </InputZone>
    </Form>
  );
}

const Form = styled.form`
  margin-top: -24px;
`;

const InputZone = styled.div<{ $disabled: boolean }>`
  background: ${(p) =>
    p.$disabled
      ? p.theme.colors.serviceBlended.white.hover
      : p.theme.colors.backgrounds.main};
  flex: 0 0 48px;
  border: 1px solid ${(p) => p.theme.colors.borders.separator};
  border-radius: 16px;
  box-sizing: border-box;
  padding: 3px 0px;
`;

const InputRow = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: text;
  padding: 6px 9px 6px 15px;
  box-sizing: border-box;
`;

const Input = styled.input`
  flex: 1 1 auto;
  border: 0 none;
  background: transparent;

  &:disabled {
    color: ${(p) => p.theme.colors.text.grey};
  }
`;

const ReplyToMessageRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 9px 6px 15px;
  box-sizing: border-box;
`;

const ReplyToMessage = styled.div`
  flex: 1 1 auto;
  border-left: 4px solid ${(p) => p.theme.colors.borders.separator};
  padding-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
