import { createGlobalStyle } from "styled-components/macro";
import { BODY_CLASS_OVERLAY_FLAG } from "./Overpage/Overpage";
import { typographyCSS } from "../theme";
import { Routing } from "./Routing";

export function App() {
  return (
    <>
      <GlobalStyle />
      <Routing />
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(p) => p.theme.colors.backgrounds.main};
    padding: 0;
    margin: 0;

    &.${BODY_CLASS_OVERLAY_FLAG} {
      overflow: hidden;
    }
  }

  body, button, input, textarea {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    ${(p) => typographyCSS(p.theme.typo.body1)};
    color: ${(p) => p.theme.colors.text.black};
  }

  h1 {
    font-family: 'Roboto', sans-serif;
    ${(p) => typographyCSS(p.theme.typo.h1)};
    color: ${(p) => p.theme.colors.text.black};
    margin: 0;
  }

  h2 {
    font-family: 'Roboto', sans-serif;
    ${(p) => typographyCSS(p.theme.typo.h2)};
    color: ${(p) => p.theme.colors.text.black};
    margin: 0;
  }

  h3 {
    font-family: 'Roboto', sans-serif;
    ${(p) => typographyCSS(p.theme.typo.h3)};
    color: ${(p) => p.theme.colors.text.black};
    margin: 0;
  }

  h4 {
    font-family: 'Roboto', sans-serif;
    ${(p) => typographyCSS(p.theme.typo.h4)};
    color: ${(p) => p.theme.colors.text.black};
    margin: 0;
  }

  a {
    color: inherit;
  }

  input {
    &:focus {
      outline: 0 none;
    }
  }

  em-emoji-picker {
    --color-border: ${(p) => p.theme.colors.backgrounds.grey};
    --font-family: 'Roboto', sans-serif;
    --font-size: 16px;
    --rgb-color: 35,32,41;
    --rgb-accent: 93,24,206;
    border: 1px solid ${(p) => p.theme.colors.backgrounds.grey};
    border-radius: 8px;
    box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25), 0px 4px 10px rgba(144, 143, 147, 0.25);
  }

  ::view-transition-old(root) {
    animation: none;
  }

  ::view-transition-new(root) {
    display: none;
  }
`;
